import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faSortAlphaDown, faSortAlphaUp, faSortNumericUp, faSortNumericDown } from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import classnames from "classnames";
library.add( faAngleDown, faAngleUp, faSortAlphaDown, faSortAlphaUp, faSortNumericUp, faSortNumericDown);

export default function SortHeader(props) {
  if (!props.sortCallback) {
      console.warn(
        "Add a callBack function property on the sort header that accepts field and direction params"
      );
  }
  if (!props.field) {
      console.warn(
        "Add a field property on the sort header that specifies the field"
      );
  }
  if (!props.displayName) {
      console.warn(
        "Add a displayName property on the sort header that specifies the field"
      );
  }

  function execCallback() {
    const dirString = props.sortDir || "asc";
    const callBackDir = props.sorted && dirString === "asc"
      ? "desc"
      : "asc";
      // dirString === "desc" ? "asc" : "desc";
    props.sortCallback(props.field, callBackDir);
  }

  const renderSpan = () => {
    const dir = props.sortDir || "asc";
    return (
      <span className={props.className ? props.className : 'sort-header'}
        title={props.customTitle ? props.customTitle : "Click to sort"}
        onClick={() => {
          if (props.spanOnly) {
            execCallback();
          }
        }}
      >
        {props.displayName} {props.sorted && dir === "asc" && <FontAwesomeIcon icon={props.isAlphabetic ? 'sort-alpha-down' : 'sort-numeric-down' }/>}
        {props.sorted && dir === "desc" && <FontAwesomeIcon icon={props.isAlphabetic ? "sort-alpha-up" : 'sort-numeric-up' }/>}
      </span>);
  };

  if (props.spanOnly)
    return renderSpan();
  return (<th onClick={() => {
      if (!props.spanOnly) {
        execCallback();
      }
    }} 
    className={classnames({'text-center': props.centered}, {size: props.size}, props.className)}
    style={{cursor: "pointer"}}
    sm={props.size}>
    {renderSpan()}
   </th>);
}