import React, {useState, useEffect, useCallback, useContext, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import DatePicker from 'react-datepicker';
import { confirmAlert } from "react-confirm-alert";
import { v4 as uuid } from "uuid";
import {
    Button, Table, Col, Row, ButtonGroup, ButtonDropdown,
    Badge, CardHeader, Card, DropdownItem,
    Dropdown, DropdownMenu, DropdownToggle, CardFooter, Modal, ModalHeader, ModalFooter, ModalBody, Input, Label, 
    Alert as RSAlert
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { over } from 'lodash';
import {
    Header,
    Footer,
    TextFilter,
    SelectFilter, FilterSwitch,
    ActiveToggleFilter,
    Loading,
    SortHeader,
    Pager,
    DateFilter, CancelInventoryModal, InventoryPriceModal, InventoryDiscountModal,
    InventoryStatusBadge, FollowupIcon, ResultHeader, FilterSearch, SellInventoryModal
} from "./";
import { constants, api } from "../utils";
import Select from "react-select";
import Alert from "react-s-alert-v3";
import {date_helpers, filter_helpers, ui_helpers} from '../helpers';
import {UserContext} from "../utils";
import {isNumber} from "lodash/lang";
import classnames from "classnames";
import {GrGlobe} from "react-icons/gr";
import download from '../assets/img/download.png';
import {MdMonetizationOn} from "react-icons/md";
const PATHNAMES = constants.PATH_NAMES;
const ICONS = constants.ICONS;

const S_IDS = constants.INVENTORY_STATUS_ID;
const NO_OVERRIDE_S_ID = constants.INVENTORY_STATUS_NOT_TO_OVERRIDE;
const FilterOptions = {
    ALL_OPEN: 0,
    IN_REVIEW: 2,
    ON_HOLD: 3,
    IN_STOCK: 4,
    PENDING: 5,
    WEB_SALE: 6,
    SOLD: 7,
    IN_PRODUCTION: 8,
    SAVED_FILTER: 9
};

const InventoryRow = ({i, selectedInventoryIds, currentUser, onViewInvoice, setDiscount, setEditPrice, onRemoveHold,
    setCancelInventoryId, setDiscountInventoryId, setPriceInventoryId, onMoveToStock, onDeleteInventory, 
    isSysAdmin, openModalCallback, onSelectInventoryId, setSaleInventoryId, setShowSaleModal, onSetPendingMove}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const invStatus = i.typeOfInventoryStatus; // i.resolvedTypeOfInventoryStatus;
  const isInventoryOwner = ui_helpers.isInventoryOwner(currentUser, i.manufacturerId, i.companyId);
  const isCurrentManufacturerUser = ui_helpers.isManufacturerUser(currentUser, i.manufacturerBrandId);
  const isCompanyAdmin = ui_helpers.isCompanyAdministrator(currentUser, i.companyId);
  const isMfgAdmin = ui_helpers.isManufacturerAdministrator(currentUser, i.manufacturerId);
  const canDispatchBeyondAStore = _.some(currentUser.roles, ro =>
          //(
            (ro.manufacturerId === i.manufacturerId || ro.companyId === i.rtoCompanyId || ro.companyId === i.companyId)
            && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator || ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)
          //)
          // || ((ro.dealerId === i.dealerId)
          //     && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
          // || ((ro.companyId === i.companyId)
          //     && (ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher))
        );
  const navPath = `${constants.PATH_NAMES.INVENTORY_VIEW}/${i.id}`;
  const isSelected = selectedInventoryIds.includes(i.id);
  const openInventoryItem = () => {
    history.push(navPath);
  };

  function renderPriceCell() {
    if (i.contractId) {
      return `${ui_helpers.formatCurrency(i.contractSalePrice)}`;
    } else if (i.originalPrice && i.isDiscounted) {
      return (
        <Col className='p-1'>
          <Row>
            <strike>{ui_helpers.formatCurrency(i.originalPrice)}</strike>
          </Row>
          <Row>
            <div style={{color: '#ff0000'}}>
              <strong>
                {ui_helpers.formatCurrency(i.retailPrice)}
              </strong>
            </div>
          </Row>
        </Col>);
    } else {
      return `${ui_helpers.formatCurrency(i.retailPrice)}`;
    }
  }

  let dropDownItems = [];
  if ((invStatus === S_IDS.InReview || invStatus === S_IDS.Hold) && (isSysAdmin || isCompanyAdmin)) {
    dropDownItems.push((<DropdownItem key="dd1" className='btn-inv-context' onClick={() => onDeleteInventory(i.id)}>Delete</DropdownItem>));
  }
  if ((invStatus === S_IDS.Hold || invStatus === S_IDS.InReview || invStatus === S_IDS.InStock)
        && (isSysAdmin || isCompanyAdmin || (isInventoryOwner && isCurrentManufacturerUser))) {
    dropDownItems.push((<Link to={`${PATHNAMES.EDIT_INVENTORY}/${i.id}`}><DropdownItem className='btn-inv-context'>Edit</DropdownItem></Link>));
  }
  if (invStatus === S_IDS.InReview && isInventoryOwner) {
    dropDownItems.push((<DropdownItem key="dd2" className='btn-inv-context' onClick={() => onMoveToStock(i.id)}>Move to Stock</DropdownItem>));
  }
  if ((invStatus === S_IDS.InStock)
    && (isSysAdmin || isCompanyAdmin || isInventoryOwner || isMfgAdmin || canDispatchBeyondAStore || i.currentUserIsResidualEditor)) {
    dropDownItems.push((<DropdownItem key="dd3" className='btn-inv-context' onClick={() => onSetPendingMove(i)}>Move to Store</DropdownItem>));
  }
  if ((invStatus === S_IDS.Hold && (isSysAdmin || isCompanyAdmin))) {
    dropDownItems.push((<DropdownItem key="on-remove-hold" className='btn-inv-context' onClick={() => onRemoveHold(i.id)}>Remove From Hold</DropdownItem>));
  }
  if ((invStatus !== S_IDS.Rented && invStatus !== S_IDS.Cancelled && invStatus !== S_IDS.PendingContract
      && (isSysAdmin || isCompanyAdmin))) {
    dropDownItems.push((<DropdownItem key="dd4" className='btn-inv-context' onClick={() => setCancelInventoryId(i.id)}>Cancel</DropdownItem>));
  }
  if ((i.billOfSaleAttachmentId && i.unitIsNew === true 
      && (isSysAdmin || isCompanyAdmin || isCurrentManufacturerUser))) {
    dropDownItems.push((<DropdownItem key="dd5" className='btn-inv-context' onClick={() => onViewInvoice(i.billOfSaleAttachmentId)}>View Wholesale Invoice</DropdownItem>));
  }
  if ((invStatus !== S_IDS.Cancelled && invStatus !== S_IDS.Rented)
      && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) {
    dropDownItems.push((<DropdownItem key="dd6" className='btn-inv-context' onClick={() => {
                                setDiscount(i.retailPrice);
                                setDiscountInventoryId(i.id);
                              }}>
                                Apply Discount
                              </DropdownItem>));
  }
  if (invStatus !== S_IDS.InReview && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) {
    dropDownItems.push((<DropdownItem key="dd7" className='btn-inv-context' onClick={() => {
                          setEditPrice(i.retailPrice);
                          setPriceInventoryId(i.id);
                        }}>
                          Edit Original Price
                        </DropdownItem>));
  }
  if ((invStatus !== S_IDS.InReview && invStatus !== S_IDS.Rented)
      && (isSysAdmin || isCompanyAdmin || isInventoryOwner)) {
    dropDownItems.push((<DropdownItem key="dd8" className='btn-inv-context' onClick={() => { 
                          // setIsMultiSale(false);
                          setSaleInventoryId(i.id);
                          setShowSaleModal(true); 
                        }}>
                          Sell
                        </DropdownItem>));
  }
  const daysAgo = date_helpers.daysAgoFromNow(date_helpers.formatDateToShortDate(i.inventoryOn));
  return (
    <tr className={classnames({selected: isSelected}, 'selectable-row')}>
      <td>
        <Label check>
          <Input type="checkbox" checked={isSelected} onChange={(e) => onSelectInventoryId(i.id)} />
        </Label>
      </td>
      <td onClick={openInventoryItem}>
        {i.dealerName}
        {i.resolvedOrderNote
          ? (<div className="text-muted">{i.resolvedOrderNote}</div>)
          : null
        }
      </td>
      <td onClick={openInventoryItem}>
        <Col>
          <div>
            {date_helpers.formatDateToShortDate(i.inventoryOn)}
          </div>
          <small>
            <div>
              {daysAgo < 0 ? `in ${daysAgo * -1} days` : null}
              {daysAgo > 0 ? `${daysAgo} day${(daysAgo === 1 ? '' : 's')} ago` : null}
            </div>
          </small>
        </Col>
      </td>
      <td onClick={openInventoryItem}>{`${i.resolvedWidthFeet}' W x ${i.resolvedLengthFeet}' L`}</td>
      <td onClick={openInventoryItem}>{i.unitManufacturerSubProductText}</td>
      <td onClick={openInventoryItem}>{`${i.unitManufacturerBaseColorText}/${i.unitManufacturerTrimColorText}/${i.unitManufacturerRoofColorText}`}</td>
      <td onClick={openInventoryItem}>{i.unitIsNew ? (<span className="text-new">New</span>) : (<span className="text-used">Used</span>)}</td>
      <td onClick={openInventoryItem}>
        {i.serialNumber}
        {i.orderNote
          ? <div className="text-muted">{i.orderNote}</div>
          : null
        }
        {i.webVisible
          ? <div className="float-end text-success" title="Published to the website"><GrGlobe style={{fontSize: "1.5rem"}} /></div>
          : null
        }
      </td>
      <td onClick={openInventoryItem}>{i.companyName}</td>
      <td onClick={openInventoryItem}>
        <div className="mb-1">
          <InventoryStatusBadge
            inventoryStatus={_.some(NO_OVERRIDE_S_ID, s => s === invStatus) ? invStatus : i.resolvedTypeOfInventoryStatus}
          />
        </div>
        <FollowupIcon id={i.id} followUpItem={constants.FOLLOW_UP.RTO} icon={ICONS.rto} done={i.followupStatus_LoadedToRTO}/>
      </td>
      <td onClick={() => i.contractId ? null : openInventoryItem(i.id)}>
        {i.contractId
          ? <Link className="btn btn-ghost" to={`${i.isRTOContract ? PATHNAMES.CONTRACT_VIEW : PATHNAMES.CASH_SALE_VIEW}/${i.contractId}`}>
              {i.contractId}
            </Link>
          : null
        }
      </td>
      <td onClick={openInventoryItem}>
        {i.invoiceNumber}
        {i.saleRequestCompanyId
          ? <div className="float-end text-dark" title="Sale proposed"><MdMonetizationOn style={{fontSize: "1.5rem"}} /></div>
          : null
        }
      </td>
      <td onClick={openInventoryItem}>
        {renderPriceCell()}
      </td>
      <td style={{overflow: 'visible', padding: 0}}>
        <Row className="mx-0">
          <Col className='d-flex'>
          {dropDownItems.length
            ? (<Dropdown
                direction="down"
                toggle={() => setOpen(!open)}
                isOpen={open}
                className='m-auto'
                size='sm'
              >
                <DropdownToggle caret={false} className="setting-btn">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8.00033" cy="3.83333" r="1.33333" fill="#2B3B63"/>
                  <circle cx="8.00033" cy="8.49996" r="1.33333" fill="#2B3B63"/>
                  <ellipse cx="8.00033" cy="13.1667" rx="1.33333" ry="1.33333" fill="#2B3B63"/>
                  </svg>
                </DropdownToggle>
                <DropdownMenu className='bg-primary' onMouseLeave={() => setOpen(!open)}>
                  {dropDownItems}
                </DropdownMenu>
              </Dropdown>)
            : null
          }
          </Col>
        </Row>
      </td>
      <td>
        <a href={navPath} target="_blank" title="Open in new tab" className="open-ext" rel="noreferrer">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_571_6911)">
          <path d="M12.7612 8.28584C12.3775 8.28584 12.0641 8.59919 12.0641 8.98288V12.3594C12.0641 13.1332 11.4374 13.7599 10.6636 13.7599H4.12806C3.35428 13.7599 2.72758 13.1332 2.72758 12.3594V5.81742C2.72758 5.04363 3.35428 4.41694 4.12806 4.41694H7.39585C7.77954 4.41694 8.09289 4.10359 8.09289 3.71989C8.09289 3.3362 7.77954 3.02285 7.39585 3.02285H4.12806C2.58689 3.01646 1.3335 4.27625 1.3335 5.81742V12.3658C1.3335 13.9069 2.58689 15.1667 4.12806 15.1667H10.6636C12.2048 15.1667 13.4582 13.9133 13.4582 12.3658V8.98928C13.4582 8.60558 13.1448 8.29224 12.7612 8.29224V8.28584Z" fill="#2B3B63"/>
          <path d="M14.6091 2.26823C14.5388 2.10196 14.4045 1.96127 14.2318 1.89093C14.1423 1.85256 14.0527 1.83337 13.9632 1.83337H9.57632C9.19263 1.83337 8.87928 2.14672 8.87928 2.53042C8.87928 2.91411 9.19263 3.22746 9.57632 3.22746H12.275L7.74099 7.76783C7.4724 8.04281 7.4724 8.48405 7.74099 8.75264C7.87528 8.88693 8.04794 8.95727 8.2334 8.95727C8.41885 8.95727 8.59151 8.88693 8.7258 8.75264L13.2534 4.21227V6.77022C13.2534 7.15392 13.5667 7.46727 13.9504 7.46727C14.3341 7.46727 14.6475 7.15392 14.6475 6.77022V2.53042C14.6475 2.44089 14.6283 2.35136 14.5899 2.26183L14.6091 2.26823Z" fill="#2B3B63"/>
          </g>
          <defs>
          <clipPath id="clip0_571_6911">
          <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.3335 1.83337)"/>
          </clipPath>
          </defs>
          </svg>
        </a>
      </td>
  </tr>);
}

export default function Inventory(props) {
const [statusResults, setStatusResults] = useState([]);
const [filters, setFilters] = useState([]);
const [clear, setClear] = useState(false);
const [showPayModal, setShowPayModal] = useState(false);
const [showSaleModal, setShowSaleModal] = useState(false);
const [checkNo, setCheckNo] = useState('');
const [comment, setComment] = useState('');
const [cancelInventoryId, setCancelInventoryId] = useState(null);
const [priceInventoryId, setPriceInventoryId] = useState(null);
const [editPrice, setEditPrice] = useState(0);
const [discountInventoryId, setDiscountInventoryId] = useState(null);
const [discount, setDiscount] = useState(0);
const [cancelComment, setCancelComment] = useState('');
const [paidOn, setPaidOn] = useState(moment());
const [canClear, setCanClear] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [loadingPayload, setLoadingPayload] = useState(null);
const [loadedPayload, setLoadedPayload] = useState(null);
const [paginatedList, setPaginatedList] = useState({});
const [totalPages, setTotalPages] = useState(0);
const [sortDirection, setSortDirection] = useState('desc');
const [totalRetailPrice, setTotalRetailPrice] = useState(0);
const [sortColumn, setSortColumn] = useState('Age');
const [companies, setCompanies] = useState([]);
const [manufacturers, setManufacturers] = useState([]);
const [dealers, setDealers] = useState([]);
const [dealerMoveList, setDealerMoveList] = useState([]);
const [pendingMoveInventory, setPendingMoveInventory] = useState(null);
const [users, setUsers] = useState([]);
const [filterCounts, setFilterCounts] = useState({});
const [filtersLoaded, setFiltersLoaded] = useState(false);
const [message, setMessage] = useState({flavor: constants.flavor.success, message: ''});
const [newOrUsedSelection, setNewOrUsedSelection] = useState(null);
const [selectAllInventory, setSelectAllInventory] = useState(false);
const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
const [pendingSaleList, setPendingSaleList] = useState([]);
const [selectedMenuOpen, setSelectedMenuOpen] = useState(false);
const [showMoveModal, setShowMoveModal] = useState(false);
// const [showAssignModal, setShowAssignModal] = useState(false);
const {currentUser} = useContext(UserContext);
const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(currentUser);
const mfgUserWithBuildFeature = ui_helpers.isAnyMfgUserWithBuildFeature(currentUser);
const userCanCreateInventory = isSysAdmin || isCompanyAdmin || ui_helpers.isAnyInventoryOwner(currentUser);
const [saleInventoryId, setSaleInventoryId] = useState(null);
// const [isMultiSale, setIsMultiSale] = useState(false);
const [selectedFilterOption, setSelectedFilterOption] = useState(FilterOptions.ALL_OPEN);
const [moveToDealer, setMoveToDealer] = useState(null);
const [assignToDriver, setAssignToDriver] = useState(null);
const [totalBadgeClass, setTotalBadgeClass] = useState('bg-dark');
const [saving, setSaving] = useState(false);
const [errorMessage, setErrorMessage] = useState(null);
const [dataReady, setDataReady] = useState(false);
const [wholesaleItems, setWholesaleItems] = useState([]);
const [isQuickFilter, setIsQuickFilter] = useState(true);
const [lastSearch, setLastSearch] = useState(null);
const [pristineLastSearch, setPristineLastSearch] = useState(null);
const [inventoryStatusList, setInventoryStatusList] = useState(
  ui_helpers.idNameToValueLabel(constants.INVENTORY_STATUS_NAME));

document.title = `Inventory - ${constants.appName}`;

  useEffect(() => {
    if (filtersLoaded) return;
    api
      .fetch('inventory/GetInventoryFilters')
      .then(r => {
        const { dealerSelect, companySelect, manufacturerSelect, inUsers, quickFilterCounts, userSavedSearch, initialData } = r.data.data;
        companySelect.unshift({ label: '[Any Company]', value: 0 });
        manufacturerSelect.unshift({ label: '[Any Manufacturer]', value: 0 });
        dealerSelect.unshift({ label: '[Any Store]', value: 0 });
        inUsers.unshift({ label: '[Any User]', value: 0 });
        setDealers(dealerSelect);
        setCompanies(companySelect);
        setManufacturers(manufacturerSelect);
        setUsers(inUsers);
        setFilterCounts(quickFilterCounts);
        if (userSavedSearch) {
          const savedSearch = JSON.parse(userSavedSearch.serializedSearch);
          const sortDetails = savedSearch.resolvedSort;
          setPageNumber(sortDetails.page);
          setSortColumn(sortDetails.sortColumn);
          const sortDir = sortDetails.sortAscending ? "asc" : "desc";
          setSortDirection(sortDir);
          setLastSearch(userSavedSearch.serializedSearch);
          setPristineLastSearch(userSavedSearch.serializedSearch);
          setLoadedPayload(
            resolvePayload(sortDetails.page, savedSearch.filters, sortDetails.sortColumn, sortDir));
          const defaultNewOrUsed = ui_helpers.resolveNewOrUsedSelectionFromFilters(savedSearch.filters);
          setNewOrUsedSelection(defaultNewOrUsed);
          setFilters(savedSearch.filters);
          setPaginatedList(initialData);
          setTotalRetailPrice(initialData.totalPrice);
          if (savedSearch.quickFilterKey) {
            setSelectedFilterOption(savedSearch.quickFilterKey);
            setIsQuickFilter(true);
            setPageNumber(1);
          } else {
            setSelectedFilterOption(FilterOptions.SAVED_FILTER);
          }
        } else {
          setFilters([{filterName: 'OpenOnly', value: true}]);
          setSelectedFilterOption(FilterOptions.ALL_OPEN);          
        }
        setTotalBadgeClass('bg-dark');
      })
      .catch((error) => {
        setMessage({ flavor: 'danger', message: 'Error when trying to get filter data: ', error });
      })
      .finally(() => setFiltersLoaded(true));
  }, [])

  useEffect(getTotalPageCount, [paginatedList]);

  useEffect(() => {
    if (!pendingMoveInventory) return;
    api.fetch(`Manufacturer/GetManufacturerDealerList/${pendingMoveInventory.manufacturerId}?activeOnly=true`).then((response) => {
      if (response && response.data && response.data.success) {
        setDealerMoveList(
          _.map(
            _.reject(response.data.data, x => x.deactivatedAt), 
              x => ({value: x.dealerId, label: x.dealerName})));
      } else {
        Alert.error('Failed to load dealer list: ' + response.data.message);
      }
    }).catch(error => console.error(error));
  }, [pendingMoveInventory]);
  
  function resolvePayload(pPageNumber = null, pFilters = null, sortCol = null, sortDir = null) {
    let payload = {
      ...resolveSort(sortCol, sortDir),
      page: pPageNumber || pageNumber
    };
    const filterList = pFilters ? pFilters : filters;
    _.each(filterList, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData(forceReload = false) {
    const payload = resolvePayload();
    if (_.isEqual(payload, loadedPayload) && !forceReload) {
      return;
    }
    setLoadingPayload(payload);
    api.post('Inventory/FindInventory', payload).then((response) => {
      if (response.data.success) {
        setPaginatedList(response.data.message);
        setTotalRetailPrice(response.data.message.totalPrice);
        setSelectedInventoryIds([]);
      }
    }).catch(error => {
      setMessage({flavor: 'danger', message: 'Error when trying to refresh data: ', error});
      console.error(error);
    }).finally(() => {
      if (filtersLoaded) {
        setLastSearch(
          filter_helpers.storeSaveSearch(
            filters, resolveSort(), pageNumber, 
              (isQuickFilter ? selectedFilterOption : null)));
      }
      setLoadedPayload(payload);
      setLoadingPayload(null);
    });
  }

  useEffect(() => {
    if (!filtersLoaded) return;
    refreshData();
  }, [filtersLoaded, pageNumber, sortColumn, sortDirection, filters])

  useEffect(() => {
    if (!filtersLoaded || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
    filter_helpers.saveSearch(
      constants.SEARCH_AREAS.INVENTORY, 
      lastSearch
    );
    setPristineLastSearch(lastSearch);
  }, [pristineLastSearch, lastSearch])

  function getTotalPageCount() {
    if (paginatedList && isNumber(paginatedList.totalItems) && isNumber(paginatedList.pageSize)) {
      setTotalPages(Math.ceil(paginatedList.totalItems / paginatedList.pageSize));
    }
  }

  // function commitAssignDriver() {
  //   if (!assignToDriver) {
  //     Alert.warning('Either select a driver or click Cancel');
  //     return;
  //   }
  //   api.post('Inventory/AssignListToDriver', {
  //     idList: selectedInventoryIds,
  //     driverId: assignToDriver.value
  //   }).then((response) => {
  //     if (response.data.success) {
  //       setShowAssignModal(false);
  //       setSelectedInventoryIds([]);
  //     } else {
  //       Alert.error(response.data.message);
  //     }
  //   }).catch(error => {
  //     console.error(error);
  //     setMessage({flavor: 'danger', message: 'Error when trying to move inventory: ', error});
  //   });
  // }

  function commitMoveSelected() {
    if (!moveToDealer) {
      Alert('Either select a destination store or click Cancel');
      return;
    }
    if (!pendingMoveInventory) {
      console.error('missing pending move inventory');
      return;
    }
    api.post('Inventory/MoveInventory', {
      idList: [pendingMoveInventory.id],
      dealerId: moveToDealer.value
    }).then((response) => {
      if (response.data.success) {
        const changedIndex = _.findIndex(paginatedList.dataSource, x => x.id === pendingMoveInventory.id);
        let newDataSource = paginatedList.dataSource.slice();
        newDataSource[changedIndex] = {
          ...newDataSource[changedIndex],
          dealerId: moveToDealer.value,
          dealerName: moveToDealer.label
        };
        setShowMoveModal(false);
        setPendingMoveInventory(null);
        setPaginatedList({
          ...paginatedList,
          dataSource: newDataSource
        });
        // refreshData(true);
      } else {
        Alert.error(response.data.message);
      }
    }).catch(error => {
      console.error(error);
      setMessage({flavor: 'danger', message: 'Error when trying to move inventory: ', error});
    });
  }

  function onViewInvoice(billOfSaleAttachmentId) {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/ViewBillOfSale', {id: billOfSaleAttachmentId}, pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error downloading the document");
    });
  }

  function onMoveToStock(inventoryId) {
    confirmAlert({
      title: "Move Inventory to In-Stock",
      message: "Are you sure you want to inventory to In-Stock?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Inventory/Approve", {id: inventoryId, comment: "", paidOn: new Date(), checkNo: ""}).then(r => {
              if (r.data.success) {
                refreshData(true);
              } else {
                Alert.warning(r.data.message);
              }
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onDeleteInventory(inventoryId) {
    confirmAlert({
      title: "Delete this Inventory Item?",
      message: "Are you sure you'd like to permanently and irreversibly delete this inventory?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Inventory/Delete", {id: inventoryId}).then(r => {
              if (!r.data.success) return;
              refreshData(true);
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function handleNewOrUsedSelection(selection) {
    let filterChanges = [];
    if (selection.label === 'New Or Used')
    {
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly' || x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only New')
    {
      selection.filterName = 'NewOnly'
      selection.value = true;
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only Used')
    {
      selection.filterName = 'UsedOnly'
      selection.value = true;
      filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    const newSelection = ui_helpers.resolveNewOrUsedSelectionFromFilters(filterChanges);
    setNewOrUsedSelection(newSelection);
  }

  function cancelInventory() {
    api.post("Inventory/Cancel", { id: cancelInventoryId, comment: cancelComment }).then(r => {
      if(!r.data.success) return;
      setCancelInventoryId(null);
      refreshData(true);
    });
  }

  function applyDiscount() {
    api.post("Inventory/ApplyDiscount", { id: discountInventoryId, price: parseFloat(ui_helpers.cleanseCurrencyCharacters(discount)) }).then(r => {
      if (r.data.success) {
        setDiscount(0);
        setDiscountInventoryId(null);
        refreshData(true);
      } else {
        Alert.warning(r.data.message);
      }
    });
  }

  function editOriginalPrice() {
    api.post(`Inventory/UpdateInventoryOriginalPrice`, { id: priceInventoryId, price: parseFloat(ui_helpers.cleanseCurrencyCharacters(editPrice)) }).then(r => {
      if(!r.data.success) return;
      setPriceInventoryId(null);
      refreshData(true);
    });
  }

  async function getDrivers() {
    return api.fetch('user/GetDrivers').then((r) => {
      if (r.data)
        return { 
          inDrivers: ui_helpers.idNameToValueLabel(r.data.data, null, false)
        };
    });
  }

  async function getUsers() {
    return api.fetch('user/GetAllActiveUsers').then((r) => {
      if (r.data)
        return { inUsers: ui_helpers.userIdNameToValueLabel(r.data.data) };
    });
  }

  function resolveSort(pSortColumn, pSortDirection) {
    return {
      sortColumn: (pSortColumn || sortColumn),
      sortAscending: ((pSortDirection || sortDirection) === 'asc')
    };
  }

  function findCSV() {
    if (loadingPayload) return;
    const payload = resolvePayload();
    setLoadingPayload(payload);
    const pdfWindow = window.open();
    api.downloadAndOpenFile('Inventory/FindCSV', payload, pdfWindow, (e) => {
        pdfWindow.close();
        setMessage({flavor: 'danger', message: 'Error when trying to export CSV data: ' + e});
      }, constants.MIME_XLSX, () => setLoadingPayload(null));
  }

  function onFilterChange(changedFilter) {
    let filterChanges = filter_helpers.getFilters(filters, changedFilter);
    if (isQuickFilter) {
      // clear prior filters if we were in a quick filter mode as we're starting fresh
      setIsQuickFilter(false);
      filterChanges = filter_helpers.getFilters([], changedFilter);
    }
    setPageNumber(1);
    setCanClear(filterChanges.length > 0);
    setFilters(filterChanges);
  }

  function doSort(field, direction){
    setSortColumn(field);
    setSortDirection(direction);
  }

  function onSelectInventoryId(iId) {
    let newList = selectedInventoryIds.slice();
    if (newList.includes(iId)) {
      newList = _.reject(newList, x => x === iId);
    } else {
      newList.push(iId);
    }
    setSelectedInventoryIds(newList);
  }

  function onSelectAllToggle() {
    const newValue = !selectAllInventory;
    setSelectAllInventory(newValue);
    if (newValue) {
      setSelectedInventoryIds(
        _.map(paginatedList.dataSource, x => x.id));
    } else {
      setSelectedInventoryIds([]);
    }
  }

    function onPaySelected() {
      if(saving) return;
      setSaving(true);
      api.post('Inventory/SetPayMultiple', { idList: selectedInventoryIds, checkNo: checkNo, paidOn: paidOn, comment: comment }).then(r => {
      if (r.data.success) {
        setCheckNo('');
        setComment('');
        setPaidOn(moment());
        setPendingSaleList([]);
        setShowPayModal(false);
        setErrorMessage(null);
        refreshData(true);
      } else {
        setErrorMessage({message: r.data.message, flavor: 'danger'});
      }
      })
      .finally(()=>setSaving(false));
  }

  function onDenySelected() {
    if (saving) return;
    setSaving(true);
    api.post('Inventory/DenySaleMulti', { idList: selectedInventoryIds, checkNo: checkNo, paidOn: paidOn, comment: 'Multiple Denial' }).then(r => {
      // partial success/failure possible here so always refresh
      refreshData(true);
      if (r.data.success) {
        setCheckNo('');
        setComment('');
        setPaidOn(moment());
        setPendingSaleList([]);
        setShowPayModal(false);
        setErrorMessage(null)
      } else {
        setErrorMessage({message: r.data.message, flavor: 'danger'});
      }
    }).finally(() => setSaving(false));
  }

  function onRemoveHoldSelected() {
    api.post("Inventory/RemoveHoldMultiple", { idList: selectedInventoryIds }).then(r => {
      // partial success/failure possible here so always refresh
      refreshData(true);
      if (!r.data.success) {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    });
  }

  function onRemoveHold(invId) {
    api.post("Inventory/RemoveHold", {id: invId}).then(r => {
      if (r.data.success) {
        refreshData(true);
      } else {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    });
  }

  function onHoldSelected() {
    api.post('Inventory/SetHoldMultiple', { idList: selectedInventoryIds }).then(r => {
      // partial success/failure possible here so always refresh
      refreshData(true);
      if (!r.data.success) {
        setMessage({message: r.data.message, flavor: 'danger'});
      }
    });
  }

  function onMoveToStock() {
    confirmAlert({
      title: "Move Inventory to In-Stock",
      message: `Are you sure you want to move all ${selectedInventoryIds.length} selected inventory items to In-Stock (if they are not already in stock)?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post("Inventory/Approve", {idList: selectedInventoryIds, comment: "", paidOn: new Date(), checkNo: ""}).then(r => {
              refreshData(true);
              if (r.data.success) {
              } else {
                setMessage({message: r.data.message, flavor: 'danger'});
              }
            });
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function getPendingSaleWholesaleSum() {
    return _.sum(_.map(pendingSaleList, w => w.purchaseAmount)) 
      + _.sum(_.map(wholesaleItems, w => w.wholesalePrice))
  }

  const clearFilters = () => {
    setFilters([]);
    setClear(!clear);
    setLoadedPayload(null);
  };

  function onSetPendingMove(inv) {
    setPendingMoveInventory(inv);
    setShowMoveModal(true);
  }

  function cancelShowApproveModal() {
      setCheckNo('');
      setComment('');
      setPaidOn(moment());
      setShowPayModal(false);
      setPendingSaleList([]);
      setErrorMessage(null)
  }

  function onShowPayModal(){
    api.post("Inventory/GetInventoryPendingSaleRecordList", { UnitInventoryIdList: selectedInventoryIds }).then(res => {
      if (res && res.data && res.data.success) {
        let records = res.data.message;
        let wholesaleItems = _.filter(paginatedList.dataSource, x => selectedInventoryIds.includes(x.id));
        if (records.length) {
          records = _.map(records, r => {
              let item = _.find(wholesaleItems, i => { return i.id === r.unitInventoryId });
              if (item !== undefined) {
                  r.serialNumber = item.serialNumber;
                  r.resolvedWidthFeet = item.resolvedWidthFeet;
                  r.resolvedLengthFeet = item.resolvedLengthFeet;
                  r.retailPrice = item.retailPrice;
              };
              return r;
          });
        }    
        let ids = _.map(records, r =>{ return r.unitInventoryId});
        wholesaleItems = _.filter(wholesaleItems, w => !ids.includes(w.id));
        setWholesaleItems(wholesaleItems);
        setPendingSaleList(records);
        setShowPayModal(true);
      }
    })
    .catch((error) => {
      setMessage({flavor: 'danger', message: 'Error when trying to get show pay data: ', error});
    });
  }

  function onApplyQuickFilter(changedFilter, filterOption, badgeClass) {
    setIsQuickFilter(true);
    setPageNumber(1);
    setFilters(
      filter_helpers.getFilters([], changedFilter));
    setSelectedFilterOption(filterOption);
    setTotalBadgeClass(badgeClass);
  }

  function getSelectedSellableItems() {
    if (saleInventoryId) {
      return _.filter(paginatedList.dataSource, x => 
        saleInventoryId === x.id
          && (isSysAdmin 
            || _.some(currentUser.roles, ro => (ro.companyId === x.companyId)
                && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager))
            || ui_helpers.isInventoryOwner(currentUser, x.manufacturerId, x.companyId)));
    }
    return _.filter(paginatedList.dataSource, x => 
      selectedInventoryIds.includes(x.id)
        && (isSysAdmin 
          || _.some(currentUser.roles, ro => (ro.companyId === x.companyId)
              && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager))
          || ui_helpers.isInventoryOwner(currentUser, x.manufacturerId, x.companyId)));
  }

  function resolveWebVisible() {
    const item = _.find(paginatedList.dataSource, x => x.id === discountInventoryId);
    return item
      ? item.webVisible
      : false;
  }

  return (
    <>    
      <Header inventory></Header>
      <div className="inner">
        <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-inline-block group-btn-2" style={{padding: "2px"}}>
          {userCanCreateInventory
            ? (<Button
                color="orange"
                className="text-light"
                tag={Link}
                to={`${PATHNAMES.NEW_INVENTORY}/0`}
              >
                <FontAwesomeIcon icon='plus' /> Add New
              </Button>)
            : null}
          {isCompanyAdmin || isSysAdmin
            ? (<Button
                tag={Link}
                color="dark"
                to={PATHNAMES.INVENTORY_SALES}
                className="text-light"
              >
                B2B Archive
              </Button>)
            : null
          }
        </div>
      {loadingPayload || !Object.keys(filterCounts).length
        ? null
        : (<div className="float-end me-0 align-items-center filter-buttons inventory-filter" style={{padding: "2px"}}>
            <Button className={classnames({"btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.ALL_OPEN})}
              onClick={() => onApplyQuickFilter({filterName: 'OpenOnly', value: true}, FilterOptions.ALL_OPEN, null)}>
                All Open {filterCounts && paginatedList.dataSource ? `(${filterCounts['open']})` : null}
            </Button>
            {mfgUserWithBuildFeature &&
              <Button className={classnames({ "btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.IN_PRODUCTION })}
                onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.InProduction]}, FilterOptions.IN_PRODUCTION, null)}>
                In Production {filterCounts && paginatedList.dataSource ? `(${filterCounts['in-production']})` : null}
              </Button>
            }
            <Button className={classnames({"btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.IN_REVIEW})}
              onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.InReview]}, FilterOptions.IN_REVIEW, null)}>
              In Review {filterCounts && paginatedList.dataSource ? `(${filterCounts['in-review']})` : null}
            </Button>
            <Button className={classnames({"btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.ON_HOLD})}
              onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.Hold]}, FilterOptions.ON_HOLD, null)}>
                On Hold {filterCounts && paginatedList.dataSource ? `(${filterCounts['on-hold']})` : null}
            </Button>
            <Button className={classnames({"btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.IN_STOCK})}
              onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.InStock]}, FilterOptions.IN_STOCK, null)}>
                In Stock {filterCounts && paginatedList.dataSource ? `(${filterCounts['in-stock']})` : null}
            </Button>
            <Button className={classnames({"btn shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.PENDING})}
              onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.PendingContract]}, FilterOptions.PENDING, null)}>
                Pending Contract {filterCounts && paginatedList.dataSource ? `(${filterCounts['pending-contract']})` : null}
            </Button>
            <Button className={classnames({"shedpro-btn-tb": true, 'btn-tb-active': selectedFilterOption === FilterOptions.SOLD})}
              onClick={() => onApplyQuickFilter({filterName: 'typeOfInventoryStatusList', value: [constants.INVENTORY_STATUS_ID.Sold]}, FilterOptions.SOLD, null)}>
                Sold {filterCounts && paginatedList.dataSource ? `(${filterCounts['sold']})` : null}
            </Button>
{/*            <Button className={classnames({"btn btn-tb-8": true, 'btn-tb-active': selectedFilterOption === FilterOptions.WEB_SALE})}
              onClick={() => onApplyQuickFilter({filterName: 'webVisible', value: true}, FilterOptions.WEB_SALE, null)}>
                Web Sale {filterCounts && paginatedList.dataSource ? `(${filterCounts['web-sale']})` : null}
            </Button>
*/}
       </div>)}
      
      {message.message 
        ? (<RSAlert color={message.flavor} toggle={() => setMessage(null)}>{message.message}</RSAlert>) 
        : null
      }
      {cancelInventoryId
        ? <CancelInventoryModal
            setShowCancelModal={() => setCancelInventoryId(null)}
            cancelComment={cancelComment}
            setCancelComment={setCancelComment}
            cancelInventory={cancelInventory}
          />
        : null
      }
      {discountInventoryId
        ? (<InventoryDiscountModal
            setShowDiscountModal={() => setDiscountInventoryId(null)}
            discount={discount}
            setDiscount={setDiscount}
            applyDiscount={applyDiscount}
            isWebVisible={resolveWebVisible()}
          />)
        : null
      }
      {priceInventoryId
        ? (<InventoryPriceModal
            setShowPriceModal={() => setPriceInventoryId(null)}
            editPrice={editPrice}
            setEditPrice={setEditPrice}
            editOriginalPrice={editOriginalPrice}
          />)
        : null
      }
      {showMoveModal && pendingMoveInventory
        ? (<Modal fullscreen="sm" toggle={() => setShowMoveModal(false)} isOpen>
            <ModalHeader>
              Move inventory to...
            </ModalHeader>
            <ModalBody>
              <Row className="mb-2">
                <Col xs="3">
                  <Label>Store</Label>
                </Col>
                <Col xs="9">
                  <Select
                    options={_.reject(dealerMoveList, d => d.value <= 0 || d.value === pendingMoveInventory.dealerId)}
                    value={moveToDealer}
                    onChange={(e) => setMoveToDealer(e)}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup className="float-end">
                    <Button color='primary' onClick={commitMoveSelected}>Move</Button>
                    <Button onClick={() => setShowMoveModal(false)}>Cancel</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
      {showPayModal
        ? (<Modal fullscreen="md" size="lg"  toggle={() => cancelShowApproveModal()} isOpen>
            <ModalHeader>
              Provide Payment Details {saving && <FontAwesomeIcon icon="spinner" className={"ml-2 text-primary float-end"} spin />}
            </ModalHeader>
            <ModalBody>
              {errorMessage
                ? (<RSAlert color={errorMessage.flavor} toggle={() => setErrorMessage(null)}>{errorMessage.message}</RSAlert>)
                : null
              }
              <Row>
                  <Col xs="3">Sale Item(s)</Col>
                  <Col>
                      <Table>
                          <thead>
                              <tr>
                                  <th>Description</th>
                                  <th>Price</th>
                                  <th>Sale Term</th>
                              </tr>
                          </thead>
                          <tbody>
                              {pendingSaleList.length ? _.map(pendingSaleList, w => (
                                  <tr>
                                      <td>{w.serialNumber}
                                          <br /><span className="small-description-text">{`${w.resolvedWidthFeet}' W x ${w.resolvedLengthFeet}' L`}</span>
                                          {w.purchasedFromCompanyId && <>
                                              <br /><span className="small-description-text">
                                                  Sold By: {w.purchasedFromCompanyName}
                                              </span>
                                          </>}
                                      </td>
                                      <td>
                                          <div>{ui_helpers.formatCurrency(w.purchaseAmount)}</div>
                                          <div  className="small-description-text">Retail: {ui_helpers.formatCurrency(w.retailPrice)}</div>
                                          {w.discountDisplay === "" ? null : <div className="small-description-text">{w.discountDisplay} Discount</div>}
                                      </td>
                                      <td>
                                          {w.saleTermsName}
                                      </td>
                                  </tr>
                              )) : null}
                              {wholesaleItems.length ? _.map(wholesaleItems, w => (
                                  <tr>
                                      <td>{w.serialNumber}
                                          <br /><span className="small-description-text">{`${w.resolvedWidthFeet}' W x ${w.resolvedLengthFeet}' L`}</span>
                                      </td>
                                      <td>
                                          <div>{ui_helpers.formatCurrency(w.wholesalePrice)}</div>
                                          <div  className="small-description-text">Retail: {ui_helpers.formatCurrency(w.retailPrice)}</div>
                                          {w.wholesaleDiscountPercentDisplay === "" ? null : <div className="small-description-text">{w.wholesaleDiscountPercentDisplay} Discount</div>}
                                      </td>
                                      <td>
                                          {'-'}
                                      </td>
                                  </tr>
                              )): null}
                          </tbody>
                          <tfoot>
                              <tr>
                                  <td />
                                  <td />
                                  <td>
                                      {ui_helpers.formatCurrency(_.sum(_.map(pendingSaleList, w => w.purchaseAmount)) + _.sum(_.map(wholesaleItems, w => w.wholesalePrice)))}
                                  </td>
                              </tr>
                          </tfoot>
                      </Table>
                  </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <Label>Paying (Wholesale)</Label>
                </Col>
                <Col xs="7">
                  <Input type='text' value={ui_helpers.formatCurrency(getPendingSaleWholesaleSum())} readOnly disabled />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <Label>Paid On</Label>
                </Col>
                <Col xs="7">
                  <DatePicker
                    selected={paidOn ? paidOn.toDate() : null}
                    onChange={(date) => setPaidOn(moment(date))}
                    showMonthDropdown
                    showYearDropdown
                    //withPortal
                    dropdownMode="select"
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <Label>Check #</Label>
                </Col>
                <Col xs="7">
                  <Input type='text' maxLength="20" value={checkNo} onChange={(e) => setCheckNo(e.target.value)} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <Label>Comment</Label>
                </Col>
                <Col xs="7">
                  <Input type='textarea' maxLength="800" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="(Optional)" />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup className="float-end">
                    {!pendingSaleList.length ? null :
                      <Button title="Deny Sale" color='danger' className="text-light" onClick={onDenySelected} disabled={!pendingSaleList.length || saving}>No Thank You</Button>
                    }
                    <Button color='primary' onClick={onPaySelected} disabled={saving}>Pay</Button>
                    <Button onClick={() => cancelShowApproveModal()} disabled={saving}>Close</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
    }
    {showSaleModal && (
      <SellInventoryModal
        toggle={() => { 
          setSaleInventoryId(null); 
          setShowSaleModal(false); 
        }}
        inventoryId={saleInventoryId}
        inventoryIdList={selectedInventoryIds}
        wholesaleItems={getSelectedSellableItems()}
        multi
        callback={() => refreshData(true)}
      />
    )}
    <FilterSearch heading="Inventory" filters={filters} onClear={clearFilters}>
      <Row>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='CompanyId'
            displayName='Company'
            value={filter_helpers.get_value('CompanyId', filters)}
            clear={clear}
            options={companies}
            isSingleSelect={true}
            isDisabled={!companies}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Company]'}
          />
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='DealerId'
            displayName='Store'
            value={filter_helpers.get_value('DealerId', filters)}
            clear={clear}
            options={dealers}
            isSingleSelect={true}
            isDisabled={!dealers}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Store]'}/>
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='ManufacturerId'
            displayName='Manufacturer'
            value={filter_helpers.get_value('ManufacturerId', filters)}
            clear={clear}
            options={manufacturers}
            isSingleSelect={true}
            isDisabled={!manufacturers}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Manufacturer]'}/>
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='typeOfInventoryStatusList'
            displayName='Status(es)'
            value={filter_helpers.get_value('typeOfInventoryStatusList', filters)}
            clear={clear}
            options={inventoryStatusList}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Status]'}/>
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='RelatedToUserId'
            displayName='Related to'
            value={filter_helpers.get_value('RelatedToUserId', filters)}
            clear={clear}
            options={users}
            isSingleSelect={true}
            isDisabled={!users}
            onChangeCallback={onFilterChange}
            placeholder={'[Any User]'}/>
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='newOrUsed'
            displayName='New Or Used'
            value={newOrUsedSelection}
            clear={clear}
            options={constants.NEW_OR_USED_OPTIONS}
            isSingleSelect
            onChangeCallback={handleNewOrUsedSelection}
            // placeholder={'New or Used'}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='CustomerName'
            displayName='Customer Name'
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('CustomerName', filters)}
            debounceTimeout={2000}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='SerialNumber'
            displayName='Serial Number'
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('SerialNumber', filters)}
            debounceTimeout={2000}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='ModelNumber'
            displayName='RTO ID'
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('ModelNumber', filters)}
            debounceTimeout={2000}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='InvoiceNumber'
            displayName='Invoice #'
            descriptionPrefix="is"
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('InvoiceNumber', filters)}
            debounceTimeout={2000}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='ContractId'
            displayName='Contract #'
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('ContractId', filters)}
            debounceTimeout={2000}
          />
        </Col>
        <Col lg='2' sm='3'>
          <FilterSwitch
            filterName="OpenOnly"
            displayName="Open Only"
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value("OpenOnly", filters)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg='2' sm='3'>
          <DateFilter
            filterName='InventoryOnSince'
            displayName='After'
            value={filter_helpers.get_value('InventoryOnSince', filters)}
            onChangeCallback={onFilterChange}
          />
        </Col>
        <Col lg='2' sm='3'>
          <DateFilter
            filterName='InventoryOnBefore'
            displayName='Before'
            value={filter_helpers.get_value('InventoryOnBefore', filters)}
            onChangeCallback={onFilterChange}
          />
        </Col>
      </Row>
    </FilterSearch>
    </div>
      <Card>
        <CardHeader>
          <Row className='d-flex justify-content-between'>
            <Col>
              <ResultHeader 
                totalCost={totalRetailPrice}
                heading='Inventory' 
                totalResults={paginatedList ? paginatedList.totalItems : 0}
                badgeClass={totalBadgeClass}
              />
            </Col>
            <Col className='d-flex justify-content-end align-items-start'>
              <ButtonGroup>
                <Button onClick={findCSV} color={'light'} className='border-dark'><img  src={download} height={16}/></Button>
                {selectedInventoryIds.length && (isSysAdmin || isCompanyAdmin) // || isInventoryOwner)
                  ? (<ButtonDropdown color="dark" className="me-2 rounded border-dark" isOpen={selectedMenuOpen} toggle={() => setSelectedMenuOpen(!selectedMenuOpen)}>
                      <DropdownToggle caret color="dark">
                        Selected
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={onShowPayModal}>Approve &amp; Pay</DropdownItem>
                        <DropdownItem onClick={onHoldSelected}>Hold</DropdownItem>
                        <DropdownItem onClick={onRemoveHoldSelected}>Remove From Hold</DropdownItem>
                        <DropdownItem onClick={() => setShowSaleModal(true)}>Sell</DropdownItem>
                        <DropdownItem onClick={onDenySelected}>Reject</DropdownItem>
                        <DropdownItem onClick={onMoveToStock}>Move to Stock</DropdownItem>
                        {/*<DropdownItem onClick={() => setShowMoveModal(true)}>Move to Store</DropdownItem>*/}
                        {/*<DropdownItem onClick={() => setShowAssignModal(true)}>Assign Driver</DropdownItem>*/}
                      </DropdownMenu>
                    </ButtonDropdown>)
                  : null
                }
              </ButtonGroup>
              <Pager
                pageNumber={pageNumber}
                pageSize={paginatedList ? paginatedList.pageSize : 0}
                totalResults={paginatedList ? paginatedList.totalItems : 0}
                onSetPageCallback={setPageNumber}
              />
            </Col>
          </Row>
        </CardHeader>
        {!showPayModal && errorMessage
          ? (<RSAlert color={errorMessage.flavor} toggle={() => setErrorMessage(null)}>{errorMessage.message}</RSAlert>)
          : null
        }
        <Table hover>
          <thead>
          <tr key={'inventory-header-row'}>
            <th>
              <Label check>
                <Input type="checkbox" checked={selectAllInventory} onChange={onSelectAllToggle} />
              </Label>
            </th>
              <SortHeader
                displayName='Store Lot'
                field='DealerName'
                sortDir={sortDirection}
                sorted={sortColumn === 'DealerName'}
                sortCallback={doSort}
                isAlphabetic/>
              <SortHeader
                displayName='Age'
                field='Age'
                sortDir={sortDirection}
                sorted={sortColumn === 'Age'}
                sortCallback={doSort}
              />
            <SortHeader
                displayName='Dimensions'
                field='WidthLength'
                sortDir={sortDirection}
                sorted={sortColumn === 'WidthLength'}
                sortCallback={doSort}
              />
            <SortHeader
                displayName='Style'
                field='Style'
                sortDir={sortDirection}
                sorted={sortColumn === 'Style'}
                sortCallback={doSort}
                isAlphabetic
              />
            <SortHeader
                displayName='Description'
                field='BaseColor'
                sortDir={sortDirection}
                sorted={sortColumn === 'BaseColor'}
                sortCallback={doSort}
                isAlphabetic
              />
            <SortHeader
              displayName='Used/New'
              field='UnitIsNew'
              sortDir={sortDirection}
              sorted={sortColumn === 'UnitIsNew'}
              sortCallback={doSort}
              isAlphabetic/>
            <SortHeader
              displayName='Serial #'
              field='SerialNo'
              sortDir={sortDirection}
              sorted={sortColumn === 'SerialNo'}
              sortCallback={doSort}
              isAlphabetic/>
            <SortHeader
              displayName='Owned by'
              field='Company'
              sortDir={sortDirection}
              sorted={sortColumn === 'Company'}
              sortCallback={doSort}
              isAlphabetic/>
            <SortHeader
              displayName='Status'
              field='Status'
              sortDir={sortDirection}
              sorted={sortColumn === 'Status'}
              sortCallback={doSort}
              isAlphabetic/>
            <SortHeader
              displayName='Contract'
              field='ContractId'
              sortDir={sortDirection}
              sorted={sortColumn === 'ContractId'}
              sortCallback={doSort}
              isAlphabetic/>
            <SortHeader
              displayName='Invoice #'
              field='InvoiceNo'
              sortDir={sortDirection}
              sorted={sortColumn === 'InvoiceNo'}
              sortCallback={doSort}/>
            <SortHeader
              displayName='Price'
              field='RetailPrice'
              sortDir={sortDirection}
              sorted={sortColumn === 'RetailPrice'}
              sortCallback={doSort}/>
              <th></th>
              <th></th>
          </tr>
          </thead>
          <tbody>
          {loadingPayload || !filtersLoaded
              ? (<tr key='loading-row'><td colSpan='13'><Loading /></td></tr>)
              : paginatedList && paginatedList.dataSource && paginatedList.dataSource.length
                  ? _.map(paginatedList.dataSource, (inv, idx) => 
                    (<InventoryRow 
                      key={`inv-row-${inv.id}-${idx}`}
                      i={inv}
                      setDiscount={setDiscount}
                      setEditPrice={setEditPrice}
                      currentUser={currentUser}
                      onMoveToStock={onMoveToStock}
                      onDeleteInventory={onDeleteInventory}
                      isSysAdmin={isSysAdmin}
                      isCompanyAdmin={isCompanyAdmin}
                      setCancelInventoryId={setCancelInventoryId}
                      setDiscountInventoryId={setDiscountInventoryId}
                      setPriceInventoryId={setPriceInventoryId}
                      selectedInventoryIds={selectedInventoryIds}
                      onSelectInventoryId={onSelectInventoryId}
                      setSaleInventoryId={setSaleInventoryId}
                      setShowSaleModal={setShowSaleModal}
                      onSetPendingMove={onSetPendingMove}
                      onViewInvoice={onViewInvoice}
                      onRemoveHold={onRemoveHold}
                    />))
                  : (<tr key='no-data-row'><td colSpan='12' className='text-center'>No Inventory items to show.</td></tr>)
          }
          </tbody>
        </Table>
        <CardFooter>
          <Pager 
            pageNumber={pageNumber} 
            pageSize={paginatedList ? paginatedList.pageSize : 0} 
            totalResults={paginatedList ? paginatedList.totalItems : 0} 
            onSetPageCallback={setPageNumber} />
        </CardFooter>
      </Card>
      </div>
      <Footer />
    </>
  );
}
