import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Alert as RSAlert
} from 'reactstrap';
import { Header, Footer } from './';
import Background from '../assets/img/login-bg.jpg';
import logo from '../assets/img/ShedPro-White.png';
import { constants, UserContext, api } from '../utils';
import Alert from "react-s-alert-v3";
const PATHNAMES = constants.PATH_NAMES;

const Login = () => {
  const { currentUser, logIn } = useContext(UserContext);
  const [username, setUserName] = useState(
    localStorage.getItem('lastUsername') || ''
  );
  const [password, setPassword] = useState('');
  const [message, setMessage]= useState(constants.ALERT_OBJECT);
  const [submitting, setSubmitting] = useState(false);

  if (currentUser) return <Redirect to={PATHNAMES.HOME} />;

  const handleUsername = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      Username: username,
      Password: password,
    };
    setSubmitting(true);
    api
      .post('public/login', payload)
      .then((response) => {
        if (response.data.success) {
          logIn(response.data.user, response.data.user.token);
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setSubmitting(false));
  };

  if (currentUser) return <Redirect to={PATHNAMES.HOME} />;

  return (
    <div className="login-page">
      {message.message ? <RSAlert flavor={message.flavor}>{message.text}</RSAlert> : null}
        <div id='loginCard' className=''>
          <CardHeader className='login-header'>
            <img src={logo} />
            <div className='login-description'>Hello! Please enter your details.</div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className='mb-4'>
                <Label>Username</Label>
                <Input type='text' onChange={handleUsername} value={username} />
              </FormGroup>
              <FormGroup className='mb-4'>
                <Label>Password</Label>
                <Input
                  type='password'
                  onChange={handlePassword}
                  value={password}
                />
              </FormGroup>
              <div className='login-footer'>
              <Link to={PATHNAMES.FORGOT_PASSWORD}>I forgot my password!</Link>
              <Button color='primary' className="float-end" onClick={handleSubmit} type="submit" disabled={submitting}>
                {submitting ? "Logging in..." : "Log In"}
              </Button>
              </div>
            </Form>
          </CardBody>
        </div>
        <div className="login-bg" style={{ backgroundImage: `url(${Background})` }}></div>
    </div>
  );
};

export default Login;
