import React, { useState, useEffect, useRef, Fragment } from "react";
import { ContentEditor, ManufacturerAdminHeader } from "./";
import {
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  ButtonGroup,
  CardBody,
  ListGroup,
  CardHeader,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { api, constants } from "../utils";
import _ from "lodash";
import { MfgAdmin, Footer } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import Alert from "react-s-alert-v3";
import { useConsole } from "../hooks";
import ToggleButton from "react-toggle-button";

const templateOptions = [
  { value: "Build Started", label: "Build Started" },
  { value: "Electricity Completed", label: "Electricity Completed" },
  { value: "Build Completed", label: "Build Completed" },
  { value: "Delivery Scheduled", label: "Delivery Scheduled" },
  { value: "Delivery Completed", label: "Delivery Completed" },
];

const templateDescriptions = [
  {
    value: "{{first-name}} we have exciting news, your build has been started!",
    label: "Build Started",
  },
  {
    value: "Electricity has successfully been installed on your unit!",
    label: "Electricity Completed",
  },
  {
    value: "Your build has been successfully completed! We will contact you for delivery soon!",
    label: "Build Completed",
  },
  {
    value:
      "Delivery has been scheduled for {{date}} please let us know if something comes up and that time no longer works..",
    label: "Delivery Scheduled",
  },
  {
    value: "{{first-name}} thank you for your business, we hope you love your new unit!",
    label: "Delivery Completed",
  },
];

const defaultBuildStep = {
  id: 0,
  name: "",
  seq: 0,
  manufacturerId: 0,
  contactCustomer: false,
  isConditional: false,
  description: "",
  notificationTemplate: "",
  deactivatedAt: null,
  deactivatedByUserId: null,
};

const editSectionTypes = {
  linkURL: {
    label: "linkURL",
    type: "URL",
  },
  linkPhone: {
    label: "linkPhone",
    type: "Phone",
  },
  linkEmail: {
    label: "linkEmail",
    type: "Email",
  },
  imageLibrary: {
    label: "imageLibrary",
    type: "Image",
  },
  documentLibrary: {
    label: "documentLibrary",
    type: "Document",
  },
};

const DragHandle = SortableHandle(() => (
  <span>
    <FontAwesomeIcon icon="bars" />
  </span>
));

const BuildStepList = SortableContainer(({ buildSteps, editBuildStep, toggleDelete }) => {
  return (
    <ListGroup>
      {_.map(buildSteps, (b, index) => {
        return (
          <SortableItem
            buildStep={b}
            index={index}
            editBuildStep={editBuildStep}
            toggleDelete={toggleDelete}
          />
        );
      })}
    </ListGroup>
  );
});

const SortableItem = SortableElement(({ buildStep, editBuildStep, toggleDelete }) => {
  return (
    <Card body className="my-1 build-step-card">
      <Row className="d-flex align-items-center">
        <Col sm={1}>
          <DragHandle />
        </Col>
        <Col sm={1}>{buildStep.seq}</Col>
        <Col>
          <span>{buildStep.name}</span>
        </Col>
        <Col>
          <Col className="d-flex justify-content-end">
            <ButtonGroup>
              <Button size={"sm"} className="bg-secondary" onClick={() => editBuildStep(buildStep)}>
                <FontAwesomeIcon icon={"edit"} />
              </Button>
              {buildStep.deactivatedAt === null ? (
                <Button size={"sm"} className="btn-delete" onClick={() => toggleDelete(buildStep)}>
                  <FontAwesomeIcon icon={"trash-alt"} />
                </Button>
              ) : (
                <Button
                  size={"sm"}
                  className="bg-success border-success"
                  onClick={() => toggleDelete(buildStep)}>
                  <FontAwesomeIcon icon={"heartbeat"} />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Col>
      </Row>
    </Card>
  );
});

export default function ManufacturerBuildSteps(props) {
  const params = useParams();
  const mfgId = params.mfgId ? params.mfgId : props.mfgId;
  const [notificationTypes, setNotificationTypes] = useState(constants.notificationTypes);
  const [buildSteps, setBuildSteps] = useState([]);
  const [buildStep, setBuildStep] = useState(defaultBuildStep);
  const [showCreateProcess, setShowCreateProcess] = useState(false);
  const [showIconList, setShowIconList] = useState(false);
  const [showNameSelect, setShowNameSelect] = useState(true);
  const [templateText, setTemplateText] = useState("");
  const [editSectionFlavor, setEditSectionFlavor] = useState(null);
  const [showEditSection, setShowEditSection] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");
  const [deletedBuildStep, setDeletedBuildStep] = useState(defaultBuildStep);

  function getBuildSteps() {
    api.fetch(`manufacturer/GetBuildSteps/${mfgId}`).then((r) => {
      if (r.data.success) {
        setBuildSteps(r.data.message.list);
        setManufacturerName(r.data.message.manufacturerName);
      }
    });
  }

  const toggleBuildStepActive = (bsId) => {
    api.post(`manufacturer/ToggleBuildStepActive/${bsId}`).then((r) => {
      if (r.data.success) getBuildSteps();
    });
  };

  const toggleDelete = (buildStep) => {
    setDeletedBuildStep(buildStep);
    setConfirmDeletion(true);
  };

  const confirmDelete = () => {
    toggleBuildStepActive(deletedBuildStep.id);
    setConfirmDeletion(false);
    setDeletedBuildStep(defaultBuildStep);
  };

  const cancelDelete = () => {
    setConfirmDeletion(false);
    setDeletedBuildStep(defaultBuildStep);
  };

  const onBuildStepChange = (fieldName, fieldValue) => {
    let newBuild = Object.assign({}, buildStep);
    newBuild[fieldName] = fieldValue;
    setBuildStep(newBuild);
  };

  const editBuildStep = (step) => {
    setBuildStep(step);
    setShowCreateProcess(true);
  };

  const saveBuildStep = () => {
    let payload = Object.assign({}, buildStep);
    payload.manufacturerId = mfgId;
    payload.defaultCustomerNotificationType = payload.defaultCustomerNotificationType
      ? payload.defaultCustomerNotificationType.value
      : constants.NOTIFICATION_TYPE_IDS.NONE;
    api.post("manufacturer/SaveBuildStep", payload)
    .then((r) => {
      if (r.data.success) {
        getBuildSteps();
        setShowCreateProcess(false);
      }
    })
    .catch((error) => {
      Alert.error("Failed to save build step.");
    })
    .finally(() => {
      setBuildStep(defaultBuildStep);
    })
  };

  const handleEditOpen = () => {
    if (showCreateProcess) {
      setShowCreateProcess(false);
      clearBuildStepEdit();
    } else {
      setShowCreateProcess(true);
    }
  };

  const clearBuildStepEdit = () => {
    setBuildStep(defaultBuildStep);
  };

  const updateBuildStepSeq = (buildItems) => {
    let newBuildItems = buildItems.slice();
    _.forEach(buildItems, (b, idx) => {
      b.seq = idx + 1;
      newBuildItems[idx] = b;
      api.post("manufacturer/UpdateBuildStepSeq", b).then((r) => {
        if (!r.data.success) {
          console.error("Failed to update the build step sequence.");
        }
      });
    });

    return newBuildItems;
  };

  const onSortEnd = (params) => {
    let newBuildSteps = buildSteps.slice();
    newBuildSteps[params.oldIndex].seq = params.newIndex + 1;
    newBuildSteps = arrayMove(newBuildSteps, params.oldIndex, params.newIndex);
    newBuildSteps = updateBuildStepSeq(newBuildSteps);
    setBuildSteps(newBuildSteps);
  };

  useEffect(getBuildSteps, []);
  console.log("Build Step", buildStep);
  return (
    <>
      <MfgAdmin mfgId={mfgId} tabName="BUILDERS" />
      <div className="inner">
        <div className="inner-white">
          <h2 className="py-2">
            <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Build Steps
          </h2>
        </div>
        <Card>
          <Row className={"d-flex justify-content-between"}>
            <Col>
              <h3>Build Steps</h3>
            </Col>
            <Col>
              <Button
                className="bg-success border-success float-end"
                onClick={() => handleEditOpen()}>
                <FontAwesomeIcon icon={`${showCreateProcess ? "arrow-right" : "plus"}`} />
              </Button>
            </Col>
          </Row>
          <CardBody>
            <Row>
              <Col>
                {buildSteps ? (
                  <BuildStepList
                    buildSteps={buildSteps}
                    editBuildStep={editBuildStep}
                    toggleDelete={toggleDelete}
                    useDragHandle
                    onSortEnd={onSortEnd}
                  />
                ) : null}
              </Col>
              {showCreateProcess ? (
                <Col>
                  <Card>
                    <CardBody>
                      <Form className="my-3">
                        <FormGroup>
                          <Label for="processName">Name</Label>
                          <Input
                            type="text"
                            name="name"
                            id="processName"
                            value={buildStep.name}
                            onChange={(e) => onBuildStepChange(e.target.name, e.target.value)}
                          />
                        </FormGroup>
                        {/*<Row className="d-flex my-3">*/}
                        {/*    <Col xs="3">*/}
                        {/*        <h3>Icon</h3>*/}
                        {/*    </Col>*/}
                        {/*    <Col xs="2">*/}
                        {/*        <Button*/}
                        {/*            color="success"*/}
                        {/*            onClick={() => {setShowIconList(!showIconList)}}>*/}
                        {/*            Add*/}
                        {/*        </Button>*/}
                        {/*    </Col>*/}
                        {/*{showIconList ? (*/}
                        {/*    <Row>*/}
                        {/*        <Col xs="12">*/}
                        {/*            <FontAwesomeIcon className="icon-progress" icon="plus-square"/>*/}
                        {/*            <FontAwesomeIcon className="icon-progress" icon="user-plus"/>*/}
                        {/*            <FontAwesomeIcon className="icon-progress" icon="search"/>*/}
                        {/*            <FontAwesomeIcon className="icon-progress" icon="truck"/>*/}
                        {/*            <FontAwesomeIcon className="icon-progress" icon="plug" />*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*) : null}*/}
                        {/*</Row>*/}
                        <FormGroup>
                          <Label for="processDescription">Description</Label>
                          <Input
                            type="textarea"
                            name="processDescription"
                            id="processDescription"
                            value={buildStep.description}
                            onChange={(e) => onBuildStepChange("description", e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Conditional Build Step</Label>
                          <ToggleButton
                            value={buildStep.isConditional}
                            activeLabel={"Yes"}
                            inactiveLabel={"No"}
                            onToggle={(v) => onBuildStepChange("isConditional", !v)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label> Contact Customer?</Label>
                          <ToggleButton
                            value={buildStep.contactCustomer}
                            activeLabel={"Yes"}
                            inactiveLabel={"No"}
                            onToggle={(v) => onBuildStepChange("contactCustomer", !v)}
                          />
                        </FormGroup>
                        <FormGroup>
                          {buildStep.contactCustomer ? (
                            <Row className="my-2">
                              <Col>
                                <Row>
                                  <FormGroup className="mb-1">
                                    {showNameSelect ? (
                                      <div>
                                        <Label for="processName">Select Template To Send</Label>
                                        <Select
                                          className="mt-1"
                                          options={templateDescriptions}
                                          onChange={(value) =>
                                            onBuildStepChange("notificationTemplate", value.value)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <Label for="processName">Name</Label>
                                        <Input placeholder="Enter name of new template here"></Input>
                                      </div>
                                    )}
                                  </FormGroup>
                                </Row>
                              </Col>
                            </Row>
                          ) : null}
                          {buildStep.contactCustomer ? (
                            <Fragment>
                              <Row className="mb-5">
                                <Col>
                                  <FormGroup>
                                    <Label>Template Text (HTML format)</Label>
                                    <Input
                                      className="mt-1"
                                      type="textarea"
                                      style={{ height: "300px" }}
                                      name="template"
                                      id="template"
                                      placeholder="Provide your template content here"
                                      value={buildStep.notificationTemplate}
                                      onChange={(e) =>
                                        onBuildStepChange("notificationTemplate", e.target.value)
                                      }
                                    />
                                    {/*                                                      <ContentEditor
                                                        onChange={(value) => onBuildStepChange('notificationTemplate', value)}
                                                        content={buildStep.notificationTemplate}
                                                        buildStepSeq={buildStep.seq}
                                                      />
*/}{" "}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label>Notification Type</Label>
                                    <Select
                                      options={notificationTypes}
                                      value={_.find(
                                        notificationTypes,
                                        (nt) =>
                                          parseInt(nt.value) ===
                                          buildStep.defaultCustomerNotificationType
                                      )}
                                      onChange={(option) =>
                                        onBuildStepChange("defaultCustomerNotificationType", option)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Fragment>
                          ) : null}
                        </FormGroup>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              className="my-2 bg-success border-success"
                              onClick={saveBuildStep}>
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
        <Modal centered isOpen={confirmDeletion}>
          <ModalHeader>Delete Build Step</ModalHeader>
          <ModalBody>
            <Row>
              <p>This could potentially prevent other build tracking features from working.</p>
              <p>Are you sure you want to delete this build step?</p>
            </Row>
          </ModalBody>
          <ModalFooter className={"d-flex justify-content-end"}>
            <Row>
              <Col>
                <Button className={"btn-secondary"} onClick={() => cancelDelete()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button className={"btn-delete"} onClick={() => confirmDelete()}>
                  Delete
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
      <Footer />
    </>
  );
}
