import React, { useState, useEffect } from "react";
import { Loading } from "./";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  ButtonGroup,
  FormGroup,
  Label,
  Collapse
} from "reactstrap";
import _ from 'lodash';
import group from "../assets/img/Group.png";
const FilterSearch = (props) => {
  const [advSearchOpen, setAdvSearchOpen] = useState(false);

  // function resolvePropsFilters() {
  //   return props.filters && _.isArray(props.filters)
  //     ? props.filters
  //     : [];
  // }

  // const [filters, setFilters] = useState(resolvePropsFilters());

  // useEffect(() => {
  //   setFilters(resolvePropsFilters());
  // }, [props.filters])

  let filterDisplay = '';
  const filters = props.filters && _.isArray(props.filters)
      ? props.filters
      : [];
  if (filters.length && advSearchOpen) {
    filterDisplay = _.map(filters, x =>
      x.value || x.startDate || x.endDate
        ? x.filteredDisplay
        : null
    ).join(" | ");
  }
  return (
    <div className="filter-wrap">
      <div className="d-flex w-100 justify-content-end">
        <Button
          color="grey"
          className="filter-button"
          size="sm"
          onClick={() => setAdvSearchOpen(!advSearchOpen)}
        >
          <img src={group}/>
          {advSearchOpen ? "Hide Advanced Search" : "Show Advanced Search"}
        </Button>
      </div>

    <Collapse isOpen={advSearchOpen} className="filter-inner">
      {props.isLoading
        ? (<Loading />)
        : (<Card id="advSearch" className="mb-2">
            <CardBody>
              <Row>
                {props.children}
              </Row>
              <Row className={'mt-2 d-flex justify-content-between '}>
                <Col>
                  <span className="filter-display">{filterDisplay}</span>
                </Col>
                <Col sm={3}>
                  <ButtonGroup className={'float-end'}>
                    <Button color="dark" disabled={filters.length ? false : true} onClick={props.onClear}>
                      Clear Criteria
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>)
      }      
    </Collapse>
  </div>
  );
};

export default FilterSearch;
