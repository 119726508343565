import axios from 'axios';
import { constants } from "./index.js";
import Alert from 'react-s-alert-v3';
import { Buffer } from "buffer";
import _ from 'lodash';
import Cookies from 'js-cookie';
import { storage } from './';
import ShedProLogo from "./../assets/img/ShedPro-White.png";
import profileImage from "./../assets/img/Avatar.png";
import Sig from "../assets/img/essentials-icons/milestone_contract.png";
import Pmt from "../assets/img/essentials-icons/milestone_payment.png";
import Land from "../assets/img/essentials-icons/milestone_landlord.png";
import Delivery from "../assets/img/essentials-icons/milestone_delivery.png";

const MAX_CACHE_SERVE_COUNT = 200;

const IMAGE_CACHE = {
  LOGO: {
    value: 1,
    label: "LOGO",
    alt: "Logo",
    src: ShedProLogo,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  },
  CONTRACT: {
    value: 1,
    label: "Signature",
    alt: "Signature",
    src: Sig,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  },
  PAYMENT: {
    value: 1,
    label: "Payment",
    alt: "Payment",
    src: Pmt,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  },
  LANDOWNER: {
    value: 1,
    label: "Landowner Permission",
    alt: "Landowner Permission",
    src: Land,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  },
  DELIVERY: {
    value: 1,
    label: "Delivery",
    alt: "Delivery",
    src: Delivery,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  },
  AVATAR: {
    value: 1,
    label: "Profile Avatar",
    alt: "Profile Avatar",
    src: profileImage,
    data: null,
    serveCount: 0,
    maxServeCount: 1000000
  }
  // GLOBE: {
  //   value: 6,
  //   label: "Published to the customer-facing website",
  //   alt: "Published to the customer-facing website",
  //   src: GrGlobe,
  //   data: null,
  //   serveCount: 0
  // }
};

function getCachedImage(key) {
  let cachedItem = IMAGE_CACHE[key];
  if (!cachedItem) {
    console.error('unknown Cache Key: ' + key);
    return null;
  }
  if (cachedItem.data !== null && cachedItem.serveCount < cachedItem.maxServeCount) {
    cachedItem.serveCount += 1;
    IMAGE_CACHE[key] = cachedItem;
    return new Promise((resolve, reject) => resolve(cachedItem.data));
  } else {
    return axios.get(cachedItem.src, { responseType: "arraybuffer" }).then((response) => {
      if (response.data) {
        const imageData = Buffer.from(response.data, "binary").toString("base64");
        cachedItem.data = `data:image/png;base64, ${imageData}`;
        cachedItem.serveCount = 0;
        IMAGE_CACHE[key] = cachedItem;
        return cachedItem.data;
      }
    });
  }
}

function userToken() {
  return storage.getItem('token');
}

function commonHeaders() {
  const token = userToken();
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };
}

//interceptor to handle expired jwt token
axios.interceptors.response.use(
  function (response) {
    const current_client_version = storage.getItem('app-version');
    const current_server_version = response.headers['shedpro-version'];
    if (
      current_client_version === null ||
      current_client_version !== current_server_version
    ) {
      if (
        current_client_version !== undefined &&
        current_server_version !== undefined
      ) {
        storage.setItem('app-version', current_server_version);
        Alert.warning(
          'This page is about to reload with an updated version...'
        );
        setTimeout(function () {
          window.location.reload(true);
        }, 2200);
      }
    }
    return response;
  },

  function (error) {
    if (error.response && error.response.status === 401) {
      console.warn('Unauthorized - redirecting to login');
      //const userAppURL = process.env.REACT_APP_CF_ENV === 'production'
      //  ? 'https://app.carefreerentals.com'
      //  : 'https://appdev.carefreerentals.com';
      storage.removeItem('currentUser');
      storage.removeItem('token');
      // const userAppURL = 'https://localhost:44359'; //'https://app.carefreerentals.com';//'https://localhost:44359';
      window.location.href = '/Authentication';
    }
    if (error.response && error.response.status === 403) {
      Alert.error(
        'Your user account does not have the permissions to take this action'
      );
    }
    return error;
  }
);

const api_root = '/api/';
  // process.env.NODE_ENV === 'development'
  //   ? 'https://localhost:44359/api/' // "http://70c8bf5c3af9.ngrok.io/api/" // "http://localhost:5000/api/"
  //   : '/api/';

const headers = () =>
  Object.assign(commonHeaders(), { 'Content-Type': 'application/json' });
const multipart_headers = () =>
  Object.assign(commonHeaders(), { 'Content-Type': 'multipart/form-data' });
const no_cache_headers = () =>
  Object.assign(commonHeaders(), {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
  return `${query.length ? '?' : ''}${query}`;
}

const api = {
  getCachedImage,
  fetch(url, params = {}) {
    return axios.get(`${api_root}${url}${queryString(params)}`, {
      headers: headers(),
    });
  },
  downloadAndOpenFile(url, data, windowHandle, onError, mimeType = "application/pdf", onFinally) {
    axios({
      method: "post",
      url: `${api_root}${url}`,
      data: data,
      responseType: 'arraybuffer',
      headers: headers(),
      timeout: 7200000
    }).then(r => {
      if (r.status !== 200 || (r.data && r.data.success === false))
        throw new Error("Error downloading file");
        let filename = r.headers.filename;
        if (mimeType === 'detect') {
          mimeType = r.headers["content-type"];
        }
        if (!filename) {
          const file = new Blob([r.data], { type: mimeType });
          const fileURL = URL.createObjectURL(file);
          windowHandle.location.href = fileURL;
        } else {
          const file = new Blob([r.data], { type: mimeType })
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          a.click();
          if (mimeType === constants.MIME_PDF || mimeType === "image/png") {
            windowHandle.location.href = url
          } else {
            windowHandle.close();
          }
        }
    }).catch((onError ? onError : ((err) => console.error(err))))
    .finally(() => {
      if (onFinally) onFinally();
    });
  },
  downloadPDF(url, pdf) {
    try {
      var link = document.createElement("a");
      // link.setAttribute("href", url);
      // link.setAttribute("download", url);
      // just for demo ______________
      link.setAttribute("href", pdf);
      link.setAttribute("download", pdf);
      link.style.visibility = "hidden";
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      Alert.error("There was an error downloading the document");
    }
  },

  ignoreCacheFetch(url, params = {}) {
    return axios.get(`${api_root}${url}${queryString(params)}`, {
      headers: no_cache_headers(),
    });
  },
  post(url, data) {
    return axios({
      method: 'post',
      url: `${api_root}${url}`,
      data: data,
      timeout: 600000,
      headers: headers(),
    });
  },
  postFormDataGetBlob(url, formData) {
    return axios.post(`${api_root}${url}`, formData, {
        headers: { Authorization: `Bearer ${userToken()}` },
        withCredentials: true,
        responseType: "blob"
    });
  },

  // patch(url, data) {
  //   return axios.patch(`${api_root}${url}`, data, { headers: headers() });
  // },

  delete(url) {
    return axios.delete(`${api_root}${url}`, { headers: headers() });
  },

  upload(verb, url, data) {
    switch (verb.toLowerCase()) {
      case 'post':
        return axios({
          method: 'post',
          url: `${api_root}${url}`,
          data: data,
          timeout: 600000,
          headers: headers(),
        });
      case 'put':
        return axios.put(`${api_root}${url}`, data, { headers: headers() });
      case 'patch':
        return axios.patch(`${api_root}${url}`, data, { headers: headers() });
      default:
    }
  },
  downloadBinary(url, mimeType) {
    const headers = Object.assign(commonHeaders(), {
      'Content-Type': 'application/json',
      Accept: mimeType,
    });
    return axios.get(`${api_root}${url}`, {
      responseType: 'arraybuffer',
      headers: headers,
    });
  },
  getRecurringPayload(contractId, paymentData) {
    return {
      ReferenceId: contractId,
      PaymentDayOfMonth: paymentData.paymentDayOfMonth,
      BillingAddress: paymentData.recurringBillingAddress,
      BillingCity: paymentData.recurringBillingCity,
      BillingState: paymentData.recurringBillingState,
      BillingZip: paymentData.recurringBillingZip,
      BillingPhone: paymentData.recurringBillingPhone,
      BillingEmail: paymentData.recurringBillingEmail,
      TypeOfCC: paymentData.creditCardType ? paymentData.creditCardType.value : null,
      CCNumber: _.trim(paymentData.ccNumber),
      CCFullName: paymentData.ccFullName,
      CCExpiryMonth: paymentData.ccExpiryMonth,
      CCExpiryYear: paymentData.ccExpiryYear,
      ACHFullName: paymentData.achFullName,
      ACHRoutingNo: paymentData.achRoutingNo,
      ACHAccountNo: paymentData.achAccountNo,
      ACHAccountType: paymentData.achAccountType,
      ACHBankName: paymentData.achBankName
    };
  },
  post_form_data(url, formData) {
    return axios.post(`${api_root}${url}`, formData, {
      headers: multipart_headers(),
    });
  },
  userToken() {
    return userToken();
  },
  catchHandler: (e) => {
    console.error(e);
  },
};

export default api;
