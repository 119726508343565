import React, { useEffect, useState, useContext, Fragment } from "react";
import Dropzone from "react-dropzone";
import { Input, Badge, Table, Button, ButtonGroup, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { date_helpers, ui_helpers } from "../helpers";
import { api, constants, UserContext } from "../utils";
import { Footer, Header, Loading } from "./";
import { confirmAlert } from "react-confirm-alert";
import Alert from "react-s-alert-v3";
import _ from "lodash";

const ReportList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [editReport, setEditReport] = useState(null);
  const [editRDLForReportId, setEditRDLForReportId] = useState(null);
  const [reportGroups, setReportGroups] = useState([]);
  const {currentUser} = useContext(UserContext);

  useEffect(loadList, []);

  function loadList() {
    setIsLoading(true);
    api.fetch('Resources/ReportList').then(res => {
      // let list = [];
      // let groupList = [];
      // _.each(res.data, rg => {
      //   groupList.push({value: rg.id, label: rg.dsc});
      //   _.each(rg.reports, r => {
      //     r.reportGroupName = rg.dsc;
      //     list.push(r);
      //   });
      // });
      setReportGroups(ui_helpers.idNameToValueLabel(res.data.reportGroupList));
      setReportList(res.data.reports);
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  function onDelete(r) {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you want to permanently delete the ${r.dsc} report?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('Resources/DeleteReport', { id: r.id }).then(res => {
              if (res.data.success) {
                loadList();
              } else {
                Alert.error(res.data.message);
              }
            }).catch((e) => console.error(e));
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function onEdit(r) {
    setEditReport(r);
  }

  function onCopy(r) {
    setEditReport({...r, id: 0});
  }

  function onSave() {
    setIsLoading(true);
    api.post('Resources/SaveReport', editReport).then(res => {
      if (res.data.success) {
        setEditReport(null);
        loadList();
      } else {
        Alert.error(res.data.message);
      }
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  function onChangeReport(fieldName, value) {
    setEditReport({
      ...editReport,
      [fieldName]: value
    });
  }

  function onDropRDL(fileList) {
    let formData = new FormData();
    formData.append('RDLReportId', editRDLForReportId);
    formData.append('Content', fileList[0]);
    api.post_form_data('Resources/UploadRDL', formData).then(r => {
      if (r.data.success) {
        setEditRDLForReportId(null);
        Alert.success('Report template updated!');
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e));
  }

  return (
    <div>
      <Header />
      <div className="inner">
      {editRDLForReportId
        ? (
        <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
        <Modal size="lg" toggle={() => setEditRDLForReportId(null)} isOpen>
            <ModalHeader>
              Edit Report SSRS Definition
            </ModalHeader>
            <ModalBody>
              <Dropzone onDrop={fileList => onDropRDL(fileList)} style={{width: "100%", backgroundColor: "#F0F0F0", borderRadius: "6px"}}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="text-center">
                    <input {...getInputProps()} />
                    <div style={{ textAlign: "center" }} className="pt-3">
                      <div className="mb-1">Drop RDL or RDLC file here or click the icon</div>
                      <FontAwesomeIcon icon="file-upload" size="3x" className="mb-4" />
                    </div>
                  </div>
                )}
              </Dropzone>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup>
                    <Button onClick={() => setEditRDLForReportId(null)}>Cancel</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          </div>
          )
        : null
      }
      {editReport
        ? (<Modal size="lg" toggle={() => setEditReport(null)} isOpen>
            <ModalHeader>
              Edit Report
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>Group</Col>
                <Col>
                  <Select
                    options={reportGroups}
                    value={_.find(reportGroups, g => g.value === editReport.reportGroupId)}
                    onChange={(newOption) => onChangeReport('reportGroupId', newOption.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>Name</Col>
                <Col>
                  <Input type='text' maxLength="100" value={editReport.dsc} 
                    onChange={(e) => onChangeReport('dsc', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Description
                </Col>
                <Col>
                  <Input type='text' maxLength="250" value={editReport.dsc2} 
                    onChange={(e) => onChangeReport('dsc2', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Seq
                </Col>
                <Col>
                  <Input type='number' value={editReport.seq} 
                    onChange={(e) => onChangeReport('seq', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>RDLC Filename
                </Col>
                <Col>
                  <Input type='text' maxLength="100" value={editReport.rdlcFilename} 
                    onChange={(e) => onChangeReport('rdlcFilename', e.target.value)} />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col>
                  <Button onClick={() => onChangeReport('isActive', !editReport.isActive)} 
                    className={classnames({"btn-success": editReport.isActive}, "me-2 text-light")}>
                    Active
                  </Button>
                  <Button onClick={() => onChangeReport('isSecure', !editReport.isSecure)} 
                    className={classnames({"btn-success": editReport.isSecure}, "me-2 text-light")}>
                    Secure
                  </Button>
                  <Button onClick={() => onChangeReport('isExcel', !editReport.isExcel)} 
                    className={classnames({"btn-success": editReport.isExcel}, "me-2 text-light")}>
                    Excel
                  </Button>
                  <Button onClick={() => onChangeReport('isPDF', !editReport.isPDF)} 
                    className={classnames({"btn-success": editReport.isPDF}, "me-2 text-light")}>
                    PDF
                  </Button>
                  <Button onClick={() => onChangeReport('isCSV', !editReport.isCSV)} 
                    className={classnames({"btn-success": editReport.isCSV}, "me-2 text-light")}>
                    CSV
                  </Button>
                  <Button onClick={() => onChangeReport('isXLSX', !editReport.isXLSX)} 
                    className={classnames({"btn-success": editReport.isXLSX}, "me-2 text-light")}>
                    XLSX
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>Procedure Name
                </Col>
                <Col>
                  <Input type='text' maxLength="50" value={editReport.procedureName} 
                    onChange={(e) => onChangeReport('procedureName', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Procedure Name 2
                </Col>
                <Col>
                  <Input type='text' maxLength="50" value={editReport.procedureName2} 
                    onChange={(e) => onChangeReport('procedureName2', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Procedure Name 3
                </Col>
                <Col>
                  <Input type='text' maxLength="50" value={editReport.procedureName3} 
                    onChange={(e) => onChangeReport('procedureName3', e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col>Procedure Name CSV
                </Col>
                <Col>
                  <Input type='text' maxLength="50" value={editReport.procedureNameCSV} 
                    onChange={(e) => onChangeReport('procedureNameCSV', e.target.value)} />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row className={'d-flex justify-content-end'}>
                <Col>
                  <ButtonGroup>
                    <Button color='primary' onClick={onSave}>Save</Button>
                    <Button onClick={() => setEditReport(null)}>Cancel</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>)
        : null
      }
      {isLoading ? (<Loading />) 
        : (<div className="p-2">
            <Row>
              <Col xs="8">
                <h3 className="my-3">
                  Reports
                  <Badge color="dark" className="ms-2" pill>
                    {reportList.length}
                  </Badge>
                </h3>
              </Col>
              <Col xs="4">
                <Button color="success" className="float-end text-light" title="Add Report" onClick={() => setEditReport({
                  id: 0,
                  reportGroupId: reportGroups[0].value,
                  procedureName: 'spRpt?',
                  isExcel: true,
                  isActive: true,
                  isCSV: true,
                  isXLSX: false
                })}>
                  <FontAwesomeIcon icon='plus' /> Report
                </Button>
              </Col>
            </Row>
            <Table>
            <thead>
              <tr>
                <th>
                  Name<br/>
                  Description
                </th>
                <th>Report Group</th>
                <th>RDLC Filename</th>
                <th>Stored Procedure Names</th>
              </tr>
            </thead>
            <tbody>
            {_.map(reportList, x => (
              <Fragment key={`r-${x.id}`}>
              <tr>
                <td>#{x.seq}: <span style={{fontWeight: "bold"}}>{x.dsc}</span><br/>
                  <span style={{fontStyle: "italic"}}>{x.dsc2}</span>
                </td>
                <td>{x.reportGroupName}</td>
                <td>{x.rdlcFilename}</td>
                <td>{x.procedureName}
                  {x.procedureName2
                    ? (<div>2: {x.procedureName2}</div>)
                    : null
                  }
                  {x.procedureName3
                    ? (<div>3: {x.procedureName3}</div>)
                    : null
                  }
                  {x.procedureNameCSV
                    ? (<div>CSV: {x.procedureNameCSV}</div>)
                    : null
                  }
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="pt-0">
                  <span className={classnames({active: x.isActive, inactive: !x.isActive}, "report-status-badge")}>Active</span>
                  <span className={classnames({active: x.isSecure, inactive: !x.isSecure}, "report-status-badge")}>Secure</span>
                  <span className={classnames({active: x.isExcel, inactive: !x.isExcel}, "report-status-badge")}>Excel</span>
                  <span className={classnames({active: x.isPDF, inactive: !x.isPDF}, "report-status-badge")}>PDF</span>
                  <span className={classnames({active: x.isCSV, inactive: !x.isCSV}, "report-status-badge")}>CSV</span>
                  <span className={classnames({active: x.isXLSX, inactive: !x.isXLSX}, "report-status-badge")}>XLSX</span>
                </td>
                <td colSpan="2" className="pt-0">
                  <ButtonGroup className="float-end tiny">
                    <Button color="primary" onClick={() => onEdit(x)}>Edit</Button>
                    <Button color="success" className="text-light" onClick={() => onCopy(x)}>Copy</Button>
                    <Button color="info" className="text-light" onClick={() => setEditRDLForReportId(x.id)}>Edit RDL</Button>
                    <Button color="danger" className="text-light" onClick={() => onDelete(x)}>Delete</Button>
                    <Button color="dark" tag={Link} to={`/Report/EditParameters/${x.id}`}>Parameters</Button>
                  </ButtonGroup>
                </td>
              </tr>
              </Fragment>))}
            </tbody>
            </Table>
          </div>)
      }
      </div>
      <Footer />
    </div>
  );
};

export default ReportList;
