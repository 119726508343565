import React, {useState, useEffect} from 'react';
import {Footer, Header, MainNav} from "./";
import {Card, CardHeader, Container, Col, CardBody, Row, Form, Input, Button, CardFooter} from "reactstrap";
import {api, constants} from '../utils';
import Alert from "react-s-alert-v3";
import {Redirect} from "react-router-dom";
import Background from '../assets/img/login-bg.jpg';
import logo from '../assets/img/ShedPro-White.png';



export default function PasswordReset(props) {
const [userName, setUserName] = useState('');
const [redirect, setRedirect] = useState('');

    const sendForgotPassword = () => {
        if(userName.length) {
            api.post('public/forgotPassword', {username: userName})
                .then(r => {
                    if(!r.data.success) {
                        Alert.error(r.data.message);
                        return;
                    }
                    Alert.success(r.data.message);
                    setTimeout(() => setRedirect(constants.PATH_NAMES.LOGIN), 700);
                }).catch(api.catchHandler);
        } else {
            Alert.warning("You must enter an email/username before requesting.");
        }

    };


if(redirect) {
    return <Redirect to={redirect}/>
}

return (
        <div className="login-page">
            <div id='loginCard' className=''>
            <CardHeader className='login-header'>
            <img src={logo} />
            <div className='login-description'>Type your username below and we'll email you a password.</div>
            </CardHeader>
            <CardBody>
                <Form>
                <div className='login-footer d-block text-center'>
                    <Input value={userName} onChange={(e) => setUserName(e.target.value)}/>
                    <Button color='primary' className='mt-4' onClick={sendForgotPassword}>
                        Send Email
                    </Button>
                </div>
                </Form>
            </CardBody>
            </div>
            <div className="login-bg" style={{ backgroundImage: `url(${Background})` }}></div>
        </div>
    );


}