import React from 'react';
import { useLocation } from "react-router-dom";
import {Badge} from "reactstrap";
import {ui_helpers} from "../helpers";
import {constants} from "../utils";
import classnames from "classnames";
const PATHNAMES = constants.PATH_NAMES;

export default function ResultHeader(props) {
  const location = useLocation();
  return (<div>
        <h2 className="page-title">
            {props.heading}
            <span className={classnames({ 'bg-dark': (props.badgeClass ? false : true), [props.badgeClass]: (props.badgeClass ? true : false)}, 'ms-2 badge rounded-pill')}>
              {props.totalResults ? props.totalResults.toLocaleString("en-US") : null}
            </span>
            {props.totalCost
              ? (<Badge color="light" className="ms-2 text-dark black-pill-border" pill>
                  {props.totalCost ? ui_helpers.formatCurrencyWithoutDecimal(props.totalCost) : 0}
                </Badge>)
              : null}
        </h2>
    </div>);
}