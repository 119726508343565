import React, { Fragment, useContext } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Header } from "./";
import { constants } from "../utils";
import { ui_helpers } from "../helpers";
import { UserContext } from "../utils";
const PATHNAMES = constants.PATH_NAMES;

export default function AdminHeader(props) {
  document.title = `Admin - ${constants.appName}`;
  const { currentUser } = useContext(UserContext);
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(currentUser);
  const isStoreAdmin = ui_helpers.isAnyDealerAdministrator(currentUser);

  return (
    <>
    <Header admin />
      </>
  );
}