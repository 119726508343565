import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Button} from "reactstrap";
import notice from "../assets/img/notice.png";

export default function CommentBell(props) {
    return (<>
    <button className={'btn-bell'} onClick={props.openCommentModal}>
        <img src={notice} />
        {props.commentCount > 0
        ? (<Badge color={'danger'} className={'notification-icon'}>
                <span className={'w-100'}>{props.commentCount}</span>
            </Badge>)
        : null}
    </button>
    </>);
}