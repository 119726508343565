import React, {Fragment, useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton from 'react-toggle-button';
import {
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ButtonGroup,
  Table,
  UncontrolledTooltip,
  Badge, 
  Spinner,
  Alert as RSAlert
} from "reactstrap";
import {DebounceInput} from "react-debounce-input";
import FloorplanTool from "../floorplan-module-2.0/FloorplanTool";
import _ from "lodash";
import moment from "moment";
import DatePicker from 'react-datepicker';
import Alert from "react-s-alert-v3";
import { confirmAlert } from "react-confirm-alert";
import classnames from 'classnames';
import { AddOnAdvice, SalespersonEdit, Loading, PriceAdjustmentEditor, Pager, BundleInfo } from "./";
import {api, ui_helpers, floorplan_helpers } from "../helpers";
import { storage, constants } from "../utils";
import Select from 'react-select'
import {filterReject} from "core-js/internals/array-iteration";
import CurrencyInput from "react-currency-input-field";

const DeliveryInfo = ({ buildingOrientations, buildingOrientation, deliveryInstructions, onChange, screenWarnings }) => {
  return (<Fragment>
    <FormGroup>
      <Label>Delivery Instructions</Label>
      <Input type="textarea" bsSize="sm" rows="3" maxLength="500" 
        value={deliveryInstructions} onChange={(e) => onChange(e.target.value, 'deliveryInstructions')} />
    </FormGroup>
    <FormGroup>
      <div title={screenWarnings.buildingOrientation ? screenWarnings.buildingOrientation : null}>
      <Label>Delivery Building Orientation {ui_helpers.requiredStar()}</Label>
      <Select
        classNamePrefix={screenWarnings.buildingOrientation ? "invalidSelect" : null}
        options={buildingOrientations}
        value={buildingOrientation}
        onChange={(x) => onChange(x, 'buildingOrientation')}
      />
      </div>
    </FormGroup>
  </Fragment>);
}

const UnitInformation = (props) => {
  const [unitData, setUnitData] = useState(props.contractData);
  const [newAddOn, setNewAddOn] = useState(null);
  const [unitAddOns, setUnitAddOns] = useState(props.addOnOptions);
  const [existingContractPairing, setExistingContractPairing] = useState(null);
  const [otherNewAddOnName, setOtherNewAddOnName] = useState("");
  const [screenWarnings, setScreenWarnings] = useState({});
  const [subProducts, setSubProducts] = useState(props.subproductTypes);
  const [subProductSkuOptions, setProductSkuOptions] = useState(props.subProductSkuOptions);
  const [baseColors, setBaseColors] = useState(props.baseColors);
  const [trimColors, setTrimColors] = useState(props.trimColors);
  const [roofColors, setRoofColors] = useState(props.roofColors);
  const [isLoading, setIsLoading] = useState(false);
  const [invPairingMode, setInvPairingMode] = useState(false);
  const [productTypes, setProductTypes] = useState(getUniqueValues(props.productTypes));
  const [selectedSku, setSelectedSku] = useState(null);
  const [defaultAddonList, setDefaultAddonList] = useState([]);
  const [showSearchingUnpairedSpinner, setShowSearchingUnpairedSpinner] = useState(props.searchingUnpaired);
  const [message, setMessage] = useState(null);
  const [unitPrice, setUnitPrice] = useState(props.unitPrice || "0");
  const [taxAmount, setTaxAmount] = useState(0);
  const [executingPairing, setExecutingPairing] = useState(false);
  //For FP
  const [showEditor, setShowEditor] = useState(false);
  const [selectedFPAddOns, setSelectedFPAddOns] = useState([]);
  const [mfgFPAddOnOptions, setMfgFPAddOnOptions] = useState([]);
  const [optionColors, setOptionColors] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);

useEffect(() => {
  if (!props.addOnOptions) return;
  setUnitAddOns(props.addOnOptions);
}, [props.addOnOptions])

useEffect(() => {
  if (_.isArray(props.contractAddOns) && !_.isEqual(props.contractAddOns, unitData.contractAddOns)) {
    setUnitData((oldData) => ({
      ...unitData,
      contractAddOns: props.contractAddOns
    }));
  }
}, [props.contractAddOns])

useEffect(() => {
  setShowSearchingUnpairedSpinner(props.searchingUnpaired);
}, [props.searchingUnpaired]);

useEffect(() => {
  const uniqueProductTypes = getUniqueValues(props.productTypes);
  setProductTypes(uniqueProductTypes);
}, [props.productTypes])

useEffect(() => {
  if (props.unitPrice && props.unitPrice !== unitPrice) {
    setUnitPrice(props.unitPrice);
  }
}, [props.unitPrice])

useEffect(() => {
  if (!props.contractData || window.isPairing) return;
  let data = {...props.contractData};
  if (props.lastPairedInventoryId && !data.subproductSku){
    data.subproductSku = constants.OTHER_OPTION;
  }
  setUnitData(data);
}, [props.contractData]);

useEffect(() => {
  let newScreenWarnings = {};
  _.each(props.fieldWarnings, w => Object.assign(newScreenWarnings, {[w.field]: w.text}))
  setScreenWarnings(newScreenWarnings);
}, [props.fieldWarnings]);

useEffect(() => {
  if (!props.salespeople || !props.salespeople.length || unitData.salesperson) return;
  setUnitData({
    ...unitData,
    salesperson: props.salespeople[0]
  });
}, [props.salespeople]);

useEffect(() => {
  if (window.isPairing
    || (!props.contractData && !props.contractData.manufacturer && !props.contractData.inventoryId)) return;
  if (props.subproductTypes) {
    setSubProducts(props.subproductTypes);
    setBaseColors(props.baseColors);
    setTrimColors(props.trimColors);
    setRoofColors(props.roofColors);
    setProductSkuOptions(props.subProductSkuOptions)
  }
}, [props.subproductTypes, props.subProductSkuOptions, props.baseColors]);

function recalculateTax(newUnitPrice = null) {
  if (!newUnitPrice)
    newUnitPrice = unitPrice;  
  const unitPriceAdjustmentsSum = _.sumBy(
    _.filter(props.priceAdjustments, (pa) => pa.includeInUnitPrice),
    (pa) => parseFloat(pa.amount)
  );
  const newTaxAmount = parseFloat(unitData.taxRate) * (parseFloat(newUnitPrice) + unitPriceAdjustmentsSum);
  setTaxAmount(newTaxAmount);
}

useEffect(recalculateTax, [unitData.taxRate, unitPrice, props.priceAdjustments]);

  function getHasSku() {
    return unitData.subproductSku 
      && unitData.subproductSku.value 
      && unitData.subproductSku.value !== constants.OTHER_ID;
  }

  // function getUnitPriceDisabled() {
  //   if (!unitData) return false;
  //   if (props.lastPairedInventoryId) return true;
  //   return (props.enableBuildFeature || props.isQuote) 
  //     && getHasSku();
  // }

  function onDeleteBundle(bundleId) {
    const newData = {
      ...unitData,
      contractAddOns: _.reject(unitData.contractAddOns, x => x.bundleId === bundleId)
    };
    setUnitData(newData);
    props.onSetDataAndAddOns(newData);
  }

  function onDeleteOption(o) {
    let newData = {
      ...unitData
    };
    const hasSku = getHasSku();
    if (o.id === 0) {
      var removed = _.find(unitData.contractAddOns, x => x.unique === o.unique);
      newData.contractAddOns = _.reject(unitData.contractAddOns, x => x.unique === o.unique);
      if (removed && props.getUnitPriceDisabled(getHasSku())) // (props.isQuote || !props.enableBuildFeature) && hasSku)
      {        
        newData.unitPrice = unitPrice - removed.price;
        setUnitPrice(newData.unitPrice);
      }      
      setUnitData(newData);
      props.onSetDataAndAddOns(newData);
    } else {
      let payload = {addOnId: o.id};
      if (hasSku) {
        payload.skuId = unitData.subproductSku.value;
      }
      api.post('Contract/DeleteAddOn', payload).then(r => {
        if (r.data.success) {
          if ((props.isQuote || !props.enableBuildFeature) && hasSku) {
            newData.unitPrice = r.data.message.price;
            setUnitPrice(newData.unitPrice);
          }
          newData.contractAddOns = _.reject(unitData.contractAddOns, x => x.id === o.id);
          setUnitData(newData);
          props.onSetDataAndAddOns(newData);
        } else {
          Alert.error(r.data.message);
        }
      }).catch((e) => console.error(e));
    }
  }

  function onAddOption() {
    if (newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT
      && (newAddOn.typeOfAddOn === constants.ADDON_TYPE_ID.OTHER || newAddOn.addOnTypeId === constants.ADDON_TYPE_ID.OTHER)
      && (!resolveLengthFeet() || !resolveWidthFeet())) 
    {
      Alert.warning('Please enter a unit length and width before saving your add-on in order to calculate a correct Sq Ft price.');
      return;
    }
    const newAddOnList = mapAddOnToUnitOptions();
    if (!newAddOnList.length) return;
    const newUnit = {
      ...unitData,
      contractAddOns: _.concat(unitData.contractAddOns, newAddOnList)
    };
    props.onSetData(newUnit);
    props.onUpdateContractAddons(newUnit.contractAddOns);
    setOtherNewAddOnName("");
    setNewAddOn(null);
  }

  function mapAddOnToUnit(u) {
    const label = u.label === constants.OTHER
      ? otherNewAddOnName
      : u.label;
    let lengthInInches = 0;
    let widthInInches = 0;
    if (u.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT) {
      const wFt = resolveWidthFeet();
      widthInInches = wFt * 12;
      if (u.sqFt) {
        const sqFt = Number(u.sqFt);
        const lengthInFeet = sqFt / wFt;
        lengthInInches = lengthInFeet * 12;        
      } else {
        lengthInInches = u.length; // in inches already here
      }
    } else if (u.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT) {
      lengthInInches = Number(u.length);
    // } else if (u.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE) {
    //   console.log('change resolved price?', u.resolvedPrice, u.price);
    }
    const idValue = 0;
    return {
      id: idValue,
      value: idValue,
      addOnId: u.addOnId > 0 ? u.addOnId : idValue,
      price: u.resolvedPrice,
      name: label,
      description: u.description,
      seq: -1,
      bundleId: u.bundleId,
      showInFloorPlan: true,
      hasDimensions: true,
      length: lengthInInches,
      width: widthInInches,
      unique: randomInt(),
      label: label,
      positionX: u.positionX,
      positionY: u.positionY,
      rotation: u.rotation,
      typeOfUnitOfMeasure: u.typeOfUnitOfMeasure,
      unitOfMeasureId: u.typeOfUnitOfMeasure,
      swingTypeId: u.typeOfSwing || 0,
      typeOfSwing: u.typeOfSwing || 0,
      additionalOption1Price: u.additionalOption1Price,
      additionalOption2Price: u.additionalOption2Price,
      additionalOptionType1Price: u.additionalOption1Price,
      additionalOptionType2Price: u.additionalOption2Price,
      additionalOption1On: u.additionalOption1On,
      additionalOption2On: u.additionalOption2On,
      additionalOption1ColorId: u.additionalOption1ColorId,
      additionalOption2ColorId: u.additionalOption2ColorId      
    };    
  }

  function resolveLengthFeet() {
    return parseFloat(_.trim(unitData.otherLength)
      ? unitData.otherLength
      : unitData.lengthFeet);
  }

  function resolveWidthFeet() {
    return parseFloat(_.trim(unitData.otherWidth)
      ? unitData.otherWidth
      : unitData.widthFeet);
  }

  function mapAddOnToUnitOptions() {
    if (!newAddOn) return [];
    if (newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT) {
      newAddOn.length = newAddOn.length * 12;
    } else if ((newAddOn.typeOfAddOn === constants.ADDON_TYPE_ID.OTHER || newAddOn.addOnTypeId === constants.ADDON_TYPE_ID.OTHER)
      && newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT) {
      // for other types that are sq. ft. UOM we assume length and width are building length and width
      // per Conrad, 12-Aug-23
      const length = resolveLengthFeet();
      const width = resolveWidthFeet();
      newAddOn.length = length * 12;
      newAddOn.width = width * 12;
      newAddOn.sqFt = length * width;
    // } else if (newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE) {
    //   console.log('set resolved price here?', newAddOn.price, newAddOn.resolvedPrice)
    }

    const item = mapAddOnToUnit(newAddOn);
    let list = [];
    const qty = parseInt(unitData.qty, 10) || 1;
    for(var i=0; i<qty; i++) {
      list.push({...item});
    }
    return list;
  }

  function randomInt() {
    return Math.floor(Math.random() * 1000);
  }

  function sizeOptions(dimension) {
    let list = [];
    switch(dimension) {
      case 'WIDTH':
        list = props.widths;
        break;
      case 'HEIGHT':
        list = props.heights;
        break;
      case 'LENGTH':
        list = props.lengths;
        break;
      default:
        return list;
    }
    if (unitData.productType && _.some(list, w => w.productTypeId === unitData.productType.value)) {
      return _.filter(list, w => w.productTypeId === unitData.productType.value);
    }
    // at this point we just return the default list (not productType specific)
    return _.filter(list, x => (x.productTypeId ? false : true));
  }

  function onConfirmRepairing() {
    setUnitData({
      ...unitData,
      unpairContractId: existingContractPairing.existingContractId,
      existingContractPairing: null
    });
  }

  function maybeCancelCaptive(onComplete) {
    if (shouldDeleteCaptiveInventory()) {
      api.post('contract/UnpairAndCancelCaptiveInventory', { id: unitData.contractId }).then(r => {
        if (r.data.success) {
          if (onComplete) {
            onComplete();
          }
        } else {
          Alert.error('An error occurred while attempting to delete the associated, captive inventory: ' + r.data.message); 
        }
      }).catch((e) => Alert.error('An unexpected error occurred while attempting to delete the associated, captive inventory.'));
    } else if (onComplete) {
      onComplete();
    }
  }

  function shouldDeleteCaptiveInventory() {
    return props.wasInitialCreation && props.captiveInventoryId;
  }

  function tryPairInventory(probeSerialNumber) {
      api.post('inventory/QueryActiveInventoryBySerial', { SerialNumber_EXACT: probeSerialNumber }).then(r => {
      if (r.data.success) {
        props.onSetDisabled(false);
        const existingContract = r.data.message.existingContract;
        const inv = r.data.message.inventory;
        let newUnitData = {};
        if (existingContract && existingContract.id !== unitData.contractId)
        {
          // confirm unpairing
          setExistingContractPairing(inv);
        } else
        {
          let confirmMessage = 'Active inventory has been found with this serial number.';
          if (shouldDeleteCaptiveInventory()) {
            confirmMessage = confirmMessage + ' However, this contract has a dedicated build associated. If you continue it will be deleted.';
          }
          confirmMessage = confirmMessage + ' Would you like to continue to pair this inventory and prefill all unit details?';
          confirmAlert({
            title: "Confirm Prefill",
            message: confirmMessage,
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  newUnitData.manufacturer = _.find(props.manufacturers, x => x.value === inv.manufacturerId);
                  if (inv.unitOptionList && inv.unitOptionList.length) {
                    inv.unitOptionList = _.map(inv.unitOptionList, x => ({ ...x, value: x.id, label: x.name, defaultPrice: x.price }));
                  }
                  // matchInvPair(inv);
                  doPairInventory(inv, probeSerialNumber);
                }
              },
              {
                label: "No"
              }
            ]
          });
        }
      } else {
        props.onSetValidationMessage({message: r.data.message, flavor: "warning"});
        props.onSetDisabled(r.data.disableSale ? true : false);
        setTimeout(() => props.onSetValidationMessage(null), 5000);
      }
    }).catch((e) => console.error(e));    
  }

  function onBlur(value, fieldName) {
    switch(fieldName) {
      case 'estimatedDeliveryDate':
        setUnitData({
          ...unitData,
          [fieldName]: ui_helpers.formatDateField(value)
        });
        break;
      case 'unitPrice':
        const newValue = ui_helpers.cleanseCurrencyCharacters(value);
        if (props.isQuote) {
          api.post('CashSale/UpdateQuoteUnitPrice', {contractId: unitData.contractId, amount: parseFloat(newValue)}).then(r => {
            if (!r.data.success) {
              Alert.error('There was an error saving the unit price.');
              return;
            }
            setUnitPrice(newValue);
            props.onSetData({ ...unitData, unitPrice: newValue });
          }).catch(err => Alert.error(err));
        } else {
          setUnitPrice(newValue);
          props.onSetData({ ...unitData, unitPrice: newValue });
        }
        break;
      case 'quoteDownPayment':
        props.onSetData({ ...unitData, quoteDownPayment: ui_helpers.cleanseCurrencyCharacters(value) });
        // setUnitData({
        //   ...unitData,
        //   quoteDownPayment: ui_helpers.cleanseCurrencyCharacters(value)
        // });
        break;
      case 'serialNumber':
        value = ui_helpers.capitalizeAllLetters(value);
        props.onSetData({ ...unitData, serialNumber: value });
        // setUnitData({
        //   ...unitData,
        //   [fieldName]: value
        // });
        if (!value || value.toUpperCase() === 'TBD') return;
        if (!unitData.captiveInventoryId) {
          tryPairInventory(value);
        }
        break;
      case "taxRateString":
        let newTaxRate = parseFloat(value);
        if (newTaxRate > 1.0) {
          newTaxRate /= 100.0;
        }
        newTaxRate = parseFloat(newTaxRate.toFixed(5));
        setUnitData({
          ...unitData,
          taxRate: newTaxRate
        });
        onChange(newTaxRate, 'taxRate');
        break;
      }
  }

  function getUniqueValues(array) {
    if (!array) return;
    return array.reduce((uniqueItem, o) => {
      if(!uniqueItem.some(obj => obj.label === o.label)) {
        uniqueItem.push(o);
      }
      return uniqueItem;
    }, []);
  }

  function applySkuChange(newUnitData, newSku) {
    newUnitData.widthFeet = newSku.width;
    newUnitData.lengthFeet = newSku.length;
    newUnitData.unitManufacturerSubProductSkuId = newSku.value;
    newUnitData.unitManufacturerSubProductSkuName = newSku.label;
    newUnitData.typeOfPorch = newSku.porchTypeId;
    newUnitData.porchMax = newSku.porchMax;
    newUnitData.porchMin = newSku.porchMin;
    newUnitData.porchWall1Length = newSku.porchWall1Length;
    newUnitData.porchWall2Length = newSku.porchWall2Length;
    newUnitData.porchWall3Length = newSku.porchWall3Length;
    newUnitData.porchWall4Length = newSku.porchWall4Length;
    return newUnitData;
  }

  function onChange(value, fieldName, callback) {
    let newData = {
      ...unitData,
      taxAmount: taxAmount // this was misbehaving on state as a property so it was given its own variable...
    };
    if (fieldName === 'subproductSku' && value) {
      if (value.defaultPrice && (!unitData.contractAddOns || !unitData.contractAddOns.length)) {
        newData[fieldName] = value;
        setUnitPrice(value.defaultPrice); // does not take into account add-on prices
        newData = applySkuChange(newData, value);
      } else {
        // prompt to verify the change & removal of existing add-ons
        setSelectedSku(value);
      }
    }
    else if (fieldName === "inventoryId" && value) {
      onBlur(value.serialNumber, "serialNumber");
    }
    else if (fieldName === "subproduct" && value && !window.isPairing) {
      newData[fieldName] = value;
      newData.subproductSku = null;
      newData.widthFeet = null;
      newData.lengthFeet = null;
      newData.otherWidth = "";
      newData.otherWidth = "";
    } else if (fieldName === 'unitPrice') {
      newData.unitPrice = value;
      setUnitPrice(value);
    } else {
      newData[fieldName] = value;
    }
    props.onSetData(newData);
    if (callback) {
      callback(value);
    }
  }

  function onChangeNewInventory(value, fieldName) {
    let newData = {
      ...unitData
    };
    if (fieldName === 'unitIsInventory') {
      if (value === true) {
        newData[fieldName] = value;
      } else {
        if (props.customSerialEnabled) {
          newData.serialNumber = "";
        }
        if (unitData.captiveInventoryId) {
          // no change, as this is not allowed...
          Alert.warning('This agreement cannot be switched to Order Sale. If inventory is no longer the right choice you\'ll need to cancel the agreement and start again.');
        } 
        else if (unitData.inventoryId)
        {
          confirmAlert({
            title: "Confirm Unpairing",
            message: 'This agreement is currently paired to inventory. Are you sure you wish to unpair that inventory and proceed with a new, order sale situation? If you choose to unpair, be SURE to change this agreement unit\'s serial number to avoid an automatic re-pairing by serial # match.',
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  api.post(`Inventory/UnpairContract/${unitData.inventoryId}`).then(r => {
                    if (r.data.success) {
                      newData.inventoryId = null;
                      newData[fieldName] = value;
                      //setUnitData(newData);
                      props.onSetData(newData);
                    } else {
                      Alert.error('Unable to unpair due to an unexpected error: ' + r.data.message);
                    }
                  }).catch(api.catchHandler);
                }
              },
              {
                label: "No"
              }
            ]
          });
        } else {
          newData[fieldName] = value;
        }
      }
    } else {
      newData[fieldName] = value;
    }
    if (fieldName === 'unitIsNew' && value === false) {
      newData.unitIsInventory = true;
    }
    // setUnitData(newData);
    props.onSetData(newData);
  }

  function getInventoryAddOnPrice(addOn) {
    if (!addOn.bundleId) {
      return ui_helpers.formatCurrency(addOn.defaultPrice);
    }
  }

  function handleBundleAddOns(newData) {
    props.onSetData(newData);
  }

  function addOnDisplayCells(addOn) {
    let additionalOptions = false;
    let name = addOn.name;
    let priceString = '';
    let priceString2 = '';
    if (addOn.bundleId) {
      priceString = 'Bundled Item';
    } else {
      let unitsString = " ea.";
      let addOnPrice = parseFloat(addOn.price || 0.00);
      if (addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT) {
        unitsString = " per ft";
        const multiplier = parseFloat(addOn.length) / 12; // expressed in inches
        priceString2 =  `x${multiplier} = ${ui_helpers.formatCurrency(addOnPrice * multiplier)}`;
      }
      else if (addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT)
      {
        unitsString = " per sq ft";
        const multiplier1 = parseFloat(addOn.length) / 12; // expressed in inches
        const multiplier2 = parseFloat(addOn.width) / 12; // expressed in inches
        priceString2 =  `x${multiplier1 * multiplier2} = ${ui_helpers.formatCurrency(addOnPrice * multiplier1 * multiplier2)}`;
      }
      else if (addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE)
      {
        unitsString = " % of base";
        // console.log('calculate % of base?', addOn)
      }
      else
      {
        if (addOn.additionalOption1On && addOn.additionalOptionType1Price) {
          addOnPrice += addOn.additionalOptionType1Price;
          additionalOptions = true;
        }
        if (addOn.additionalOption2On && addOn.additionalOptionType2Price) {
          addOnPrice += addOn.additionalOptionType2Price;
          additionalOptions = true;
        }
      }
      priceString = `${ui_helpers.formatCurrency(addOnPrice)} ${unitsString}`;
    }
    return (<Fragment>
      <td>{name}{additionalOptions ? " w/ options" : ""}</td>
      <td colSpan={2}>
        {addOn.bundleId
          ? (<span className='text-muted' style={{fontStyle: "italic"}}>{priceString}</span>)
          : null
        }
        {!addOn.bundleId
          ? (<Fragment>
              <div style={{fontSize: "0.9rem"}}>{priceString}</div>
              {priceString2
                ? (<div className='text-muted' style={{fontSize: "0.9rem"}}>{priceString2}</div>)
                : null
              }              
            </Fragment>)
          : null
        }
      </td>
    </Fragment>);
  }

  function confirmPairInv(selectedInv) {
    if (shouldDeleteCaptiveInventory()) {
      confirmAlert({
        title: "Confirm Prefill",
        message: 'This contract has a dedicated build associated. If you continue it will be deleted. Are you certain that you wish to continue to pair this inventory, prefill all unit details and discard the previously associated inventory?',
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              doPairInventory(selectedInv);
            }
          },
          {
            label: "No"
          }
        ]
      });
    } else {
      doPairInventory(selectedInv);
    }
  }

  function applyInventoryValues(data, selectedInv, pRoofColors, pTrimColors, pBaseColors) {
    data.inventoryId = selectedInv.id ? selectedInv.id : selectedInv.inventoryId;
    data.serialNumber = selectedInv.serialNumber;
    data.manufacturer = selectedInv.manufacturerId
      ? { value: selectedInv.manufacturerId, label: selectedInv.manufacturerName }
      : constants.OTHER_OPTION;
    //data.dealer = { value: selectedInv.dealerId, label: selectedInv.dealerName };
    data.productType = selectedInv.productTypeId
      ? { value: selectedInv.productTypeId, label: selectedInv.productTypeName }
      : constants.OTHER_OPTION;
    data.otherSubproduct = selectedInv.unitManufacturerSubProductText;
    data.otherRoofColor = selectedInv.unitManufacturerRoofColorText;
    data.otherTrimColor = selectedInv.unitManufacturerTrimColorText;
    data.otherBaseColor = selectedInv.unitManufacturerBaseColorText;
    data.otherUnitType = selectedInv.unitTypeName || selectedInv.unitTypeOtherText;
    data.unitIsNew = selectedInv.unitIsNew;
    data.unitIsInventory = true;
    data.roofColor = null;
    data.trimColor = null;
    data.baseColor = null;
    data.unitType = null;
    ui_helpers.resolveListEntryPropsByText(data, 'unitType', 'otherUnitType', props.unitTypes);
    if (pRoofColors) {
      ui_helpers.resolveListEntryPropsByText(data, 'roofColor', 'otherRoofColor', pRoofColors);
      ui_helpers.resolveListEntryPropsByText(data, 'trimColor', 'otherTrimColor', pTrimColors);
      ui_helpers.resolveListEntryPropsByText(data, 'baseColor', 'otherBaseColor', pBaseColors);      
    }
    data.unitManufacturerSubproductId = selectedInv.unitManufacturerSubProductId;
    data.unitManufacturerSubproductName = selectedInv.unitManufacturerSubProductText;
    data.subproduct = selectedInv.unitManufacturerSubProductId && selectedInv.unitManufacturerSubProductId > 0
      ? { value: selectedInv.unitManufacturerSubProductId, label: selectedInv.unitManufacturerSubProductText }
      : constants.OTHER_OPTION;
    data.unitManufacturerSubProductSkuId = selectedInv.skuId || selectedInv.unitManufacturerSubProductSkuId;
    data.unitManufacturerSubProductSkuName = selectedInv.unitManufacturerSubProductSkuName;
    data.subproductSku = data.unitManufacturerSubProductSkuId && data.unitManufacturerSubProductSkuId > 0
      ? { value: data.unitManufacturerSubProductSkuId, label: selectedInv.unitManufacturerSubProductSkuName }
      : constants.OTHER_OPTION;
    data.otherWidth = selectedInv.resolvedWidthFeet
      ? selectedInv.resolvedWidthFeet
      : selectedInv.unitWidthFeetOtherText
        ? selectedInv.unitWidthFeetOtherText
        : selectedInv.unitWidthFeet;
    data.otherLength = selectedInv.resolvedLengthFeet
      ? selectedInv.resolvedLengthFeet
      : selectedInv.unitLengthFeetOtherText
        ? selectedInv.unitLengthFeetOtherText
        : selectedInv.unitLengthFeet;
    data.otherHeight = selectedInv.resolvedHeightFeet;
    data.widthFeet = selectedInv.resolvedWidthFeet || selectedInv.unitWidthFeet;
    data.lengthFeet = selectedInv.resolvedLengthFeet || selectedInv.unitLengthFeet;
    data.heightFeet = selectedInv.resolvedHeightFeet;
    data.unitLengthFeet = null;
    data.unitWidthFeet = null;
    if (selectedInv.associatedBundles) {
      data.associatedBundles = selectedInv.associatedBundles;
    }
  }

  function doPairInventory(selectedInv, overrideSerialNumber = null) {
    window.isPairing = true;
    let data = Object.assign({}, unitData);
    applyInventoryValues(data, selectedInv);
    let contractAddOns = [];
    setExecutingPairing(true);
    const invId = selectedInv.id
      ? selectedInv.id
      : selectedInv.inventoryId;
    api.post(`inventory/GetInventoryAddonWithPrice/` + invId).then(res => {
      let newUnitPrice = unitPrice;
      if (res.data && res.data.success) {
        newUnitPrice = res.data.message.price;
        contractAddOns = res.data.message.addOnList.length
          ? _.map(res.data.message.addOnList, u => {
              return {
                ...u,
                id: 0,
                addOnId: u.addOnId > 0 ? u.addOnId : 0,
                unique: randomInt(),
              };
          })
          : [];
      }
      maybeCancelCaptive(() => {
        data.contractAddOns = contractAddOns;
        data.unitPrice = newUnitPrice;
        setUnitPrice(newUnitPrice);
        props.onSetData(data);
        props.onUpdateContractAddons(contractAddOns, false, data);
        props.clearUnpairedInvList();
        props.loadPairedInventoryLists(data.manufacturer.value, data.productType.value, data.subproduct.value, 
            data.subproductSku.value, newUnitPrice, invId)
          .then(({newUnitData, spOptions, skuOptions, bcOptions, trimOptions, roofOptions}) => {
            setSubProducts(spOptions);
            setProductSkuOptions(skuOptions)
            setBaseColors(bcOptions);
            setTrimColors(trimOptions);
            setRoofColors(roofOptions);
            setTimeout(() => {
              if (overrideSerialNumber) {
                selectedInv.serialNumber = overrideSerialNumber;
              }
              applyInventoryValues(newUnitData, selectedInv, roofOptions, trimOptions, bcOptions);
              setUnitData(newUnitData);
              window.isPairing = false;
              props.setLastPairedInventoryId(invId);
              setInvPairingMode(false);
              setExecutingPairing(false);              
            }, 500);
          }).catch(() => setExecutingPairing(false));
      });
    }).catch(() => setExecutingPairing(false));
  }

  function onShowLayoutEditor() {
    let mfgId = unitData.manufacturer && unitData.manufacturer.value !== constants.OTHER_ID
      ? unitData.manufacturer.value
      : null;
    if (!mfgId) {
      Alert.warning('A manufacturer must be selected in order to design a floor plan.');
      return;
    }
    let skuId = unitData.subproductSku.value && unitData.subproductSku.value !== constants.OTHER_ID
      ? unitData.subproductSku.value
      : null;
    const payload = {
      ManufacturerId: mfgId,
      SubProductSKUId: skuId,
      InventoryId: null,
      QuoteContractId: props.isQuote
        ? props.contractId
        : null,
      ActiveOnly: true
    };
    api.post("Reference/GetMfgListForFloorPlan", payload).then(res => {
      if (res.data.success) {
        setSelectedFPAddOns(res.data.message.selectedAddOnOptions)
        setMfgFPAddOnOptions(res.data.message.addonOptions)
        setOptionColors(ui_helpers.idNameToValueLabel(res.data.message.optionColors, null, false));
        setBundleOptions(res.data.message.bundleOptions || []);
        setUnitData({
          ...unitData,
          porchColorId: res.data.message.mfgSku.porchColorId, 
          typeOfPorch: res.data.message.mfgSku.porchTypeId,
          porchMax: res.data.message.mfgSku.porchMax,
          porchMin: res.data.message.mfgSku.porchMin,
          porchWall1Length: res.data.message.mfgSku.porchWall1Length,
          porchWall2Length: res.data.message.mfgSku.porchWall2Length,
          porchWall3Length: res.data.message.mfgSku.porchWall3Length,
          porchWall4Length: res.data.message.mfgSku.porchWall4Length,
          porchColorId: res.data.message.inventory?.porchColorId,
          fontSize: res.data.message.inventory?.fontSize,
          rulerOffset: res.data.message.inventory?.rulerOffSet
        });
      }
      setShowEditor(true);
    }).catch(err => console.error(err));
  }

  function resolveSkuBasePrice() {
    if (unitData.subproductSku && unitData.subproductSku.basePrice) {
      return unitData.subproductSku.basePrice;
    } else if (unitData.subproductSku && unitData.subproductSku.value && subProductSkuOptions && subProductSkuOptions.length) {
      const sps = _.find(subProductSkuOptions, x => x.value === unitData.subproductSku.value);
      if (sps) {
        return sps.basePrice;
      }
    }
    return unitPrice;
  }

  function renderFloorplan() {
    const skuBasePrice = resolveSkuBasePrice();
    const fakeInventory = {
      id: unitData.contractId,
      unitWidthFeet: unitData.widthFeet,
      unitWidthFeetOtherText: unitData.otherWidth,
      unitLengthFeet: unitData.lengthFeet,
      unitLengthFeetOtherText: unitData.otherLength,
      unitHeightFeet: unitData.heightFeet,
      unitHeightFeetOtherText: unitData.otherHeight,
      agreedPrice: unitData.agreedPrice,
      unitManufacturerSubProductSku: unitData.subproductSku,
      basePrice: skuBasePrice, 
      standardSkuBasePrice: skuBasePrice,
      typeOfPorch: unitData.typeOfPorch,
      porchMax: unitData.porchMax,
      porchMin: unitData.porchMin,
      porchWall1Length: unitData.porchWall1Length,
      porchWall2Length: unitData.porchWall2Length,
      porchWall3Length: unitData.porchWall3Length,
      porchWall4Length: unitData.porchWall4Length,
      porchColorId: unitData.porchColorId,
      rulerOffset: unitData.rulerOffset
      //standardRetailPrice: null
    };
    const exterior = floorplan_helpers.mapExteriorForFP(fakeInventory, constants.FPContext.contract);
    return (<FloorplanTool
      noInventory
      editingContext={constants.FPContext.contract}
      exterior={exterior}
      optionColors={optionColors}
      addOnOpts={floorplan_helpers.mapAddonOptionsForFP(mfgFPAddOnOptions, constants.FPContext.contract)}
      selectedAddOns={floorplan_helpers.mapSelectedAddonOptionsForFP(selectedFPAddOns, constants.FPContext.contract, exterior.height)}
      saveCallback={onFPSave}
      rulerOffset={fakeInventory.rulerOffset || floorplan_helpers.defaultRulerOffset}
      fontSize={fakeInventory.fontSize || floorplan_helpers.defaultFontSize}
      onExit={() => setShowEditor(false)}
      bundleOptions={floorplan_helpers.mapBundlesForFP(bundleOptions, constants.FPContext.contract)}
      modelName={getHasSku()
      ? unitData.subproductSku.label
      : unitData.serialNumber}
    />);
  }

  function onFPSave(exterior, selectedAddOns, rulerOffset, fontSize, imgUrl) {
    floorplan_helpers.saveFloorPlanChanges(
      constants.FPContext.contract,
      exterior,
      selectedAddOns,
      fontSize,
      rulerOffset,
      imgUrl,
      (newPrice, newAddOnList) => {
        _.each(newAddOnList, x => {
          x.label = x.addOnName;
          x.resolvedPrice = x.price;
          if (x.addOnTypeId === constants.ADDON_TYPE_ID.LOFT && !x.width) {
            x.width = exterior.height;
          }
        });
        const newList = _.map(newAddOnList, mapAddOnToUnit);
        const newUnit = {
          ...unitData,
          unitPrice: newPrice,
          contractAddOns: newList
        };
        setUnitPrice(newPrice);
        props.onSetDataAndAddOns(newUnit);
        setTimeout(recalculateTax, 500);
      },
      null,
      unitPrice,
      unitData.subproductSku ? unitData.subproductSku.value : null
    );
  }

  const heightOptions = sizeOptions('HEIGHT');
  const widthOptions = sizeOptions('WIDTH');
  const lengthOptions = sizeOptions('LENGTH');
  const unitPriceDisabled = props.getUnitPriceDisabled(getHasSku());
  const disableSKUChanges = invPairingMode
    || (unitData.agreedPrice && unitData.inventoryId)
    || (!unitData?.captiveInventoryId && unitData.inventoryId);
  return (
    <Fragment>
    <Form className="mt-2">
      {isLoading || executingPairing ? <Loading /> : (
      <Row>
        <Col>
          {unitData.captiveInventoryId
            ? null
            : (<FormGroup>
                <Label>
                  Pair To Inventory? 
                    <ToggleButton
                      value={invPairingMode}
                      activeLabel={"Yes"}
                      inactiveLabel={"No"}
                      colors={constants.TOGGLE_BUTTON_COLORS}
                      onToggle={(e) => {
                          if (invPairingMode) {
                            props.clearUnpairedInvList();
                          }
                          setInvPairingMode(!e); 
                        }
                      }
                    />
                </Label>
              </FormGroup>)
          }
          {invPairingMode
            ? (<Fragment>
              {showSearchingUnpairedSpinner
                ? (<Spinner size='sm' />)
                : (<div>
                    <Row>
                      <Col xs={9}>
                        <Label>
                          Search Inventory <FontAwesomeIcon icon="info-circle" id="invSearchInfo"/>: 
                          <UncontrolledTooltip
                            placement="right"
                            target="invSearchInfo"
                          >
                            Ex: Serial #, Invoice #, Base/Trim/Roof color, Manufacturer name
                          </UncontrolledTooltip>
                        </Label>
                        <DebounceInput
                          type="text"
                          min={3}
                          className="form-control"
                          debounceTimeout={300}
                          placeholder="Type at least 3 characters to search"
                          value={props.searchText}
                          onChange={(e) => {
                            props.setSearchText(e.target.value);
                          }}
                        />
                      </Col>
                      <Col xs={3}>
                        <Button size="sm" className="float-end mt-4" color="primary" onClick={() => props.findUnpairedInv(props.searchText)}>
                          Find
                        </Button>
                      </Col>
                    </Row>
                    {props.searchText && props.unpairedInvList && <>
                      <Table>
                          <thead>
                            <tr>
                              <th>Results</th>
                              <th/>
                            </tr>
                          </thead>
                          <tbody>
                          {props.unpairedInvList.dataSource && props.unpairedInvList.dataSource.length
                            ? props.unpairedInvList.dataSource.map(i => ( 
                              <tr key={i.id}>
                                <td>
                                   <div><span className="boldData" >Serial Number:</span> {i.serialNumber}</div>
                                   <div className="small-text"><span className="boldData" >Product Type</span>: {i.productTypeName} | <span className="boldData" >Style:</span> {i.unitManufacturerSubProductText}</div>
                                   <div className="small-text"><span className="boldData" >Base:</span> {i.unitManufacturerBaseColorText} | <span className="boldData" >Roof:</span> {i.unitManufacturerRoofColorText} | <span className="boldData" >Trim:</span> {i.unitManufacturerTrimColorText}</div>
                                   <div className="small-text"><span className="boldData" >Manufacturer:</span> {i.manufacturerName}</div>
                                   <div className="small-text"><span className="boldData" >Dealer:</span> {i.dealerName}</div>
                                   <div className="small-text"><span className="boldData" >Invoice Number:</span> {i.invoiceNumber} | <span className="boldData" >Model Number:</span> {i.modelNumber}</div>
                                </td>
                                <td>
                                   <Button
                                     size="sm"
                                     color="primary"
                                     onClick={() => confirmPairInv(i)}
                                   >
                                     Select
                                   </Button>
                                </td>
                              </tr>
                             ))
                            : (<tr>No Inventory Records Found</tr>)
                          }
                          </tbody>
                      </Table>
                      <Pager pageNumber={props.unpairedInvList ? props.unpairedInvList.pageNumber : 1} pageSize={props.unpairedInvList ? props.unpairedInvList.pageSize : 0} totalResults={props.unpairedInvList ? props.unpairedInvList.totalItems : 0} onSetPageCallback={props.setPageNumber} />
                      </>
                    }
                  </div>)
              }
              </Fragment>) 
            : null
          }
          <FormGroup>
            <Label>
              Manufacturer {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.manufacturer ? screenWarnings.manufacturer : null}>
              <Select
                options={props.manufacturers}
                classNamePrefix={screenWarnings.manufacturer ? "invalidSelect" : null}
                value={unitData.manufacturer}
                onChange={(x) => onChange(x, 'manufacturer')}
                isDisabled={invPairingMode}
              />
            </div>
            {unitData.manufacturer && unitData.manufacturer.value === constants.OTHER_ID
              ? (<div className="control-group">
                  <Input type="text" className="mt-1" bsSize="sm" maxLength="150" placeholder="Other Details" 
                    value={unitData.otherManufacturer} 
                    onChange={(e) => onChange(e.target.value, 'otherManufacturer')}
                    disabled={invPairingMode} />
                </div>)
              : null
            }
          </FormGroup>
          <FormGroup>
            <Label>Is this unit?</Label>
            <div className={classnames({"active": unitData.unitIsNew === true}, "badge badge-large badge-primary")}
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                onChangeNewInventory(true, 'unitIsNew');
              }}>
              <Input type="radio" className="mt-0" name="new_or_used" checked={unitData.unitIsNew === true} onChange={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                onChangeNewInventory(true, 'unitIsNew');
              }} /> NEW
            </div>
            <div className={classnames({"active": unitData.unitIsNew === false}, "badge badge-large badge-clown")} 
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                onChangeNewInventory(false, 'unitIsNew');
              }}>
              <Input type="radio" className="mt-0" name="new_or_used" checked={unitData.unitIsNew === false} onChange={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                onChangeNewInventory(false, 'unitIsNew');
              }} /> USED
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Unit is:</Label>
            <div
              disabled={unitData.unitIsNew === false}
              className={classnames({"active": unitData.unitIsInventory === true}, "badge badge-large badge-primary")} 
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                if (unitData.unitIsNew !== false) {
                  onChangeNewInventory(true, 'unitIsInventory');
                }
              }}>
              <Input type="radio" className="mt-0" name="sold_from_inv"
                disabled={unitData.unitIsNew === false}
                checked={unitData.unitIsInventory === true}
                onChange={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  if (unitData.unitIsNew !== false) {
                    onChangeNewInventory(true, 'unitIsInventory');
                  }
                }} /> Inventory
            </div>
            <div
              disabled={unitData.unitIsNew === false}
              className={classnames({"active": unitData.unitIsInventory === false}, "badge badge-large badge-clown")} 
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                if (unitData.unitIsNew !== false) {
                  onChangeNewInventory(false, 'unitIsInventory');
                }
              }}>
              <Input type="radio" className="mt-0" name="sold_from_inv" 
                disabled={unitData.unitIsNew === false}
                checked={unitData.unitIsInventory === false} 
                onChange={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  if (unitData.unitIsNew !== false) {
                    onChangeNewInventory(false, 'unitIsInventory');
                  }
                }} /> Order Sale
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              Serial # {ui_helpers.requiredStar()} {props.lastPairedInventoryId ? <Badge color="success" title="Inventory Paired" className="ml-2">Inventory Paired</Badge> : null}
            </Label>
            <Input
              type="text"
              maxLength="40" 
              title={screenWarnings.serialNumber ? screenWarnings.serialNumber : null}
              className={classnames({invalid: (screenWarnings.serialNumber ? true : false)})}
              value={unitData.serialNumber} 
              onChange={(e) => onChange(e.target.value, 'serialNumber')}
              onBlur={(e) => onBlur(e.target.value, 'serialNumber')}
              disabled={!props.isQuote && (invPairingMode || props.customSerialEnabled)}
              placeholder={props.customSerialEnabled && !props.isNewBuild ? "Serial Number will be generated" : ""}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Product Type {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.productType ? screenWarnings.productType : null}>
              <Select
                classNamePrefix={screenWarnings.productType ? "invalidSelect" : null}
                options={productTypes}
                value={unitData.productType}
                onChange={(x) => onChange(x, 'productType')}
                isDisabled={invPairingMode || unitData.agreedPrice}
              />
            </div>
          </FormGroup>
          {props.contractType === constants.CONTRACT_TYPE.QUOTE
            ? (<SalespersonEdit
                salespeople={props.salespeople}
                salesperson={unitData.salesperson}
                otherSalesperson={unitData.otherSalesperson}
                screenWarnings={screenWarnings}
                onChange={onChange}
                onDeleteSalesperson={props.onDeleteSalesperson}
              />)
            : null
          }
          {props.contractType === constants.CONTRACT_TYPE.RTO
          || props.contractType === constants.CONTRACT_TYPE.QUOTE
            ? null
            : (<Fragment>
                <FormGroup style={{clear: "both"}}>
                  <div><Label>Estimated Delivery Date</Label></div>
                  <div className="datePickerDiv">
                    <DatePicker
                      title={screenWarnings.estimatedDeliveryDate ? screenWarnings.estimatedDeliveryDate : null}
                      className={classnames({invalid: (screenWarnings.estimatedDeliveryDate ? true : false)}, 'form-control')}
                      selected={unitData.estimatedDeliveryDate ? unitData.estimatedDeliveryDate.toDate() : new Date()}
                      onChange={(date) => onChange(date ? moment(date) : null, 'estimatedDeliveryDate')}
                      showMonthDropdown
                      showYearDropdown
                      withPortal
                      dropdownMode="select"
                    />
                  </div>
                </FormGroup>
                <SalespersonEdit
                  salespeople={props.salespeople}
                  salesperson={unitData.salesperson}
                  otherSalesperson={unitData.otherSalesperson}
                  screenWarnings={screenWarnings}
                  onChange={onChange}
                  onDeleteSalesperson={props.onDeleteSalesperson}
                />
              </Fragment>)
          }
        </Col>
        <Col className={props.isQuote ? "form-col-even-quote" : "form-col-even"}>
          <FormGroup>
            <Label>
              Style {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.subproduct ? screenWarnings.subproduct : null}>
              <Select
                classNamePrefix={screenWarnings.subproduct ? "invalidSelect" : null}
                options={subProducts}
                value={unitData.subproduct}
                onChange={(x) => onChange(x, 'subproduct')}
                isDisabled={disableSKUChanges}
              />
            </div>
            {unitData.subproduct && unitData.subproduct.label === 'Other'
              ? (<div className="control-group">
                  <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details" 
                    value={unitData.otherSubproduct} 
                    onChange={(e) => onChange(e.target.value, 'otherSubproduct')}
                    disabled={invPairingMode} />
                </div>)
              : null
            }
          </FormGroup>
          <FormGroup>
            <Label>
              SKU / Size {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.subproductSku ? screenWarnings.subproductSku : null}>
              <Select
                classNamePrefix={screenWarnings.subproductSku ? "invalidSelect" : null}
                options={subProductSkuOptions}
                value={unitData.subproductSku}
                onChange={(e) => onChange(e, 'subproductSku')}
                isDisabled={disableSKUChanges}
              />
            </div>
            {unitData.subproductSku && unitData.subproductSku.value === constants.OTHER_ID
               ? (
                   <div style={{minHeight: "50px", marginTop: "4px"}}>
                      <span style={{float: "left", clear: "both", padding: "12px 3px 0 0", fontWeight: "bold"}}>W</span>
                        <div style={{float: "left", width: "80px", display: "inline-block", padding: "0 3px"}} title={screenWarnings.widthFeet ? screenWarnings.widthFeet : null}>
                            <div className="control-group">
                                <Input type="text" bsSize="sm" maxLength="20" value={unitData.otherWidth}
                                    title={screenWarnings.widthFeet ? screenWarnings.widthFeet : null}
                                    className={classnames({ invalid: (screenWarnings.widthFeet ? true : false) })}
                                    onChange={(e) => onChange(e.target.value, 'otherWidth')} disabled={invPairingMode} />
                            </div>
                        </div>
                        <span style={{float: "left", fontSize: "16pt"}}>'</span> 
                        <span style={{float: "left", padding: "12px 3px 0 16px"}}>x</span> 
                        <span style={{float: "left", padding: "12px 3px 0 0", fontWeight: "bold"}}>L</span>
                        <div style={{float: "left", width: "80px", display: "inline-block", padding: "0 3px"}} title={screenWarnings.lengthFeet ? screenWarnings.lengthFeet : null}>
                            <div className="control-group">
                                <Input type="text" bsSize="sm" maxLength="20"
                                    value={unitData.otherLength}
                                    onChange={(e) =>onChange(e.target.value, 'otherLength')}
                                    title={screenWarnings.lengthFeet ? screenWarnings.lengthFeet : null}
                                    className={classnames({ invalid: (screenWarnings.lengthFeet ? true : false) })}
                                                  disabled={invPairingMode} />
                            </div>
                        </div>
                        <span style={{float: "left", fontWeight: "bold", fontSize: "16pt"}}>'</span> 
                        <span style={{float: "left", padding: "12px 3px 0 16px"}}>x</span> 
                        <span style={{float: "left", padding: "12px 3px 0 0", fontWeight: "bold"}}>H</span>
                        <div style={{float: "left", width: "80px", display: "inline-block", padding: "0 3px"}} title={screenWarnings.heightFeet ? screenWarnings.heightFeet : null}>
                          <div className="control-group">
                            <Input
                              type="text"
                              bsSize="sm"
                              maxLength="20" 
                              value={unitData.otherHeight} 
                              onChange={(e) => onChange(e.target.value, 'otherHeight')} 
                              disabled={invPairingMode}
                              placeholder="Optional"
                            />
                          </div>
                        </div>
                        <span style={{float: "left", fontWeight: "bold", fontSize: "16pt"}}>'</span> 
                    </div>

             //   <div className="control-group">
             //     <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details" 
             //       value={unitData.otherSubproduct} onChange={(e) => onChange(e.target.value, 'otherSubproduct')} />
             //   </div>
             )
              : null
            }
          </FormGroup>
          {props.hideUnitType
            ? null
            : (<FormGroup>
                <Label>
                  Material {ui_helpers.requiredStar()}
                </Label>
                <div title={screenWarnings.unitType ? screenWarnings.unitType : null}>
                  <Select
                    classNamePrefix={screenWarnings.unitType ? "invalidSelect" : null}
                    options={props.unitTypes}
                    value={unitData.unitType}
                    onChange={(x) => onChange(x, 'unitType')}
                    isDisabled={invPairingMode}
                  />
                </div>
                {unitData.unitType && unitData.unitType.label === constants.OTHER
                  ? (<div className="control-group">
                      <Input type="text" className="mt-1" bsSize="sm" maxLength="150" placeholder="Other Details" 
                        value={unitData.otherUnitType} onChange={(e) => onChange(e.target.value, 'otherUnitType')}
                        disabled={invPairingMode}
                      />
                    </div>)
                  : null
                }
              </FormGroup>)
          }
          <FormGroup>
            <Label>
              Base Color {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.baseColor ? screenWarnings.baseColor : null}>
              <Select
                classNamePrefix={screenWarnings.baseColor ? "invalidSelect" : null}
                options={baseColors}
                value={baseColors && unitData.baseColor}
                onChange={(x) => onChange(x, 'baseColor')}
                isDisabled={invPairingMode}
              />
            </div>
            {unitData.baseColor && unitData.baseColor.label === constants.OTHER
              ? (<div className="control-group">
                  <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details" 
                    value={unitData.otherBaseColor} onChange={(e) => onChange(e.target.value, 'otherBaseColor')} disabled={invPairingMode}/>
                </div>)
              : null
            }
          </FormGroup>
          <FormGroup>
            <Label>
              Trim Color {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.trimColor ? screenWarnings.trimColor : null}>
              <Select
                classNamePrefix={screenWarnings.trimColor ? "invalidSelect" : null}
                options={trimColors}
                value={trimColors && unitData.trimColor}
                onChange={(x) => onChange(x, 'trimColor')}
                isDisabled={invPairingMode}
              />
            </div>
            {unitData.trimColor && unitData.trimColor.label === constants.OTHER
              ? (<div className="control-group">
                  <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details" 
                    value={unitData.otherTrimColor} onChange={(e) => onChange(e.target.value, 'otherTrimColor')} disabled={invPairingMode}/>
                </div>)
              : null
            }
          </FormGroup>
          <FormGroup>
            <Label>
              Roof Color {ui_helpers.requiredStar()}
            </Label>
            <div title={screenWarnings.roofColor ? screenWarnings.roofColor : null}>
              <Select
                classNamePrefix={screenWarnings.roofColor ? "invalidSelect" : null}
                options={roofColors}
                value={roofColors && unitData.roofColor}
                onChange={(x) => onChange(x, 'roofColor')}
                isDisabled={invPairingMode}
              />
            </div>
            {unitData.roofColor && unitData.roofColor.label === constants.OTHER
              ? (<div className="control-group">
                  <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details" 
                    value={unitData.otherRoofColor} onChange={(e) => onChange(e.target.value, 'otherRoofColor')} disabled={invPairingMode}/>
                </div>)
              : null
            }
          </FormGroup>
        </Col>
        <Col>
          <div className="mb-2">
            <Label>
              Unit Price {ui_helpers.requiredStar()}
            </Label>
            <Input
              type="text"
              maxLength="15"
              style={{minWidth: "140px", width: "40%"}}
              value={
                unitPriceDisabled
                  ? ui_helpers.formatCurrency(unitPrice)
                  : unitPrice
              }
              title={
                screenWarnings.unitPrice
                  ? screenWarnings.unitPrice
                  : null
              }
              className={classnames(
                { invalid: screenWarnings.unitPrice ? true : false },
                "text-end form-control"
              )}
              onChange={(e) => onChange(e.target.value, "unitPrice")}
              onBlur={(e) => onBlur(e.target.value, "unitPrice")}
              disabled={unitPriceDisabled}
            />
{/*            <Input
              type="text"
              maxLength="15"
              value={unitPriceDisabled ? ui_helpers.formatCurrency(unitPrice) : unitPrice} 
              onChange={(e) => onChange(e.target.value, 'unitPrice')} 
              onBlur={(e) => onBlur(e.target.value, 'unitPrice')} 
              disabled={unitPriceDisabled}
            />
*/}          </div>
          {props.isQuote
            ? (<Fragment>
                  <div className="mb-1">
                    <Label>
                      Custom Down Payment (Only if known)
                    </Label>
                    <Input type="text" maxLength="15" value={unitData.quoteDownPayment} 
                      onChange={(e) => onChange(e.target.value, 'quoteDownPayment')} 
                      onBlur={(e) => onBlur(e.target.value, 'quoteDownPayment')} 
                    />
                  </div>
                  <PriceAdjustmentEditor
                    unitPrice={unitPrice}
                    priceAdjustments={props.priceAdjustments}
                    adjustmentTypeOptions={props.adjustmentTypeOptions}
                    pendingPriceAdjustment={props.pendingPriceAdjustment}
                    addPriceAdjustment={props.addPriceAdjustment}
                    deletePriceAdjustment={props.deletePriceAdjustment}
                    setPendingPriceAdjustment={props.setPendingPriceAdjustment}
                  />
                  <Row className="mb-1">
                    <Col xs="6">
                      <Label>Tax Rate</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        bsSize="sm"
                        maxLength="15"
                        value={unitData.taxRateString}
                        title={
                          screenWarnings.taxRate
                            ? screenWarnings.taxRate
                            : null
                        }
                        className={classnames(
                          { invalid: screenWarnings.taxRate ? true : false },
                          "text-end" // form-control
                        )}
                        style={{ width: "80px", float: "left" }}
                        onChange={(e) => onChange(e.target.value, "taxRateString")}
                        onBlur={(e) => onBlur(e.target.value, "taxRateString")}
                      />
                      <div
                        style={{
                          float: "left",
                          width: "50px",
                          paddingTop: "4px",
                        }}
                      >
                        {" "}
                        %
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs="6">
                      <Label>Tax Amount</Label>
                    </Col>
                    <Col xs="6">
                      <Input
                        type="text"
                        bsSize="sm"
                        className="text-end"
                        readOnly
                        disabled
                        value={ui_helpers.formatCurrency(taxAmount)}
                      />
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col>
                      <Button size="lg" color="dark" onClick={onShowLayoutEditor}>
                        Launch Add-Ons &amp; Layout Editor{" "}
                        <FontAwesomeIcon icon="arrow-right" />
                      </Button>
                    </Col>
                  </Row>                  
              </Fragment>)
            : (<div className={'mt-4'}>
                <DeliveryInfo
                  buildingOrientations={props.buildingOrientations}
                  buildingOrientation={unitData.buildingOrientation}
                  deliveryInstructions={unitData.deliveryInstructions || ""}
                  screenWarnings={screenWarnings}
                  onChange={onChange}
                />
              </div>)
          }
        </Col>
        {existingContractPairing
          ? (<Modal isOpen={true}>
              <ModalHeader>
                <Button className="close float-right" dataDismiss="modal" ariaLabel="Close" onClick={() => setExistingContractPairing(null)}>
                  <FontAwesomeIcon icon="times-circle" />
                </Button>
                <h4 className="modal-title">Existing Contract Paired</h4>
              </ModalHeader>
              <ModalBody>
                This inventory item is currently paired with contract {existingContractPairing.existingContractAgreementNumber}.
              </ModalBody>
              <ModalFooter>
                <ButtonGroup className="float-right">
                  <Button dataDismiss="modal" size="sm" onClick={() => setExistingContractPairing(null)}>
                    Cancel
                  </Button>
                  <Button color="dark" size="sm" onClick={onConfirmRepairing}>
                    Unpair &amp; Replace
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>)
          : null
        }       
          <Col className={props.isQuote ? "form-col-even-quote" : "form-col-even"}>
          {message ? <div><RSAlert color={message.flavor}>{message.message}</RSAlert></div> : null}
          {unitData && unitData.unitIsNew === null ? null : (
          (!props.isNewBuild || !props.enableBuildFeature || props.isQuote) 
            ? <Fragment>
                <BundleInfo
                  selectedSku={unitData.subproductSku}
                  manufacturer={unitData.manufacturer}
                  data={unitData}
                  onChange={handleBundleAddOns}
                  bundleContext={constants.BUNDLE_CONTEXT.contract}
                  updateAddons={props.onUpdateContractAddons}
                />
                <div>
                  <FormGroup>
                    <Row>
                      <Label>Unit Add-Ons</Label>
                      <Col xs={6} title={newAddOn ? newAddOn.label : ""}>
                        <Select
                          options={unitAddOns}
                          isDisabled={unitPriceDisabled}
                          defaultValue={{value: constants.SELECT_OPTION_ID, label: "Select Option"}}
                          value={newAddOn}
                          onChange={(e) => {
                            if (e.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT) {
                              e.length = constants.DEFAULT_LINEAR_FOOT;
                            } else if (e.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT) {
                              e.sqFt = constants.DEFAULT_SQ_FT;
                            // } else if (e.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE) {
                            //   console.log('set event params here?', e)
                            }
                            e.qty = 1;
                            setNewAddOn(e)
                          }}
                        />
                        {newAddOn && newAddOn.value === constants.OTHER_ID
                          ? (<div className="control-group">
                              <Input type="text" bsSize="sm" className="mt-1" maxLength="150" placeholder="Other Details"
                                value={otherNewAddOnName} onChange={(e) => setOtherNewAddOnName(e.target.value)} />
                            </div>)
                          : null
                        }
                      </Col>
                      <Col xs={6}>
                        {newAddOn && newAddOn.value !== constants.SELECT_OPTION_ID
                          ? (<Fragment>
                              <CurrencyInput
                                prefix={'$'}
                                decimalsLimit={2}
                                className={`form-control float-start`}
                                style={{width: "4rem", textAlign: "right"}}
                                value={newAddOn.resolvedPrice || 0.00}
                                placeholder="Price"
                                onValueChange={(e) => setNewAddOn({...newAddOn, resolvedPrice: e})} 
                              />
                              {(newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT 
                                || newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT)
                                && newAddOn.addOnTypeId !== constants.ADDON_TYPE_ID.OTHER
                                && newAddOn.typeOfAddOn !== constants.ADDON_TYPE_ID.OTHER
                                ? <div className="control-group">
                                    <CurrencyInput
                                      suffix={newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT
                                        ? 'ft'
                                        : 'sqft'}
                                      decimalsLimit={2}
                                      className='form-control float-start ms-1'
                                      style={{width: "70px", textAlign: "right"}}
                                      value={newAddOn.sqFt || newAddOn.length || 0}
                                      placeholder="# Feet"
                                      onValueChange={(e) => {
                                        let newState = {
                                          ...newAddOn
                                        };
                                        if (newAddOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT) {
                                          newState.sqFt = e;
                                        } else {
                                          newState.length = e;
                                        }
                                        setNewAddOn(newState);
                                      }} 
                                    /> 
                                  </div>
                                : null
			                        }
                              {newAddOn.value !== constants.OTHER_ID || otherNewAddOnName
                                ? (<Button color="success" className="text-light float-end" onClick={onAddOption}>
                                    <FontAwesomeIcon icon="plus-circle" />
                                  </Button>)
                                : null
                              }
                            </Fragment>)
                          : null
                        }
                      </Col>
                    </Row>
                  </FormGroup>
                <Row>
                  <Col>
                    <Table>
                        <tbody>
                        {unitData && unitData.contractAddOns && unitData.contractAddOns.length
                            ? _.map(unitData.contractAddOns, (u, idx) => (
                              <tr key={`uo${idx}-${u.id}`}>
                                {addOnDisplayCells(u)}
                                <td>
                                  {u.bundleId
                                    ? (<Button
                                          size="sm"
                                          color="danger"
                                          className="text-white float-end"
                                          onClick={() => onDeleteBundle(u.bundleId)}
                                        >
                                        <FontAwesomeIcon icon="times-circle" /> B
                                      </Button>)
                                    : null
                                  }
                                  {!u.bundleId && (props.isQuote || !unitPriceDisabled)
                                    ? (<Button
                                          size="sm"
                                          color="danger"
                                          className="text-white float-end"
                                          onClick={() => onDeleteOption(u)}
                                        >
                                        <FontAwesomeIcon icon="times-circle" />
                                      </Button>)
                                    : null
                                  }
                                </td>
                              </tr>))
                            : null
                        }
                        </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Fragment>
            :  <div><Row><Col className="p-3"><AddOnAdvice text={`Add-Ons will be added in the Build step`}/></Col></Row></div>
          )}
        </Col>    
      </Row>)}
      <Modal isOpen={selectedSku !==null} toggle={() => setSelectedSku(null)}>
        <ModalHeader>Confirm SKU Size Change</ModalHeader>
        <ModalBody>
            Changing the SKU Size will remove the current list of unit options. Are you sure you want to proceed?
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button color="secondary" onClick={() => setSelectedSku(null)}>No</Button>
            <Button color="primary" onClick={() => { 
              const newData = applySkuChange({
                ...unitData,
                subproductSku: selectedSku
              }, selectedSku);
              setUnitData(newData);
              props.onSetData(newData);
              setSelectedSku(null);
            }}>Yes</Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </Form>
    {showEditor && (
      <div style={constants.popOutStyle}>
        {renderFloorplan()}
      </div>
    )}
    </Fragment>
  );
};

export default UnitInformation;
