import React from "react";

export default function AddOnAdvice(props) {
  return (
    <div style={{ fontStyle: "italic" }}>
      {props.text ? (
        props.text
      ) : (
        <div>
          Please note, any add-ons with a price above '$0' will add to the sale
          price of the contract. If you do{" "}
          <span style={{ fontWeight: "bold" }}>not</span> intend to increase the
          base price of the unit, leave the price on each add-on at $0.
        </div>
      )}
    </div>
  );
}
