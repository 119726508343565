import React, {useState, useEffect} from 'react';
import {useParams, useLocation, Link} from "react-router-dom";
import classnames from "classnames";
import {MfgAdmin, Footer} from "./";
import {Alert as AlertBanner, Button, Card, CardBody, CardHeader, Col, Container, Label, Row, Table} from "reactstrap";
import {api, ui_helpers} from "../helpers";
import Alert from "react-s-alert-v3";
import {constants} from "../utils";
import _ from "lodash";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToggleButton from "react-toggle-button";

export default function ManufacturerStores(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [stores, setStores] = useState([]);
  const [manufacturerStores, setManufacturerStores] = useState([]);
  const [storeToSave, setStoreToSave] = useState({});
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const [manufacturerName, setManufacturerName] = useState('');

  useEffect(() => {
    refreshStores();
    refreshMfgStores();
  }, []);

  // function getMfgAssociatedStores() {
  //     return api.fetch(`manufacturer/getManufacturerAssociatedStores/${mfgId}`)
  //         .then(r => {
  //             if(!r.data.success){
  //                 Alert.error(r.data.message);
  //             }
  //             return {incStores: ui_helpers.idNameToValueLabel(r.data.message)}
  //         }).catch(err => console.error(err));
  // }

  function saveStoreToManufacturer(storeToSave) {
    if(_.isEmpty(storeToSave)) 
    {
        Alert.error("Please select a store before attempting to add")
        return;
    }
    const ManufacturerDealerDto = {
        ManufacturerId: mfgId,
        DealerId: storeToSave.value,
        isInventory: true,
        isSales: true
    };
    api.post("manufacturer/SaveManufacturerDealer", ManufacturerDealerDto).then(response => {
      if(!response.data.success) return;
      refreshMfgStores();
    }).catch(error => setMessage({
      message:"There was an error saving the manufacturer store",
      flavor: constants.flavor.error}
    ));
  }

  function refreshMfgStores() {
    api.fetch(`manufacturer/GetManufacturerDealerList/${mfgId}`).then(r => {
      if(!r.data.data) return;
      setManufacturerStores(r.data.data);
    }).catch(err => console.error(err));
  }

  function refreshStores() {
    api.fetch(`manufacturer/DealerList/${mfgId}`).then(r => {
      setStores(
        ui_helpers.idNameToValueLabel(r.data.message.list));
      setManufacturerName(r.data.message.manufacturerName);
    }).catch(error => Alert.error("There was an error loading the store list."));
  }

  function onToggleDealerInventory(manufacturerStore) {
    api
        .post("manufacturer/ToggleDealerIsInventory", manufacturerStore)
        .then(response => {
            let manufacturerStoresList = manufacturerStores.slice();
            let index = _.findIndex(manufacturerStoresList, manufacturerStore)
            manufacturerStoresList[index].isInventory = !manufacturerStoresList[index].isInventory;
            setManufacturerStores(manufacturerStoresList);
        })
        .catch(error => setMessage({
            message:"There was an error toggling store inventory.",
            flavor: constants.flavor.error}
        ));
  }

  function onToggleDealerSales(manufacturerStore) {
    api.post("manufacturer/ToggleDealerIsSales", manufacturerStore)
        .then(response => {
            let manufacturerStoresList = manufacturerStores.slice();
            let index = _.findIndex(manufacturerStoresList, manufacturerStore)
            manufacturerStoresList[index].isSales = !manufacturerStoresList[index].isSales;
            setManufacturerStores(manufacturerStoresList);
        })
        .catch(error => setMessage({
            message:"There was an error toggling store sales.",
            flavor: constants.flavor.error}
        ));
  }

  function onActivateDealer(manufacturerStore) {
    api.post("manufacturer/ActivateManufacturerDealer", manufacturerStore).then(response => {
      let manufacturerStoresList = manufacturerStores.slice();
      let index = _.findIndex(manufacturerStoresList, manufacturerStore)
      manufacturerStoresList[index].deactivatedAt = null;
      setManufacturerStores(manufacturerStoresList);
    }).catch(error => setMessage({
      message:"There was an error activating this store.",
      flavor: constants.flavor.error}
    ));
  }

  function onDeactivateDealer(manufacturerStore) {
    api.post("manufacturer/DeactivateManufacturerDealer", manufacturerStore).then(response => {
      let manufacturerStoresList = manufacturerStores.slice();
      let index = _.findIndex(manufacturerStoresList, manufacturerStore)
      manufacturerStoresList[index].deactivatedAt = new Date();
      setManufacturerStores(manufacturerStoresList);
    }).catch(error => setMessage({
      message:"There was an error deactivating this store.",
      flavor: constants.flavor.error}
    ));
  }

  function setSelectedStore(option) {
    setStoreToSave({
      label: option.label,
      value: option.value
    });
  }

  function getSelectedStore(store) {
    const selected_option = _.find(stores, x => x.label === store.label);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

return (<>
    <MfgAdmin mfgId={mfgId} tabName="STORES" />
    <div className='inner'>
        <div className='inner-white'>
        {message.message ? <AlertBanner fade color={message.flavor}>{message.message}</AlertBanner> : null}
        <h2 className="py-2">
          <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Stores
        </h2>
        </div>
              <Card>
                  <CardBody>
                      <Row>
                          <Col xs="4">
                              <Label>Store</Label>
                              <Select
                                  options={stores}
                                  value={getSelectedStore(storeToSave)}
                                  onChange={(option) => setSelectedStore(option)}
                              />
                          </Col>
                          <Col xs="1">
                              <br />
                              <Button
                                  size="sm"
                                  className="float-start btn-success cf-success"
                                  onClick={() => saveStoreToManufacturer(storeToSave)}
                                  style={{marginTop:"11px"}}
                              >
                                  <FontAwesomeIcon icon="plus" color="white" />
                              </Button>
                          </Col>
                          <Col xs="6">
                              <Table size="sm">
                                  <thead>
                                  <tr>
                                      <th>Store Name</th>
                                      <th>Cash Sales</th>
                                      <th>Inventory</th>
                                      <th/>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {_.map(manufacturerStores, ms => (
                                      <tr key={ms.dealerId} className={classnames({"bg-secondary": ms.deactivatedAt ? true : false})}>
                                          <td>{ms.dealerName}</td>
                                          <td>
                                              <ToggleButton
                                                  value={ms.isSales}
                                                  activeLabel={"Yes"}
                                                  inactiveLabel={"No"}
                                                  onToggle={() => onToggleDealerSales(ms)}
                                              />
                                          </td>
                                          <td>
                                              <ToggleButton
                                                  value={ms.isInventory}
                                                  activeLabel={"Yes"}
                                                  inactiveLabel={"No"}
                                                  onToggle={() => onToggleDealerInventory(ms)}
                                              />
                                          </td>
                                          <td>
                                            {ms.deactivatedAt === null ? (
                                                <Button
                                                  size="sm"
                                                  className="float-end btn-delete cf-danger"
                                                  onClick={() => onDeactivateDealer(ms)}
                                                >
                                                  <FontAwesomeIcon icon="trash-alt" />
                                                </Button>
                                            ) : (
                                                <Button
                                                  size="sm"
                                                  className="float-end bg-success border-success cf-success"
                                                  onClick={() => onActivateDealer(ms)}
                                                >
                                                  <FontAwesomeIcon icon='heartbeat'/>
                                                </Button>
                                            )}
                                          </td>
                                      </tr>
                                  ))}
                                  </tbody>
                              </Table>
                          </Col>
                      </Row>
                  </CardBody>
              </Card>
      </div>
    <Footer/></>);
}