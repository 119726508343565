import React, { useContext, useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
} from "reactstrap";
import { ui_helpers, filter_helpers } from "../helpers";
import {
  TextFilter,
  CompanyAddEdit,
  AdminHeader,
  FilterSearch,
  Footer,
  FilterSwitch,
} from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext, api, constants } from "../utils";
import _ from "lodash";
import Alert from "react-s-alert-v3";

export default function Companies(props) {
  const userCtx = useContext(UserContext);
  const [pagedList, setPagedList] = useState({
    pageNumber: 1,
    totalCount: 1,
  });
  const [sortColumn, setSortColumn] = useState("Id");
  const [companies, setCompanies] = useState([]);
  const [sortAscending, setSortAscending] = useState(true);
  const [addEditCompany, setAddEditCompany] = useState(false);
  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
  ]);
  const [redirect, setRedirect] = useState("");
  const PATHNAMES = constants.PATH_NAMES;
  const isSysAdmin = ui_helpers.isSystemAdministrator(userCtx.currentUser);
  const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(userCtx.currentUser);
  const isStoreAdmin = ui_helpers.isAnyDealerAdministrator(userCtx.currentUser);
  useEffect(() => {
    refreshList();
  }, [filters]);

  function refreshList(
    sortedColumn,
    sortedAscending,
    sortedFilters,
    pageNumber
  ) {
    const resolvedFilters = sortedFilters || filters;
    const resolvedSort = {
      sortedColumn: sortedColumn || sortColumn,
      sortAscending: sortedAscending || sortAscending,
    };
    const payload = {
      activeOnly: null,
      includeCaptiveCompanies: true,
      name: null,
      ...resolvedSort,
      ...filter_helpers.filtersToObject(resolvedFilters),
      pageSize: 100,
      page: pageNumber || (pagedList ? pagedList.pageNumber : 1),
    };
    api
      .post("Company/List", payload)
      .then((r) => {
        if (!r.data.success) {
          Alert.error("There was an error loading the Company list");
        }
        setCompanies(r.data.data.items);
        setPagedList({
          page: r.data.data.pageNumber,
          totalItems: r.data.data.totalCount,
        });
        sortedColumn ? setSortColumn(sortedColumn) : setSortColumn(sortColumn);
        sortedAscending
          ? setSortAscending(sortedAscending)
          : setSortAscending(sortAscending);
      })
      .catch((error) =>
        Alert.error("There was an error loading the company list")
      );
  }

  function clearFilters() {
    setFilters([]);
    refreshList(null, null, filters);
  }

  function toggleAddEditCompany() {
    setAddEditCompany(!addEditCompany);
    refreshList();
  }

  function onChangeFilter(changedFilter) {
    const newFilters = filter_helpers.getFilters(filters, changedFilter);
    setFilters(newFilters);
    refreshList(null, null, newFilters, 1);
  }

  function activateCompany(companyId) {
    const company = _.find(companies, (x) => x.id === companyId);
    api
      .post(`company/ActivateCompany/${companyId}`)
      .then((response) => {
        refreshList();
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  function deactivateCompany(companyId) {
    const company = _.find(companies, (x) => x.id === companyId);
    api
      .post(`company/DeactivateCompany/${companyId}`)
      .then((response) => {
        refreshList();
      })
      .catch((error) =>
        Alert.error("There was an error activating the store.")
      );
  }

  useEffect(() => {
    if (
      ui_helpers.isSystemAdministrator(userCtx.currentUser) ||
      ui_helpers.isAnyCompanyAdministrator(userCtx.currentUser)
    )
      return;
    setRedirect("/");
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <AdminHeader companies />
      <div className="inner">
      <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex justify-content-center">
          <div className="me-0 rounded">
          <Button
            tag={Link}
            to={PATHNAMES.ADMIN_USERS}
            className="cf-sidebar"
            active={props.users}>
            USERS
          </Button>
          <Button
            tag={Link}
            to={PATHNAMES.ADMIN_COMPANIES}
            className="cf-sidebar"
            active={props.companies}>
            COMPANIES
          </Button>
          {isSysAdmin || isMfgAdmin ? (
            <Button
              tag={Link}
              to={PATHNAMES.ADMIN_MANUFACTURERS}
              className="cf-sidebar"
              active={props.mfg}>
              MANUFACTURERS
            </Button>
          ) : null}
          {isSysAdmin || isStoreAdmin ? (
            <Button
              tag={Link}
              to={PATHNAMES.ADMIN_STORES}
              className="cf-sidebar"
              active={props.stores}>
              STORES
            </Button>
          ) : null}
          {isSysAdmin ? (
            <Fragment>
              <Button
                tag={Link}
                to={PATHNAMES.ADMIN_TAXRATES}
                className="cf-sidebar"
                active={props.taxes}>
                TAX RATES
              </Button>
              <Button
                tag={Link}
                to={PATHNAMES.ADMIN_REGIONS}
                className="cf-sidebar"
                active={props.regions}>
                STATES
              </Button>
            </Fragment>
          ) : null}
        </div>
        </div>
          <FilterSearch filters={filters} onClear={clearFilters}>
            <Row>
              <Col xs="4">
                <TextFilter
                  filterName="name"
                  displayName="Company Name"
                  onChangeCallback={onChangeFilter}
                  value={filter_helpers.get_value("name", filters)}
                />
              </Col>
              <Col xs="4">
                <FilterSwitch
                  filterName="ActiveOnly"
                  displayName="Active Only"
                  onChangeCallback={onChangeFilter}
                  value={filter_helpers.get_value("ActiveOnly", filters)}
                />
              </Col>
            </Row>
          </FilterSearch>
        </div>
        <Row className="g-0">
          <Col>
            <Card>
                <Row>
                  <Col>
                    <h2
                      className="page-title"
                      style={{ color: "#2c3b64", fontWeight: 600 }}>
                      COMPANIES
                    </h2>
                  </Col>
                  <Col>
                    <ButtonGroup className="float-end">
                      {ui_helpers.isSystemAdministrator(userCtx.currentUser) ? (
                        <Button
                          size="sm"
                          className="btn-success cf-success buttonGroupSpacing"
                          style={{ color: "white" }}
                          onClick={() => toggleAddEditCompany()}>
                          {addEditCompany ? (
                            <FontAwesomeIcon icon="minus" />
                          ) : (
                            <FontAwesomeIcon icon="plus" />
                          )}{" "}
                          Company
                        </Button>
                      ) : null}
                      <Link to="/">
                        <Button size="sm" className="btn-dark">
                          <FontAwesomeIcon icon="home" />
                        </Button>
                      </Link>
                    </ButtonGroup>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
        <Row className="rowSpacing">
          <Col>
            <Card>
              <CardBody className="pt-0 rowSpacing">
                {addEditCompany ? (
                  <CompanyAddEdit onSaveCallback={toggleAddEditCompany} />
                ) : (
                  <Table hover>
                    <thead className="rowSpacing">
                      <tr>
                        <th>Company Name</th>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>Address 3</th>
                        <th>Phone Number</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {companies ? (
                        _.map(companies, (c) => (
                          <tr key={c.id}>
                            <td>
                              <Link
                                to={`${constants.PATH_NAMES.COMPANY_ADMIN}/${c.id}`}>
                                {c.name}
                              </Link>
                              {c.captiveToManufacturerId != null ? (
                                <div
                                  className="badge bg-info"
                                  style={{
                                    marginInline: 5,
                                    alignItems: "center",
                                  }}>
                                  Manufacturer Owner
                                </div>
                              ) : null}
                              {c.captiveToStoreId != null ? (
                                <div
                                  className="badge bg-success"
                                  style={{
                                    marginInline: 5,
                                    alignItems: "center",
                                  }}>
                                  Store Owner
                                </div>
                              ) : null}
                            </td>
                            <td>{c.remitTo1}</td>
                            <td>{c.remitTo2}</td>
                            <td>{c.remitTo3}</td>
                            <td>{c.remitTo4}</td>
                            <td>
                              {c.deactivatedAt === null ? (
                                <Button
                                  size="sm"
                                  className="float-end btn-delete"
                                  onClick={() => deactivateCompany(c.id)}>
                                  <FontAwesomeIcon icon="trash-alt" />
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  className="float-end bg-success border-success"
                                  onClick={() => activateCompany(c.id)}>
                                  <FontAwesomeIcon icon={"heartbeat"} />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Companies to Show.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
      <Footer />
    </>
  );
}
