import React from "react";

const Footer = () => {
  return (
    <footer>
      <p style={{textAlign: "center", margin:0}}>
        &copy; {new Date().getFullYear()} - ShedPro. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
