import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    Row, Col,
    Button, Input, InputGroup, Table, ButtonGroup,
    Navbar,
    NavbarBrand,
    Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toolbar, MainNav, CommentBell, CommentModal } from "./";
import { confirmAlert } from "react-confirm-alert";
import { constants, UserContext, api } from "../utils";
import loading from "./Loading";
import signout from "../assets/img/signout.png";
import search from "../assets/img/search.png";

const PATHNAMES = constants.PATH_NAMES;

export default function Header({ toolbar, children, message, setMessage, inventory, invoiceNumber, agreementNumber, mfgName, loading, ...rest }) {
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [logo, setLogo] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const { logIn, logOut, currentUser, impersonating, clearImpersonation } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    if(!currentUser) return;
    let isActive = true;
    refreshComments(isActive);
    return () => {
      isActive = false;
    };
  }, [page]);

  useEffect(() => {
    api.getCachedImage('LOGO').then(image => setLogo(image));
    api.getCachedImage('AVATAR').then(image => setAvatar(image));
  }, []);

  const refreshComments = (isActive, newPage = null, forceRefresh = false) => {
    if (!forceRefresh && newPage && newPage === page)
      return;
    const resolvedPage = newPage
      ? newPage
      : page;
    api.post('home/GetLatestUnseenComments', resolvedPage).then((r) => {
      if (!r.data || !r.data.commentList)
        return;
      if (isActive) {
        setComments(r.data.commentList);
        setCommentCount(r.data.totalCount);
        setPage(r.data.commentList.pageNumber);
      }
    });
  };

  function markCommentAsSeen(comment) {
    let payloadArray = [];
    payloadArray.push(comment);
    api.post('home/MarkCommentsSeen', payloadArray).then(() => refreshComments(true, page, true));
  }

  function markAllSeen(totalCount) {
    confirmAlert({
      title: "Mark All Read",
      message: `${totalCount} comments will be marked as read. Are you sure?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('home/MarkAllCommentsSeen', {}).then(() => refreshComments(true, 1, true));            
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  function undoMarkAsSeen() {
    api.fetch('home/UndoSeen').then(() => refreshComments(true, page, true));
  }

  function onSearchKeyDown(e) {
    if (e.which !== 13) return;
    onSearch();
    return false;
  }

  function onSearch() {
    if (searchLoading) return;
    setSearchLoading(true);
    api.fetch('home/GlobalSearch/' + searchTerm).then(r => {
      if (r.data.success) {
        setSearchResults(r.data.message);
      } else {
        console.error(r.data.message);
      }
    }).catch((e) => console.error(e))
    .finally(() => setSearchLoading(false));
  }

  return (
    <>
      <Navbar id="topmostnav">
        <Row className="align-items-center" style={{ width: "calc( 100% + 24px)" }}>
          <Col sm="2">
            <NavbarBrand tag={Link} to={PATHNAMES.HOME}>
              <img src={logo} alt="ShedPro Logo" />
            </NavbarBrand>
          </Col>
          <Col sm="10">
          {currentUser ? (
            <div className="d-flex align-items-center justify-content-end" style={{ width: "100%", marginRight: 0 }}>
              <div className="searchCommentArea mgr-24" sm="8">
                  {searchLoading ? (
                    <Spinner animation="border" size={"sm"}/>
                  ) : (
                    <InputGroup className="me-2" size="sm">
                      <Input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onKeyDown={onSearchKeyDown}
                        maxLength="100"
                        onChange={(e) => setSearchTerm(e.target.value)}></Input>
                      <Button
                        className="btn-search"
                        onClick={onSearch}>
                        <img src={search} alt="Search" />
                      </Button>
                    </InputGroup>
                  )}
              </div>
              {comments ? (
                <div className="mgr-24">
                  <CommentBell
                    openCommentModal={() => setShowComments(!showComments)}
                    commentCount={comments.totalItems}
                  />
                </div>
              ) : null}
                <div id="userMenu">
                  <span className="d-none d-md-inline-block mgr-24">
                    <Link to={PATHNAMES.USER_PROFILE} className="navbar-link me-2">
                      <img className="user-avatar" src={avatar} alt="User Avatar" />
                      {currentUser.firstName} {currentUser.lastName}
                    </Link>
                  </span>
                  <Button
                    size="sm"
                    color="light"
                    id="signoutLink"
                    className="signout"
                    onClick={() => logOut()}
                  >
                    <img src={signout} />
                    Logout
                  </Button>
                    {impersonating ?
                    <Button size='sm' color='warning' className='navbar-link ms-1' onClick={clearImpersonation}>Stop Impersonating</Button>
                    : null}
                </div>
            </div>
            
          ) : null}
          </Col>
        </Row>
      </Navbar>
      <MainNav
        comments={comments} 
        markAsSeen={markCommentAsSeen}
        markAllSeen={markAllSeen}
        refresh={() => refreshComments(true)} 
        undoMarkAsSeen={undoMarkAsSeen}
        onSetPageCallback={setPage}
        inventory={inventory}
        invoiceNumber={invoiceNumber}
        agreementNumber={agreementNumber} 
        mfgName={mfgName}
        loading={loading}
        storeName={rest.storeName}
        companyName={rest.companyName}
        mfgAdmin={rest.mfgAdmin}
        storeAdmin={rest.storeAdmin}
        companyAdmin={rest.companyAdmin}
      />
      {toolbar ? <Toolbar>{children}</Toolbar> : null}
      {searchResults === null ? null : (
        <Modal
          fullscreen="sm"
          size="lg"
          toggle={() => setSearchResults(null)}
          isOpen>
          <ModalHeader>Search Results</ModalHeader>
          <ModalBody>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Item Type</th>
                  <th>Serial/Invoice #</th>
                  <th>Status</th>
                  <th>Customer Name</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.map(searchResults, (sr) => (
                  <tr key={sr.id}>
                    <td>{sr.itemTypeName}</td>
                    <td>{sr.serialNumber || sr.invoiceNumber}</td>
                    <td>{sr.statusName}</td>
                    <td>{sr.customerName}</td>
                    <td>
                      <a href={sr.url}>View</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Row className={"d-flex justify-content-end"}>
              <Col>
                <ButtonGroup className="float-end">
                  <Button onClick={() => setSearchResults(null)}>
                    Close
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}

      {showComments ? (
        <CommentModal
          isOpen
          setIsOpen={(nextOpenState) => {
            // on close, refresh and set page back to 1
            if (!nextOpenState) {
              setPage(1);
            }
            setShowComments(nextOpenState);
          }}
          undoMarkAsSeen={undoMarkAsSeen}
          comments={comments}
          markAsSeen={markCommentAsSeen}
          markAllSeen={markAllSeen}
          refresh={() => refreshComments(true)}
          onSetPageCallback={setPage}
        />
      ) : null}
    </>
  );
}
