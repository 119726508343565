import React, {useState, useEffect, useContext} from 'react';
import {useParams, useLocation, Link} from "react-router-dom";
import {UserAddEdit, MfgAdmin, Footer} from "./";
import {Container, Row, Button, Alert as AlertBanner, Col, Card, CardHeader, CardBody, Table} from 'reactstrap';
import {constants, UserContext} from "../utils";
import {api} from "../helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToggleButton from "react-toggle-button";
import _ from "lodash";
const flavor = constants.flavor;

export default function ManufacturerUsers(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [newUser, setNewUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const userCtx = useContext(UserContext);

  useEffect(refreshUsers, []);

  function refreshUsers() {
    api.fetch(`manufacturer/GetUsers/${mfgId}`).then(response => {
      if (response.data.success) {
        setUsers(response.data.message.list);
        setManufacturerName(response.data.message.manufacturerName);
      }
    }).catch(error => 
      setMessage({
          message: "There was an error retrieving the users.",
          flavor: constants.flavor.error
      }));
  }

  function handleRoleChange(value, userId, role) {
    const roleTypeId = _.find(constants.roles, x => x.label === role).value;
    if (value) {
      //remove role
      const userRoles = _.find(users, x => x.id === userId).roles;
      const roleId = _.find(userRoles, x => x.typeOfUserRole === roleTypeId).id;
      api.post("manufacturer/RemoveRole", {
        "typeOfUserRole": roleTypeId,
        "userId": userId,
        "manufacturerId": mfgId,
        "id": roleId
      }).then(r => {
          if (r.data.success) {
              setUsers(r.data.data);
              setMessage({
                message:"The new role was saved.",
                flavor: constants.flavor.success
              });
          } else {
              setMessage({
                message:"There was an error saving the new role.",
                flavor: constants.flavor.error
              });
          }
      });
      return;
    }
    //add role
    api.post("manufacturer/AddRole", {
      "typeOfUserRole": roleTypeId,
      "userId": userId,
      "manufacturerId": mfgId
    }).then(r => {
      if (r.data.success) {
          setUsers(r.data.data);
          setMessage({
            message:"The new role was saved.",
            flavor: constants.flavor.success
          });
      } else {
          setMessage({
            message:"There was an error saving the new role.",
            flavor: constants.flavor.error
          });
      }
    });
  }

  return (<>
    <MfgAdmin mfgId={mfgId} tabName="USERS" />
    <div className='inner'>
        <div className='inner-white'>
        {message.message ? <AlertBanner fade color={message.flavor}>{message.message}</AlertBanner> : null}
        <h2 className="py-2">
            <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Users
        </h2>
        </div>
              

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h2 className="page-title">Users</h2>
                                </Col>
                                <Col>
                                    <Button
                                        size="sm"
                                        className="float-end btn-success"
                                        onClick={() => setNewUser(!newUser)}
                                        style={{color:"white"}}
                                    >
                                        <FontAwesomeIcon icon={newUser ? "minus" : "plus"} color="white" />{' '}
                                        User
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {!newUser && (
                                <Table hover>
                                    <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th className='text-center'>Administrator</th>
                                        <th className='text-center'>Create Inventory</th>
                                        <th className='text-center'>View Contracts</th>
                                        <th className='text-center'>View Inventory</th>
                                        <th className='text-center'>View Reports</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map(u => (
                                        <tr key={u.id}>
                                            <th>{u.firstName} {u.lastName} ({u.name})</th>
                                            <td align="center">
                                                <ToggleButton
                                                    value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.Administrator)}
                                                    activeLabel={"Yes"}
                                                    inactiveLabel={"No"}
                                                    onToggle={(v) => handleRoleChange(v, u.id, "Administrator")}
                                                />
                                            </td>
                                            <td align="center">
                                                <ToggleButton
                                                    value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.InventoryCreator)}
                                                    activeLabel={"Yes"}
                                                    inactiveLabel={"No"}
                                                    onToggle={(v) => handleRoleChange(v, u.id, "Inventory Creator")}
                                                />
                                            </td>
                                            <td align="center">
                                                <ToggleButton
                                                    value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.ContractViewer)}
                                                    activeLabel={"Yes"}
                                                    inactiveLabel={"No"}
                                                    onToggle={(v) => handleRoleChange(v, u.id, "Contract Viewer")}
                                                />
                                            </td>
                                            <td align="center">
                                                <ToggleButton
                                                    value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.InventoryViewer)}
                                                    activeLabel={"Yes"}
                                                    inactiveLabel={"No"}
                                                    onToggle={(v) => handleRoleChange(v, u.id, "Inventory Viewer")}
                                                />
                                            </td>
                                            <td align="center">
                                                <ToggleButton
                                                    value={_.some(u.roles, ur => ur.typeOfUserRole === constants.ROLE_IDS.ReportViewer)}
                                                    activeLabel={"Yes"}
                                                    inactiveLabel={"No"}
                                                    onToggle={(v) => handleRoleChange(v, u.id, "Report Viewer")}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            )}
                            {newUser && (
                                <UserAddEdit
                                    currentUser={userCtx.currentUser}
                                    returnRoute=""
                                    computedMatch={mfgId}
                                    manufacturer={true}
                                    cancel={() => setNewUser(false)}
                                    callback={refreshUsers}
                                />
                            )}

                        </CardBody>
                    </Card>
      </div>
      <Footer/>
  </>);
}