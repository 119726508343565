import React, { useEffect, useState, useContext } from "react";
import { Footer, Header, Loading } from "./";
import _ from "lodash";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { api, constants, UserContext } from "../utils";
import { ui_helpers } from "../helpers";
const PATHNAMES = constants.PATH_NAMES;

export default function ReportsIndex() {
  document.title = `Reports - ${constants.appName}`;
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const {currentUser} = useContext(UserContext);
  useEffect(() => {
    setIsLoading(true);
    api.fetch('Resources/ReportGroups').then(res => {
      setReportData(res.data);
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <Header resources noComments></Header>
      <div className="inner">
      <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
        <Button color="orange" className="text-light" tag={Link} to={PATHNAMES.RESOURCES_EDITREPORTDEF} style={{width:"auto"}}>
          Edit Report Definitions
        </Button>
        </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="inner-white mt-10">
          <Row >
            {reportData && reportData.length ?
              _.map(reportData, (g) => (
                <Col key={g.dsc}>
                  <div className="report-header" style={{color: "#2c3b64", fontWeight: 600}}>{g.dsc.toUpperCase()}</div>
                  <hr className="mt-0" />
                  {g.reports && g.reports.length &&
                    _.map(g.reports, (r) => (
                      <div className="mt-1 mb-2" key={`rpt-${r.id}`}>
                        <Badge tag={Link} to={`/Report/${r.id}/Parameters`} className="report-link" color="secondary" pill>
                          {r.dsc}
                        </Badge>
                        <div className="report-dsc">{r.dsc2}</div>
                      </div>
                    ))}
                </Col>
              )) : null}
          </Row>
        </div>
      )}
      </div>
      <Footer />
    </div>
  );
};
