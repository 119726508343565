import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Table } from "reactstrap";
import { Header, Footer } from "./";

const BuildManagement = (props) => {
  return (
    <div>
      <Header />
      <Container>
        <Col xs="12" className="text-center">
          <h1 className="my-4">Build Management</h1>
        </Col>
        <Col xs="12" className="text-center">
          <div className="d-flex justify-content-center">
            <h2 className="mb-4">Current Builds</h2>
            <Col className="d-flex justify-content-end">
              <Link to={{ pathname: "/BuildDetails" }}>
                <Button className="pt-2">+New Build</Button>
              </Link>
            </Col>
          </div>
        </Col>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Order Placed On</th>
              <th>Current Step</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                onClick={() => {
                  props.toggleTab("6");
                }}>
                #336776
              </td>
              <td>01/11/2022</td>
              <td>Electricity Install</td>
            </tr>
            <tr>
              <td>#336777</td>
              <td>01/12/2022</td>
              <td>Inspection</td>
            </tr>
            <tr>
              <td>#336778</td>
              <td>01/12/2022</td>
              <td>Metal Ordered</td>
            </tr>
          </tbody>
        </Table>

        <Col xs="12" className="text-center">
          <div className="d-flex justify-content-center">
            <h2 className="mt-5 mb-4">Completed Builds</h2>
            <Col className="d-flex justify-content-end"></Col>
          </div>
        </Col>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Order Placed On</th>
              <th>Completed On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#336744</td>
              <td>10/11/2022</td>
              <td>01/11/2022</td>
            </tr>
            <tr>
              <td>#336745</td>
              <td>10/12/2022</td>
              <td>01/12/2022</td>
            </tr>
            <tr>
              <td>#336746</td>
              <td>10/12/2022</td>
              <td>01/12/2022</td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Footer />
    </div>
  );
};

export default BuildManagement;
