import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Row, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Footer, Header, Loading } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, constants } from "../utils";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Dropzone from "react-dropzone";
import _ from "lodash";
import Alert from "react-s-alert-v3";

const EditDocBrief = ({ name, dsc, onDelete, onEdit }) => {
  return (
    <div className="document-brief">
      <Row>
        <Col xs={10}>
          <strong>{name}</strong>
          <br />
          <em>{dsc}</em>
        </Col>
        <Col xs={2} className="d-flex justify-content-end align-items-end">
          <ButtonGroup size="sm">
            <Button color="dark" onClick={onEdit}>Edit</Button>
            <Button color="danger" className="text-light" onClick={onDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

const EditDocList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [editDoc, setEditDoc] = useState(null);
  const [docList, setDocList] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(loadList, []);

  function loadList() {
    setIsLoading(true);
    api.fetch('Resources/DocList').then(res => {
      setDocList(res.data);
    }).catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
  }

  function onSaveDoc() {
    if (!editDoc.name) {
      Alert.error("Each document requires a name");
      return;
    }
    let formData = new FormData();
    formData.append('Id', editDoc.id);
    formData.append('Name', editDoc.name);
    formData.append('Dsc', editDoc.dsc);
    formData.append('Content', file);
    setSaving(true);
    api.post_form_data('Resources/SaveDoc', formData).then(r => {
      if (r.data.success) {
        setEditDoc(null);
        loadList();
      } else {
        Alert.error(r.data.message);
      }
    }).catch((e) => console.error(e))
    .finally(() => setSaving(false));
  }

  function onChange(fieldName, value) {
    setEditDoc({
      ...editDoc,
      [fieldName]: value
    });
  }

  function onDelete(r) {
    confirmAlert({
      title: "Confirm Deletion",
      message: `Are you sure you want to permanently delete the ${r.dsc} document?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('Resources/DeleteDoc', { id: r.id }).then(res => {
              if (res.data.success) {
                loadList();
              } else {
                Alert.error(res.data.message);
              }
            }).catch((e) => console.error(e));
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  return (
    <div>
      <Header />
      <div className="inner">
      <div className="inner-white">
        <Button color="primary" tag={Link} to="/Resources/Docs">View All</Button>
        <Button color="success" className="text-light" onClick={() => setEditDoc({id: 0})}>
          Add
        </Button>
      </div>
      {editDoc
        ? (<Modal size="lg" toggle={() => setEditDoc(null)} isOpen>
            <ModalHeader>
              Edit Document
            </ModalHeader>
            <ModalBody>
              <Row className="mb-2">
                <Col xs="4">Name</Col>
                <Col xs="8">
                  <Input type='text' maxLength="200" value={editDoc.name} 
                    onChange={(e) => onChange('name', e.target.value)} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">Description</Col>
                <Col xs="8">
                  <Input type='text' maxLength="500" value={editDoc.dsc} 
                    onChange={(e) => onChange('dsc', e.target.value)} />
                </Col>
              </Row>
              {editDoc.id
                ? (<Row>
                    <Col xs="4">Content Type</Col>
                    <Col xs="8">
                      <Input type='text' readOnly value={editDoc.mimeType} />
                    </Col>
                  </Row>)
                : null
              }
              <Dropzone onDrop={fileList => setFile(fileList[0])} style={{width: "100%", backgroundColor: "#F0F0F0", borderRadius: "6px"}}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="text-center">
                    <input {...getInputProps()} />
                    <div style={{ textAlign: "center" }} className="pt-3">
                      <div className="mb-1">Drop Document here or click the icon</div>
                      {file
                        ? (<span className="text-success"><FontAwesomeIcon icon="check" size="3x" className="mb-4" /></span>)
                        : (<FontAwesomeIcon icon="file-upload" size="3x" className="mb-4" />)
                      }
                    </div>
                  </div>
                )}
              </Dropzone>
            </ModalBody>
            <ModalFooter>
              {saving
                ? (<Loading messageText="Uploading..." />)
                : (<Row className={'d-flex justify-content-end'}>
                    <Col>
                      <ButtonGroup>
                        <Button color="primary" onClick={onSaveDoc}>Save</Button>
                        <Button onClick={() => setEditDoc(null)}>Cancel</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>)
              }
            </ModalFooter>
          </Modal> )
        : null
      }
      {isLoading ? (
        <Loading />
      ) : (
        <div className="inner-white" style={{marginTop: "10px"}}>
          {docList &&
            _.map(docList, (d) => (
              <EditDocBrief key={d.id} name={d.name} dsc={d.dsc} onEdit={() => setEditDoc(d)} onDelete={() => onDelete(d)} />
            ))}
        </div>
      ) }
      </div>
      <Footer />
    </div>
  );
};

export default EditDocList;
