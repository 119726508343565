import React from "react";
import _ from "lodash";
import classnames from 'classnames';
import {Badge} from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { date_helpers, constants } from "./";
import { storage } from "../utils";
import Alert from "react-s-alert-v3";

const validateSelection = (obj) => {
  return obj && (obj.value > 0 || obj.id > 0) ? obj.value : null;
};

const cleanEmptyStrings = (item) => {
  return item === "" ? null : item;
};

function formatCurrency(v) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(v);
}

const COMPANY_CONTENT_TYPES = [
  {id: 1, name: 'Cash Sale Header'},
  {id: 2, name: 'Cash Sale Invoice'},
  {id: 3, name: 'Logo'},
  {id: 4, name: 'Detailed Information Page'},
  {id: 5, name: 'Contract'},
  {id: 6, name: 'Landowner Permission'},
  {id: 7, name: 'Delivery Confirmation'},
  {id: 8, name: 'Recurring Payment Authorization'},
  {id: 9, name: 'Wholesale Invoice'},
  {id: 10, name: 'Inventory Retail Invoice'}
];

const isSystemAdmin = (user) => {
  return _.some(user.roles, r => r.typeOfUserRole === constants.ROLE_IDS.SystemAdministrator || r.value === constants.ROLE_IDS.SystemAdministrator);
};

function reOrderMyArrayList(ArrayList) {
  const defaultLabel = _.find(ArrayList, (o) => o.label === constants.SELECT_OPTION_TEXT);
  if (defaultLabel) {
    let copyOfOriginal = JSON.parse(JSON.stringify(ArrayList));
    _.remove(copyOfOriginal, (o) => o.label === constants.SELECT_OPTION_TEXT);
    copyOfOriginal.unshift(defaultLabel);
    return copyOfOriginal;
  } else {
    let copyOfOriginal = JSON.parse(JSON.stringify(ArrayList));
    copyOfOriginal.unshift({ value: constants.SELECT_OPTION_ID, label: constants.SELECT_OPTION_TEXT });
    return copyOfOriginal;
  }
}

const resolveNotificationType = (nt) => {
  if (nt && nt.value !== null && nt.value !== undefined) return nt.value;
  if (nt) return nt;
  return constants.NOTIFICATION_TYPE_IDS.NONE;
};

const roleToRoleString = (role) => {
  const standardRole = _.find(constants.roles, r => r.value === role.typeOfUserRole);
  const roleName = role ? standardRole.label : 'NO-SUCH-ROLE';
  var context = null;
  if (role.manufacturerId) {
    context = 'Manufacturer:*';
  } else if (role.companyId) {
    context = 'Company:*';
  } else if (role.dealerId) {
    context = 'Dealer:*';
  }
  return context === null
    ? roleName
    : `${roleName}-${context}`;
};

const mapUserRoleNames = (user) => {
  if (!user || !user.roles) return [];
  return _.map(user.roles, ro => roleToRoleString(ro));
};

export default {
    requiredStar() {
      return (<span className="text-danger" style={{fontWeight: "bold", fontSize: "1.1rem"}}>*</span>);
    },

    nullableString(s) {
      return s ? s : "";
    },

    nullableInt(x) {
      return x ? x : 0;
    },

    isMobile: function(window) {
      return window.screen.width <= 680;
    },
    mapRoleNames: mapUserRoleNames,

    validateMfg(m) {
      let warnings = [];
      if (m.name === "") {
          warnings.push("Provide a name for this manufacturer to save.");
      }
      if (m.address1 === "") {
          warnings.push("Provide an address for this manufacturer in order to save.");
      }
      if (m.city === "") {
          warnings.push("Provide a city for this manufacturer in order to save.");
      }
      if (m.state === "") {
          warnings.push("Provide an state for this manufacturer in order to save.");
      }
      if (m.zip === "" || m.zip.length < 5) {
          warnings.push("Provide a valid zip for this manufacturer in order to save.");
      }
      if (m.isOwner && m.enableCashSales && m.enableCreditCardPayments && 
          (!m.company
            || !m.company.typeOfPaymentProcessor 
            || !m.company.paymentGatewayApiLoginId
            || !m.company.paymentGatewayTransactionKey
            || m.company.typeOfPaymentProcessor === constants.PAYMENT_PROCESSOR_UNKNOWN_ID
            || m.company.typeOfPaymentProcessor === constants.PAYMENT_PROCESSOR_NONE_ID
            || _.trim(m.company.paymentGatewayApiLoginId) === ''
            || _.trim(m.company.paymentGatewayTransactionKey) === ''
          )
      ) {
        warnings.push("Either select a valid payment processor and enter API details or choose to disable credit card payments.");
      }
      return warnings;
    },

    prepareMfgPayload(mfg) {
      if (!mfg.inventoryWholesaleDiscountPercent && mfg.inventoryDiscount) {
        mfg.inventoryWholesaleDiscountPercent = mfg.inventoryDiscount;
      }
      // mfg.hideManufacturerUnitTypeSelection = mfg.hideUnitType;
      if (mfg.enableCashSales) {
         mfg.enableCashSales = true;
      }
      if (mfg.enableRTOContracts) {
        mfg.enableRTOContracts = true;
      }
      return mfg;
    },

    formatPhoneField(v) {
      if (!v) return v;
      v = v.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g,'');
      if (v.length === 10) {
          v = v.substring(0, 3) + '-' + v.substring(3, 6) + '-' + v.substring(6, 10);
      }
      return v;
    },

    renderContractStatusBadge(typeOfContractStatus, extraClasses = null, deliveredAt = null) {
      let statusClass = 'text-dark';
      let statusName = '';
      switch (typeOfContractStatus) {
        case constants.CONTRACT_STATUS_ID.Draft:
          statusClass = 'bg-info cf3';
          statusName = 'Draft';
          break;
        case constants.CONTRACT_STATUS_ID.Quote:
          statusClass = 'bg-info cf0';
          statusName = 'Quote';
          break;
        case constants.CONTRACT_STATUS_ID.PreVerification:
          statusClass = 'bg-info cf3';
          statusName = 'Pre-Verification';
          break;
        case constants.CONTRACT_STATUS_ID.InReview:
          statusClass = 'bg-ghost cf4';
          statusName = 'In Review';
          break;
        case constants.CONTRACT_STATUS_ID.OnHold:
          statusClass = 'bg-warning';
          statusName = 'On Hold';
          break;
        case constants.CONTRACT_STATUS_ID.PendingDelivery:
          statusClass = 'bg-primary cf5';
          statusName = 'In Progress';
          break;
        case constants.CONTRACT_STATUS_ID.Delivered:
          statusClass = 'bg-success cf6';
          statusName = deliveredAt
            ? 'Completed ' + date_helpers.toMinDateFormat(deliveredAt)
            : 'Completed';
          break;
        case constants.CONTRACT_STATUS_ID.PendingProcessing:
          statusClass = 'bg-success cf6';
          statusName = 'Pending Processing';
          break;
        case constants.CONTRACT_STATUS_ID.Processed:
          statusClass = 'bg-dark';
          statusName = 'Processed';
          break;
        case constants.CONTRACT_STATUS_ID.Cancelled:
          statusClass = 'bg-danger';
          statusName = 'Cancelled';
          break;
        case constants.CONTRACT_STATUS_ID.Quote:
          statusClass = 'bg-info cf0';
          statusName = 'Quote';
          break;
      }
      return (<Badge className={classnames({[statusClass]: true}, extraClasses)}>{statusName}</Badge>);
    },

    priceAdjustmentCaption(pa) {
      if (!pa.percentage) {
        return formatCurrency(pa.amount);
      }
      let pct = parseFloat(pa.percentage);
      if (pct < 1.0) {
        pct = pct * 100.0;
      }
      return formatCurrency(pa.amount) + ' (' + pct.toFixed(2) + '%)';
    },

    prepareMfgCompanyPayload(mfg) {
      if (!mfg.company) {
        mfg.company = {};
      }
      if (isNaN(parseInt(mfg.companyId, 10))) {
        mfg.companyId = 0; // indicates isOwner=true on the server
        mfg.company.id = 0;
      }
      mfg.company.name = mfg.name;
      mfg.company.alias = mfg.name;
      // mfg.company.rTOCompanyId = mfg.company.rtoProcessingCompany
      //   ? mfg.company.rtoProcessingCompany.value
      //   : null;
      mfg.company.typeOfPaymentProcessor = mfg.company.paymentProcessor
        ? mfg.company.paymentProcessor.value
        : _.find(constants.paymentProcessorTypes, t => t.label === 'None').value;
      if (mfg.company.states) {
        mfg.company.stateIds = _.map(mfg.company.states, s => s.value);
        delete mfg.company.states;
      }
      mfg.company.paymentGatewayApiLoginId = mfg.company.apiKey;
      mfg.company.paymentGatewayTransactionKey = mfg.company.transactionKey;
      // these 2 values are required but irrelevant for owner mfg companies
      mfg.company.minContractValue = 0;
      mfg.company.maxContractValue = 12000;
      delete mfg.company.rtoProcessingCompany;
      delete mfg.company.paymentProcessor;
      delete mfg.company.apiKey;
      delete mfg.company.transactionKey;
      delete mfg.company.address1;
      delete mfg.company.address2;
      delete mfg.company.city;
      delete mfg.company.state;
      delete mfg.company.zip;
      delete mfg.company.phone;
      delete mfg.company.fax;
      return mfg;
    },

    prepareStoreCompanyPayload(s) {
      if (isNaN(parseInt(s.companyId, 10))) {
        s.companyId = 0; // indicates isOwner=true on the server
        s.company.id = 0;
      }
      s.company.name = s.name;
      s.company.alias = s.name;
      s.company.RTOCompanyId = s.company.rtoProcessingCompany
        ? s.company.rtoProcessingCompany.value
        : null;
      s.company.typeOfPaymentProcessor = s.company.paymentProcessor
        ? s.company.paymentProcessor.value
        : _.find(constants.paymentProcessorTypes, t => t.label === 'None').value;
      if (s.company.states) {
        s.company.stateIds = _.map(s.company.states, s => s.value);
        delete s.company.states;
      }
      s.company.paymentGatewayApiLoginId = s.company.apiKey;
      s.company.paymentGatewayTransactionKey = s.company.transactionKey;
      // these 2 values are required but irrelevant for owner mfg companies
      s.company.minContractValue = 0;
      s.company.maxContractValue = 12000;
      delete s.company.rtoProcessingCompany;
      delete s.company.paymentProcessor;
      delete s.company.apiKey;
      delete s.company.transactionKey;
      delete s.company.address1;
      delete s.company.address2;
      delete s.company.city;
      delete s.company.state;
      delete s.company.zip;
      delete s.company.phone;
      delete s.company.fax;
      return s;
    },

    shapeStore(s, companyList) {
      if (s.companyId) 
      {
        let c = s.company;
        c.apiKey = c.paymentGatewayApiLoginId;
        c.transactionKey = c.paymentGatewayTransactionKey;
        c.rtoProcessingCompany = _.find(companyList, x => x.value === c.rtoCompanyId);
        c.paymentProcessor = _.find(constants.paymentProcessorTypes, p => p.value === c.typeOfPaymentProcessor);
      }
      return s;
    },

    shapeMfg(mfg, companyList) {
      mfg.inventoryDiscount = mfg.inventoryWholesaleDiscountPercent;
      // mfg.hideUnitType = mfg.hideManufacturerUnitTypeSelection;
      if (mfg.companyId && mfg.company)
      {
        let c = mfg.company;
        c.apiKey = c.paymentGatewayApiLoginId;
        c.transactionKey = c.paymentGatewayTransactionKey;
        c.rtoProcessingCompany = _.find(companyList, x => x.value === c.rtoCompanyId);
        c.paymentProcessor = _.find(constants.paymentProcessorTypes, p => p.value === c.typeOfPaymentProcessor);
      }
      return mfg;
    },

    buildCaptiveHeaderPayload(mfgCompanyId, file) {
      let headerPayload = new FormData();
      headerPayload.append('CompanyId', mfgCompanyId);
      headerPayload.append('File', file);
      headerPayload.append('TypeOfCompanyContent', constants.CASH_SALE_INVOICE_TEMPLATE_ID);
      headerPayload.append('Id', null);
      headerPayload.append('RegionId', null);
      headerPayload.append('IsDefault', true);
      headerPayload.append('CultureId', constants.CULTURE_ENGLISH_ID);
      return headerPayload;
    },

    resolveNewManufacturerState(oldState, fieldName, value) {
      const priorFieldValue = oldState.manufacturer[fieldName];
      let newState = {
        manufacturer: {
            ...oldState.manufacturer,
            [fieldName]: value
        }
      };
      // default mfg fields if appropriate
      if (newState.manufacturer.isOwner && ['address1', 'address2', 'city', 'state', 'zip', 'phone', 'fax'].includes(fieldName)) {
        newState.manufacturer.company = Object.assign({}, oldState.manufacturer.company);
        switch(fieldName) {
          case 'address1':
            if (!newState.manufacturer.company.remitTo1 || newState.manufacturer.company.remitTo1 === priorFieldValue) {
              newState.manufacturer.company.remitTo1 = value;
            }
            break;
          case 'address2':
            if (!newState.manufacturer.company.remitTo2 || newState.manufacturer.company.remitTo2 === priorFieldValue) {
              newState.manufacturer.company.remitTo2 = value;
            }
            break;
          case 'city':
          case 'state':
          case 'zip':
            const priorCityStateZip = `${oldState.manufacturer.city}, ${oldState.manufacturer.state} ${oldState.manufacturer.zip}`;
            if (!newState.manufacturer.company.remitTo3 || newState.manufacturer.company.remitTo3 === priorCityStateZip) {
              newState.manufacturer.company.remitTo3 = `${newState.manufacturer.city}, ${newState.manufacturer.state} ${newState.manufacturer.zip}`;
            }
            break;
          case 'phone':
          case 'fax':
            const priorPhoneFax = `${oldState.manufacturer.phone}  Fax ${oldState.manufacturer.fax}`;
            if (!newState.manufacturer.company.remitTo4 || newState.manufacturer.company.remitTo4 === priorPhoneFax) {
              newState.manufacturer.company.remitTo4 = `${newState.manufacturer.phone}  Fax ${newState.manufacturer.fax}`;
            }
            break;
        }
      }
      return newState;  
    },
    roleContextRouteId(context, user) {
        if(context === 'mfg') {
            let adminRoles = _.filter(user.roles, r => r.typeOfUserRole === constants.ROLE_IDS.Administrator);
            if (adminRoles.length && _.some(adminRoles, r => r.manufacturerId ? true : false)) {
              if (_.filter(adminRoles, r => r.manufacturerId ? true : false).length === 1) {
                const firstMFGAdminRole = _.find(adminRoles, r => r.manufacturerId);
                return `${constants.PATH_NAMES.ADMIN_SINGLE_MANUFACTURER}/${firstMFGAdminRole.manufacturerId}`;
              } else {
                return constants.PATH_NAMES.ADMIN_MANUFACTURERS;
              }
            }
        }
        if(context === 'company') {
            let adminRoles = _.filter(user.roles, r => r.typeOfUserRole === constants.ROLE_IDS.Administrator);
            if (adminRoles.length && _.some(adminRoles, r => r.companyId ? true : false)) {
                const firstCompanyAdminRole = _.find(adminRoles, r => r.companyId);
                return `${constants.PATH_NAMES.COMPANY_ADMIN}/${firstCompanyAdminRole.companyId}`;
            }
        }
        if(context === 'dealer') {
            let adminRoles = _.filter(user.roles, r => r.typeOfUserRole === constants.ROLE_IDS.Administrator);
            if(adminRoles.length && _.some(adminRoles, r => r.dealerId ? true : false)) {
                const firstStoreAdminRole = _.find(adminRoles, r => r.dealerId);
                return `/admin/store/${firstStoreAdminRole.dealerId}`;
            }
        }
        return;
    },

    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    getContentTypeName(contentTypeId) {
      var ct = _.find(COMPANY_CONTENT_TYPES, r => r.id === contentTypeId);
      if (ct) return ct.name;
      return "";
    },

    valueLabelToIdName(list, propName = null, stringifyValue = true) {
        if (!list || !list.length || list.length === 0) {
          return [];
        }
        const idNameList = _.map(list, x => ({
          ...x,
          name: x.label,
          id: (stringifyValue ? `${x.value}` : x.value),
          description: x.description ? x.description : ""
        }));
        return propName
          ? {
              [propName]: idNameList
          }
          : idNameList;
    },
    idNameToValueLabel(list, propName = null, stringifyValue = true) {
        if (!list || !list.length || list.length === 0) {
          return [];
        }
        const valueLabelList = _.map(list, x => ({
          ...x,
          label: x.name,
          value: (stringifyValue ? `${x.id}` : x.id),
          description: x.description ? x.description : ""
        }));
        return propName
          ? {
              [propName]: valueLabelList
          }
          : valueLabelList;
    },
    mapIdNameToValueLabel(id, name, propName = null) {
        if (!id || name.length === 0) {
          return null;
        }
        let valueLabel = { value: id, label: name };
        return propName
          ? {
                [propName]: valueLabel
            }
          : valueLabel;
    },
    mapPropToValueLabel(x, propName = null) {
        if (!x ) {
          return null;
        }
        let valueLabel = {...x, value: x.id, label: x.name };
        return propName
          ? {
                [propName]: valueLabel
            }
          : valueLabel;
    },
    mapPropToIdName(x, propName = null) {
        if (!x ) {
          return null;
        }
        let idName = {...x, id: x.value, name: x.label };
        return propName
          ? {
                [propName]: idName
            }
            : idName;
    },
    userIdNameToValueLabel(list, propName = null) {
        if (!list || !list.length || list.length === 0) {
            return [];
        }
        const valueLabelList = _.map(list, x => {
            return {
                label: `${x.lastName}, ${x.firstName}`,
                value: x.id,
                description: x.description ? x.description : ""
            };
        });
        return propName
            ? {
                [propName]: valueLabelList
            }
            : valueLabelList;
    },

    getPageCount(totalItems, pageSize) {
        return Math.ceil(totalItems / pageSize);
    },

    formatSSNField(v) {
      if (!v) return v;
      v = v.replace(/[- ]/g, '');
      if (v.length === 9) {
        v = v.substring(0, 3) + '-' + v.substring(3, 5) + '-' + v.substring(5, 9);
      }
      return v;
    },

    formatDateField(v) {
      var test = moment(v, ["MM/DD/YYYY", "M/D/YY", "MM/D/YY", "MM/DD/YY", "MM/D/YYYY", "M/D/YYYY", "YYYY-MM-DD"]);
      if (test.isValid()) {
          if (test.year() > new Date().getFullYear()) {
              test.year(test.year() - 100);
          }
          return test.format("MM/DD/YYYY");
      }
      return "";
    },

    capitalizeField(fieldString) {
      let array = fieldString.split(' ');
      let newArray = []
      for (let i = 0; i < array.length; i++) {
        if(array[i].length > 0) {
          newArray.push(_.capitalize(array[i]))
        }
      }
      return newArray.join(' ');
    },

    cleanseCurrencyCharacters(s) {
        if (s === null || s === undefined || isNaN(s)) return '';
        if (typeof s === 'number') return s;
        return s.replace('$', '')
            .replace(',', '');
    },

    formatNumber(v) {
      if (v === null || v === undefined || typeof v !== 'number') return "";
      return v.toLocaleString("en-US");
    },

    formatCurrency,

    formatCurrencyWholeDollar(v) {
      var s = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(v);
      return s.substring(0, s.length - 3);
    },

    reOrderMyArrayList,

    sortColors(bigArray, string) {
      const copyOfOriginal = bigArray.slice();
      let emptyArray = [];
      for (let i = 0; i < copyOfOriginal.length; i++) {
        let colorIteration = copyOfOriginal[i].colorTypeOptions;
        let aColor = _.find(colorIteration, { name: string });
        if (copyOfOriginal[i].manufacturerColorName) {
          aColor.color = copyOfOriginal[i].manufacturerColorName;
          emptyArray.push(aColor);
        }
      }
      return reOrderMyArrayList(
          _.chain(emptyArray)
              .reject((o) => !o.manufacturerSubProductColorId)
              .map((c) => ({
                  label: c.color,
                  value: c.manufacturerSubProductColorId,
              }))
              .value()
      );
    },

    formatCurrencyWithoutDecimal(v) {
      return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
      }).format(v);
    },

    isUserValid(u) {
      let warnings = [];
      if (!u.homeCompany && !u.homeCompanyId) {
        warnings.push("Home company is missing.");
      }
      // if (!u.primaryNotificationType || u.primaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.NONE) {
      //   warnings.push("Select a primary notification preference.");
      // } else 
      if (!u.phone && (
          u.primaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.SMS 
          // || u.primaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.PHONE 
          || u.secondaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.SMS 
          // || u.secondaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.PHONE 
          || u.primaryNotificationType === constants.NOTIFICATION_TYPE_IDS.SMS 
          // || u.primaryNotificationType === constants.NOTIFICATION_TYPE_IDS.PHONE 
          || u.secondaryNotificationType === constants.NOTIFICATION_TYPE_IDS.SMS 
          // || u.secondaryNotificationType === constants.NOTIFICATION_TYPE_IDS.PHONE
          )) {
        warnings.push("You must provide a phone # if the primary or secondary notification type is SMS.");
      }
      if (!u.phone && u.sendSMSUponContractApproval) {
        warnings.push("You must provide a mobile phone # if SMS messages are to be sent upon contract approval.");
      }
      // if (!u.secondaryNotificationType || u.secondaryNotificationType.value === constants.NOTIFICATION_TYPE_IDS.NONE) {
      //    warnings.push("Select a secondary notification preference.");
      // }
      if (!u.firstName || !u.lastName) {
        warnings.push("Provide both a first and last name.");
      }
      if (!u.username) {
        warnings.push("Provide a unique username.");
      }
      if (!u.email) {
        warnings.push("Provide a valid email address.");
      }
      if (!u.id && !u.initialPassword) {
        warnings.push("Provide an initial password for this new user in order to save.");
      }
      if (!u.id && !u.roles.length ) {
        warnings.push("Please select a role for this user.");
      }
      if (warnings.length) {
        return warnings.join(' ');
      }
      return null;
    },

    addAllStoreRoles(existingRoles, storeId, storeName) {
      const STORE_ROLES = [
        constants.ROLE_IDS.ContractViewer,
        constants.ROLE_IDS.ContractCreator,
        constants.ROLE_IDS.InventoryViewer,
        constants.ROLE_IDS.Dispatcher,
        constants.ROLE_IDS.TransportViewer,
        constants.ROLE_IDS.Administrator,
        constants.ROLE_IDS.ReportViewer
      ];
      let result = existingRoles.slice();
      for(var i=0; i < STORE_ROLES.length; i++) {
        const roleId = STORE_ROLES[i];
        if (_.some(result, er => er.typeOfUserRole === roleId && er.dealerId === storeId)) continue;
        result.push({
          typeOfUserRole: roleId,
          companyId: null,
          companyName: null,
          dealerId: storeId,
          dealerName: storeName,
          manufacturerId: null,
          manufacturerName: null
        });
      }
      return result;
    },

    addAllMfgRoles(existingRoles, manufacturerId, manufacturerName) {
      const MFG_ROLES = [
        constants.ROLE_IDS.InventoryCreator,
        constants.ROLE_IDS.InventoryManager,
        constants.ROLE_IDS.TransportViewer,
        constants.ROLE_IDS.Administrator,
        constants.ROLE_IDS.ReportViewer
      ];
      let result = existingRoles.slice();
      for(var i=0; i < MFG_ROLES.length; i++) {
        const roleId = MFG_ROLES[i];
        if (_.some(result, er => er.typeOfUserRole === roleId && er.manufacturerId === manufacturerId)) continue;
        result.push({
          typeOfUserRole: roleId,
          companyId: null,
          companyName: null,
          dealerId: null,
          dealerName: null,
          manufacturerId: manufacturerId,
          manufacturerName: manufacturerName
        });
      }
      return result;
    },

    userPayload(u) {
      let payload = {
        "firstName": u.firstName,
        "lastName": u.lastName,
        "email": u.email,
        "username": u.username,
        "phone": u.phone,
        "autoSendReports": u.autoSendReports,
        "notifyOnNewContract": u.notifyOnNewContract,
        "sendSMSUponContractApproval": u.sendSMSUponContractApproval,
        "timezone": u.timezone.value,
        "roles": u.roles,
        "canImpersonate": u.canImpersonate,
        "homeCompanyId": u.homeCompany?.value ? parseInt(u.homeCompany?.value) : u.homeCompanyId,
        "primaryNotificationType": resolveNotificationType(u.primaryNotificationType),
        "secondaryNotificationType": resolveNotificationType(u.secondaryNotificationType)
      };
      if (u.id) {
        payload.id = u.id;
      } else {
        payload.initialPassword = u.initialPassword;
      }
      return payload;
    },

    resolveListEntryPropsByText(o, selectionPropName, otherPropName, list, fallbackOtherPropName = null) {
      const selectedItemText = o[selectionPropName] && o[selectionPropName].label !== constants.OTHER
        ? o[selectionPropName].label
        : o[otherPropName];
      const selectedItem = _.find(list, t => t.label === selectedItemText);
      if (selectedItem) {
        o[selectionPropName] = selectedItem;
        if (selectedItem.label !== constants.OTHER) {
          o[otherPropName] = "";
        }
      } else {
        o[selectionPropName] = constants.OTHER_OPTION;
      }
      if (fallbackOtherPropName && o[fallbackOtherPropName]) {
        o[otherPropName] = o[fallbackOtherPropName];
      }
    },

    addIsEditingField(list, propName = null) {
        if (!list || !list.length || list.length === 0) {
            return [];
        }
        const isEditingList = _.map(list,
            x => {
                return {
                    ...x,
                    isEditing: false
                }
            });
        return propName ? { [propName]: isEditingList } : isEditingList;
    },

  greenCheck() {
    return (
      <span className="text-success">
        <FontAwesomeIcon icon="check" />
      </span>
    );
  },
  redExclamation() {
    return (
      <span className="text-danger">
        <FontAwesomeIcon icon="exclamation-circle" />
      </span>
    );
  },
  sumStatusTypes(statuses, idToCheck) {
    return _.sumBy(statuses, idToCheck);
  },

  parseManualCardInformation(cardZip, cardNo, cardMMYY, cardCVC) {
    let creditCardData = null;
    try {
        let zip = _.trim(cardZip);
        zip = zip
            ? zip.replace(/[ \-a-zA-Z]/gi, '')
            : "";
        // blank invalid zips
        // if (zip && zip.length < 5) {
        //   zip = "";
        // }
        
        creditCardData = {
          IsCreditOrDebit: true,
          CCNumber: _.trim(cardNo.split(' ').join('')),
          ExpiryMonth: cardMMYY.substring(0, 2),
          ExpiryYear: cardMMYY.substring(2, 4),
          ServiceCode: _.trim(cardCVC),
          // FirstName: $.trim($('#cc-firstname').val()),
          // LastName: $.trim($('#cc-lastname').val()),
          Zip: zip,
          ErrorMessage: ''
        };
        if (creditCardData.CCNumber === null || (creditCardData.CCNumber.length !== 15 && creditCardData.CCNumber.length !== 16)) {
            creditCardData.ErrorMessage = 'The card number must be 15 or 16 digits';
        }
        else if (creditCardData.Zip.length < 5) {
          creditCardData.ErrorMessage = 'The Zip should be at least 5 digits'
        }
        else if (cardMMYY.length !== 4) {
          creditCardData.ErrorMessage = 'Expiration month and year must be exactly 4 digits in the form MMYY';
        }
        else if (creditCardData.ExpiryMonth === null || creditCardData.ExpiryMonth.length !== 2) {
          creditCardData.ErrorMessage = 'Expiration month should be 2 digits';
        }
        else if (creditCardData.ExpiryYear === null || creditCardData.ExpiryYear.length !== 2) {
          creditCardData.ErrorMessage = 'Expiration year should be 2 digits';
        }
        else if (creditCardData.ServiceCode === null || creditCardData.ServiceCode.length < 3) {
          creditCardData.ErrorMessage = 'CCV should be 3-4 digits';
        }
    } catch (err) {
      if (!creditCardData) {
        creditCardData = { ErrorMessage: '' };
      }
      creditCardData.ErrorMessage = 'There was an problem with the card information. Please try again.';
    }
    return creditCardData;
  },

  redX() {
    return (
      <span className="text-danger">
        <FontAwesomeIcon icon="times-circle" />
      </span>
    );
  },
  replaceTextIfTooLong(characterCount, text) {
      return text.length > characterCount ? text.substring(0, characterCount) + '...' : text;
  },
  filterAvailableRoles(user) {
    let roleList = constants.roles.slice();
    if (!isSystemAdmin(user)) {
      roleList = _.reject(roleList, r => r.value === constants.ROLE_IDS.SystemAdministrator
          || r.value === constants.ROLE_IDS.UserImpersonator);
    }
    return _.sortBy(_.map(roleList, r => ({...r, label: r.name})), "label");
  },
  filterAvailableRolesByContext(context, roles) {
    let newRoles = [];
    const mfgRoles = [
      constants.ROLE_IDS.Administrator,
      constants.ROLE_IDS.ContractViewer,
      constants.ROLE_IDS.InventoryCreator,
      constants.ROLE_IDS.InventoryViewer,
      constants.ROLE_IDS.ReportViewer
    ];
    const storeRoles = [
      constants.ROLE_IDS.ContractCreator,
      constants.ROLE_IDS.ContractViewer,
      constants.ROLE_IDS.Driver,
      constants.ROLE_IDS.Dispatcher,
      constants.ROLE_IDS.TransportViewer,
      constants.ROLE_IDS.InventoryViewer,
      constants.ROLE_IDS.ReportViewer
    ];
    const driversRoles = [
      constants.ROLE_IDS.Driver,
      constants.ROLE_IDS.Dispatcher
    ];
    if (context === "manufacturer") {
      _.each(roles, x => {
        if (mfgRoles.includes(x.value)) {
          newRoles.push(x);
        }
      })
      return newRoles;
    } else if (context === "store") {
      _.each(roles, x => {
          if (storeRoles.includes(x.value)) {
            newRoles.push(x);
          }
      })
      return newRoles;
    } else if (context === "drivers") {
      _.each(roles, x => {
          if (driversRoles.includes(x.value)) {
            newRoles.push(x);
          }
      })
      return newRoles;
    }
    return roles;
  },
  isContextMfgUser(user, mfgId)
  {
      return _.includes(this.filterAvailableRolesByContext('manufacturer', user.roles), {'manufacturerCompanyId': mfgId});
  },
  isContextManufacturerUser(user, mfgId)
  {
    let role = _.find(user.roles, r => r.manufacturerId === mfgId);
    return !!role;
  },
  formatContractStatusIdToName(id){
     const found =
      _.find(constants.CONTRACT_STATUS_NAME, {'id': id});
     return found.name;
  },
  formatInventoryStatusIdToName(id){
    const found = _.find(constants.INVENTORY_STATUS_NAME, {'id': id});
    return found.name;
  },
  formatStatusIdToName(collection, id) {
      if(!collection || id < 0) return null;
      let found = _.find(collection, c => c.id === id);
      if(!found) return null;
      return found.name;
  },
  convertToSelectValue(collection) {
      _.map(collection, c => {
          c.label = c.name;
          c.value = c.id;
          return c;
      })
  },
  essentialBackground(milestoneStatusId, daysSinceInception) {
      if (milestoneStatusId === null) // default to green for missing entries (CashSale/Contract for one)
          return "success";
      switch (milestoneStatusId)
      {
          case constants.MILESTONE_STATUS_IDS.Incomplete:
              return daysSinceInception > 30 ? "orange" : "cf-yellow";
          case constants.MILESTONE_STATUS_IDS.NotApplicable:
          case constants.MILESTONE_STATUS_IDS.Complete:
          default: // default to green for missing entries (CashSale/Contract for one)
              return "success";
      }
  },
  followUpBackground(followupType, isComplete) {
    // inverted logic for On Hold
    if (followupType === constants.FOLLOW_UP.HOLD) {
      return isComplete ? "danger" : "success";
    }
    return isComplete ? "success" : "cf-yellow";
  },
  resolveTooltipTypeByBgColor(bgClass) {
    switch(bgClass) {
      case 'danger':
        return 'error';
      case 'cf-yellow':
        return "warning";
      case 'success':
        return 'success';
      default:
        return 'warning';
    }
  },
  isSystemAdministrator(user) {
    return isSystemAdmin(user);
  },
  isRTOCompanyAdmin(user, companyId) {
    if(!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.companyId == companyId
        && ro.typeOfUserRole == constants.ROLE_IDS.Administrator
        && ro.isRTOCompany);
  },
  isAnyRTOCompanyAdmin(user) {
    if(!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.companyId
        && ro.typeOfUserRole == constants.ROLE_IDS.Administrator
        && ro.isRTOCompany);
  },
  isAnyCompanyAdministrator(user) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.companyId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },
  companyAdministratorCount(user) {
    if (!user || !user.roles) return 0;
    var companyIdList = _.chain(user.roles)
        .filter(ro => ro.companyId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
        .map(ro => ro.companyId)
        .value();
    return _.uniq(companyIdList).length;
  },
  companyCount(user) {
    if (!user || !user.roles) return 0;
    const companyIdList = _.chain(user.roles)
        .filter(ro => ro.companyId)
        .map(ro => ro.companyId)
        .value();
    return _.uniq(companyIdList).length;
  },
  manufacturerCompanyCount(user){
    if (!user || !user.roles) return false;
    var mcIdList = _.chain(user.roles)
    .filter(ro => ro.manufacturerCompanyId)
    .map(ro => ro.manufacturerCompanyId)
    .uniq()
    .value();
    return mcIdList.length;
  },
  isContextCompanyUser(user, companyId) {
      if (!user || !user.roles) return false;
      if (isSystemAdmin(user)) return true;
      return _.some(user.roles, ro => ro.companyId === companyId);
  },
  isAnyDealerAdministrator(user) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.dealerId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },

  isAnyManufacturerAdministrator(user) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.manufacturerId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },

  isCompanyAdministrator(user, companyId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.companyId === companyId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },

  isDealerAdministrator(user, dealerId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.dealerId === dealerId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },

  isDealerContractUser(user, dealerId) {
    if (!user || !user.roles) return false;
    // if(isSystemAdmin(user)) return true;
    return _.some(user.roles, ro => ro.dealerId === dealerId 
        && (ro.typeOfUserRole === constants.ROLE_IDS.Administrator
            || ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator
            || ro.typeOfUserRole === constants.ROLE_IDS.ContractManager));
  },
  resolveNewOrUsedSelectionFromFilters(filters) {
    let findValue = constants.NEW_OR_USED.NEW_OR_USED;
    if (_.some(filters, f => f.filterName === 'NewOnly')) {
      findValue = constants.NEW_OR_USED.ONLY_NEW;
    } else if (_.some(filters, f => f.filterName === 'UsedOnly')) {
      findValue = constants.NEW_OR_USED.ONLY_USED;
    }
    return _.find(constants.NEW_OR_USED_OPTIONS, x => x.value === findValue);
  },
  isManufacturerAdministrator(user, mfgId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => ro.manufacturerId === mfgId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },
  isReportingUser(user) {
    if (!user || !user.roles) return false;
    return isSystemAdmin(user) || _.some(user.roles, ro => ro.typeOfUserRole === constants.ROLE_IDS.ReportViewer);
  },
  isRequestedSaleCompanyAdministrator(user, companyId) {
    if (!user || !user.roles || !companyId) return false;
    return _.some(user.roles, ro => ro.companyId === companyId 
      && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  },
  isAnyInventoryOwner(user) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => 
        ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator
        || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager
        || (ro.typeOfUserRole === constants.ROLE_IDS.Administrator && ro.manufacturerId));
  },
  isInventoryOwner(user, mfgId, companyId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => 
        ro.typeOfUserRole === constants.ROLE_IDS.Administrator
        && ro.manufacturerId === mfgId 
        && ro.manufacturerCompanyId === companyId
      );
  },
  isAnyMfgUser(user) {
      if (!user || !user.roles) return false;
      if(isSystemAdmin(user)) return true;
      return _.some(user.roles, ro => ro.manufacturerId);
  },
  isAnyMfgUserWithBuildFeature(user) {
      if (!user || !user.roles) return false;
      if(isSystemAdmin(user)) return true;
      return _.some(user.roles, ro => ro.manufacturerId && ro.buildFeature === true);
  },
  isManufacturerUser(user, mfgId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => 
        ro.manufacturerId === mfgId 
        && (ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator
            || ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager
            || ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
      );
  },
  hasBuildFeature(user) {
    if (!user || !user.roles) return false;
    if (isSystemAdmin(user)) return true;
    return _.some(user.roles, {buildFeature: true});
  },
  getMfgIdForBuildQuickFilters(user) {
    if (!user || !user.roles) return null;
    let mfgList = _.filter(user.roles, ro =>
        ro.manufacturerId !== null
        && ro.typeOfUserRole === constants.ROLE_IDS.Administrator
    )
    if (mfgList && (!mfgList.length || (mfgList.lenght && mfgList.length > 1))) return null;
    return mfgList[0].manufacturerId;
  },
  canManageContracts(user, companyId, dealerId) {
    if (!user || !user.roles) return false;
    return _.some(user.roles, ro => (
        ((ro.companyId || ro.manufacturerCompanyId) && (ro.companyId === companyId || ro.manufacturerCompanyId === companyId))
        || (ro.dealerId && ro.dealerId === dealerId)
      )
      && (ro.typeOfUserRole === constants.ROLE_IDS.ContractManager
          || ro.typeOfUserRole === constants.ROLE_IDS.Administrator));
  },
  canCreateContracts(user, companyId, dealerId) {
    if (!user || !user.roles) return false;
    if (isSystemAdmin(user)) return true;
    return _.some(user.roles, ro => (
          ((ro.companyId || ro.manufacturerCompanyId) && (ro.companyId === companyId || ro.manufacturerCompanyId === companyId))
          || (ro.dealerId && ro.dealerId === dealerId)
          )
        && (ro.typeOfUserRole === constants.ROLE_IDS.ContractManager
            || ro.typeOfUserRole === constants.ROLE_IDS.Administrator
            || ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator));
  },
  isContractOwner(user, companyId) {
      if (!user || !user.roles) return false;
      if (isSystemAdmin(user)) return true;
      return _.some(user.roles, ro => (
            (ro.companyId || ro.manufacturerCompanyId) && (ro.companyId === companyId || ro.manufacturerCompanyId === companyId)
            )
          && (ro.typeOfUserRole === constants.ROLE_IDS.ContractManager
              || ro.typeOfUserRole === constants.ROLE_IDS.Administrator));
  },
  canCreateInventory(user, companyId, dealerId, mfgId) {
      if (!user || !user.roles) return false;
      if (isSystemAdmin(user)) return true;
      return _.some(user.roles, ro => (
            ((ro.companyId || ro.manufacturerCompanyId) && (ro.companyId === companyId || ro.manufacturerCompanyId === companyId))
            || (ro.dealerId && ro.dealerId === dealerId)
            || (ro.manufacturerId && ro.manufacturerId === mfgId)
            )
          && (ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager
              || ro.typeOfUserRole === constants.ROLE_IDS.Administrator
              || ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator));
  },
  isStoreUser(user, dealerId) {
    if (!user || !user.roles) return false;
    if (isSystemAdmin(user)) return true;
    return _.some(user.roles, ro => (
        ro.dealerId && ro.dealerId === dealerId
        && (ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager
            || ro.typeOfUserRole === constants.ROLE_IDS.Administrator
            || ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator
            || ro.typeOfUserRole === constants.ROLE_IDS.InventoryViewer
            || ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator
            || ro.typeOfUserRole === constants.ROLE_IDS.ContractViewer
        )));
  },
  hasPermission(user, permission) {
    const permissions = mapUserRoleNames(user);
    return permissions && _.includes(permissions, permission);
  },
  conditionalCount(cnt) {
    if (cnt === undefined) return null;
    return ` (${cnt})`;
  },
  sumTotalCost(results){
    let total = 0;
    _.each(results, r => {
        total += r.retailPrice;
    });
    return total;
  },
  hasAnyPermission(user, permissions) {
    if (!permissions || !permissions.length || !user) return false;
    const user_permissions = mapUserRoleNames(user);
    return _.intersection(user_permissions, permissions).length > 0;
  },
  hasContractCreationPermission(user) {
    if (!user || !user.roles) return false;
    if (isSystemAdmin(user)) return true;
    return _.some(user.roles, ro => (ro.typeOfUserRole === constants.ROLE_IDS.ContractManager
      || ro.typeOfUserRole === constants.ROLE_IDS.Administrator
      || ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator));
  },

  browserExportCSVFile: function (csv, fileTitle) {
    var fname = fileTitle || "export.csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fname);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fname);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  taxRateStringFromTaxRate(taxRate) {
    return taxRate < 1.0
      ? (taxRate * 100.0).toFixed(3)
      : taxRate;
  },
  taxRateFromString(taxRateString) {
    let rate = parseFloat(taxRateString);
    if (rate > 1.0) {
      rate /= 100.0;
    }
    rate = parseFloat(rate.toFixed(5));
    // setTaxRate(rate);
    const s = (rate * 100.0).toFixed(3);
    // setTaxRateString(s);
    storage.setItem('lastTaxRate', s);
    return {
      rate: rate,
      str: s
    };
  },
  mapBuildStepsForUI(list) {
    return _.map(list, x => ({
      ...x,
      customerNotificationType: _.filter(constants.notificationTypes, nt => nt.value === x.customerNotificationType)
    }));
  },
  capitalizeFirstLetter(stringText) {
    stringText = stringText.toLowerCase();
    let stringArray = stringText.split(" ");

    let newString = "";
    for(let i =0; i < stringArray.length; i++){
      let text = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
      newString += `${text} `;
    }
    return newString.trim();
  },
  capitalizeAllLetters(string) {
    return string.toUpperCase();
  },
  chop(s, maxLen) {
    if (!s || s.length <= maxLen) return s;
    return s.substr(0, maxLen) + "...";
  },
  sideFeeSum(adjustments) {
    const sideFees = _.filter(adjustments, pa => !pa.includeInUnitPrice);
    return sideFees.length
      ? _.sumBy(sideFees, pa => parseFloat(pa.amount))
      : 0.0;
  }
};