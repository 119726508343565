import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Container,
  Table,
  CardBody,
  CardHeader,
  Card,
  ButtonGroup,
} from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";
import { api, constants } from "../utils";
import { input_validation } from "../helpers";
import { DebounceInput } from "react-debounce-input";

const defaultDefect = {
  unitInventoryId: 0,
  defectId: 0,
  defectCount: 0,
  defectPenaltyValue: 0,
};

const InspectionRow = ({ i, unitId, defectItem, step, isEditing }) => {
  const [defect, setDefect] = useState(defectItem ? defectItem : defaultDefect);
  const [loading, setLoading] = useState(false);

  const updateInspection = (defectItem) => {
    setLoading(true);
    defectItem.unitInventoryId = unitId;
    defectItem.defectId = i.id;
    api
      .post("Inventory/UpdateInspectionScore", defectItem)
      .then((r) => {
        if (!r.data) {
          Alert.error("There was an error updating the Penalty Value.");
          return;
        }
        setDefect(r.data);
      })
      .finally(() => setLoading(false));
  };

  const handlePenaltyValueChange = (number) => {
    let retNumber = parseInt(number, 10);
    if (!input_validation.is_int(retNumber)) {
      Alert.error("The number you entered is not a number");
      return;
    }
    let retDefect = Object.assign({}, defect);
    retDefect.defectPenaltyValue = retNumber;
    setDefect(retDefect);
    updateInspection(retDefect);
  };

  useEffect(() => {
    if (!defectItem) return;
    setDefect(defectItem);
  }, [defectItem]);

  const addToPenaltyValue = () => {
    let newDefect = Object.assign({}, defect);
    newDefect.defectPenaltyValue++;
    updateInspection(newDefect);
  };

  const subtractFromPenaltyValue = () => {
    let newDefect = Object.assign({}, defect);
    if (newDefect.defectPenaltyValue > 0) {
      newDefect.defectPenaltyValue--;
      updateInspection(newDefect);
    }
  };

  return (
    <tr key={`i-${i.id}`}>
      <td>{i.name}</td>
      <td className={"d-flex justify-content-center"}>
        <Row className={"d-flex justify-content-center"}>
          <Col sm={4} className="d-flex justify-content-end m-0">
            <Button
              size={"sm"}
              onClick={() => subtractFromPenaltyValue()}
              disabled={
                loading ||
                !defect.defectPenaltyValue > 0 ||
                (step.completedAt && !isEditing)
              }
              className={"bg-primary"}>
              <FontAwesomeIcon icon={"minus"} />
            </Button>
          </Col>
          <Col sm={3} className={"p-0 m-0 d-flex justify-content-center"}>
            <DebounceInput
              type={"text"}
              value={defect.defectPenaltyValue}
              onChange={(e) => handlePenaltyValueChange(e.target.value)}
              min={0}
              debounceTimeout={500}
              className={"text-center"}
              style={{ width: "45px" }}
            />
          </Col>
          <Col sm={4} className="d-flex justify-content-start m-0">
            <Button
              size={"sm"}
              onClick={() => addToPenaltyValue()}
              disabled={loading || (step.completedAt && !isEditing)}
              className={"bg-primary"}>
              <FontAwesomeIcon icon={"plus"} />
            </Button>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default function BuildInspection(props) {
  const [alert, setAlert] = useState(constants.ALERT_OBJECT);
  const [inspections, setInspections] = useState([]);
  const [defectList, setDefectList] = useState([]);
  const [buildStep, setBuildStep] = useState(
    Object.assign({}, props.buildStep)
  );
  const [isEditing, setIsEditing] = useState(false);

  const getDefectList = (isActive) => {
    api
      .fetch(`Reference/ListMfgDefects/${props.inventory.manufacturerId}`)
      .then((r) => {
        if (!r.data) {
          setAlert({
            message: "There was an error retrieving the defects.",
            flavor: constants.flavor.error,
          });
          return;
        }
        if (isActive) {
          setInspections(r.data.message.list);
        }
      });
  };

  const getInspectionScores = (isActive) => {
    api
      .fetch(`Inventory/ListInventoryInspectionScores/${props.inventory.id}`)
      .then((r) => {
        if (!r.data) return;
        if (isActive) {
          setDefectList(r.data);
        }
      });
  };

  useEffect(() => {
    if (!props.inventory.id) return;
    let isActive = true;
    getDefectList(isActive);
    getInspectionScores(isActive);
    return () => {
      isActive = false;
    };
  }, [props.inventory]);

  const onBuildStepChanged = (fieldName, fieldValue) => {
    let newBuildStep = Object.assign({}, buildStep);
    newBuildStep[fieldName] = fieldValue;
    setBuildStep(newBuildStep);
  };

  const markBuildStepComplete = () => {
    let payload = Object.assign({}, buildStep);
    if (!payload.completedBy.length) {
      Alert.warning(
        "You must enter the name of the person who completed this step."
      );
      return;
    }
    payload.customerNotificationType = payload.customerNotificationType.value;
    api
      .post("inventory/MarkBuildStepComplete", payload)
      .then((r) => {
        if (r.data.success) {
          props.getBuildSteps();
          Alert.success(`${buildStep.name} marked complete`);
        } else {
          Alert.error("There was an issue marking this build step as complete");
        }
      })
      .catch((err) => console.error(err));
  };

  const getEdiitngButtons = () => {
    if (isEditing) {
      return (
        <ButtonGroup>
          <Button
            className={"bg-success border-success"}
            onClick={markBuildStepComplete}>
            Save Score
          </Button>
          <Button onClick={() => setIsEditing(false)}>
            <FontAwesomeIcon icon={"times-circle"} />
          </Button>
        </ButtonGroup>
      );
    } else {
      return (
        <Button onClick={() => setIsEditing(true)}>
          <FontAwesomeIcon icon={"edit"} />
        </Button>
      );
    }
  };

  return (
    <div>
      <Container className="mt-5">
        <Card>
          <CardHeader>
            <Row>
              <Col xs="10" sm="10" md="8">
                <h3 className="mt-2">Inspection Form</h3>
              </Col>
            </Row>
            <Row></Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Input
                  type={"text"}
                  placeholder={"Completed by.."}
                  name={"completedBy"}
                  value={buildStep.completedBy}
                  onChange={(e) =>
                    onBuildStepChanged(e.target.name, e.target.value)
                  }
                />
              </Col>
              <Col>
                {buildStep.completedAt ? (
                  getEdiitngButtons()
                ) : (
                  <Button
                    className={"bg-success border-success"}
                    onClick={markBuildStepComplete}>
                    Mark as Complete
                  </Button>
                )}
              </Col>
            </Row>
            <Col sm={{ size: 12 }}>
              <Table size={"sm"} className={"mt-4"} borderless>
                <thead>
                  <tr key={"i-headers"}>
                    <th>
                      <Row>
                        <div className="bold">Inspection Defect Checklist</div>
                      </Row>
                      <Row>
                        <h6 className="mt-3">
                          Builder:{" "}
                          {props.assignedBuilder.completedBy
                            ? props.assignedBuilder.completedBy
                            : "No Builder Assigned"}
                        </h6>
                      </Row>
                    </th>
                    <th className={"text-center"}># of Defects</th>
                  </tr>
                </thead>
                <tbody>
                  {inspections
                    ? _.map(inspections, (i) => {
                        return (
                          <InspectionRow
                            key={`irw-${i}`}
                            i={i}
                            unitId={props.inventory.id}
                            step={props.buildStep}
                            isEditing={isEditing}
                            defectItem={_.find(
                              defectList,
                              (d) => d.defectId === i.id
                            )}
                          />
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </CardBody>
        </Card>
        <Col sm="12" md={{ size: 8, offset: 2 }}></Col>
      </Container>
    </div>
  );
}
