import React, { useState, useEffect, useContext, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonGroup,
  Container,
  Row,
  Col,
  Card,
  Badge,
  Popover,
  PopoverBody,
  Table,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Alert as RSAlert,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import classnames from "classnames";
import { Footer, Header, Loading, DetailsTable, DetailsRow, PriceSummaryTable } from "./";
import { api, constants, UserContext } from "../utils";
import { date_helpers, ui_helpers } from "../helpers";
import Alert from "react-s-alert-v3";

const TABS = {
  CUSTOMER: "customer",
  UNIT: "unit",
  PRICING: "pricing",
  ESSENTIALS: "essentials",
  UPLOADS: "uploads",
  COMMENTS: "comments",
  HISTORY: "history",
};

const RECIPIENT_TYPE = {
  OTHER: "other",
  CUSTOMER: "customer",
  DEALER_USERS: "dealer-users",
};

const QuoteView = ({ contractType }) => {
  const history = useHistory();
  let { routeContractId, routeTabId } = useParams();
  if (routeContractId) {
    routeContractId = parseInt(routeContractId, 10);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [contract, setContract] = useState({});
  const [openAccordionItem, setOpenAccordionItem] = useState(
    routeTabId ? routeTabId : TABS.CUSTOMER
  );
  const [showSSN, setShowSSN] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [propertyOwnerPopoverOpen, setPropertyOwnerPopoverOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentAndMilestoneList, setAttachmentAndMilestoneList] = useState([]);
  const [linkRecipientType, setLinkRecipientType] = useState(RECIPIENT_TYPE.OTHER);
  const [linkVisibilityTypeId, setLinkVisibilityTypeId] = useState(
    constants.QUOTE_MOBILE_VISIBILITY_TYPE_ID
  );
  const [otherRecipient, setOtherRecipient] = useState("");
  const [comment, setComment] = useState("");
  const [commentPrivate, setCommentPrivate] = useState(false);
  const [hasMissingAttachments, setHasMissingAttachments] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const [currentUserIsSysAdminOrCompanyAdmin, setCurrentUserIsSysAdminOrCompanyAdmin] =
    useState(false);
  const { currentUser } = useContext(UserContext);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [canManageContract, setCanManageContract] = useState(false);
  const [isContractCreator, setIsContractCreator] = useState(false);
  const [isManufacturerUser, setIsManufacturerUser] = useState(false);
  const [isContractOwner, setIsContractOwner] = useState(false);
  const [isStoreUser, setIsStoreUser] = useState(false);
  const [canDispatch, setCanDispatch] = useState(false);
  const [isDriverOrDispatcher, setIsDriverOrDispatcher] = useState(false);
  const [canAddComments, setCanAddComments] = useState(false);
  const [canAddAttachments, setCanAddAttachments] = useState(false);
  const [controllerUrl, setControllerUrl] = useState(
    contractType === constants.CONTRACT_TYPE.RTO ? "contract" : "cashsale"
  );
  const [convertDropdownOpen, setConvertDropdownOpen] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [convertQuoteTo, setConvertQuoteTo] = useState("");
  const [showQuoteConversionModal, setShowQuoteConversionModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [files, setFiles] = useState([]);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [promptingUploadAttachmentTypeId, setPromptingUploadAttachmentTypeId] = useState(null);
  const [contractImages, setContractImages] = useState([]);

  useEffect(loadContract, []);

  useEffect(() => {
    if (!contract) return;
    setPermissions();
  }, [contract]);

  function loadContract() {
    setIsLoading(true);
    api
      .fetch(`${controllerUrl}/GetQuoteDetails/${routeContractId}`)
      .then((r) => {
        if (r.data.success) {
          let c = r.data.message.contract;
          setContract(r.data.message.contract);
          setComments(r.data.message.commentList);
          setPaymentOptions(r.data.message.contractTermOptionList);
          //setDlwAmount(r.data.message.dlwAmount);
          setAddOns(r.data.message.unitOptionList);
          setCurrentUserIsSysAdminOrCompanyAdmin(
            r.data.message.currentUserIsSysAdminOrCompanyAdmin
          );
          setAttachmentList(r.data.message.attachmentList);
          setAttachmentAndMilestoneList(r.data.message.attachmentAndMilestoneList);
          setHasMissingAttachments(r.data.message.hasMissingAttachments);
          setContractImages(r.data.message.imageList);
          if (c.customerEmail && c.customerEmail.length > 0) {
            setLinkRecipientType(RECIPIENT_TYPE.CUSTOMER);
          }
          window.scroll(0, 0);
        } else if (r.data.message === constants.ERROR_MESSAGES.ERR_ACCESS_DENIED) {
          Alert.error(r.data.message);
          setRedirectTo("/");
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }

  function toggleAccordion(targetId) {
    if (_.values(TABS).includes(targetId) && targetId !== openAccordionItem) {
      setOpenAccordionItem(targetId);
    } else {
      setOpenAccordionItem("0");
    }
  }

  function submitComment() {
    api
      .post("contract/addComment", {
        id: routeContractId,
        comment: comment,
        isPrivate: commentPrivate,
      })
      .then((r) => {
        if (r.data.success) {
          setComment("");
          setShowCommentModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function deleteComment(commentId) {
    api
      .post("contract/deleteComment", { id: commentId })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function deleteContract() {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to permanently delete this contract?",
      buttons: [
        {
          label: "Yes",
          onClick: () => simpleContractOperation("contract/DeleteContract"),
        },
        {
          label: "No",
        },
      ],
    });
  }

  function cancelContract() {
    setComment("");
    setShowCancelModal(true);
  }

  function commitCancelContract() {
    api
      .post("contract/CancelContract", {
        id: routeContractId,
        comment: comment,
      })
      .then((r) => {
        if (r.data.success) {
          setShowCancelModal(false);
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onSendMobileLink() {
    if (!linkRecipientType) {
      Alert.warning("Select a link recipient type before attempting to send");
      return;
    }
    if (linkRecipientType === RECIPIENT_TYPE.OTHER && !_.trim(otherRecipient)) {
      Alert.warning("Provide recipient details before attempting to send");
      return;
    }
    let recipientEmail = "";
    if (linkRecipientType === RECIPIENT_TYPE.OTHER) {
      recipientEmail = _.trim(otherRecipient);
    } else if (linkRecipientType === RECIPIENT_TYPE.CUSTOMER) {
      recipientEmail = contract.customerEmail;
    }
    api
      .post("contract/SendMobileContractLink", {
        contractId: routeContractId,
        recipientEmail: recipientEmail,
        allRelatedDealerUsers: linkRecipientType === RECIPIENT_TYPE.RELATED_DEALERS,
        allRelatedDealerDrivers: false,
        mobileVisibilityTypeId: linkVisibilityTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          setShowLinkModal(false);
          Alert.success(
            recipientEmail
              ? "A restricted link to the mobile contract site has just been sent to " +
                  recipientEmail
              : "The requested link has been sent!"
          );
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function generateQuote() {
    api
      .post(`${controllerUrl}/GenerateQuoteDocuments/${routeContractId}`)
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function onDownloadAttachment(key = null) {
    const pdfWindow = window.open();
    // null document key means download all
    api.downloadAndOpenFile(
      "contract/DownloadContractDocument",
      { referenceId: routeContractId, documentKey: key },
      pdfWindow,
      (e) => {
        pdfWindow.close();
        console.error(e);
        Alert.error("There was an error downloading the document");
      },
      "detect"
    );
  }

  function onDownloadImage(key, mimeType) {
    const pdfWindow = window.open();
    api.downloadAndOpenFile(
      "contract/DownloadContractDocument",
      { referenceId: routeContractId, documentKey: key },
      pdfWindow,
      (e) => {
        pdfWindow.close();
        console.error(e);
        // Alert.error("There was an error downloading the specified document");
      },
      "detect"
    );
  }

  function simpleContractOperation(url) {
    api
      .post(url, { id: routeContractId })
      .then((r) => {
        if (r.data.success) {
          if (url === "contract/DeleteContract") {
            history.push("/Contracts/Search");
          }
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  function resolveRecipientColor(recipientType) {
    return linkRecipientType === recipientType ? "primary" : "secondary";
  }

  function resolvedHeight() {
    let heightDsc = "";
    if (!contract || (!contract.unitHeightFeet && !contract.unitHeightFeetOtherText))
      return heightDsc;
    if (contract.unitHeightFeetOtherText) {
      return ` x ${contract.unitHeightFeetOtherText}' H`;
    }
    return ` x ${contract.unitHeightFeet}' H`;
  }
  function resolveDataByFieldName(fieldName, typeOfTerm) {
    let term = paymentOptions.find((o) => o.typeOfContractTerm === typeOfTerm);
    if (term !== undefined) {
      return ui_helpers.formatCurrency(term[fieldName]);
    }
    return "";
  }

  function setPermissions() {
    const isSystemAdmin = ui_helpers.isSystemAdministrator(currentUser);
    const canManageContract = ui_helpers.canCreateContracts(
      currentUser,
      contract.companyId,
      contract.dealerId
    );
    const isContractCreator = _.some(
      currentUser.roles,
      (ro) =>
        (ro.dealerId === contract.dealerId || ro.companyId === contract.rtoCompanyId) &&
        ro.typeOfUserRole === constants.ROLE_IDS.ContractCreator
    );
    const isManufacturerUser = _.some(
      currentUser.roles,
      (ro) =>
        ro.manufacturerId === contract.manufacturerId &&
        (ro.typeOfUserRole === constants.ROLE_IDS.InventoryCreator ||
          ro.typeOfUserRole === constants.ROLE_IDS.InventoryManager ||
          ro.typeOfUserRole === constants.ROLE_IDS.Administrator)
    );
    const canDispatch =
      isSystemAdmin ||
      _.some(
        currentUser.roles,
        (ro) =>
          ((ro.manufacturerId === contract.manufacturerId ||
            ro.companyId === contract.rtoCompanyId ||
            ro.companyId === contract.companyId) &&
            ro.typeOfUserRole === constants.ROLE_IDS.Administrator) ||
          ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher ||
          (ro.dealerId === contract.dealerId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher) ||
          (ro.companyId === contract.companyId &&
            ro.typeOfUserRole === constants.ROLE_IDS.Dispatcher)
      );
    const isDriver = _.some(currentUser.roles, ro =>
        ((ro.manufacturerId === contract.manufacturerId ||
          ro.companyId === contract.rtoCompanyId ||
          ro.dealerId === contract.dealerId ||
          ro.companyId === contract.companyId) &&
          ro.typeOfUserRole === constants.ROLE_IDS.Driver)
    );
    const canAddComments =
      canManageContract || isContractCreator || isManufacturerUser || canDispatch;
    const canAddAttachments = canManageContract;
    const isContractOwner = isSysAdmin || ui_helpers.isContractOwner(currentUser, contract.companyId);
    const isStoreUser = isSysAdmin || ui_helpers.isStoreUser(currentUser, contract.dealerId);

    setIsSysAdmin(isSystemAdmin);
    setCanManageContract(canManageContract);
    setIsContractCreator(isContractCreator);
    setIsManufacturerUser(isManufacturerUser);
    setCanDispatch(canDispatch);
    setCanAddComments(canAddComments);
    setCanAddAttachments(canAddAttachments);
    setIsContractOwner(isContractOwner);
    setIsStoreUser(isStoreUser);
    setCanDispatch(canDispatch);
    setIsDriverOrDispatcher(canDispatch || isDriver);
  };

  const convertToCashSale = () => {
    if (!routeContractId) return;
    api
      .post(`CashSale/ConvertQuoteToCashSale/${routeContractId}`)
      .then((response) => {
        if (response.data.success) {
          setRedirectTo(`${constants.PATH_NAMES.CONVERT_QUOTE_TO_CASH_SALE}/${routeContractId}`);
          setMessage(null);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((e) => console.error(e));
  };

  function generateAndNavigateToNewQuote() {
    if (!routeContractId) return;
    api
      .post("CashSale/NewQuoteVersion", { id: routeContractId })
      .then((response) => {
        if (response.data.success) {
          setMessage(null);
          setRedirectTo(constants.PATH_NAMES.NEW_QUOTE_VERSION + "/" + response.data.message);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  const convertToContract = () => {
    if (!routeContractId) return;
    api
      .post(`Contract/ConvertQuoteToContract/${routeContractId}`)
      .then((response) => {
        if (response.data.success) {
          setRedirectTo(`${constants.PATH_NAMES.CONVERT_QUOTE_TO_RTO}/${routeContractId}`);
          setMessage(null);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((e) => console.error(e));
  };

  function onShowMobileLink() {
    if (contract.customerPhone1) {
      setOtherRecipient(ui_helpers.formatPhoneField(contract.customerPhone1));
      setLinkRecipientType(RECIPIENT_TYPE.OTHER);
    }
    setShowLinkModal(true);
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const unitPriceWithAdjustmentsString = ui_helpers.formatCurrency(
    contract.unitPrice + contract.sumOfPriceAdjustments
  );

  function commitUpload() {
    let payload = new FormData();
    payload.append("referenceId", routeContractId);
    payload.append("file", files[0]);
    if (promptingUploadAttachmentTypeId) {
      payload.append("typeOfAttachment", promptingUploadAttachmentTypeId);
    }
    const url = promptingUploadAttachmentTypeId === constants.ATTACHMENT_TYPE_IDS.TaxExemptionForm
        ? "contract/UploadTaxExemptionForm"
        : "contract/UploadContractImage";
    setClickedUpload(true);
    api
      .post_form_data(url, payload)
      .then((r) => {
        if (r.data.success) {
          Alert.success("The file was saved successfully");
          if (promptingUploadAttachmentTypeId) {
            setPromptingUploadAttachmentTypeId(null);
          }
          setFiles([]);
          loadContract();
        } else {
          setMessage({
            errorMessage:
              r.data.message || "There was an error saving the upload",
            color: "warning",
          });
          Alert.warning(
            r.data.message || "There was an error saving the upload"
          );
          setTimeout(() => {
            setMessage(null);
          }, 5000);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setClickedUpload(false));
  }

  function deleteAttachment(attachmentId, e, attachmentTypeId = null) {
    e.stopPropagation();
    api
      .post("contract/DeleteAttachment/" + routeContractId, {
        id: attachmentId,
        attachmentTypeId: attachmentTypeId,
      })
      .then((r) => {
        if (r.data.success) {
          loadContract();
        } else {
          Alert.error(r.data.message);
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <div>
      <Header agreementNumber={contract ? contract.agreementNumber : ""} pos />
      <div className="inner">
        <div className="inner-white">
          {showQuoteConversionModal ? (
            <Modal
              fullscreen="sm"
              toggle={() => {
                setMessage(null);
                setShowQuoteConversionModal(false);
              }}
              isOpen>
              <ModalHeader>Confirm Quote Conversion</ModalHeader>
              <ModalBody>
                <div>{message ? <RSAlert color="danger">{message}</RSAlert> : null}</div>
                <div>
                  Once converted this process cannot be undone. Quote will be moved to the{" "}
                  <strong>{convertQuoteTo}</strong> tab.
                </div>
              </ModalBody>
              <ModalFooter>
                <Row className={"d-flex justify-content-end"}>
                  <Col>
                    <ButtonGroup className="float-end">
                      <Button
                        color="primary"
                        onClick={
                          convertQuoteTo === constants.RTO_STRING
                            ? convertToContract
                            : convertToCashSale
                        }>
                        Confirm
                      </Button>
                      <Button
                        onClick={() => {
                          setMessage(null);
                          setShowQuoteConversionModal(false);
                        }}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          ) : null}
          {showCommentModal ? (
            <Modal fullscreen="sm" toggle={() => setShowCommentModal(false)} isOpen>
              <ModalHeader>Add Comment</ModalHeader>
              <ModalBody>
                <Input
                  type="textarea"
                  className="comment-textarea"
                  maxLength="800"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div
                  className="mb-1"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCommentPrivate(!commentPrivate);
                  }}>
                  <Input
                    type="checkbox"
                    checked={commentPrivate}
                    onChange={(e) => {
                      e.stopPropagation();
                      setCommentPrivate(!commentPrivate);
                    }}
                  />{" "}
                  Private to {contract.companyName}
                </div>
              </ModalBody>
              <ModalFooter>
                <Row className={"d-flex justify-content-end"}>
                  <Col>
                    <ButtonGroup className="float-end">
                      <Button color="primary" onClick={submitComment}>
                        Save
                      </Button>
                      <Button onClick={() => setShowCommentModal(false)}>Close</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          ) : null}
          {showCancelModal ? (
            <Modal fullscreen="sm" toggle={() => setShowCancelModal(false)} isOpen>
              <ModalHeader>Provide a cancel reason</ModalHeader>
              <ModalBody>
                <Input
                  type="textarea"
                  maxLength="800"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Row className={"d-flex justify-content-end"}>
                  <Col>
                    <ButtonGroup className="float-end">
                      <Button color="primary" onClick={commitCancelContract}>
                        Save
                      </Button>
                      <Button onClick={() => setShowCancelModal(false)}>Close</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          ) : null}
          {showLinkModal ? (
            <Modal fullscreen="sm" size="md" toggle={() => setShowLinkModal(false)} isOpen>
              <ModalHeader>Send Mobile Link</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="6">
                    <p>To recipient(s):</p>
                    <ButtonGroup vertical className="w-100">
                      {contract.customerEmail ? (
                        <Button
                          block
                          color={resolveRecipientColor(RECIPIENT_TYPE.CUSTOMER)}
                          onClick={() => setLinkRecipientType(RECIPIENT_TYPE.CUSTOMER)}>
                          Customer ({contract.customerEmail})
                        </Button>
                      ) : null}
                      <Button
                        block
                        color={resolveRecipientColor(RECIPIENT_TYPE.OTHER)}
                        onClick={() => setLinkRecipientType(RECIPIENT_TYPE.OTHER)}>
                        Click to enter Phone #
                      </Button>
                    </ButtonGroup>
                    {linkRecipientType === RECIPIENT_TYPE.OTHER ? (
                      <Input
                        type="text"
                        maxLength="150"
                        className="mt-2"
                        placeholder="Recipient Email/Mobile #"
                        value={otherRecipient}
                        onChange={(e) => setOtherRecipient(e.target.value)}
                      />
                    ) : null}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row className={"d-flex justify-content-end"}>
                  <Col>
                    <ButtonGroup className="float-end">
                      <Button color="primary" onClick={onSendMobileLink}>
                        Send Mobile Link
                      </Button>
                      <Button onClick={() => setShowLinkModal(false)}>Close</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          ) : null}
          <div className="d-flex justify-content-between">
            <div className="align-items-center btn-group-light" style={{ padding: "2px" }}>
              {contract.typeOfContractStatus === constants.CONTRACT_STATUS_ID.Cancelled ? null : (
                <>
                  {contract.mfgExpirationDate &&
                  new Date(contract.mfgExpirationDate) < new Date() ? null : (
                    <>
                      <Button color="dark" onClick={() => onShowMobileLink()}>
                        Send Mobile Link
                      </Button>
                      <Dropdown
                        isOpen={convertDropdownOpen}
                        toggle={() => setConvertDropdownOpen(!convertDropdownOpen)}
                        className="d-inline">
                        <DropdownToggle caret color="info">
                          Convert to...
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setConvertQuoteTo(constants.RTO_STRING);
                              setShowQuoteConversionModal(true);
                            }}>
                            RTO Contract
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setConvertQuoteTo(constants.CASH_SALE_STRING);
                              setShowQuoteConversionModal(true);
                            }}>
                            Cash Sale
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </>
                  )}
                  <Button color="warning" onClick={generateAndNavigateToNewQuote}>
                    New Version
                  </Button>
                  <Button color="danger" className="text-light" onClick={cancelContract}>
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <Card>
          {isLoading && contract ? (
            <Loading />
          ) : (
            <Container className="mt-4">
              <Row>
                <Col xs={3}>
                  <h5>
                    <span>Quote # {routeContractId}</span>
                  </h5>
                </Col>
                <Col xs={7}>
                  <h5>
                    Quoted by {contract.dealerName}{" "}
                    <span className="text-info">
                      ({_.trim(contract.createdByUserName)}) on{" "}
                      {date_helpers.formatDateToShortDate(contract.createdAt)}
                    </span>
                    <span className="quote-expired-title">
                      {contract.mfgExpirationDate &&
                      new Date(contract.mfgExpirationDate) < new Date()
                        ? " - Expired"
                        : ""}
                    </span>
                  </h5>
                </Col>
                <Col xs={2}>
                  <h3>
                    {ui_helpers.renderContractStatusBadge(
                      contract.typeOfContractStatus,
                      "float-end"
                    )}
                  </h3>
                </Col>
              </Row>
              <Row className="mt-3">
                <Accordion open={openAccordionItem} toggle={toggleAccordion}>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.CUSTOMER}>
                      <FontAwesomeIcon icon="user" className="me-2" />
                      {contract && `${contract.customerFirstName} ${contract.customerLastName}`}
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.CUSTOMER}>
                      <Row>
                        <Col md={6}>
                          <DetailsTable>
                            <DetailsRow heading="First Name" data={contract.customerFirstName} />
                            <DetailsRow heading="Last Name" data={contract.customerLastName} />
                            <DetailsRow heading="Cell Phone" data={contract.customerPhone1} />
                            <DetailsRow heading="Secondary Phone" data={contract.customerPhone2} />
                            <DetailsRow heading="Email" data={contract.customerEmail} />
                            <DetailsRow
                              heading="Delivery Address 1"
                              data={contract.deliveryAddress1}
                            />
                            <DetailsRow
                              heading="Delivery Address 2"
                              data={contract.deliveryAddress2}
                            />
                            <DetailsRow
                              heading="Delivery City, State, Zip"
                              data={`${contract.deliveryCity} ${contract.deliveryState}, ${contract.deliveryZip}`}
                            />
                            <DetailsRow heading="Delivery Region" data={contract.regionName} />
                            <DetailsRow
                              heading="Delivery County"
                              data={`${contract.deliveryCounty} (${contract.taxRateDisplay}% tax rate)`}
                            />
                            <DetailsRow
                              heading="In City Limits?"
                              data={contract.isInCityLimits ? "Yes" : "No"}
                            />
                            {contractType === constants.CONTRACT_TYPE.RTO ? (
                              <tr>
                                <th>
                                  <span
                                    id="pop1"
                                    onMouseEnter={() => setPropertyOwnerPopoverOpen(true)}
                                    onMouseLeave={() => setPropertyOwnerPopoverOpen(false)}
                                    className="contract-popover">
                                    Property Owner?
                                  </span>
                                  <Popover
                                    placement="top"
                                    isOpen={propertyOwnerPopoverOpen}
                                    target="pop1"
                                    toggle={() => setPropertyOwnerPopoverOpen(false)}>
                                    <PopoverBody>
                                      The customer owns the property at which the unit will reside.
                                    </PopoverBody>
                                  </Popover>
                                </th>
                                <td>
                                  <Row className="align-items-center">
                                    <Col sm="8">{contract.isPropertyOwner ? "Yes" : "No"}</Col>
                                  </Row>
                                </td>
                              </tr>
                            ) : null}
                            <DetailsRow heading="Billing Name" data={contract.billingName} />
                            <DetailsRow
                              heading="Billing Address 1"
                              data={contract.billingAddress1}
                            />
                            <DetailsRow
                              heading="Billing Address 2"
                              data={contract.billingAddress2}
                            />
                            <DetailsRow
                              heading="Billing City, State Zip"
                              data={`${contract.billingCity} ${contract.billingState}, ${contract.billingZip}`}
                            />
                            {contractType === constants.CONTRACT_TYPE.RTO ? (
                              <Fragment>
                                <DetailsRow
                                  heading="Land Owner First Name"
                                  data={contract.landownerFirstName}
                                />
                                <DetailsRow
                                  heading="Land Owner Last Name"
                                  data={contract.landownerLastName}
                                />
                                <DetailsRow
                                  heading="Land Owner Address 1"
                                  data={contract.landownerAddress1}
                                />
                                <DetailsRow
                                  heading="Land Owner Address 2"
                                  data={contract.landownerAddress2}
                                />
                                <DetailsRow
                                  heading="Land Owner City, State, Zip"
                                  data={
                                    contract.landownerFirstName
                                      ? `${contract.landownerCity} ${contract.landownerState}, ${contract.landownerZip}`
                                      : ""
                                  }
                                />
                                <DetailsRow
                                  heading="Land Owner Phone"
                                  data={
                                    contract.landownerPhone &&
                                    ui_helpers.formatPhoneField(contract.landownerPhone)
                                  }
                                />
                                <DetailsRow
                                  heading="Land Owner Email"
                                  data={contract.landownerEmail}
                                />
                                {_.map(additionalContacts, (ct, idx) => (
                                  <Fragment key={`contact-${ct.id}`}>
                                    <DetailsRow
                                      heading={`Additional Contact Name ${idx + 1}`}
                                      data={ct.firstName ? `${ct.firstName} ${ct.lastName}` : ""}
                                    />
                                    <DetailsRow
                                      heading={`Additional Contact Phone ${idx + 1}`}
                                      data={ct.phone && ui_helpers.formatPhoneField(ct.phone)}
                                    />
                                    <DetailsRow
                                      heading={`Additional Contact Email ${idx + 1}`}
                                      data={ct.email}
                                    />
                                    <DetailsRow
                                      heading={`Add'l Contact Relationship ${idx + 1}`}
                                      data={ct.relationshipToCustomer}
                                    />
                                  </Fragment>
                                ))}
                              </Fragment>
                            ) : (
                              <DetailsRow
                                heading="Preferred Contract Language"
                                data={contract.cultureName}
                              />
                            )}
                          </DetailsTable>
                        </Col>
                        {contractType === constants.CONTRACT_TYPE.RTO ? (
                          <Col md={6}>
                            <DetailsTable>
                              <tr>
                                <th>SSN</th>
                                <td>
                                  <Row className="align-items-center">
                                    <Col sm="8">
                                      {showSSN
                                        ? ui_helpers.formatSSNField(contract.customerSSN)
                                        : contract.customerSSNMaskedDisplay}
                                    </Col>
                                    {currentUserIsSysAdminOrCompanyAdmin ? (
                                      <Col>
                                        <Button
                                          size="sm"
                                          color="dark"
                                          className="float-end"
                                          onClick={() => setShowSSN(!showSSN)}>
                                          {showSSN ? "Hide" : "Show"}
                                        </Button>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </td>
                              </tr>
                              <DetailsRow
                                heading="License #"
                                data={contract.customerLicenseNumber}
                              />
                              <DetailsRow
                                heading="Date of Birth"
                                data={date_helpers.formatDateToShortDate(
                                  contract.customerDateOfBirth
                                )}
                              />
                              <DetailsRow
                                heading="Employment Type"
                                data={contract.customerEmploymentTypeName}
                              />
                              <DetailsRow
                                heading="Employer Name"
                                data={contract.customerEmployerName}
                              />
                              <DetailsRow
                                heading="Employer Address 1"
                                data={contract.customerEmployerAddress1}
                              />
                              <DetailsRow
                                heading="Employer Address 2"
                                data={contract.customerEmployerAddress2}
                              />
                              <DetailsRow
                                heading="Employer City, State Zip"
                                data={
                                  contract.customerEmployerName
                                    ? `${contract.customerEmployerCity} ${contract.customerEmployerState}, ${contract.customerEmployerZip}`
                                    : ""
                                }
                              />
                              <DetailsRow
                                heading="Corenter First Name"
                                data={contract.corenterFirstName}
                              />
                              <DetailsRow
                                heading="Corenter Last Name"
                                data={contract.corenterLastName}
                              />
                              <DetailsRow
                                heading="Corenter Date Of Birth"
                                data={
                                  contract.corenterDateOfBirth ? contract.corenterDateOfBirth : null
                                }
                              />
                              <DetailsRow
                                heading="Corenter Phone"
                                data={
                                  contract.corenterPhone &&
                                  ui_helpers.formatPhoneField(contract.corenterPhone)
                                }
                              />
                              <DetailsRow heading="Corenter Email" data={contract.corenterEmail} />
                              <DetailsRow
                                heading="Corenter Address 1"
                                data={contract.corenderAddress1}
                              />
                              <DetailsRow
                                heading="Corenter Address 2"
                                data={contract.corenterAddress2}
                              />
                              <DetailsRow
                                heading="Corenter City, State Zip"
                                data={
                                  contract.corenterFirstName
                                    ? `${contract.corenterCity} ${contract.corenterState}, ${contract.corenterZip}`
                                    : ""
                                }
                              />
                              <DetailsRow
                                heading="Preferred Contract Language"
                                data={contract.cultureName}
                              />
                            </DetailsTable>
                          </Col>
                        ) : null}
                      </Row>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.UNIT}>
                      <FontAwesomeIcon icon="building" className="me-2" />
                      Unit Information
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.UNIT}>
                      <Row>
                        <Col md={6}>
                          <DetailsTable>
                            {contractType === constants.CONTRACT_TYPE.RTO ? null : (
                              <tr>
                                <th>Owned By</th>
                                <td>
                                  <Row className="align-items-center">
                                    <Col sm="8">{contract.companyName}</Col>
                                  </Row>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>Serial #</th>
                              <td>
                                <Row className="align-items-center">
                                  <Col sm="8">{contract.serialNumber}</Col>
                                </Row>
                              </td>
                            </tr>
                            <DetailsRow heading="Product Type" data={contract.productTypeName} />
                            <DetailsRow heading="New?" data={contract.unitIsNew ? "Yes" : "No"} />
                            <DetailsRow heading="Manufacturer" data={contract.manufacturerName} />
                            <DetailsRow heading="Material" data={contract.unitTypeName} />
                            <DetailsRow
                              heading="Base Color"
                              data={contract.unitManufacturerBaseColorText}
                            />
                            <DetailsRow
                              heading="Trim Color"
                              data={contract.unitManufacturerTrimColorText}
                            />
                            <DetailsRow
                              heading="Roof Color"
                              data={contract.unitManufacturerRoofColorText}
                            />
                            <DetailsRow
                              heading="Style"
                              data={contract.unitManufacturerSubproductText}
                            />
                            <DetailsRow
                              heading="Dimensions (W x L x H)"
                              data={`${
                                contract.unitWidthFeetOtherText
                                  ? contract.unitWidthFeetOtherText
                                  : contract.unitWidthFeet
                              }' W x ${
                                contract.unitLengthFeetOtherText
                                  ? contract.unitLengthFeetOtherText
                                  : contract.unitLengthFeet
                              }' L${resolvedHeight()}`}
                            />
                          </DetailsTable>
                        </Col>
                        <Col md={6}>
                          <DetailsTable>
                            <DetailsRow
                              heading="Related Inventory"
                              data={
                                contract.relatedUnitInventoryInvoiceNo
                                  ? contract.relatedUnitInventoryInvoiceNo
                                  : "(None)"
                              }
                            />
                            <DetailsRow
                              heading="Unit Price w/ Adjustments"
                              numeric
                              data={unitPriceWithAdjustmentsString}
                            />
                            <tr>
                              <th>Add-Ons</th>
                              <td>
                                <Table size="sm" className="mb-0">
                                  <thead>
                                    <tr>
                                      <th>Description</th>
                                      <th>Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.map(addOns, (a, idx) => (
                                      <tr key={`${idx}-${a.name}`}>
                                        <td>{a.displayNameWithOptionDescription}</td>
                                        <td>
                                          {a.bundleId
                                            ? "Bundle Item"
                                            : a.totalPrice
                                            ? ui_helpers.formatCurrency(a.totalPrice)
                                            : ui_helpers.formatCurrency(a.price)}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </DetailsTable>
                        </Col>
                      </Row>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.PRICING}>
                      <FontAwesomeIcon icon="dollar-sign" className="me-2" />
                      Pricing
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.PRICING}>
                      <Row>
                        <Col md={6}>
                          <DetailsTable>
                            <tr>
                              <th colSpan="2" className="quote-pricing-view-title">
                                Cash Sale Price
                              </th>
                            </tr>
                            <DetailsRow
                              heading="Unit Price"
                              data={unitPriceWithAdjustmentsString}
                              numeric
                            />
                            {contract.totalTaxAmount ? (
                              <tr>
                                <th>Tax</th>
                                <td>
                                  <Row className="align-items-center">
                                    <Col>
                                      {contract.taxRateDisplay}% (
                                      {ui_helpers.formatCurrency(contract.totalTaxAmount)} Total)
                                    </Col>
                                    {contract.standardTaxRate ? (
                                      <Col>[{contract.standardTaxRateDisplay}% Suggested]</Col>
                                    ) : null}
                                  </Row>
                                </td>
                              </tr>
                            ) : null}
                            {contract.priceAdjustments &&
                              _.map(
                                _.filter(contract.priceAdjustments, (pa) => !pa.includeInUnitPrice),
                                (pa) => {
                                  return (
                                    <DetailsRow
                                      key={`pax-${pa.id}`}
                                      numeric
                                      heading={pa.description}
                                      data={ui_helpers.priceAdjustmentCaption(pa)}
                                    />
                                  );
                                }
                              )}
                          </DetailsTable>
                          <PriceSummaryTable
                            unitPrice={contract.unitPrice}
                            priceAdjustments={contract.priceAdjustments}
                            unitPriceWithAdjustmentsString={unitPriceWithAdjustmentsString}
                          />
                        </Col>
                        <Col md={6}>
                          <DetailsTable>
                            <DetailsRow
                              heading="Quote Expiration Date"
                              data={
                                contract.mfgExpirationDate
                                  ? `${date_helpers.formatDateToShortDate(
                                      contract.mfgExpirationDate
                                    )}${
                                      contract.mfgExpirationDate &&
                                      new Date(contract.mfgExpirationDate) < new Date()
                                        ? " - Expired"
                                        : ""
                                    }`
                                  : ""
                              }
                            />
                            {contract.quoteDownPayment ? (
                              <DetailsRow
                                heading="Down Payment"
                                data={ui_helpers.formatCurrency(
                                  parseFloat(contract.quoteDownPayment)
                                )}
                                numeric
                              />
                            ) : null}
                          </DetailsTable>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-1">
                          <DetailsTable className="mb-0">
                            <tr>
                              <th colSpan="2" className="quote-pricing-view-title">
                                RTO Price
                              </th>
                            </tr>
                          </DetailsTable>
                        </Col>
                      </Row>
                      <Row>
                        {paymentOptions.length && paymentOptions.length > 0
                          ? paymentOptions.map((o, idx) => (
                              <Col key={`pay-${idx}-${o.name}`}>
                                <DetailsTable>
                                  <tr>
                                    <th colSpan="2" className="quote-pricing-view-title">
                                      {o.name}
                                    </th>
                                  </tr>
                                  <DetailsRow
                                    heading="Unit Price"
                                    data={unitPriceWithAdjustmentsString}
                                    numeric
                                  />
                                  <DetailsRow
                                    heading="LDW"
                                    data={resolveDataByFieldName("dlwAmount", o.typeOfContractTerm)}
                                    numeric
                                  />
                                  <DetailsRow
                                    heading="Minimum Security Deposit"
                                    data={resolveDataByFieldName(
                                      "minimumSecurityDepositAmount",
                                      o.typeOfContractTerm
                                    )}
                                    numeric
                                  />
                                  <DetailsRow
                                    heading="Required For Delivery"
                                    data={resolveDataByFieldName(
                                      "requiredForDeliveryAmount",
                                      o.typeOfContractTerm
                                    )}
                                    numeric
                                  />
                                  <DetailsRow
                                    heading="Monthly Payment Before Tax"
                                    data={resolveDataByFieldName(
                                      "monthlyPaymentAmount",
                                      o.typeOfContractTerm
                                    )}
                                    numeric
                                  />
                                  <DetailsRow
                                    heading="Total Rental Cost"
                                    data={resolveDataByFieldName(
                                      "totalRentalAmount",
                                      o.typeOfContractTerm
                                    )}
                                    numeric
                                  />
                                </DetailsTable>
                              </Col>
                            ))
                          : null}
                      </Row>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.ESSENTIALS}>
                      <FontAwesomeIcon icon="print" className="me-2" />
                      Essentials
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.ESSENTIALS}>
                      <Table>
                        <thead>
                          {hasMissingAttachments ? (
                            <tr>
                              <td colSpan="6">
                                <Button
                                  size="sm"
                                  className="btn-ghost float-end"
                                  onClick={() => generateQuote()}>
                                  Generate Quote
                                </Button>
                              </td>
                            </tr>
                          ) : null}
                        </thead>
                        <tbody>
                          {attachmentAndMilestoneList.length && attachmentAndMilestoneList.length > 0 ? (
                            attachmentAndMilestoneList.map((a, idx) => (
                              <tr key={`att-${a.key}`}>
                                <td>
                                  <Button
                                    color="link"
                                    className="download-link"
                                    onClick={() => onDownloadAttachment(a.key)}>
                                    {a.name}
                                  </Button>
                                </td>
                                <td className="text-center mt-1"></td>
                                <td></td>
                                <td style={{ width: "170px" }}>{a.createdByUserFirstName}</td>
                                <td style={{ width: "200px" }}>
                                  {date_helpers.formatDateToShortDate(a.createdAt)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No quote found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.UPLOADS}>
                      <FontAwesomeIcon icon="image" className="me-2" />
                      Uploads
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.UPLOADS}>
                      {canManageContract || isSysAdmin || isStoreUser || isDriverOrDispatcher ? (
                        <Dropzone
                          onDrop={(fileList) => setFiles([fileList[0]])}
                          accept={["image/jpg", "image/jpeg", "image/png", "application/pdf"]}
                          style={{
                            width: "100%",
                            backgroundColor: "#F0F0F0",
                            borderRadius: "6px",
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="text-center">
                              <input {...getInputProps()} />
                              <div style={{ textAlign: "center" }} className="pt-3">
                                <div className="mb-1">Drop file here or click the icon</div>
                                <FontAwesomeIcon
                                  color={files.length && "secondary"}
                                  icon={files.length ? "check-square" : "file-upload"}
                                  size="3x"
                                  className="mb-4"
                                />
                                {files.length
                                  ? _.map(files, (f) => (
                                      <div key={f.name} className="description-text">
                                        {f.name}
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      ) : null}
                      {files.length ? (
                        <ButtonGroup size="sm" className="float-end text-light">
                          <Button
                            color="secondary"
                            size="sm"
                            disabled={clickedUpload}
                            onClick={() => setFiles([])}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            size="sm"
                            disabled={clickedUpload}
                            className="float-end py-2 text-light"
                            onClick={commitUpload}
                          >
                            Save
                          </Button>
                        </ButtonGroup>
                      ) : null}
                      <div style={{ clear: "both" }}>
                        <h4 className="mb-0">Images</h4>
                        <hr className="mt-0" />
                        {contractImages.length === 0 ? (
                          <div>No images are available.</div>
                        ) : (
                          <Row>
                            {_.map(contractImages, (ci) => (
                              <Col key={`img-${ci.id}`} xs={12} md={3}>
                                <span key={ci.id} className="image-border">
                                  <div className="inline-block">
                                    {canManageContract && isContractOwner ? (
                                      <Button
                                        className="xs text-light bg-cf-red position-absolute"
                                        onClick={(e) => deleteAttachment(ci.id, e)}>
                                        <FontAwesomeIcon icon="times-circle" />
                                      </Button>
                                    )
                                    : null}
                                    <img
                                      style={{ cursor: "pointer" }}
                                      className="img-thumbnail"
                                      width="120"
                                      title={`Added by ${ci.createdByUserFullName} at ${ci.createdAtLocalized} - click to download`}
                                      src={`/api/Contract/ViewImage/${ci.key}?preview=true`}
                                      onClick={() => onDownloadImage(ci.key, constants.MIME_PDF)}
                                    />
                                    {ci.name != null ? (
                                      <p
                                        style={{
                                          fontStyle: "italic",
                                        }}>
                                        {ci.name}
                                      </p>
                                    ) : null}
                                  </div>
                                </span>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId={TABS.COMMENTS}>
                      <Row className="w-100 align-items-center">
                        <Col>
                          <FontAwesomeIcon icon="comment" className="me-2" />
                          Comments
                          <Badge
                            color="dark"
                            className={
                              "ms-2 align-items-center justify-content-center text-center"
                            }>
                            {comments && comments.length}
                          </Badge>
                        </Col>
                      </Row>
                    </AccordionHeader>
                    <AccordionBody accordionId={TABS.COMMENTS}>
                      {canAddComments ? (
                        <Row>
                          <Col>
                            <Button
                              color="success"
                              className="float-end text-light mb-1"
                              size="sm"
                              onClick={() => setShowCommentModal(true)}>
                              <FontAwesomeIcon icon="plus" /> Add
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                      <Table size="sm">
                        <tbody>
                          {_.map(comments, (c) => (
                            <tr
                              key={c.id}
                              className={classnames({ private: c.isPrivate }, "comment-row")}>
                              <td className="content">{c.dsc}</td>
                              <td style={{ width: "180px" }}>{c.createdAtLocalized}</td>
                              <td style={{ width: "200px" }}>{c.userFullName}</td>
                              <td style={{ width: "50px" }}>
                                {c.userId === currentUser.id ||
                                currentUserIsSysAdminOrCompanyAdmin ? (
                                  <Button
                                    color="danger"
                                    className="text-light"
                                    size="sm"
                                    title="Delete"
                                    onClick={() => deleteComment(c.id)}>
                                    <FontAwesomeIcon icon="times-circle" />
                                  </Button>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Row>
            </Container>
          )}
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default QuoteView;
