import React, { Component, Fragment } from 'react';
import { Row, Col, Label, Input, ButtonGroup, Button } from "reactstrap";
import { api, constants } from "../helpers";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropZone } from "../components";
import _ from "lodash";
import Select from "react-select";
import Alert from "react-s-alert-v3";

export default class EditStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: props.store,
      cashSaleHeaderImage: null,
      generatingKey: false,
      isRTOAdmin: props.isRTOAdmin
    };
    this.toggleOwner = this.toggleOwner.bind(this);
    this.updateStoreState = this.updateStoreState.bind(this);
    this.updateCompanyState = this.updateCompanyState.bind(this);
  }

  toggleOwner(value) {
    const newValue = !value;
    if (newValue && (this.state?.store?.company?.id && this.state.store.company?.id > 0)) {
      Alert.info("Loading existing company details...");
    }
    this.setState(prevState => ({
      ...prevState,
      store: {
        ...prevState.store,
        isOwner: newValue
      }
    }));
  }

  updateStoreState(fieldName, value) {
    this.setState(prevState => ({
      ...prevState,
      store: {
        ...prevState.store,
        [fieldName]: value
      }
    }));
  }

  updateCompanyState(fieldName, value) {
    this.setState({
      store: {
        ...this.state.store,
        company: {
          ...this.state.store.company,
          [fieldName]: value
        }
      }
    });
  }

  onGenerateApiKey() {
    this.setState({
      generatingKey: true
    }, () => {
      api
        .fetch(`store/GenerateCarefreeApiKey`)
        .then(response => {
          this.setState({
            store: { ...this.state.store, carefreeApiKey: response.data.data },
            generatingKey: false,
          });
        })
        .catch(error => Alert.error("There was an error generating api key."));
    })
  }

  render() {
    const s = this.state.store;
    return (<Fragment>
    <Row>
      <Col xs="4">
        <Label>Store Name</Label>
        <Input
          type="text"
          name="name"
          value={s.name}
          maxLength="100"
          onChange={(event) => this.updateStoreState(event.target.name, event.target.value)}
        />
      </Col>
      <Col xs="4">
        <Label>Store Abbreviation</Label>
        <Input
          type="text"
          name="abbr"
          value={s.abbr}
          maxLength="15"
          onChange={(event) => this.updateStoreState(event.target.name, event.target.value)}
        />
      </Col>
      <Col xs="4">
        <ButtonGroup className="float-end">
          <Button
            size="sm"
            className="btn-success text-white cf-success buttonGroupSpacing"
            onClick={() => this.props.onSave(this.state.store, this.state.cashSaleHeaderImage)}
          >
            <FontAwesomeIcon icon="save" />{" "}
            Save
          </Button>
          <Button
            size="sm"
            className="btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            <FontAwesomeIcon icon="times-circle" />{" "}
            Cancel
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
    <Row className="rowSpacing">
      {this.state.isRTOAdmin ?
        (<Fragment>
          <Col xs="3">
          <Label>90 Days Same As Cash</Label>
          <ToggleButton
            value={s.sac}
            activeLabel={"Yes"}
            inactiveLabel={"No"}
            onToggle={(value) => this.updateStoreState('sac', !value)}
          />
        </Col>
        <Col xs="3">
          <Label>Can Set Retained Status</Label>
          <ToggleButton
            value={s.canSetRetainedStatus}
            activeLabel={"Yes"}
            inactiveLabel={"No"}
            onToggle={(value) => this.updateStoreState('canSetRetainedStatus', !value)}
          />
        </Col>
        </Fragment>)
        : null
      }
      <Col xs="3">
        <Label>Use Tablet Signature Capture</Label>
        <ToggleButton
          value={s.isTabletSignatureCapture || false}
          activeLabel={"Yes"}
          inactiveLabel={"No"}
          onToggle={(value) => this.updateStoreState('isTabletSignatureCapture', !value)}
        />
      </Col>
      <Col xs="3">
        <Label>Notify Creator Upon Contract Approval</Label>
        <ToggleButton
          value={s.notifyUponContractApproval}
          activeLabel={"Yes"}
          inactiveLabel={"No"}
          onToggle={(value) => this.updateStoreState('notifyUponContractApproval', !value)}
        />
      </Col>
    </Row>
    <Row className="rowSpacing">
        <Col sm="4">
          <Label>Website API Key (blank to disable)</Label>
          <Input
            type="text"
            name="wcApiKey"
            value={s.wcApiKey || ""}
            maxLength="50"
            onChange={(event) =>
              this.updateStoreState(event.target.name, event.target.value)
            }
          />
        </Col>
        <Col sm="4">
          <Label>Website Secret Key (blank to disable)</Label>
          <Input
            type="text"
            name="wcApiSecret"
            value={s.wcApiSecret || ""}
            maxLength="50"
            onChange={(event) =>
              this.updateStoreState(event.target.name, event.target.value)
            }
          />
        </Col>
        <Col sm="4">
          <Label>Website URL (blank to disable)</Label>
          <Input
            type="text"
            name="wcUrl"
            value={s.wcUrl || ""}
            maxLength="255"
            onChange={(event) =>
              this.updateStoreState(event.target.name, event.target.value)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Label>Carefree Api Key</Label>
          <Input
            type="text"
            name="carefreeApiKey"
            value={s.carefreeApiKey || ""}
            maxLength="255"
            disabled={true}
            onChange={(event) =>
              this.updateStoreState(event.target.name, event.target.value)
            }
          />
        </Col>
        <Col>
          <Button
            size="sm"
            className="btn-dark buttonGroupSpacing mt-4"
            disabled={!this.state.store}
            onClick={() => this.onGenerateApiKey()}
          >
            {this.state.generatingKey &&
              <Fragment>
                <FontAwesomeIcon icon="spinner" spin /> {" "}
              </Fragment>
            }
            Generate Key
          </Button>
        </Col>
    </Row>
    <Row className="rowSpacing">
      <Col xs="12">
        <Label>Building Tag Text</Label>
        <Input
          type="text"
          name="buildingTagText"
          value={s.buildingTagText}
          maxLength="200"
          onChange={(event) => this.updateStoreState(event.target.name, event.target.value)}
        />
      </Col>
    </Row>
    <Row className="rowSpacing">
      <Col sm="3">
        <Label>Tracking Commission</Label>
        <ToggleButton
          value={s.isTrackingCommission}
          activeLabel={"Yes"}
          inactiveLabel={"No"}
          onToggle={(value) => this.updateStoreState('isTrackingCommission', !value)}
        />
      </Col>
    </Row>
    {this.props.isSysAdmin
      ? (<Row className="rowSpacing">
          <Col sm="3">
            <Label>Can Own</Label>
            <ToggleButton
              value={s.companyId || s.isOwner}
              activeLabel={"Yes"}
              inactiveLabel={"No"}
              onToggle={(value) => this.toggleOwner(value)}
            />
          </Col>
          {s.companyId || s.isOwner
          ? (<Fragment>
              <Col sm="3">
                <Label>Allows Cash Sales</Label>
                <ToggleButton
                  value={s.enableCashSales}
                  activeLabel={"Yes"}
                  inactiveLabel={"No"}
                  onToggle={(value) => this.updateStoreState('enableCashSales', !value)}
                />
              </Col>
              {s.enableCashSales 
                ? (<Col sm="3">
                    <Label>Accept Credit Card Payments</Label>
                    <ToggleButton
                      value={s.enableCreditCardPayments}
                      activeLabel={"Yes"}
                      inactiveLabel={"No"}
                      onToggle={(value) => this.updateStoreState('enableCreditCardPayments', !value)}
                    />
                    </Col>)
                : null
              }
            </Fragment>)
          : null
        }
        </Row>)
      : null
    }
    {s.companyId || s.isOwner
      ? (<Fragment>
      <Row className="rowSpacing">
        <Col>
          <h4>Owner Company Information</h4>
            <Row>
              <Col>
                <Label>Address 1</Label>
                <Input
                  type="text"
                  name="remitTo1"
                  maxLength="100"
                  value={s.company?.remitTo1}
                  onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Address 2</Label>
                <Input
                  type="text"
                  name="remitTo2"
                  maxLength="100"
                  value={s.company?.remitTo2}
                  onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>City State, Zip</Label>
                <Input
                  type="text"
                  name="remitTo3"
                  maxLength="100"
                  value={s.company?.remitTo3}
                  onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Phone Number, Fax Number, Email</Label>
                <Input
                  type="text"
                  name="remitTo4"
                  maxLength="100"
                  value={s.company?.remitTo4}
                  onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                />
              </Col>
            </Row>
{/*          {s.enableRTOContracts
            ? (<Row className="pt-3">
                <Col xs="6">
                  <Label>RTO Processing Company</Label>
                  <Select
                    value={s.company?.rtoProcessingCompany}
                    options={_.filter(this.props.companies, c => c.value !== s.company?.id)}
                    onChange={(option) => this.updateCompanyState('rtoProcessingCompany', option)}
                  />
                </Col>
              </Row>)
            : null
          }
*/}
          </Col>
          <Col>
            {!s.id && (s.companyId || s.isOwner)
              ? (<Row>
              <Col xs="12">
                <Label>States</Label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti={true}
                  isClearable={true}
                  onBlurResetsInput={false}
                  onSelectResetsInput={false}
                  onCloseResetsInput={false}
                  classNamePrefix="react-select"
                  options={this.props.states}
                  value={s.company?.states}
                  onChange={(selections) => {
                    let values = [];
                    if (selections && selections.length) {
                      values = selections;
                    }
                    this.updateCompanyState('states', values)
                  }}
                />
              </Col>
            </Row>)
              : null
            }
        {s.enableCashSales && s.enableCreditCardPayments ? 
          (
            <Fragment>
              <h4>Cash Sale Credit Card Processing</h4>
              <Row>
                  <Col>
                    <Label>Payment Processor API Service</Label>
                    <Select
                      value={s.company?.paymentProcessor}
                      options={constants.paymentProcessorTypes}
                      onChange={(option) => this.updateCompanyState('paymentProcessor', option)}
                    />
                  </Col>
              </Row>
              <Row>
                  <Col>
                    <Label>API Key</Label>
                    <Input
                      type="text"
                      name="apiKey"
                      value={s.company?.apiKey}
                      onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                    />
                  </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Transaction Key</Label>
                  <Input
                    type="text"
                    name="transactionKey"
                    value={s.company?.transactionKey}
                    onChange={(e) => this.updateCompanyState(e.target.name, e.target.value)}
                  />
                </Col>
              </Row>
            </Fragment>
        ) : null
        }
        </Col>
      </Row>
      {s.enableCashSales
        ? (<Row className="rowSpacing">
            <Col xs="6">
              <h4>Cash Sale Header Image</h4>
              <DropZone
                acceptTypes={["image/png"]}
                hideCancel
                contentTypeId={constants.CASH_SALE_INVOICE_TEMPLATE_ID}
                captiveContext={{
                  isDefault: true,
                  regionId: 0,
                  cultureId: constants.CULTURE_ENGLISH_ID
                }}
                onSave={(file) => this.setState({cashSaleHeaderImage: file})}
                companyId={s.company?.id}
                message1="Template headers must be PNG format."
                message2="Preferred width is 1388px wide x 258px high or at least this ratio of Width to Height and smaller."
                //message1="Logos must be PNG format."
                //message2="Preferred width is 167px wide x 75px high or at least this ratio of Width to Height."
              />
            </Col>
          </Row>)
        : null
      }
    </Fragment>)
    : null
  }
</Fragment>);
  }
}

