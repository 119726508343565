import React, { useEffect, useState } from "react";
import ToggleButton from "react-toggle-button";
import { Row } from "reactstrap";

export default function ActiveToggleFilter(props) {
  const [filterValue, setFilterValue] = useState(props.value || false);

  useEffect(() => {
    if (props.clear) {
      onChange(false);
    }
  }, [props.clear]);

  useEffect(() => {
    if (filterValue === props.value) return;
    onChange(props.value);
  }, [props.value]);

  function onChange(newValue) {
    newValue = Boolean(newValue);
    const filteredDisplay =
      newValue != filterValue
        ? `${props.displayName}: ${newValue}`
        : `${props.displayName}: ${filterValue}`;
    if (newValue !== props.value) {
      props.onChangeCallback({
        filterName: props.filterName,
        value: newValue,
        filteredDisplay: filteredDisplay,
      });
    }
    setFilterValue(newValue);
  }

  return (
    <Row>
      <span className="text-center">{props.displayName}</span>
      <div className="d-flex justify-content-center">
        <ToggleButton
          value={filterValue}
          activeLabel={"Yes"}
          inactiveLabel={"No"}
          onToggle={(v) => onChange(!v)}
        />
      </div>
    </Row>
  );
}
