import React, {useState, Fragment, useEffect} from 'react';
import {Button, ButtonGroup, Card, CardHeader, Col, Row, Table, Container, Modal, ModalHeader,
ModalBody, ModalFooter, Input, Label} from "reactstrap";
import {useParams, Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {Header, MfgAdmin, Loading, Footer} from './';
import {api} from "../helpers";
import Alert from "react-s-alert-v3";

const defaultBuilder = {
  id: 0,
  firstName: '',
  lastName: '',
  abbreviation: ''
};

export default function ManufacturerBuilders(props) {
  const params = useParams();
  const mfgId = params.mfgId
    ? params.mfgId
    : props.mfgId;
  const [builderModal, setBuilderModal] = useState(false);
  const [isEditingBuilder, setIsEditingBuilder] = useState(false);
  const [builder, setBuilder] = useState(defaultBuilder);
  const [builders, setBuilders] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(getBuilders, []);

  const toggleBuilderActive = (builderId) => {
    api.post(`manufacturer/ToggleBuilderActive/${builderId}`).then(r => {
      if(!r.data.success) Alert.error('There was an issue toggling the state of this builder.');
      getBuilders();
    });
  };

  function getBuilders() {
    setLoading(true);
    api.fetch(`Reference/GetManufacturerBuilders/${mfgId}`).then(r => {
      setBuilders(r.data.message.list);
      setManufacturerName(r.data.message.manufacturerName);
    }).finally(() => setLoading(false));
  }

  const toggleBuilderModal = () => {
      setBuilderModal(!builderModal);
  };

  const closeBuilderModal = () => {
      setBuilderModal(false);
      cleanseBuilderModal();
  };

  const onBuilderChange = (fieldName, fieldValue) => {
      let newBuilder = Object.assign({}, builder);
      newBuilder[fieldName] = fieldValue;
      setBuilder(newBuilder);
  };

  const editBuilder= (builder) => {
      setBuilder(builder);
      setIsEditingBuilder(true);
      setBuilderModal(true);
  };

  const saveBuilder = () => {
      let builderId = builder.id ? builder.id : 0;
      let payload = {
          id: builderId,
          firstName: builder.firstName,
          lastName: builder.lastName,
          abbreviation: builder.abbreviation,
          manufacturerId: mfgId
      }
      api.post('manufacturer/SaveBuilder', payload)
          .then(r => {
              if(r.data.success)
              {
                  Alert.success("Saved Builder");
                  closeBuilderModal();
                  getBuilders();
              }
              else Alert.error("Error saving builder");
          })
  };

  const cleanseBuilderModal = () => {
      setBuilder(defaultBuilder);
  };
    return (<><Fragment/>
    <MfgAdmin mfgId={mfgId} tabName="BUILDERS" />
    <div className='inner'>
    <div className='inner-white'>
            <h2 className="py-2">
              <Link to={`/admin/manufacturer/${mfgId}`}>{manufacturerName}</Link> Builders
            </h2>
    </div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h2 className="page-title">Builders</h2>
                        </Col>
                        <Col>
                            <Button className='float-end bg-success' onClick={() => setBuilderModal(true)}><FontAwesomeIcon icon='plus'/></Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Table hover>
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Abbreviation</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {builders
                        ? _.map(builders, b =>
                            <tr key={`b-${b.id}`}>
                                <td sm={3}>
                                    {b.firstName}
                                </td>
                                <td sm={3}>
                                    {b.lastName}
                                </td>
                                <td sm={2}>
                                    {b.abbreviation}
                                </td>
                                <td sm={1} className='d-flex justify-content-end'>
                                    {b.deactivatedAt === null ? (
                                        <ButtonGroup>
                                            <Button onClick={() => editBuilder(b)}>
                                                <FontAwesomeIcon icon={'edit'}/>
                                            </Button>
                                            <Button
                                                size="sm"
                                                className="float-center btn-delete"
                                                onClick={() => toggleBuilderActive(b.id)}
                                            >
                                                <FontAwesomeIcon icon="trash-alt" />
                                            </Button>
                                        </ButtonGroup>
                                    ) : (
                                        <Button
                                            size="sm"
                                            className="float-end btn-success cf-success"
                                            onClick={() => toggleBuilderActive(b.id)}
                                        >
                                            <FontAwesomeIcon icon={'heartbeat'} className='text-white'/>
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        )
                        : null}
                    </tbody>
                </Table>
            </Card>
        <Modal isOpen={builderModal} centered>
            <ModalHeader>
                {isEditingBuilder ? `Edit ${builder.firstName} ${builder.lastName}` : 'Add new Builder'}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label>First Name</Label>
                        <Input type='text' value={builder.firstName} name='firstName'
                               onChange={(e) =>
                                   onBuilderChange(e.target.name, e.target.value)}/>
                    </Col>
                    <Col>
                        <Label>Last Name</Label>
                        <Input type='text' value={builder.lastName} name='lastName'
                               onChange={(e) =>
                                   onBuilderChange(e.target.name, e.target.value)}/>
                    </Col>
                    <Col>
                        <Label>Abbreviation</Label>
                        <Input type='text' value={builder.abbreviation} name='abbreviation'
                               onChange={(e) =>
                                   onBuilderChange(e.target.name, e.target.value)}/>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-end'>
                <Button color='secondary' onClick={() => closeBuilderModal()}>Cancel</Button>
                <Button color='primary' onClick={() => saveBuilder()}>Save</Button>
            </ModalFooter>
        </Modal>
      </div>
    <Footer/>
  </>);
}