import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { constants } from "../utils";
import { ui_helpers } from "../helpers";

export default function FollowupIcon(props) {
  let text;
  switch (props.followUpItem) {
    case constants.FOLLOW_UP.RTO:
      text = {
        notComplete: "This unit has NOT been loaded to RTO",
        complete: "This unit has been loaded to RTO",
      };
      break;
    case constants.FOLLOW_UP.CALL:
      text = {
        notComplete: "No courtesy call has been placed.",
        complete: "A courtesy call has been placed.",
      };
      break;
    case constants.FOLLOW_UP.HOLD:
      text = {
        notComplete: "This contract is not on hold.",
        complete: "This contract is on hold.",
      };
      break;
    case constants.FOLLOW_UP.COMMISSION:
      text = {
        notComplete: "Commission has not been paid.",
        complete: "Commission has been paid.",
      };
      break;
    default:
      text = {}; // unknown item type
      break;
  }

  const key = `tt-${props.id}`;
  const bgClass = ui_helpers.followUpBackground(props.followUpItem, props.done);
  return (
    <>
      <Badge id={`fi-${props.id}`} data-tip data-for={key} className={`bg-${bgClass} me-1`}>
        <FontAwesomeIcon icon={props.icon} style={{ color: props.done ? "#fff" : "#000" }} />
      </Badge>
      <ReactTooltip
        id={key}
        place="bottom"
        type={ui_helpers.resolveTooltipTypeByBgColor(bgClass)}
        effect={"solid"}>
        <p style={{ fontSize: "1rem", opacity: "1" }}>
          {props.done ? text.complete : text.notComplete}
        </p>
      </ReactTooltip>
    </>
  );
}
