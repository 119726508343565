import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  DropZone as CFDropzone,
  Header,
  Loading,
  SubNavigationDropdown,
  CompanyPricing,
} from "./";
import {
  Alert as RSAlert,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  ListGroup,
  Row,
  Button,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  ButtonGroup,
  Label,
  Input,
  Table,
  Collapse,
  Badge,
  Form,
  FormGroup,
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";
import { api, constants, date_helpers, ui_helpers } from "../helpers";
import { UserContext } from "../utils";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";
import Alert from "react-s-alert-v3";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import ToggleButton from "react-toggle-button";
import { RadioGroup, Radio } from "react-radio-group";
import { Users } from "../pages";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import { useConsole } from "../hooks";
import { useHistory } from "react-router-dom";
import {
  Footer
} from '../components';
const tabNames = {
  Details: "1",
  Templates: "2",
  LDW: "3",
  Users: "4",
  Manufacturers: "5",
  Stores: "6",
  States: "7",
  Promotions: "8",
  SystemMessage: "9",
  // Reports: "10",
  Payments: "11",
  PricingRules: "12",
};

const defaultPromotion = {
  name: "",
  code: "",
  effectiveFrom: null,
  effectiveThrough: null,
  deactivatedAt: null,
  promotionType: {},
  mustBeUsed: false,
  companyTerms: [],
};

const DragHandle = SortableHandle(() => (
  <span>
    <FontAwesomeIcon icon="arrows-alt" />
  </span>
));

const SortableItem = SortableElement(({ company, toggleActivate }) => (
  <Card>
    <CardBody>
      <Row className="mb-1">
        <Col xs="1">
          <DragHandle />
        </Col>
        <Col xs="3">Priority: {company.prioritySeq}</Col>
        <Col xs="3">{company.company.name}</Col>
        <Col xs="3">
          <Row>
            <Col className="text-right">Active?</Col>
            <Col>
              <ToggleButton
                value={company.deactivatedAt === null}
                activeLabel={"Yes"}
                inactiveLabel={"No"}
                onToggle={(value) => toggleActivate(value, company)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
));

const SortableCompanyList = SortableContainer(
  ({ companies, toggleActivate }) => {
    return (
      <ListGroup>
        {companies.map((company, index) => (
          <SortableItem
            key={`priorities-${company.companyId}-${index}`}
            index={index}
            collection={company.regionId}
            company={company}
            toggleActivate={toggleActivate}
          />
        ))}
      </ListGroup>
    );
  }
);

export function CompanyTermPromotion({ data, terms, addCompanyTerm }) {
  const [termList, setTermList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [tab, setTab] = useState("");

  useEffect(() => {
    initializeState();
  }, []);

  const initializeState = () => {
    let pt = _.chain(terms)
      .orderBy((t) => t.productTypeId)
      .map((t) => t.productTypeName)
      .uniq()
      .value();
    setProductTypeList(pt);

    let list = [];
    if (data && data.companyTerms && data.companyTerms.length) {
      pt.slice().forEach((type) => {
        let tlist = _.chain(terms.slice())
          .map((tl) => {
            tl.companyTermId = tl.id;
            return tl;
          })
          .filter((tl) => tl.productTypeName === type)
          .orderBy((tl) => tl.contractTermId)
          .value();
        tlist.forEach((term) => {
          term.selected = _.some(data.companyTerms, (t) => t.companyTermId === term.companyTermId);
          list.push(term);
        });
      });
    } else {
      list =
        terms && terms.length
          ? _.chain(terms.slice())
              .orderBy((t) => t.contractTermId)
              .map((t) => {
                t.selected = false;
                t.companyTermId = t.id;
                return t;
              })
              .value()
          : [];
    }
    setTermList(list);
    setTab(pt && pt.length ? pt[0] : "");
  };
  function onSelected(item) {
    let promoTerms = Object.assign([], data.companyTerms);
    if (item.selected) {
      promoTerms = _.reject(
        promoTerms,
        (p) => p.companyTermId === item.companyTermId
      );
    } else {
      let existing = _.find(
        promoTerms,
        (p) => p.companyTermId === item.companyTermId
      );
      if (existing === undefined) {
        promoTerms.push({
          companyTermId: item.companyTermId,
          contractTermName: item.contractTermName,
          contractTermId: item.contractTermId,
          productTypeId: item.productTypeId,
          productTypeName: item.productTypeName,
          promotionId: data.id,
        });
      }
    }
    let t = termList.slice();
    let tIndex = _.findIndex(t, (i) => i.companyTermId === item.companyTermId);
    t[tIndex].selected = !item.selected;
    setTermList(t);
    addCompanyTerm(promoTerms);
  }
  return (
    <>
      {data && productTypeList && productTypeList.length > 0 ? (
        <Card className="p-2">
          <Nav tabs>
            {productTypeList.map((t) => (
              <NavItem key={`promo${data.id}${t}`}>
                <NavLink
                  className={`${tab === t ? "active" : ""}`}
                  onClick={() => {
                    if (tab !== t) {
                      setTab(t);
                    }
                  }}
                >
                  {t}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={tab} className="mt-2">
            {productTypeList.map((t) => (
              <TabPane key={`promo${data.id}${t}tab`} tabId={t}>
                <Row>
                  <Col className="text-center description-text">
                    <span>Available to the following terms</span>
                  </Col>
                </Row>
                <Row className="p-2 text-center">
                  {termList && termList.length > 0
                    ? termList
                        .filter((p) => p.productTypeName === t)
                        .map((pt) => (
                          <Col
                            className="p-2 "
                            key={`${pt.productTypeName}${t}`}
                          >
                            <input
                              type="checkbox"
                              value={pt.selected}
                              checked={pt.selected}
                              onClick={() => onSelected(pt)}
                              className="m-2"
                            />
                            <span>{pt.contractTermName}</span>
                          </Col>
                        ))
                    : null}
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </Card>
      ) : null}
    </>
  );
}
const updatePrioritySequence = (regionId, companies) => {
  let updatedSequences = [];
  _.each(companies, (company) => {
    const index = _.findIndex(companies, (x) => x.id === company.id);
    company.prioritySeq = index + 1;
    updatedSequences.push(company);
  });
  return updatedSequences;
};

const groupTemplates = (templateList) => {
  let list = _.chain(templateList)
    .map((x) => {
      x.contentTypeName = x.typeOfCompanyContentName; //ui_helpers.getContentTypeName(x.typeOfCompanyContent);
      return x;
    })
    .sortBy("cultureName")
    .sortBy("regionName")
    .sortBy("contentTypeName")
    .value();
  let priorType = null;
  const color1 = "bg-light";
  const color2 = "bg-white";
  let bgClass = color1;
  _.each(list, (x) => {
    if (x.contentTypeName !== priorType) {
      priorType = x.contentTypeName;
      bgClass = bgClass === color1 ? color2 : color1;
    }
    x.bgClass = bgClass;
  });
  return list;
};

const shapeCompanyRegions = (list, stateList) => {
  if (list && list.length && stateList && stateList.length) {
    let newCompanyRegions = [];
    const groupedByRegion = _.groupBy(list, (e) => e.regionId);
    for (const regionId in groupedByRegion) {
      const regionKey = parseInt(regionId, 10);
      const region = _.find(stateList, (s) => s.id === regionKey);
      newCompanyRegions.push({
        regionId: regionKey,
        regionName: region.label,
        companyList: groupedByRegion[regionId]
      });
    }
    return {
      companyRegionList: _.sortBy(newCompanyRegions, r => r.regionName),
      selectedStateList: _.map(stateList, s => ({
        ...s, 
        selected: _.some(newCompanyRegions, cr => cr.regionId === s.id)
      }))
    };
  } else {
    return {
      companyRegionList: [],
      selectedStateList: _.map(stateList, s => ({
        ...s, 
        selected: false
      }))
    };
  }
};

const DataRow = (props) => {
  return (
    <Row className="rowSpacing">
      <Col xs="4">
        <strong>{props.header}</strong>
      </Col>
      <Col xs="8">{props.data}</Col>
    </Row>
  );
};

export default function CompanyAdmin(props) {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(
    props?.match?.params && props?.match?.params.tab
      ? props?.match?.params.tab
      : tabNames.Details
  );
  const [routeCompanyId, setRouteCompanyId] = useState(props?.match?.params.id);
  const [company, setCompany] = useState(constants.EMPTY_COMPANY);
  const [companies, setCompanies] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [groupedTemplates, setGroupedTemplates] = useState([]);
  const [newLdw, setNewLdw] = useState({
    minContractValue: "",
    monthlyPrice: "",
  });
  const [ldws, setLdws] = useState([]);
  const [ldwtoEdit, setLdwToEdit] = useState({});
  const [loadingCompanyStores, setLoadingCompanyStores] = useState(false);
  const [logo, setLogo] = useState(null);
  const [cashSaleHeader, setCashSaleHeader] = useState(null);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isEditingLogo, setIsEditingLogo] = useState(false);
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [addNewLdw, setAddNewLdw] = useState(false);
  const [addNewSystemMessage, setAddNewSystemMessage] = useState(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [isEditingPricingRules, setIsEditingPricingRules] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [users, setUsers] = useState([]);
  const [companyManufacturers, setCompanyManufacturers] = useState([]);
  const [manufacturerToSave, setManufacturerToSave] = useState({});
  const [stores, setStores] = useState([]);
  const [companyDealerPriorities, setCompanyDealerPriorities] = useState([]);
  const [companyStores, setCompanyStores] = useState([]);
  const [storeToSave, setStoretoSave] = useState({});
  const [states, setStates] = useState([]);
  const [cultures, setCultures] = useState([]);
  const [stateToSave, setStateToSave] = useState({});
  const [validCredentials, setValidCredentials] = useState(null);
  const [stateCompanyToSave, setStateCompanyToSave] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyRegions, setCompanyRegions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [companyTerms, setCompanyTerms] = useState([]);
  const [activeCompanyStoresOnly, setActiveCompanyStoresOnly] = useState(true);
  const [editingStoreRegions, setEditingStoreRegions] = useState(null);
  const [achDetails, setACHDetails] = useState({
    achPaymentProcessorMerchantId: 0,
    achProcessorUsername: "",
    achProcessorPassword: "",
    typeOfACHProvider: {
      value: null,
      label: "",
    },
  });
  const [isEditingACH, setIsEditingACH] = useState(false);
  const [newPromotion, setNewPromotion] = useState(null);
  const [promotionTypes, setPromotionTypes] = useState(constants.promotionTypes);
  const [systemMessages, setSystemMessages] = useState([]);
  const [showAllSystemMessages, setShowAllSystemMessages] = useState(false);
  const [newSystemMessage, setNewSystemMessage] = useState({
    effectiveFrom: null,
    effectiveTo: null,
    severity: {
      value: "",
      label: "",
    },
    isText: false,
    message: "",
  });
  const [messageToUploadImageTo, setMessageToUploadImageTo] = useState(null);
  const [severityTypes, setSeverityTypes] = useState(constants.severityTypes);
  const [payment, setPayment] = useState({
    paymentProcessor: {
      value: "",
      label: "",
    },
    apiKey: "",
    transactionKey: "",
    creditCardFeePercentage: 0,
  });
  const [pricingRules, setPricingRules] = useState({
    minContractValue: "",
    maxContractValue: "",
    checkedRule: "",
    doNotAllow: false,
    overage: false,
  });

  const userCtx = useContext(UserContext);
  const isSysAdmin = ui_helpers.isSystemAdministrator(userCtx.currentUser);
  const isCompanyAdmin = _.some(userCtx.currentUser.roles, 
    ro => ro.companyId === routeCompanyId && ro.typeOfUserRole === constants.ROLE_IDS.Administrator);
  const history = useHistory();

  const getCompanyDetails = (companyId) => {
    return api
      .fetch(`company/One/${companyId}`)
      .then((r) => {
        setCompany(r.data.data);
        setPricingRules({
          minContractValue: r.data.data.minContractValue,
          maxContractValue: r.data.data.maxContractValue,
          maxInitialPaymentPercent: r.data.data.maxInitialPaymentPercent
            ? r.data.data.maxInitialPaymentPercent * 100
            : 0,
          checkedRule:
            r.data.data.enablePriceOverrideWithAdditionalDownPaymentPct === null
              ? "doNotAllow"
              : "overage",
          doNotAllow: r.data.data
            .enablePriceOverrideWithAdditionalDownPaymentPct
            ? false
            : true,
          overage: r.data.data.enablePriceOverrideWithAdditionalDownPaymentPct
            ? true
            : false,
        });
        setPayment({
          paymentProcessor: {
            value: r.data.data.typeOfPaymentProcessor,
            label: _.find(constants.paymentProcessorTypes, (x) => {
              if (x.value === r.data.data.typeOfPaymentProcessor) {
                return x;
              }
            }).label,
          },
          apiKey: r.data.data.paymentGatewayApiLoginId
            ? r.data.data.paymentGatewayApiLoginId
            : "",
          transactionKey: r.data.data.paymentGatewayTransactionKey
            ? r.data.data.paymentGatewayTransactionKey
            : "",
          // rtoProcessingCompany: {
          //     value: r.data.data.rtoCompanyId,
          //     label: r.data.data.rtoCompanyName
          // },
          creditCardFeePercentage: r.data.data.creditCardFeePercentage * 100,
        });
        setACHDetails({
          typeOfACHProvider: {
            value: r.data.data.typeOfACHProvider,
            label: _.find(constants.ACH_PROCESSORS, (a) => {
              if (a.value === r.data.data.typeOfACHProvider) return a;
            })?.label,
          },
          achPaymentProcessorMerchantId:
            r.data.data.achPaymentProcessorMerchantId,
          achProcessorUsername: r.data.data.achProcessorUsername,
        });
      })
      .catch((error) =>
        Alert.error(error, "There was an error loading the company details")
      );
  };

  const getCompaniesForSelect = () => {
    return api
      .fetch("user/GetCompanies") // 'company/Companies'
      .then((r) => {
        const companyList = _.filter(
          r.data.data,
          (x) => !x.captiveToManufacturerId && !x.captiveToStoreId
        );
        setCompanies(ui_helpers.idNameToValueLabel(companyList));
      })
      .catch((error) =>
        Alert.error("There was an error loading the company list")
      );
  };

  const getLdwsByCompany = (companyId) => {
    return api
      .fetch(`company/ldws/${companyId}`)
      .then((r) =>
        setLdws(
          ui_helpers.addIsEditingField(
            _.sortBy(r.data.data, (x) => x.minContractValue)
          )
        )
      )
      .catch((error) =>
        Alert.error("There was an error loading the company ldw list")
      );
  };

  const getRegions = () => {
    return api
      .fetch("reference/RegionList")
      .then((r) => setStates(ui_helpers.idNameToValueLabel(r.data.data)))
      .catch((error) =>
        Alert.error("There was an error loading the state list")
      );
  };

  const getCultures = () => {
    return api
      .fetch("reference/CultureList")
      .then((r) => setCultures(ui_helpers.idNameToValueLabel(r.data.data)))
      .catch((error) =>
        Alert.error("There was an error loading the culture list")
      );
  };

  const getManufacturers = () => {
    return api
      .fetch("company/ManufacturerList")
      .then((r) => setManufacturers(ui_helpers.idNameToValueLabel(r.data.data)))
      .catch((error) =>
        Alert.error("There was an error loading the manufacturer list")
      );
  };

  const getCompanyManufacturers = (companyId) => {
    return api
      .fetch(`company/CompanyManufacturerList/${companyId}`)
      .then((r) => setCompanyManufacturers(r.data.data))
      .catch((error) =>
        Alert.error("There was an error loading the company manufacturer list")
      );
  };

  const getStores = () => {
    return api
      .fetch("company/DealerList")
      .then((r) => setStores(ui_helpers.idNameToValueLabel(r.data.data)))
      .catch((error) =>
        Alert.error("There was an error loading the store list")
      );
  };

  const getCompanyStores = (companyId) => {
    setLoadingCompanyStores(true);
    return api
      .fetch(`company/CompanyDealerList/${companyId}`)
      .then((r) => setCompanyStores(r.data.data))
      .catch((error) =>
        Alert.error("There was an error loading the company store list")
      ).finally(() => setLoadingCompanyStores(false));
  };

  const getCompanyPromotions = (companyId) => {
    return api
      .fetch(`company/CompanyPromotionList/${companyId}`)
      .then((r) => setPromotions(ui_helpers.addIsEditingField(r.data.data)))
      .catch((error) =>
        Alert.error("There was an error loading the company promotion list")
      );
  };

  const getCompanyTerms = (companyId) => {
    return api
      .fetch(`company/GetCompanyTermList/${companyId}`)
      .then((r) =>
        setCompanyTerms(
          ui_helpers.idNameToValueLabel(
            ui_helpers.addIsEditingField(r.data.message)
          )
        )
      )
      .catch((error) =>
        Alert.error("There was an error loading the company term list")
      );
  };

  const getCompanySystemMessages = (companyId) => {
    return api
      .fetch(`company/CompanySystemMessageList/${companyId}`)
      .then((r) => setSystemMessages(ui_helpers.addIsEditingField(r.data.data)))
      .catch((error) =>
        Alert.error(
          "There was an error loading the company system message list"
        )
      );
  };

  const getCompanyTemplates = (companyId) => {
    return api
      .fetch(`company/CompanyTemplateList/${companyId}`)
      .then((r) => {
        setTemplates(r.data.data);
        setGroupedTemplates(groupTemplates(r.data.data));
      })
      .catch((error) =>
        Alert.error(
          error,
          "There was an error loading the company template list"
        )
      );
  };

  const getCompanyRegions = (companyId, stateList) => {
    return api
      .fetch(`company/CompanyRegionsList/${companyId}`)
      .then((r) => {
        const {companyRegionList, selectedStateList} = shapeCompanyRegions(r.data.data, stateList)
        setCompanyRegions(companyRegionList);
        setSelectedStates(selectedStateList);
      })
      .catch((error) =>
        Alert.error("There was an error loading the company state list")
      );
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    Promise.all([
      getCompanyDetails(id),
      getCompaniesForSelect(),
      getLdwsByCompany(id),
      getRegions(),
      getCultures(),
      getManufacturers(),
      getCompanyManufacturers(id),
      getStores(),
      getCompanyStores(id),
      getCompanyPromotions(id),
      getCompanySystemMessages(id),
      getCompanyTemplates(id),
      getCompanyTerms(id),
    ])
      .then((aggResults) => {})
      .catch(api.catchHandler)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!states || !states.length) return;
    getCompanyRegions(routeCompanyId, states);
  }, [states]);

  function refreshCompanyTemplates(companyId) {
    api
      .fetch(`company/CompanyTemplateList/${companyId}`)
      .then((r) => {
        setTemplates(r.data.data);
        setGroupedTemplates(groupTemplates(r.data.data));
      })
      .catch((error) =>
        Alert.error("There was an error refreshing the company template list.")
      );
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  //Company Screen
  function editCompanyDetails() {
    setIsEditingCompany(true);
  }

  function cancelEditCompany() {
    setIsEditingCompany(false);
  }

  function updateCompanyState(fieldName, value) {
    setCompany({
      ...company,
      [fieldName]: value,
    });
  }

  function saveCompanyDetails() {
    let payload = Object.assign({}, company);
    if (!company.id) {
      payload.Id = 0;
      payload.TypeOfPaymentProcessor = 1; // none
      payload.PaymentGatewayApiLoginId = "";
      payload.PaymentGatewayTransactionKey = "";
      payload.MinContractValue = 500;
      payload.MaxContractValue = 12000;
      payload.RTOCompanyName = "";
    }
    api
      .post("company/SaveCompanyDetails", payload)
      .then((response) => {
        if (response.data.success) {
          setIsEditingCompany(false);
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) =>
        Alert.error("There was an error saving the company details")
      );
  }

  //Templates Screen
  function loadLogo() {
    setEditingTemplate(null);
    setIsEditingLogo(false);
    setIsEditingHeader(false);
  }

  function loadHeader() {
    setEditingTemplate(null);
    setIsEditingLogo(false);
    setIsEditingHeader(false);
  }

  function loadTemplates() {
    getCompanyTemplates(routeCompanyId)
      .then((r) => {
        setTemplates(r.templates);
        setGroupedTemplates(r.groupedTemplates);
        setEditingTemplate(null);
        setIsEditingLogo(false);
        setIsEditingHeader(false);
      })
      .catch((error) =>
        Alert.error("There was an error reloading the template list")
      );
  }

  function validateAch() {
    const payload = {
      companyId: parseInt(routeCompanyId, 10),
      merchantId: parseInt(achDetails.achPaymentProcessorMerchantId, 10),
      typeOfACHProvider: achDetails.typeOfACHProvider.value,
      username: achDetails.achProcessorUsername,
      password: achDetails.achProcessorPassword,
    };
    api
      .post(`company/ValidateACHCredentials`, payload)
      .then((r) => {
        if (r.data) {
          if (r.data.success) setValidCredentials(true);
          else setValidCredentials(false);
        }
      })
      .catch(api.catchHandler);
  }

  function editLogo() {
    setIsEditingLogo(true);
  }

  function cancelEditLogo() {
    setIsEditingLogo(false);
  }

  function editHeader() {
    setIsEditingHeader(true);
  }

  function cancelEditHeader() {
    setIsEditingHeader(false);
  }

  function deleteTemplate(templateId) {
    if (!window.confirm("Are you sure you'd like to delete this template?"))
      return;
    api
      .delete("company/template/" + templateId)
      .then((response) => {
        if (response.data.success) {
          getCompanyTemplates(routeCompanyId)
            .then((newStatus) => {
              // this.setState(newStatus);
              const { templates, groupedTemplates } = newStatus;
              setTemplates(templates);
              setGroupedTemplates(groupedTemplates);
            })
            .catch(api.catchHandler);
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) =>
        Alert.error("There was an error deleting the template")
      );
  }

  function showTemplateUploadModal(template, contentTypeId) {
    if (template === null) {
      template = {
        id: 0,
        contentTypeId: contentTypeId,
        isDefault: template.isDefault,
        regionId: template.regionId,
        cultureId: template.cultureId,
      };
    }
    if (!template.contentTypeId && template.typeOfCompanyContent) {
      template.contentTypeId = template.typeOfCompanyContent;
    }
    setEditingTemplate(template);
  }

  //LDW Screen
  function toggleAddNewLdw() {
    setAddNewLdw(!addNewLdw);
  }

  function handleLdwStateChange(fieldName, value) {
    setNewLdw({
      ...newLdw,
      [fieldName]: value,
    });
  }

  function toggleEditExistingLdw(ldwId) {
    const ldwsArray = ldws.slice();
    const ldw = _.find(ldwsArray, (x) => x.id === ldwId);
    ldw.isEditing = true;
    setLdws(ldwsArray);
  }

  function updateLdwMinContractValue(ldwId, value) {
    const ldwsArray = ldws.slice();
    const ldw = _.find(ldwsArray, (x) => x.id === ldwId);
    ldw.minContractValue = value;
    setLdws(ldwsArray);
  }

  function updateLdwMonthlyPrice(ldwId, value) {
    const ldwsArray = ldws.slice();
    const ldw = _.find(ldwsArray, (x) => x.id === ldwId);
    ldw.monthlyPrice = value;
    setLdws(ldwsArray);
  }

  function cancelLdwEdit(ldwId) {
    const ldwsArray = ldws.slice();
    const ldw = _.find(ldwsArray, (x) => x.id === ldwId);
    ldw.isEditing = false;
    setLdws(ldwsArray);
  }

  function saveLdw(update) {
    const ldwsArray = ldws.slice();
    if (update === false) {
      const ldw = {
        CompanyId: company.id,
        MinContractValue: newLdw.minContractValue,
        MonthlyPrice: newLdw.monthlyPrice,
      };
      api
        .post("company/SaveLdw", ldw)
        .then((response) => {
          const savedLdw = response.data.data;
          savedLdw.isEditing = false;
          ldwsArray.push(savedLdw);
          setAddNewLdw(false);
          setLdws(ldwsArray);
          setNewLdw({
            minContractPrice: "",
            monthlyPrice: "",
          });
        })
        .catch((error) =>
          Alert.error("There was an error updating the company details")
        );
    } else {
      const ldw = {
        Id: update.id,
        CompanyId: company.id,
        MinContractValue: update.minContractValue,
        MonthlyPrice: update.monthlyPrice,
      };
      api
        .post("company/SaveLdw", ldw)
        .then((response) => {
          cancelLdwEdit(update.id);
        })
        .catch((error) =>
          Alert.error("There was an error updating the company details")
        );
    }
  }

  function deleteLdw(ldwId) {
    const ldwsArray = ldws.slice();
    api
      .post(`company/DeleteLdw/${ldwId}`)
      .then((response) => {
        const filtered = _.filter(ldwsArray, (x) => x.id !== ldwId);
        setLdws(filtered);
      })
      .catch((error) => Alert.error("There was an error deleting the LDW"));
  }

  //Company Manufacturers Screen
  function getSelectedManufacturer(manufacturer) {
    const selected_option = _.find(
      manufacturers,
      (x) => x.label === manufacturer.label
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedManufacturer(option) {
    setManufacturerToSave({
      value: option.value,
      label: option.label,
    });
  }

  function saveManufacturerToCompany() {
    if (!manufacturerToSave || _.isEmpty(manufacturerToSave)) {
      Alert.warning("Select a manufacturer from the list in order to add an association.");
      return;
    }
    const CompanyManufacturerDto = {
      CompanyId: company.id,
      ManufacturerId: manufacturerToSave.value,
    };
    api
      .post("company/AddCompanyManufacturer", CompanyManufacturerDto)
      .then((response) => {
        if (response.data.success) {
          getCompanyManufacturers(routeCompanyId).then((r) => {
            setManufacturerToSave({});
          });
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) =>
        Alert.error("There was an error saving the company manufacturer")
      );
  }

  function activateManufacturer(companyManufacturer) {
    const newCompanyManufacturers = companyManufacturers.slice();
    const foundCM = _.find(
      newCompanyManufacturers,
      (x) => x.id === companyManufacturer.id
    );
    const CompanyManufacturerDto = {
      CompanyId: companyManufacturer.companyId,
      ManufacturerId: companyManufacturer.manufacturerId,
    };
    api
      .post("company/ActivateCompanyManufacturer", CompanyManufacturerDto)
      .then((response) => {
        const activated = response.data.data;
        foundCM.deactivatedAt = activated.deactivatedAt;
        foundCM.deactivatedByUserId = activated.deactivatedByUserId;
        setCompanyManufacturers(companyManufacturers);
      })
      .catch((error) =>
        Alert.error("There was an error activating the company manufacturer")
      );
  }

  function deactivateManufacturer(cm) {
    const newCompanyManufacturers = companyManufacturers.slice();
    api.post("company/DeactivateCompanyManufacturer", {
        CompanyId: cm.companyId,
        ManufacturerId: cm.manufacturerId,
      }).then((response) => {
        const deactivated = response.data.data;
        let foundCM = _.find(newCompanyManufacturers, (x) => x.id === cm.id);
        foundCM.deactivatedAt = deactivated.deactivatedAt;
        foundCM.deactivatedByUserId = deactivated.deactivatedByUserId;
        setCompanyManufacturers(newCompanyManufacturers);
      })
      .catch((error) =>
        Alert.error("There was an error deactivating the company manufacturer")
      );
  }

  //Company Stores Screen
  function getSelectedStore(store) {
    const selected_option = _.find(stores, (x) => x.label === store.label);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedStore(option) {
    setStoretoSave({
      label: option.label,
      value: option.value,
    });
  }

  function saveStoreToCompany() {
    if (!storeToSave.value) {
      Alert.warning(
        "Select a store to associate prior to clicking the add button"
      );
      return;
    }
    api
      .post("company/AddCompanyDealer", {
        CompanyId: company.id,
        DealerId: storeToSave.value,
      })
      .then((response) => {
        if (response.data.success) {
          setStoretoSave({});
          setCompanyStores([response.data.data, ...companyStores]);
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) =>
        Alert.error("There was an error saving the company store")
      );
  }

  function toggleStore(store, active) {
    const stores = companyStores.slice();
    const foundStore = _.find(stores, (x) => x.id === store.id);
    const route = active
      ? "company/ActivateCompanyDealer"
      : "company/DeactivateCompanyDealer";
    api
      .post(route, {
        Id: store.id,
        CompanyId: store.companyId,
        DealerId: store.dealerId,
      })
      .then((response) => {
        const changed = response.data.data;
        foundStore.deactivatedAt = changed.deactivatedAt;
        foundStore.deactivatedByUserId = changed.deactivatedByUserId;
        setCompanyStores(stores);
      })
      .catch((error) =>
        Alert.error(
          "There was an error changing activation of the company store"
        )
      );
  }

  function onToggleDealerRTO(store) {
    const newValue = !store.isRTO;
    if (
      (company.captiveToManufacturerId || company.captiveToStoreId) &&
      newValue
    ) {
      Alert.error(
        "Store relationships for this company may not be marked capable of RTO due to this company's affiliation with a manufacturer or store owner."
      );
      return;
    }
    api
      .post("company/ToggleDealerRTO", { ...store, isRTO: newValue })
      .then((response) => {
        const stores = companyStores.slice();
        const idx = _.findIndex(stores, (x) => x.id === store.id);
        stores[idx].isRTO = !stores[idx].isRTO;
        setCompanyStores(stores);
      })
      .catch((error) =>
        Alert.error("There was an error toggling store RTO sales.")
      );
  }

  function onToggleDealerCashSales(store) {
    api
      .post("company/ToggleDealerCashSales", {
        ...store,
        isCashSales: !store.isCashSales,
      })
      .then((response) => {
        const stores = companyStores.slice();
        const idx = _.findIndex(stores, (x) => x.id === store.id);
        stores[idx].isCashSales = !stores[idx].isCashSales;
        setCompanyStores(stores);
      })
      .catch((error) =>
        Alert.error("There was an error toggling store cash sales.")
      );
  }

  //States Screen
  function getSelectedState(state) {
    const selected_option = _.find(states, (x) => x.label === state.label);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function getSelectedCompany(c) {
    if (!c) return null;
    const selected_option = _.find(companies, (x) => x.value === c.value);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedState(option) {
    setStateToSave({
      label: option.label,
      value: option.value,
    });
  }

  function setSelectedStateCompany(option) {
    setStateCompanyToSave({
      label: option.label,
      value: option.value,
    });
  }

  const onSortEnd = (params) => {
    let newCompanyRegions = companyRegions.slice();
    const regionId = params.collection;
    const regionIndex = _.findIndex(
      newCompanyRegions,
      (cr) => cr.regionId === regionId
    );
    newCompanyRegions[regionIndex].companyList = arrayMove(
      newCompanyRegions[regionIndex].companyList,
      params.oldIndex,
      params.newIndex
    );
    _.each(newCompanyRegions[regionIndex].companyList, (c, idx) => {
      c.prioritySeq = idx + 1;
    });
    setCompanyRegions(newCompanyRegions);
    _.forEach(
      updatePrioritySequence(
        regionId,
        newCompanyRegions[regionIndex].companyList
      ),
      (c) => {
        c.ownerCompanyId = company.id;
        api
          .post("company/UpdateCompanyRegionPrioritySequence", c)
          .then((response) => {
            if (!response.data.success) {
              const msg = "Failed to update company sequence";
              console.error(msg, c);
              throw new Error(msg);
            }
          })
          .catch((error) =>
            Alert.error("There was an error updating the priority sequence.")
          );
      }
    );
  };

  function saveStateToCompany() {
    const CompanyRegionDto = {
      OwnerCompanyId: company.id,
      CompanyId: stateCompanyToSave.value,
      RegionId: stateToSave.value,
    };
    api
      .post("company/AddCompanyRegion", CompanyRegionDto)
      .then((response) => {
        if (response.data.success === false) {
          Alert.error(response.data.message);
        } else {
          setStateToSave({});
          setStateCompanyToSave(null);
          getCompanyRegions(company.id, states).then((regionState) =>
            // this.setState(regionState));
            // setCompanyRegions([regionState, ...companyRegions]));
            refreshCompanyTemplates(company.id)
          );
        }
      })
      .catch((error) =>
        Alert.error("There was an error saving the company state")
      );
  }

  // deactivateState(state) {
  //     const states = this.state.companyRegions.slice();
  //     const foundRegion = _.find(states, x => x.id === state.id);
  //     const CompanyRegionDto = {
  //         Id: state.id,
  //         OwnerCompanyId: state.ownerCompany.id,
  //         CompanyId: state.companyId,
  //         RegionId: state.regionId
  //     }
  //     api
  //         .post("company/DeactivateCompanyRegion", CompanyRegionDto)
  //         .then(response => {
  //             const activated = response.data.data;
  //             foundRegion.deactivatedAt = activated.deactivatedAt;
  //             foundRegion.deactivatedByUserId = activated.deactivatedByUserId;
  //             this.setState({
  //                 companyRegions: states
  //             });
  //             this.refreshCompanyTemplates(this.state.company.id);
  //         })
  //         .catch(error => Alert.error("There was an error activating the company state"));
  // }

  //Promotions Screen

  function getPromotionsRowStyling(throughDate, deactivatedAt) {
    if (deactivatedAt !== null) {
      return "";
    }
    const today = new Date();
    const through = new Date(throughDate);
    if (today < through) {
      return "tr-success";
    }
    if (today > through) {
      return "";
    }
  }

  function handlePromotionStateChange(fieldName, value) {
    setNewPromotion({
      ...newPromotion,
      [fieldName]: value,
    });
  }

  function onFromDateSelect(effectiveFrom) {
    setNewPromotion({
      ...newPromotion,
      effectiveFrom: effectiveFrom,
    });
  }

  function onThroughDateSelect(effectiveThrough) {
    setNewPromotion({
      ...newPromotion,
      effectiveThrough: effectiveThrough,
    });
  }

  function getSelectedPromotionType(type) {
    const selected_option = _.find(
      promotionTypes,
      (x) => x.label === type.label
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedPromotionType(option) {
    setNewPromotion({
      ...newPromotion,
      promotionType: {
        value: option.value,
        label: option.label,
      },
    });
  }

  function editPromotion(promotionId) {
    const promos = promotions.slice();
    const promo = _.find(promos, (x) => x.id === promotionId);
    promo.isEditing = true;
    setPromotions(promos);
  }

  function cancelPromoEdit(promoId) {
    const promos = promotions.slice();
    const promo = _.find(promos, (x) => x.id === promoId);
    promo.isEditing = false;
    setPromotions(promos);
    getCompanyPromotions(company.id);
  }

  function updatePromoName(value, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.name = value;
    setPromotions(promos);
  }

  function updatePromoCode(value, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.code = value;
    setPromotions(promos);
  }

  function getPromotionType(type) {
    const selected_option = _.find(promotionTypes, (x) => x.value === type);
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }
  function addCompanyTerm(options, promoId, newPromo) {
    if (newPromo) {
      setNewPromotion({
        ...newPromotion,
        companyTerms: options,
      });
    } else {
      const promos = promotions.slice();
      const promoToEdit = _.find(promos, (x) => x.id === promoId);
      promoToEdit.companyTerms = options;
      setPromotions(promos);
    }
  }
  function setPromotionType(option, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.typeOfPromotion = option.value;
    setPromotions(promos);
  }

  function updateFromDate(effectiveFrom, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.effectiveFrom = effectiveFrom;
    setPromotions(promos);
  }

  function updateThroughDate(effectiveThrough, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.effectiveThrough = effectiveThrough;
    setPromotions(promos);
  }

  function updateUsedToggle(value, promoId) {
    const promos = promotions.slice();
    const promoToEdit = _.find(promos, (x) => x.id === promoId);
    promoToEdit.mustBeUsed = !value;
    setPromotions(promos);
  }

  function savePromotion() {
    const promotion = newPromotion;
    const PromotionDto = {
      Id: promotion.id ? promotion.id : 0,
      Name: promotion.name,
      Code: promotion.code,
      EffectiveFrom: promotion.effectiveFrom,
      EffectiveThrough: promotion.effectiveThrough,
      TypeOfPromotion: promotion.promotionType.value,
      MustBeUsed: promotion.mustBeUsed,
      CompanyId: company.id,
      CompanyTerms: promotion.companyTerms,
    };

    api
      .post("company/SaveCompanyPromotion", PromotionDto)
      .then((response) => {
        const savedPromo = response.data.data;
        savedPromo.isEditing = false;
        setNewPromotion(null);
        setPromotions([...promotions, savedPromo]);
        getCompanyPromotions(company.id);
      })
      .catch((error) => Alert.error("The promotion did not save"));
  }

  function updatePromotion(promo) {
    api
      .post("company/SaveCompanyPromotion", promo)
      .then((response) => {
        cancelPromoEdit(promo.id);
      })
      .catch((error) => Alert.error("The promotion did not save"));
  }

  function activatePromotion(promotion) {
    const promos = promotions.slice();
    const foundPromo = _.find(promos, (x) => x.id === promotion.id);
    const PromotionDto = {
      Id: promotion.id,
      Name: promotion.name,
      Code: promotion.code,
      EffectiveFrom: promotion.effectiveFrom,
      EffectiveThrough: promotion.effectiveThrough,
      PromotionTypeId: promotion.typeOfPromotion,
      MustBeUsed: promotion.mustBeUsed,
      CompanyId: company.id,
    };
    api
      .post("company/ActivatePromotion", PromotionDto)
      .then((response) => {
        getCompanyPromotions(company.id);
      })
      .catch((error) =>
        Alert.error("There was an error activating the company promotion")
      );
  }

  function deactivatePromotion(promotion) {
    const promos = promotions.slice();
    const foundPromo = _.find(promos, (x) => x.id === promotion.id);
    const PromotionDto = {
      Id: promotion.id,
      Name: promotion.name,
      Code: promotion.code,
      EffectiveFrom: promotion.effectiveFrom,
      EffectiveThrough: promotion.effectiveThrough,
      PromotionTypeId: promotion.typeOfPromotion,
      MustBeUsed: promotion.mustBeUsed,
      CompanyId: company.id,
    };
    api
      .post("company/DeactivatePromotion", PromotionDto)
      .then((response) => {
        getCompanyPromotions(company.id);
      })
      .catch((error) =>
        Alert.error("There was an error activating the company promotion")
      );
  }

  //System Messages Screen
  function toggleAddNewSystemMessage() {
    setAddNewSystemMessage(!addNewSystemMessage);
  }

  function messageIsEffectiveNow(fromDate, throughDate) {
    return moment().isAfter(fromDate) && moment().isBefore(throughDate);
  }

  function getMessagesRowStyling(fromDate, throughDate, deactivatedAt) {
    if (deactivatedAt) return "bg-secondary";
    if (messageIsEffectiveNow(fromDate, throughDate))
      return "cf-success";
    return "";
  }

  function getSeverityBadgeClassName(severity) {
    switch (severity) {
      case "Informational":
        return "cf-info";
      case "High":
        return "cf-warning";
      case "Critical":
        return "cf-danger";
      case "Normal":
      default:
        return "";
    }
  }

  function handleSystemMessageStateChange(fieldName, value) {
    setNewSystemMessage({
      ...newSystemMessage,
      [fieldName]: value,
    });
  }

  function onSmFromDateSelect(effectiveFrom) {
    setNewSystemMessage({
      ...newSystemMessage,
      effectiveFrom: effectiveFrom,
    });
  }

  function onSmThroughDateSelect(effectiveThrough) {
    setNewSystemMessage({
      ...newSystemMessage,
      effectiveThrough: effectiveThrough,
    });
  }

  function getSelectedSeverityType(type) {
    const selected_option = _.find(
      constants.severityTypes,
      (x) => x.label === type.label
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSelectedSeverityType(option) {
    setNewSystemMessage({
      ...newSystemMessage,
      severity: {
        value: option.value,
        label: option.label,
      },
    });
  }

  function onSmTypeChange(e) {
    setNewSystemMessage({
      ...newSystemMessage,
      isText: !e,
    });
  }

  function onSmFileCapture(file) {
    setNewSystemMessage({
      ...newSystemMessage,
      file: file,
    });
  }

  function onUpdateSystemDefaultContent(file, title) {
    let payload = new FormData();
    payload.append("Id", 0);
    payload.append("CompanyId", 0);
    payload.append("File", file);
    payload.append("IsDefault", true);
    payload.append("CultureId", editingTemplate.cultureId);
    payload.append("RegionId", editingTemplate.regionId);
    payload.append("TypeOfCompanyContent", editingTemplate.contentTypeId);
    setUploading(true);
    api.post_form_data("company/SetSystemDefaultTemplate", payload).then((response) => {
      if (response.data.success) {
        Alert.success(editingTemplate.modalTitle + ' replaced');
        setEditingTemplate(null);
        getCompanyTemplates(routeCompanyId);
      } else {
        Alert.error('Failed to update system default template: ' + response.data.message);
      }
    })
    .catch((error) =>
      Alert.error("The system message image did not save successfully")
    ).finally(() => setUploading(false));
  }

  function onUpdateSystemMessageImage(file) {
    let payload = new FormData();
    payload.append("Id", messageToUploadImageTo);
    payload.append("File", file);
    api
      .post_form_data("company/SaveSystemMessageImage", payload)
      .then((response) => {
        setMessageToUploadImageTo(null);
        getCompanySystemMessages(routeCompanyId);
      })
      .catch((error) =>
        Alert.error("The system message image did not save successfully")
      );
  }

  function editSystemMessage(messageId) {
    const tmp = systemMessages.slice();
    let message = _.find(tmp, (x) => x.id === messageId);
    message.isEditing = true;
    setSystemMessages(tmp);
  }

  function cancelMessageEdit(messageId) {
    const tmp = systemMessages.slice();
    let message = _.find(tmp, (x) => x.id === messageId);
    message.isEditing = false;
    setSystemMessages(tmp);
  }

  function updateMessageFromDate(fr, messageId) {
    const tmp = systemMessages.slice();
    let message = _.find(tmp, (x) => x.id === messageId);
    message.effectiveFrom = fr;
    message.effectiveThrough = moment(message.effectiveThrough);
    setSystemMessages(tmp);
  }

  function updateMessageThroughDate(through, messageId) {
    const tmp = systemMessages.slice();
    let message = _.find(tmp, (x) => x.id === messageId);
    message.effectiveFrom = moment(message.effectiveFrom);
    message.effectiveThrough = through;
    setSystemMessages(tmp);
  }

  function updateMessageText(value, messageId) {
    const tmp = systemMessages.slice();
    let message = _.find(tmp, (x) => x.id === messageId);
    message.message = value;
    setSystemMessages(tmp);
  }

  function onDownloadSystemMessageImage(messageId) {
    api
      .downloadBinary(`public/GetMessageImage/${messageId}`, "image/png")
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "image/png;charset=utf-8;",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, "message.png");
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "message.png");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Alert.error("There was an error downloading the message image");
      });
  }

  function getSeverityType(type) {
    const selected_option = _.find(
      constants.severityTypes,
      (x) => x.value === type
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  function setSeverityType(option, messageId) {
    const systemMessages = systemMessages.slice();
    const message = _.find(systemMessages, (x) => x.id === messageId);
    message.typeOfSeverity = option.value;
    setSystemMessages(systemMessages);
  }

  function saveSystemMessage() {
    const message = newSystemMessage;
    let payload = new FormData();
    payload.append("companyId", company.id);
    payload.append(
      "EffectiveFrom",
      date_helpers.formatDateForServer(message.effectiveFrom)
    );
    payload.append(
      "EffectiveThrough",
      date_helpers.formatDateForServer(message.effectiveThrough)
    );
    payload.append("TypeOfSeverity", message.severity.value);
    if (message.isText) {
      payload.append("message", message.message);
    } else {
      payload.append("file", message.file);
    }
    api
      .post_form_data("company/SaveSystemMessage", payload)
      .then((response) => {
        setNewSystemMessage({
          effectiveFrom: null,
          effectiveTo: null,
          severity: {
            value: "",
            label: "",
          },
          message: "",
        });
        setAddNewSystemMessage(false);
        getCompanySystemMessages(routeCompanyId);
      })
      .catch((error) => Alert.error("The system message did not save"));
  }

  function updateSystemMessage(message) {
    let payload = new FormData();
    payload.append("Id", message.id);
    payload.append("companyId", company.id);
    payload.append(
      "EffectiveFrom",
      date_helpers.formatDateForServer(message.effectiveFrom)
    );
    payload.append(
      "EffectiveThrough",
      date_helpers.formatDateForServer(message.effectiveThrough)
    );
    payload.append("TypeOfSeverity", message.typeOfSeverity);
    payload.append("message", message.message);
    api
      .post_form_data("company/SaveSystemMessage", payload)
      .then((response) => {
        cancelMessageEdit(message.id);
      })
      .catch((error) => Alert.error("The system message did not save"));
  }

  function activateSystemMessage(message) {
    const messages = systemMessages.slice();
    const foundMessage = _.find(messages, (x) => x.id === message.id);
    api
      .post("company/ActivateSystemMessage", { Id: message.id })
      .then((response) => {
        const activated = response.data.data;
        foundMessage.deactivatedAt = activated.deactivatedAt;
        foundMessage.deactivatedByUserId = activated.deactivatedByUserId;
        setSystemMessages(messages);
      })
      .catch((error) =>
        Alert.error("There was an error activating the system message")
      );
  }

  function deactivateSystemMessage(message) {
    const messages = systemMessages.slice();
    const foundMessage = _.find(messages, (x) => x.id === message.id);
    api
      .post("company/DeactivateSystemMessage", { Id: message.id })
      .then((response) => {
        const activated = response.data.data;
        foundMessage.deactivatedAt = activated.deactivatedAt;
        foundMessage.deactivatedByUserId = activated.deactivatedByUserId;
        setSystemMessages(messages);
      })
      .catch((error) =>
        Alert.error("There was an error deactivating the system message")
      );
  }

  //Reports Toggle Screen
  // changeReportStatus(value, groupName, reportId) {
  //     const reportGroups = this.state.reportGroups.slice();
  //     const groupWithReport = _.find(reportGroups, x => x.groupName === groupName);
  //     const reportToUpdate = _.find(groupWithReport.reports, x => x.id === reportId);
  //     if (value === true) {
  //         api
  //             .post("company/DeactivateCompanyReport", reportToUpdate)
  //             .then(response => {
  //                 const updatedReport = response.data.data;
  //                 reportToUpdate.deactivatedAt = updatedReport.deactivatedAt;
  //                 reportToUpdate.deactivatedByUserId = updatedReport.deactivatedByUserId;
  //                 reportToUpdate.isActive = false;
  //                 this.setState({
  //                     reportGroups: reportGroups
  //                 });
  //             })
  //             .catch(error => error);
  //     } else if (!value) {
  //         api
  //             .post("company/ActivateCompanyReport", reportToUpdate)
  //             .then(response => {
  //                 const updatedReport = response.data.data;
  //                 reportToUpdate.id = updatedReport.id;
  //                 reportToUpdate.deactivatedAt = updatedReport.deactivatedAt;
  //                 reportToUpdate.deactivatedByUserId = updatedReport.deactivatedByUserId;
  //                 reportToUpdate.isActive = true;
  //                 this.setState({
  //                     reportGroups: reportGroups
  //                 });
  //             })
  //             .catch(error => error);
  //     }
  // }

  //Payments Screen
  function toggleEditPayment() {
    setIsEditingPayment(!isEditingPayment);
  }

  function getPaymentProcessor(processor) {
    const selected_option = _.find(
      constants.paymentProcessorTypes,
      (x) => x.label === processor.label
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  }

  const getACHPaymentProcessor = (processor) => {
    const selected_option = _.find(
      constants.ACH_PROCESSORS,
      (x) => x.label === processor.label
    );
    if (selected_option) {
      return [selected_option];
    }
    return null;
  };

  function setPaymentProcessor(option) {
    setPayment({
      ...payment,
      paymentProcessor: option,
    });
  }

  const setACHPaymentProcessor = (option) => {
    setACHDetails({
      ...achDetails,
      typeOfACHProvider: {
        value: option.value,
        label: option.label,
      },
    });
  };

  // function setRtoCompany(option) {
  //     setPayment({
  //         ...payment,
  //         rtoProcessingCompany: option
  //     });
  // }

  function updatePaymentState(fieldName, value) {
    setPayment({
      ...payment,
      [fieldName]: value,
    });
  }

  function savePaymentDetails() {
    if (!company) return;
    const pct = parseFloat(payment.creditCardFeePercentage);
    const CompanyDto = {
      Id: company.id,
      TypeOfPaymentProcessor: payment.paymentProcessor.value,
      PaymentGatewayApiLoginId: payment.apiKey,
      PaymentGatewayTransactionKey: payment.transactionKey,
      // RTOCompanyId: payment.rtoProcessingCompany.value,
      creditCardFeePercentage: pct >= 1.0 ? pct / 100.0 : pct,
    };
    api
      .post("company/UpdatePaymentProcessingDetails", CompanyDto)
      .then((r) => {
        if (r.data && r.data.success) {
          setIsEditingPayment(false);
          getCompanyDetails(company.id);
          Alert.success(
            "Payment processing information was successfully saved."
          );
        }
      })
      .catch((error) =>
        Alert.error("The payment processing details did not save")
      );
  }

  //Pricing Rules Screen
  function toggleEditPricingRules() {
    setIsEditingPricingRules(!isEditingPricingRules);
  }

  function updatePricingRulesState(fieldName, value) {
    setPricingRules({
      ...pricingRules,
      [fieldName]: value,
    });
  }

  function handlePricingRulesChange(value) {
    setPricingRules({
      ...pricingRules,
      checkedRule: value,
    });
  }

  // function savePricingRules() {
  //   const CompanyDto = {
  //     Id: company.id,
  //     MinContractValue: pricingRules.minContractValue,
  //     MaxContractValue: pricingRules.maxContractValue,
  //     EnablePriceOverrideWithAdditionalDownPaymentPct:
  //       pricingRules.checkedRule === "doNotAllow" ? null : 100,
  //     MaxInitialPaymentPercent: pricingRules.maxInitialPaymentPercent
  //       ? pricingRules.maxInitialPaymentPercent / 100
  //       : 0,
  //   };
  //   api
  //     .post("company/UpdateCompanyPricingRules", CompanyDto)
  //     .then((r) => {
  //       setIsEditingPricingRules(false);
  //       setPricingRules({
  //         minContractValue: r.data.data.minContractValue,
  //         maxContractValue: r.data.data.maxContractValue,
  //         checkedRule:
  //           r.data.data.enablePriceOverrideWithAdditionalDownPaymentPct === null
  //             ? "doNotAllow"
  //             : "overage",
  //         doNotAllow: r.data.data
  //           .enablePriceOverrideWithAdditionalDownPaymentPct
  //           ? false
  //           : true,
  //         overage: r.data.data.enablePriceOverrideWithAdditionalDownPaymentPct
  //           ? true
  //           : false,
  //         maxInitialPaymentPercent: r.data.data.maxInitialPaymentPercent * 100,
  //       });
  //     })
  //     .catch((error) =>
  //       Alert.error("There was an error saving the pricing rules")
  //     );
  // }

  function openPDF(bytes) {
    var blob = new Blob([bytes], {
      type: "application/pdf;charset=utf-8;"
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, "file.pdf");
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "file.pdf");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  function onDownloadDefaultTemplate(cultureId, regionId) {
    api.downloadBinary(`company/downloadDefaultTemplate/${regionId}/${cultureId}`, constants.MIME_PDF).then((response) => {
      openPDF(response.data);
    }).catch((error) => {
      console.error(error);
      Alert.error("There was an error downloading the template");
    });
  }

  function onDownloadContent(contentId) {
    api.downloadBinary(`company/downloadtemplate/${contentId}`, constants.MIME_PDF).then((response) => {
      openPDF(response.data);
    }).catch((error) => {
      console.error(error);
      Alert.error("There was an error downloading the template");
    });
  }

  const saveACHDetails = () => {
    const payload = {
      companyId: parseInt(id, 10),
      merchantId: parseInt(achDetails.achPaymentProcessorMerchantId, 10) || 0,
      typeOfACHProvider: achDetails.typeOfACHProvider.value,
      username: achDetails.achProcessorUsername,
      password: achDetails.achProcessorPassword || "",
    };
    api.post("company/SaveACHDetails", payload).then((r) => {
      if (!r.data.success) {
        Alert.error("There was an issue saving the ACH Payment Details.");
        return;
      }
      Alert.success("Successfully saved your ACH Details.");
      cancelACH();
    });
  };

  const onACHDetailsChange = (property, value) => {
    let editACH = Object.assign({}, achDetails);
    editACH[property] = value;
    setACHDetails(editACH);
  };

  const cancelACH = () => {
    setIsEditingACH(false);
  };

  const testACHButtonDisabled = () => {
    return (
      achDetails.achProcessorUsername &&
      achDetails.achProcessorPassword &&
      achDetails.achPaymentProcessorMerchantId
    );
  };

  function loadCompanyDealerPriorities(dealerId) {
    api.post("company/GetCompanyDealerPriorities", {dealerId: dealerId, companyId: routeCompanyId}).then((r) => {
      setCompanyDealerPriorities(r.data);
    });
  }

  function onToggleCompanyDealerRegion(dealerId, regionId) {
    api.post("company/ToggleCompanyDealerRegion", {dealerId: dealerId, companyId: routeCompanyId, regionId: regionId}).then((r) => {
      setCompanyDealerPriorities(r.data);
    });
  }

  function onToggleCompanyRegion(regionId, on) {
    const url = on
      ? "company/ActivateCompanyRegion"
      : "company/DeactivateCompanyRegion";
    api.post(url, {companyId: routeCompanyId, regionId: regionId}).then((r) => {
      const {companyRegionList, selectedStateList} = shapeCompanyRegions(r.data.data, states);
      setCompanyRegions(companyRegionList);
      setSelectedStates(selectedStateList);
    });
  }

  const isCaptiveCompany = company.manufacturerId || company.captiveToStoreId ? true : false;
  return (
    <>
      <Header companyAdmin companyName={company ? company.name : null}/>
      <div className="inner">
      <div className="inner-white">
        <div className={"d-flex justify-content-center me-0"}>
          <Button
            onClick={() => toggle(tabNames.Details)}
            active={activeTab === tabNames.Details}
            disabled={isLoading}
          >
            Details
          </Button>
          {!isCaptiveCompany || (isCaptiveCompany && ui_helpers.isSystemAdministrator(userCtx.currentUser)) ? (
            <SubNavigationDropdown buttonText={"Relationships"}>
              <DropdownItem
                onClick={() => toggle(tabNames.Users)}
                active={activeTab === tabNames.Users}
                disabled={isLoading}
              >
                Users
              </DropdownItem>
              {company.captiveToStoreId ? null : (
                <DropdownItem
                  onClick={() => toggle(tabNames.Stores)}
                  active={activeTab === tabNames.Stores}
                  disabled={isLoading}
                >
                  Stores
                </DropdownItem>
              )}
              {company.manufacturerId ? null : (
                <DropdownItem
                  onClick={() => toggle(tabNames.Manufacturers)}
                  active={activeTab === tabNames.Manufacturers}
                  disabled={isLoading}
                >
                  Manufacturers
                </DropdownItem>
              )}
            </SubNavigationDropdown>
          ) : null}
          <Button
            className="sub-navigation-dropdown"
            onClick={() => toggle(tabNames.States)}
            active={activeTab === tabNames.States}
            disabled={isLoading}
          >
            States
          </Button>
          <Button
            className="sub-navigation-dropdown"
            onClick={() => toggle(tabNames.Payments)}
            active={activeTab === tabNames.Payments}
            disabled={isLoading}
          >
            Payments
          </Button>
          <SubNavigationDropdown buttonText={"Settings"}>
            <DropdownItem
              onClick={() => toggle(tabNames.Templates)}
              active={activeTab === tabNames.Templates}
              disabled={isLoading}
            >
              Templates
            </DropdownItem>
            {isCaptiveCompany ? null : (
              <DropdownItem
                onClick={() => toggle(tabNames.Promotions)}
                active={activeTab === tabNames.Promotions}
                disabled={isLoading}
              >
                Promotions
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => toggle(tabNames.SystemMessage)}
              active={activeTab === tabNames.SystemMessage}
              disabled={isLoading}
            >
              System Message
            </DropdownItem>
            <DropdownItem
              onClick={() => toggle(tabNames.PricingRules)}
              active={activeTab === tabNames.PricingRules}
              disabled={isLoading}
            >
              Pricing Rules
            </DropdownItem>
            {isCaptiveCompany ? null : (
              <DropdownItem
                onClick={() => toggle(tabNames.LDW)}
                active={activeTab === tabNames.LDW}
                disabled={isLoading}
              >
                LDW
              </DropdownItem>
            )}
          </SubNavigationDropdown>
        </div>
      </div>
      <div className="inner-white" style={{marginTop: "10px"}}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TabContent activeTab={activeTab} className="mt-2">
              <TabPane tabId={tabNames.Details}>
                <Row className="mb-0">
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col>
                            <h2 className="page-title">
                              {company ? company.name : "None"} Details
                            </h2>
                          </Col>
                          <Col>
                            {isEditingCompany ? (
                              <ButtonGroup className="float-end">
                                <Button
                                  size="sm"
                                  className="btn-success cf-success"
                                  onClick={() => saveCompanyDetails()}
                                >
                                  <FontAwesomeIcon icon="save" /> Save
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-secondary"
                                  onClick={() => cancelEditCompany()}
                                >
                                  <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                size="sm"
                                className="float-end btn-dark"
                                onClick={() => editCompanyDetails()}
                              >
                                <FontAwesomeIcon icon="edit" />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {isEditingCompany ? (
                          <div>
                            <Row>
                              <Col>
                                <Label>Company Name</Label>
                                <Input
                                  type="text"
                                  name="name"
                                  // onBlur={(e) => onBlur(e.target.value, 'name')}
                                  value={company.name}
                                  onChange={(event) =>
                                    updateCompanyState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Address 1</Label>
                                <Input
                                  type="text"
                                  name="remitTo1"
                                  value={
                                    company.remitTo1 ? company.remitTo1 : ""
                                  }
                                  onChange={(event) =>
                                    updateCompanyState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Address 2</Label>
                                <Input
                                  type="text"
                                  name="remitTo2"
                                  value={
                                    company.remitTo2 ? company.remitTo2 : ""
                                  }
                                  onChange={(event) =>
                                    updateCompanyState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>City State, Zip</Label>
                                <Input
                                  type="text"
                                  name="remitTo3"
                                  value={
                                    company.remitTo3 ? company.remitTo3 : ""
                                  }
                                  onChange={(event) =>
                                    updateCompanyState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Phone Number, Fax Number, Email</Label>
                                <Input
                                  type="text"
                                  name="remitTo4"
                                  value={
                                    company.remitTo4 ? company.remitTo4 : ""
                                  }
                                  onChange={(event) =>
                                    updateCompanyState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>
                                  Bypass Contract In-Review Status?
                                  <ToggleButton
                                    inactiveLabel={"No"}
                                    activeLabel={"Yes"}
                                    value={company.enableCashSaleByPassReview}
                                    checked={company.enableCashSaleByPassReview}
                                    onToggle={(value) => {
                                      updateCompanyState(
                                        "enableCashSaleByPassReview",
                                        !value
                                      );
                                    }}
                                    colors={{
                                      inctive: {
                                        base: "rgb(207,221,245)",
                                        hover: "rgb(177, 191, 215)",
                                      },
                                      active: {
                                        base: "rgb(1,124,66)",
                                        hover: "rgb(24,94,32)",
                                      },
                                    }}
                                  />
                                </Label>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <Row>
                            <Col>
                              <DataRow
                                header="Company Name"
                                data={company.name}
                              />
                              <DataRow
                                header="Address 1"
                                data={company.remitTo1}
                              />
                              <DataRow
                                header="Address 2"
                                data={company.remitTo2}
                              />
                              <DataRow
                                header="City State, Zip"
                                data={company.remitTo3}
                              />
                              <DataRow
                                header="Phone, Fax, Email"
                                data={company.remitTo4}
                              />
                              <DataRow
                                header="Manufacturer Owner"
                                data={
                                  company.manufacturerId
                                    ? ui_helpers.greenCheck()
                                    : "No"
                                }
                              />
                              <DataRow
                                header="Store Owner"
                                data={
                                  company.captiveToStoreId
                                    ? ui_helpers.greenCheck()
                                    : "No"
                                }
                              />
                              <DataRow
                                header="Bypass Cash Sale In-Review Status?"
                                data={
                                  company.enableCashSaleByPassReview
                                    ? ui_helpers.greenCheck()
                                    : "No"
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.Users}>
                <Row>
                  <Col sm="12">
                    <Users
                      companyId={id}
                      currentUser={userCtx.currentUser}
                      returnRoute={`${constants.PATH_NAMES.COMPANY_ADMIN}/${routeCompanyId}/${tabNames.Users}`}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.Stores}>
                <Card>
                  <CardHeader>
                    <h2 className="page-title">{company.name} Stores</h2>
                  </CardHeader>
                  <CardBody>
                  {(isSysAdmin || isCompanyAdmin) && (
                    <Row>
                      <Col xs="4">
                        <Label>Store</Label>
                        <Select
                          options={_.reject(stores, st => _.some(companyStores, cs => cs.dealer.id === st.id))}
                          value={getSelectedStore(storeToSave)}
                          onChange={(option) => setSelectedStore(option)}
                        />
                      </Col>
                      <Col xs="1" className="pt-1">
                        <Button
                          size="sm"
                          color="success"
                          className="text-light mt-4"
                          onClick={saveStoreToCompany}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </Col>
                      <Col xs="4" className="pt-1">
                        <div className="mt-4">
                          <input
                            type="checkbox"
                            style={{transform: "scale(1.4)"}}
                            checked={activeCompanyStoresOnly}
                            onClick={() => setActiveCompanyStoresOnly(!activeCompanyStoresOnly)}
                            className="m-2"
                          />
                          Active Only
                        </div>
                      </Col>
                    </Row>
                  )}
                  {loadingCompanyStores
                    ? (<Loading />)
                    : (<Row className="mt-3">
                    <Col xs="8">
                      <Table size="sm">
                        <thead>
                          <tr>
                            <th>Store Name</th>
                            <th>Cash Sales</th>
                            <th>RTO</th>
                            <th>RTO Regions</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(_.filter(companyStores, cs => !activeCompanyStoresOnly || cs.deactivatedAt === null), (s) => (
                            <tr key={s.id} className={editingStoreRegions && editingStoreRegions.id === s.id ? 'bg-warning' : null}>
                              <td>{s.dealer.name}</td>
                              <td>
                                {s.deactivatedAt === null ? (
                                  <ToggleButton
                                    value={s.isCashSales}
                                    activeLabel={"Yes"}
                                    inactiveLabel={"No"}
                                    onToggle={() =>
                                      onToggleDealerCashSales(s)
                                    }
                                  />
                                ) : null}
                              </td>
                              <td>
                                {s.deactivatedAt === null ? (
                                  <ToggleButton
                                    value={s.isRTO}
                                    activeLabel={"Yes"}
                                    inactiveLabel={"No"}
                                    onToggle={() => onToggleDealerRTO(s)}
                                  />
                                ) : null}
                              </td>
                              <td>
                                {s.isRTO
                                  ? (<span>{s.regionCSV}
                                      <Button
                                        title="Change region associations"
                                        color="dark"
                                        className="float-end text-light xs"
                                        onClick={() => {
                                          setEditingStoreRegions(s);
                                          loadCompanyDealerPriorities(s.dealer.id);
                                        }}
                                      >
                                        <FontAwesomeIcon icon='edit' />
                                      </Button>
                                    </span>)
                                  : null
                                }
                              </td>
                              <td>
                                {s.deactivatedAt === null ? (
                                  <Button
                                    size="sm"
                                    className="float-end btn-danger cf-danger text-light"
                                    onClick={() => toggleStore(s, false)}
                                  >
                                    <FontAwesomeIcon icon="trash-alt" />
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    className="float-end btn-success cf-success text-light"
                                    onClick={() => toggleStore(s, true)}
                                  >
                                    Revive
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                    {editingStoreRegions
                      ? (<Col>
                          <Button
                            size="sm"
                            color="secondary"
                            className="float-end"
                            onClick={() => {
                              setEditingStoreRegions(null);
                              getCompanyStores(routeCompanyId);
                            }}
                          >
                            <FontAwesomeIcon icon="times-circle" />
                          </Button>
                          <h4 className="pt-2" style={{textDecoration: "underline"}}>{editingStoreRegions.dealer.name} Regions</h4>
                          {_.map(companyRegions, r => (
                            <Row className="mb-2" key={`rgRTO-${r.regionName}`}>
                              <Col xs={6}>
                                {r.regionName}
                              </Col>
                              <Col xs={3}>
                                <ToggleButton
                                  value={_.some(companyDealerPriorities, p => p.dealerId === editingStoreRegions.dealer.id && p.regionId === r.regionId)}
                                  activeLabel="Yes"
                                  inactiveLabel="No"
                                  onToggle={() => onToggleCompanyDealerRegion(editingStoreRegions.dealer.id, r.regionId)}
                                />
                              </Col>
                            </Row>))}
                        </Col>)
                      : null
                    }
                  </Row>)
                  }                  
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={tabNames.Manufacturers}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <h2 className="page-title">Company Manufacturers</h2>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          {isSysAdmin ? (
                            <Fragment>
                              <Col xs="4">
                                <Label>Manufacturer</Label>
                                <Select
                                  options={manufacturers}
                                  value={getSelectedManufacturer(manufacturerToSave)}
                                  onChange={(option) => setSelectedManufacturer(option)}
                                />
                              </Col>
                              <Col xs="1">
                                <br />
                                <Button
                                  size="sm"
                                  className="float-right btn-success cf-success text-white mt-1"
                                  onClick={saveManufacturerToCompany}
                                >
                                  <FontAwesomeIcon icon="plus" />
                                </Button>
                              </Col>
                            </Fragment>
                          ) : null}
                          <Col xs="4">
                            <Table size="sm">
                              <thead>
                                <tr>
                                  <th>Manufacturer</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {companyManufacturers.map((cm) => (
                                  <tr key={cm.id}>
                                    <td>{cm.manufacturerName}</td>
                                    <td>
                                      {cm.deactivatedAt === null ? (
                                        <Button
                                          size="sm"
                                          className="float-end btn-danger text-light"
                                          onClick={() =>
                                            deactivateManufacturer(cm)
                                          }
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />
                                        </Button>
                                      ) : (
                                        <Button
                                          size="sm"
                                          className="float-end btn-success cf-success text-light"
                                          onClick={() =>
                                            activateManufacturer(cm)
                                          }
                                        >
                                          Revive
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.States}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <h4 className="page-title">
                          {company ? company.name : 'Company'} States
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="6">
                          {company && (company.captiveToManufacturerId || company.captiveToStoreId)
                            ? (<RSAlert color='warning'>
                                {company.name} is captive to a {company.captiveToManufacturerId ? 'manufacturer' : 'store'} and cannot serve as an RTO company.
                              </RSAlert>)
                            : _.map(selectedStates, s => (
                            <Row key={`st-${s.id}${s.selected ? 1 : 0}`} style={{borderBottom: "solid 1px #000"}}>
                              <Col xs={6} style={{fontSize: "1.3rem"}}>
                                {s.label}
                              </Col>
                              <Col xs={3} className="pt-1">
                                <ToggleButton
                                  value={s.selected}
                                  activeLabel={"Yes"}
                                  inactiveLabel={"No"}
                                  onToggle={(value) => onToggleCompanyRegion(s.id, !value)}
                                />
                              </Col>
                            </Row>
                          ))}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.Payments}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col>
                            <h2 className="page-title">Payment</h2>
                          </Col>
                          {/*<Col>*/}
                          {/*    {isEditingPayment ? (*/}
                          {/*        <ButtonGroup className="float-end">*/}
                          {/*            <Button*/}
                          {/*                size="sm"*/}
                          {/*                className="btn-success cf-success"*/}
                          {/*                onClick={() => savePaymentDetails()}*/}
                          {/*            >*/}
                          {/*                <FontAwesomeIcon icon="save" />{" "}*/}
                          {/*                Save*/}
                          {/*            </Button>*/}
                          {/*            <Button*/}
                          {/*                size="sm"*/}
                          {/*                className="btn-secondary"*/}
                          {/*                onClick={() => toggleEditPayment()}*/}
                          {/*            >*/}
                          {/*                <FontAwesomeIcon icon="times-circle" />{" "}*/}
                          {/*                Cancel*/}
                          {/*            </Button>*/}
                          {/*        </ButtonGroup>*/}
                          {/*    ) : (*/}
                          {/*        <Button*/}
                          {/*            size="sm"*/}
                          {/*            className="btn-dark float-end"*/}
                          {/*            onClick={() => toggleEditPayment()}*/}
                          {/*        >*/}
                          {/*            <FontAwesomeIcon icon="edit" />*/}
                          {/*        </Button>*/}
                          {/*    )}*/}
                          {/*</Col>*/}
                          <Col className={"d-flex justify-content-end"}>
                            {isEditingPayment ? (
                              <ButtonGroup className={"float-end"}>
                                <Button
                                  onClick={() => savePaymentDetails()}
                                  size={"sm"}
                                  className={"bg-success border-success"}
                                >
                                  <FontAwesomeIcon icon={"save"} />
                                </Button>
                                <Button
                                  onClick={() => toggleEditPayment()}
                                  size={"sm"}
                                >
                                  <FontAwesomeIcon icon={"times-circle"} />
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                onClick={() => toggleEditPayment()}
                                size={"sm"}
                              >
                                <FontAwesomeIcon icon={"edit"} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {isEditingPayment ? (
                          <div>
                            <Row>
                              <Col>
                                <Label>Payment Processor</Label>
                                <Select
                                  value={getPaymentProcessor(
                                    payment.paymentProcessor
                                  )}
                                  options={constants.paymentProcessorTypes}
                                  onChange={(option) =>
                                    setPaymentProcessor(option)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Payment API Key</Label>
                                <Input
                                  type="text"
                                  name="apiKey"
                                  value={payment.apiKey}
                                  onChange={(event) =>
                                    updatePaymentState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label>Transaction Key</Label>
                                <Input
                                  type="text"
                                  name="transactionKey"
                                  value={payment.transactionKey}
                                  onChange={(event) =>
                                    updatePaymentState(
                                      event.target.name,
                                      event.target.value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            {/*                                                  <Col>
                                                      <Label>Processing Company</Label>
                                                      <Select
                                                          value={payment.rtoProcessingCompany}
                                                          options={companies}
                                                          onChange={(option) => setRtoCompany(option)}
                                                      />
                                                  </Col>
*/}
                            <Row className={"mt-2"}>
                              <Col>
                                <Label>CC Fee %</Label>
                                <Input
                                  type={"text"}
                                  value={payment.creditCardFeePercentage}
                                  name={"creditCardFeePercentage"}
                                  onChange={(e) =>
                                    updatePaymentState(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                />
                                {/*<CurrencyInput suffix={'%'} name={'creditCardFeePercentage'}*/}
                                {/*               value={payment.creditCardFeePercentage} className={'form-control'}*/}
                                {/*               onChange={(e) =>*/}
                                {/*                   updatePaymentState(e.target.name, e.target.value)}/>*/}
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <Row>
                            <Col>
                              <DataRow
                                header="Payment Processor"
                                data={payment.paymentProcessor.label}
                              />
                              <DataRow
                                header="Payment API Key"
                                data={payment.apiKey}
                              />
                              <DataRow
                                header="Transaction Key"
                                data={payment.transactionKey}
                              />
                              {/*<DataRow header="Processing Company" data={payment.rtoProcessingCompany.label} />*/}
                              <DataRow
                                header="CC Fee %"
                                data={
                                  payment.creditCardFeePercentage
                                    ? payment.creditCardFeePercentage + "%"
                                    : 0 + "%"
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className={"mt-3"}>
                  <Col sm={"12"}>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col sm={"5"}>
                            <h2 className="page-title">ACH Processor</h2>
                          </Col>
                          <Col className={"d-flex justify-content-end"}>
                            {isEditingACH ? (
                              <ButtonGroup className={"float-end"}>
                                <Button
                                  onClick={() => saveACHDetails()}
                                  size={"sm"}
                                  className={"bg-success border-success"}
                                >
                                  <FontAwesomeIcon icon={"save"} />
                                </Button>
                                <Button onClick={() => cancelACH()} size={"sm"}>
                                  <FontAwesomeIcon icon={"times-circle"} />
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                onClick={() => setIsEditingACH(true)}
                                size={"sm"}
                              >
                                <FontAwesomeIcon icon={"edit"} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {isEditingACH ? (
                          <div>
                            <Form></Form>
                            <FormGroup>
                              <Col>
                                <Label>Processor</Label>
                                <Select
                                  options={constants.ACH_PROCESSORS}
                                  value={getACHPaymentProcessor(
                                    achDetails.typeOfACHProvider
                                  )}
                                  onChange={(option) =>
                                    setACHPaymentProcessor(option)
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup>
                              <Col>
                                <Label>Merchant Id</Label>
                                <Input
                                  type={"text"}
                                  name="achPaymentProcessorMerchantId"
                                  value={
                                    achDetails.achPaymentProcessorMerchantId
                                  }
                                  onChange={(e) =>
                                    onACHDetailsChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  invalid={
                                    validCredentials !== null &&
                                    validCredentials === false
                                  }
                                  valid={
                                    validCredentials !== null &&
                                    validCredentials === true
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup>
                              <Col>
                                <Label>Username</Label>
                                <Input
                                  type={"text"}
                                  name={"achProcessorUsername"}
                                  value={achDetails.achProcessorUsername}
                                  onChange={(e) =>
                                    onACHDetailsChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  invalid={
                                    validCredentials !== null &&
                                    validCredentials === false
                                  }
                                  valid={
                                    validCredentials !== null &&
                                    validCredentials === true
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup>
                              <Col>
                                <Label>Password</Label>
                                <Input
                                  type={"text"}
                                  name={"achProcessorPassword"}
                                  value={achDetails.achProcessorPassword}
                                  onChange={(e) =>
                                    onACHDetailsChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  invalid={
                                    validCredentials !== null &&
                                    validCredentials === false
                                  }
                                  valid={
                                    validCredentials !== null &&
                                    validCredentials === true
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <Row
                              className={"d-flex justify-content-start mt-1"}
                            >
                              <Col lg={2}>
                                {achDetails.achProcessorPassword ? (
                                  <Button
                                    size={"sm"}
                                    disabled={
                                      !achDetails &&
                                      !achDetails.achProcessorPassword
                                    }
                                    onClick={() => validateAch()}
                                  >
                                    Test Credentials
                                  </Button>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <>
                            <DataRow
                              header="ACH Processor"
                              data={achDetails.typeOfACHProvider.label}
                            />
                            <DataRow
                              header="ACH Merchant Id"
                              data={achDetails.achPaymentProcessorMerchantId}
                            />
                            <DataRow
                              header="ACH Merchant Username"
                              data={achDetails.achProcessorUsername}
                            />
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.Templates}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <h2 className="page-title">Templates</h2>
                      </CardHeader>
                      <CardBody>
                        {editingTemplate && editingTemplate.isSystemDefault
                          ? (<Modal size="lg" isOpen centered>
                            <ModalHeader>Set {editingTemplate.modalTitle} System-Wide Default Template</ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col className="pt-3">
                                {uploading
                                  ? (<Loading />)
                                  : (<Dropzone onDrop={(fileList) => onUpdateSystemDefaultContent(fileList[0])} accept={[constants.MIME_PDF]} style={{width: "100%"}}>
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()} className="text-center">
                                        <input {...getInputProps()} />
                                        <div style={{ textAlign: "center" }} className="pt-3">
                                          <div className="mb-1">Drop file here or click the icon</div>
                                          <FontAwesomeIcon icon="file-upload" size="3x" className="mb-4" />
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>)
                                }
                                </Col>
                              </Row>
                            </ModalBody>
                            {uploading
                              ? null
                              : (<ModalFooter>
                                  <Row>
                                    <Col>
                                      <Button color="secondary float-end" onClick={() => setEditingTemplate(null)}>
                                        Cancel
                                      </Button>
                                    </Col>
                                  </Row>
                                </ModalFooter>)
                            }
                          </Modal>)
                          : null
                        }
                        {editingTemplate && !editingTemplate.isSystemDefault ? (
                          <Modal isOpen centered>
                            <ModalHeader>Upload Template</ModalHeader>
                            <ModalBody>
                              <CFDropzone
                                route="SaveTemplate"
                                acceptTypes={[constants.MIME_PDF]}
                                companyId={company.id}
                                editingTemplateId={editingTemplate.id}
                                contentTypeId={editingTemplate.contentTypeId}
                                cultureId={editingTemplate.cultureId}
                                cultureList={cultures}
                                isDefault={editingTemplate.isDefault}
                                regionId={editingTemplate.regionId}
                                regionList={states}
                                message1="All templates must be in PDF format and should contain appropriately named fields for data injection."
                                message2=""
                                hideCancel
                                close={() => setEditingTemplate(null)}
                                closeAndReload={() => {
                                  setEditingTemplate(null);
                                  getCompanyTemplates(routeCompanyId);
                                }}
                              />
                            </ModalBody>
                            <ModalFooter>
                              <Row>
                                <Col className="d-flex flex-row justify-content-end">
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      color="secondary"
                                      onClick={() => setEditingTemplate(null)}
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                </Col>
                              </Row>
                            </ModalFooter>
                          </Modal>
                        ) : null}
                        <Row>
                          <Col>
                            <CardBody>
                              <Row>
                                <Col>
                                  <h5>Company Logo</h5>
                                  {isEditingLogo ? (
                                    <Card>
                                      <CardBody>
                                        <CFDropzone
                                          acceptTypes={["image/png"]}
                                          route="SaveLogo"
                                          companyId={company.id}
                                          message1="Logos must be PNG format."
                                          message2="Preferred width is 167px wide x 75px high or at least this ratio of Width to Height."
                                          close={() => setIsEditingLogo(false)}
                                          closeAndReload={loadLogo}
                                        />
                                      </CardBody>
                                    </Card>
                                  ) : (
                                    <React.Fragment>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className="float-end"
                                        onClick={editLogo}
                                      >
                                        <FontAwesomeIcon icon="file-upload" />{" "}
                                        Set
                                      </Button>
                                      <img
                                        alt="Logo"
                                        style={{ width: "167px" }}
                                        src={`api/public/GetCompanyLogo/${company.id}`}
                                      />
                                    </React.Fragment>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <CardBody>
                              <Row>
                                <Col>
                                  <h5>Header Image</h5>
                                  {isEditingHeader ? (
                                    <Card>
                                      <CardBody>
                                        <CFDropzone
                                          route="SaveHeader"
                                          acceptTypes={["image/png"]}
                                          companyId={company.id}
                                          message1="Template headers must be PNG format."
                                          message2="Preferred width is 1388px wide x 258px high or at least this ratio of Width to Height and smaller."
                                          close={() =>
                                            setIsEditingHeader(false)
                                          }
                                          closeAndReload={loadHeader}
                                        />
                                      </CardBody>
                                    </Card>
                                  ) : (
                                    <React.Fragment>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className="float-end"
                                        onClick={editHeader}
                                      >
                                        <FontAwesomeIcon icon="file-upload" />{" "}
                                        Set
                                      </Button>
                                      <img
                                        alt="Header"
                                        style={{ width: "600px" }}
                                        src={`api/public/GetCompanyHeader/${company.id}`}
                                      />
                                    </React.Fragment>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <CardBody>
                              <Row>
                                <Col>
                                  <h5>Company Templates</h5>
                                </Col>
                              </Row>
                              <Row className="rowSpacing">
                                <Col>
                                  <Table size="sm">
                                    <thead>
                                      <tr>
                                        <th>Template</th>
                                        <th>State</th>
                                        <th>Language</th>
                                        <th>Content</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {_.map(_.filter(groupedTemplates, c => c.typeOfCompanyContent === constants.COMPANY_CONTENT_TYPES.CONTRACT), c => (
                                        <tr
                                          className={c.bgClass}
                                          key={`${c.companyId}-${c.typeOfCompanyContent}-${c.regionId}-${c.cultureId}`}
                                        >
                                          <td>{c.contentTypeName}</td>
                                          <td>{c.regionName}</td>
                                          <td>{c.cultureName}</td>
                                          <td>
                                            {c.fileExists ? (
                                              <Button color="primary" onClick={() => onDownloadContent(c.id)} size="sm">
                                                <FontAwesomeIcon icon="download" /> (Company Specific)
                                              </Button>
                                            ) : (
                                              <Button color="dark" onClick={() => onDownloadDefaultTemplate(c.cultureId, c.regionId)} size="sm">
                                                <FontAwesomeIcon icon="download" /> (Default, System-wide)
                                              </Button>
                                            )}{" "}
                                            {c.isDefault ? (
                                              <Badge className="bg-success">
                                                default
                                              </Badge>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            <ButtonGroup className="float-end">
                                            {c.fileExists ? (
                                              <Button color="primary" size="sm" onClick={() => showTemplateUploadModal(c, c.typeOfCompanyContent)}>
                                                <FontAwesomeIcon icon="file-upload" /> Set
                                              </Button>
                                            ) : (
                                              <Button color="dark" size="sm" onClick={() => setEditingTemplate({
                                                                                                          id: 0,
                                                                                                          isSystemDefault: true,
                                                                                                          contentTypeId: c.typeOfCompanyContent,
                                                                                                          modalTitle: `${c.regionName} ${c.cultureName} ${c.contentTypeName}`,
                                                                                                          isDefault: true,
                                                                                                          regionId: c.regionId,
                                                                                                          cultureId: c.cultureId
                                                                                                        })}>
                                                <FontAwesomeIcon icon="file-upload" /> Set System Default
                                              </Button>
                                            )}{" "}
                                              {c.fileExists
                                                ? (<Fragment>
                                                  {c.typeOfCompanyContent === constants.COMPANY_CONTENT_TYPES.CONTRACT ? (
                                                    <Button
                                                      size="sm"
                                                      title="Edit Signature Locations"
                                                      onClick={() => window.open("/edit-signature-locations/" + c.id, "_blank")}
                                                    >
                                                      <FontAwesomeIcon icon="edit" /> Signature Locations
                                                    </Button>
                                                  ) : null}
                                                  <Button
                                                    color="danger"
                                                    className="text-light"
                                                    size="sm"
                                                    onClick={() => deleteTemplate(c.id)}
                                                    title="Delete"
                                                  >
                                                    <FontAwesomeIcon icon="times-circle" />
                                                  </Button>
                                                </Fragment>
                                              ) : null}
                                              {!c.fileExists && c.typeOfCompanyContent === constants.COMPANY_CONTENT_TYPES.CONTRACT
                                                ? (<Button
                                                    size="sm"
                                                    target="_blank"
                                                    title="Edit Signature Locations"
                                                    onClick={() => window.open("/edit-default-signature-locations/" + c.regionId + "/" + c.cultureId, "_blank")}
                                                  >
                                                    <FontAwesomeIcon icon="edit" /> Signature Locations
                                                  </Button>)
                                                : null
                                              }
                                            </ButtonGroup>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.Promotions}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="9">
                            <h2 className="page-title">Company Promotions</h2>
                          </Col>
                          <Col>
                            {newPromotion ? (
                              <ButtonGroup className="float-end">
                                <Button
                                  size="sm"
                                  className="btn-success cf-success"
                                  onClick={() => savePromotion()}
                                >
                                  <FontAwesomeIcon icon="save" /> Save
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-secondary"
                                  onClick={() => setNewPromotion(null)}
                                >
                                  <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                size="sm"
                                color="success"
                                className="float-end text-light"
                                onClick={() =>
                                  setNewPromotion(
                                    Object.assign({}, defaultPromotion)
                                  )
                                }
                              >
                                <FontAwesomeIcon icon="plus" /> Add
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Collapse isOpen={newPromotion}>
                          <Card>
                            {newPromotion ? (
                              <CardBody>
                                <Row>
                                  <Col>
                                    <Label>Name</Label>
                                    <Input
                                      type="text"
                                      name="name"
                                      value={newPromotion.name}
                                      onChange={(event) =>
                                        handlePromotionStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label>Code</Label>
                                    <Input
                                      type="text"
                                      name="code"
                                      value={newPromotion.code}
                                      onChange={(event) =>
                                        handlePromotionStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <div>
                                      <Label>Effective From</Label>
                                    </div>
                                    <div className="datePickerDiv">
                                      <DatePicker
                                        selected={
                                          newPromotion.effectiveFrom
                                            ? moment(
                                                newPromotion.effectiveFrom
                                              ).toDate()
                                            : null
                                        }
                                        //   onChange={(date) => onFromDateSelect(moment(date).toDate())}
                                        onChange={(date) =>
                                          onFromDateSelect(date)
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        withPortal
                                        dropdownMode="select"
                                        placeholderText=""
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div>
                                      <Label>Effective Through</Label>
                                    </div>
                                    <div className="datePickerDiv">
                                      <DatePicker
                                        selected={
                                          newPromotion.effectiveThrough
                                            ? moment(newPromotion.effectiveThrough).toDate()
                                            : null
                                        }
                                        //   onChange={(date) => onThroughDateSelect(moment(date).toDate())}
                                        onChange={(date) =>
                                          onThroughDateSelect(date)
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        withPortal
                                        dropdownMode="select"
                                        placeholderText=""
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Row className="rowSpacing">
                                      <Col>
                                        <Label>Promotion Type</Label>
                                        <Select
                                          options={promotionTypes}
                                          value={getSelectedPromotionType(
                                            newPromotion.promotionType
                                          )}
                                          onChange={(option) =>
                                            setSelectedPromotionType(option)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="rowSpacing">
                                      <Col>
                                        <Label>Unit is USED</Label>
                                        <ToggleButton
                                          value={
                                            newPromotion.mustBeUsed || false
                                          }
                                          activeLabel={"Yes"}
                                          inactiveLabel={"No"}
                                          onToggle={(value) => {
                                            setNewPromotion({
                                              ...newPromotion,
                                              mustBeUsed: !value,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <CompanyTermPromotion
                                      data={newPromotion}
                                      terms={companyTerms}
                                      addCompanyTerm={(options) =>
                                        addCompanyTerm(options, null, true)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            ) : null}
                          </Card>
                        </Collapse>
                      </CardHeader>
                      <CardBody>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Code</th>
                              <th>Effective From</th>
                              <th>Effective Through</th>
                              <th />
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {promotions.map((p) => (
                              <tr
                                key={p.id}
                                className={getPromotionsRowStyling(
                                  p.effectiveThrough,
                                  p.deactivatedAt
                                )}
                              >
                                <td>
                                  {p.isEditing ? (
                                    <Input
                                      style={{ maxWidth: "150px" }}
                                      type="text"
                                      name="name"
                                      value={p.name}
                                      onChange={(event) =>
                                        updatePromoName(
                                          event.target.value,
                                          p.id
                                        )
                                      }
                                    />
                                  ) : (
                                    p.name
                                  )}
                                </td>
                                <td style={{ color: "blue" }}>
                                  {p.isEditing ? (
                                    <Input
                                      style={{ maxWidth: "100px" }}
                                      type="text"
                                      name="code"
                                      value={p.code}
                                      onChange={(event) =>
                                        updatePromoCode(
                                          event.target.value,
                                          p.id
                                        )
                                      }
                                    />
                                  ) : (
                                    <strong>{p.code}</strong>
                                  )}
                                </td>
                                <td>
                                  {p.isEditing ? (
                                    <div className="datePickerDiv">
                                      <DatePicker
                                        selected={
                                          p.effectiveFrom
                                            ? moment(p.effectiveFrom).toDate()
                                            : null
                                        }
                                        onChange={(date) =>
                                          updateFromDate(date, p.id)
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        withPortal
                                        dropdownMode="select"
                                      />
                                    </div>
                                  ) : (
                                    date_helpers.formatDateToShortDate(
                                      p.effectiveFrom
                                    )
                                  )}
                                </td>
                                <td>
                                  {p.isEditing ? (
                                    <div className="datePickerDiv">
                                      <DatePicker
                                        selected={
                                          p.effectiveThrough
                                            ? moment(p.effectiveThrough).toDate()
                                            : null
                                        }
                                        onChange={(date) =>
                                          updateThroughDate(date, p.id)
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        withPortal
                                        dropdownMode="select"
                                      />
                                    </div>
                                  ) : (
                                    date_helpers.formatDateToShortDate(
                                      p.effectiveThrough
                                    )
                                  )}
                                </td>
                                {p.isEditing && (
                                  <td style={{ minWidth: "250px" }}>
                                    <Select
                                      options={promotionTypes}
                                      value={getPromotionType(
                                        p.typeOfPromotion
                                      )}
                                      onChange={(option) =>
                                        setPromotionType(option, p.id)
                                      }
                                    />
                                    <hr />
                                    <div>
                                      <Row>
                                        <Col xs="2">
                                          <Label>Unit is USED</Label>
                                          <ToggleButton
                                            value={p.mustBeUsed || false}
                                            activeLabel={"Yes"}
                                            inactiveLabel={"No"}
                                            onToggle={(value) =>
                                              updateUsedToggle(value, p.id)
                                            }
                                          />
                                        </Col>
                                        <Col>
                                          <CompanyTermPromotion
                                            data={p}
                                            terms={companyTerms}
                                            addCompanyTerm={(options) =>
                                              addCompanyTerm(options, p.id)
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                )}
                                <td>
                                  {p.deactivatedAt === null ? (
                                    <ButtonGroup className="float-end">
                                      {p.isEditing ? (
                                        <Fragment>
                                          <Button
                                            size="sm"
                                            color="primary"
                                            onClick={() => updatePromotion(p)}
                                          >
                                            <FontAwesomeIcon icon="save" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            className="btn-secondary"
                                            onClick={() =>
                                              cancelPromoEdit(p.id)
                                            }
                                          >
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <Button
                                            size="sm"
                                            className="btn-dark"
                                            onClick={() => editPromotion(p.id)}
                                          >
                                            <FontAwesomeIcon icon="edit" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            className="btn-danger text-light cf-danger"
                                            onClick={() =>
                                              deactivatePromotion(p)
                                            }
                                          >
                                            <FontAwesomeIcon icon="trash-alt" />
                                          </Button>
                                        </Fragment>
                                      )}
                                    </ButtonGroup>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="float-end btn-success cf-success text-light"
                                      onClick={() => activatePromotion(p)}
                                    >
                                      Revive
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.SystemMessage}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="9">
                            <h2 className="page-title">System Messages</h2>
                          </Col>
                          <Col>
                            {addNewSystemMessage ? (
                              <ButtonGroup className="float-end">
                                <Button
                                  size="sm"
                                  color="success"
                                  className="text-light"
                                  onClick={saveSystemMessage}
                                >
                                  <FontAwesomeIcon icon="save" /> Save
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-secondary"
                                  onClick={toggleAddNewSystemMessage}
                                >
                                  <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                size="sm"
                                color="success"
                                className="float-end text-light"
                                onClick={toggleAddNewSystemMessage}
                              >
                                <FontAwesomeIcon icon="plus" /> Add
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Collapse isOpen={addNewSystemMessage}>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col xs="2">
                                  <div>
                                    <Label>Effective From</Label>
                                  </div>
                                  <div className="datePickerDiv">
                                    <DatePicker
                                      selected={
                                        newSystemMessage.effectiveFrom
                                          ? moment(
                                              newSystemMessage.effectiveFrom
                                            ).toDate()
                                          : null
                                      }
                                      onChange={(date) =>
                                        onSmFromDateSelect(moment(date))
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      withPortal
                                      dropdownMode="select"
                                    />
                                  </div>
                                </Col>
                                <Col xs="2">
                                  <div>
                                    <Label>Effective Through</Label>
                                  </div>
                                  <div className="datePickerDiv">
                                    <DatePicker
                                      selected={
                                        newSystemMessage.effectiveThrough
                                          ? moment(
                                              newSystemMessage.effectiveThrough
                                            ).toDate()
                                          : null
                                      }
                                      onChange={(date) =>
                                        onSmThroughDateSelect(moment(date))
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      withPortal
                                      dropdownMode="select"
                                    />
                                  </div>
                                </Col>
                                <Col xs="3">
                                  <Label>Severity</Label>
                                  <Select
                                    options={severityTypes}
                                    value={getSelectedSeverityType(
                                      newSystemMessage.severity
                                    )}
                                    onChange={(option) =>
                                      setSelectedSeverityType(option)
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Label>Message is text</Label>
                                  <ToggleButton
                                    value={newSystemMessage.isText}
                                    activeLabel="Yes"
                                    inactiveLabel="No"
                                    onToggle={(e) => onSmTypeChange(e)}
                                  />
                                </Col>
                              </Row>
                              {newSystemMessage.isText ? (
                                <Row className="rowSpacing">
                                  <Col>
                                    <Label>Message</Label>
                                    <DebounceInput
                                      element="textarea"
                                      maxLength="2000"
                                      minLength="2"
                                      value={newSystemMessage.message}
                                      onChange={(event) =>
                                        handleSystemMessageStateChange(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <div>
                                  <CFDropzone
                                    companyId={routeCompanyId}
                                    acceptTypes={[
                                      "image/png",
                                      "image/jpg",
                                      "image/jpeg",
                                    ]}
                                    message1="Image must be PNG or JPEG format."
                                    onFileCapture={(file) =>
                                      onSmFileCapture(file)
                                    }
                                  />
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </Collapse>
                      </CardHeader>
                      <CardBody>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>Effective</th>
                              <th>Severity</th>
                              <th>Created</th>
                              <th>Message</th>
                              <th style={{width: "18%"}}>
                                <div className="float-end">
                                  <ToggleButton
                                    value={showAllSystemMessages}
                                    activeLabel="Yes"
                                    inactiveLabel="No"
                                    onToggle={(value) => setShowAllSystemMessages(!showAllSystemMessages)}
                                  />
                                </div>
                                <span>
                                  Show Inactive Messages
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(_.reject(systemMessages, s => {
                              if (showAllSystemMessages || s.isEditing)
                                return false;
                              return s.deactivatedAt || !messageIsEffectiveNow(s.effectiveFrom, s.effectiveThrough);
                            }), (s) => (
                              <tr
                                key={`${s.id}-${s.imageURL}`}
                                className={getMessagesRowStyling(
                                  s.effectiveFrom,
                                  s.effectiveThrough,
                                  s.deactivatedAt
                                )}
                              >
                                <td>
                                  {s.isEditing ? (
                                    <div>
                                      <div className="datePickerDiv">
                                        <DatePicker
                                          selected={
                                            s.effectiveFrom
                                              ? moment(s.effectiveFrom).toDate()
                                              : null
                                          }
                                          onChange={(date) =>
                                            updateMessageFromDate(
                                              moment(date),
                                              s.id
                                            )
                                          }
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          withPortal
                                          dropdownMode="select"
                                        />
                                      </div>
                                      <div
                                        className="datePickerDiv"
                                        style={{ clear: "both" }}
                                      >
                                        <DatePicker
                                          selected={
                                            s.effectiveThrough
                                              ? moment(s.effectiveThrough).toDate()
                                              : null
                                          }
                                          onChange={(date) =>
                                            updateMessageThroughDate(
                                              moment(date),
                                              s.id
                                            )
                                          }
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          withPortal
                                          dropdownMode="select"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    `${date_helpers.formatDateToShortDate(
                                      s.effectiveFrom
                                    )} - 
                                                                            ${date_helpers.formatDateToShortDate(
                                                                              s.effectiveThrough
                                                                            )}`
                                  )}
                                </td>
                                <td
                                  style={{ color: "#000000", width: "150px" }}
                                >
                                  {s.isEditing ? (
                                    <Select
                                      options={severityTypes}
                                      value={getSeverityType(s.typeOfSeverity)}
                                      onChange={(option) =>
                                        setSeverityType(option, s.id)
                                      }
                                    />
                                  ) : (
                                    <Badge
                                      className={getSeverityBadgeClassName(
                                        _.find(
                                          constants.severityTypes,
                                          (x) => x.value === s.typeOfSeverity
                                        ).label
                                      )}
                                    >
                                      {
                                        _.find(
                                          constants.severityTypes,
                                          (x) => x.value === s.typeOfSeverity
                                        ).label
                                      }
                                    </Badge>
                                  )}
                                </td>
                                <td>
                                  {date_helpers.formatDateToShortDate(
                                    s.createdAt
                                  )}
                                </td>
                                <td style={{ width: "50%" }}>
                                  {s.isEditing ? (
                                    <DebounceInput
                                      element="textarea"
                                      maxLength="2000"
                                      minLength="2"
                                      rows="3"
                                      style={{ width: "300px" }}
                                      value={s.message}
                                      onChange={(event) =>
                                        updateMessageText(
                                          event.target.value,
                                          s.id
                                        )
                                      }
                                      debounceTimeout={300}
                                    />
                                  ) : s.imageURL ? (
                                    <Fragment>
                                      <Button
                                        onClick={() => onDownloadSystemMessageImage(s.id)}
                                        size="sm"
                                      >
                                        <FontAwesomeIcon icon="download" />
                                      </Button>
                                      {s.url
                                        ? (<img style={{ width: "200px", marginLeft: "20px" }} width="220" alt="Message Image" src={s.url} />)
                                        : (<Button
                                            onClick={() => {
                                              setSystemMessages(
                                                _.map(systemMessages, m => {
                                                  let newM = Object.assign({}, m);
                                                  if (m.id === s.id) {
                                                    newM.url = `api/public/GetMessageImage/${s.id}`;
                                                  }
                                                  return newM;
                                                }));
                                            }}
                                            size="sm"
                                            className="ms-2"
                                            color="dark"
                                          >
                                            <FontAwesomeIcon icon="eye" /> Display Image
                                          </Button>)
                                      }
                                      {s.message ? (
                                        <div>{s.message}</div>
                                      ) : null}
                                    </Fragment>
                                  ) : (
                                    s.message
                                  )}
                                </td>
                                <td>
                                  {s.deactivatedAt === null ? (
                                    <ButtonGroup className="float-end">
                                      <Button
                                        size="sm"
                                        className="float-end btn-primary cf-primary"
                                        onClick={() =>
                                          setMessageToUploadImageTo(s.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon="file-upload" />{" "}
                                        Set Image
                                      </Button>
                                      {s.isEditing ? (
                                        <Fragment>
                                          <Button
                                            size="sm"
                                            className="text-light"
                                            color="success"
                                            onClick={() =>
                                              updateSystemMessage(s)
                                            }
                                          >
                                            <FontAwesomeIcon icon="save" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            className="btn-secondary"
                                            onClick={() =>
                                              cancelMessageEdit(s.id)
                                            }
                                          >
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <Button
                                            size="sm"
                                            color="dark"
                                            onClick={() =>
                                              editSystemMessage(s.id)
                                            }
                                          >
                                            <FontAwesomeIcon icon="edit" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            color="danger"
                                            className="text-light"
                                            onClick={() =>
                                              deactivateSystemMessage(s)
                                            }
                                          >
                                            <FontAwesomeIcon icon="trash-alt" />
                                          </Button>
                                        </Fragment>
                                      )}
                                    </ButtonGroup>
                                  ) : (
                                    <Button
                                      size="sm"
                                      color="success"
                                      className="float-end text-light"
                                      onClick={() => activateSystemMessage(s)}
                                    >
                                      Revive
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {messageToUploadImageTo ? (
                  <Modal isOpen={true} centered>
                    <ModalHeader>Edit System Message Image</ModalHeader>
                    <ModalBody>
                      <CFDropzone
                        companyId={routeCompanyId}
                        acceptTypes={["image/png", "image/jpg", "image/jpeg"]}
                        message1="Image must be PNG or JPEG format."
                        onFileCapture={(file) =>
                          onUpdateSystemMessageImage(file)
                        }
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Row>
                        <Col className="d-flex flex-row justify-content-end">
                          <Col className="d-flex justify-content-end">
                            <Button
                              color="secondary"
                              onClick={() => setMessageToUploadImageTo(null)}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                    </ModalFooter>
                  </Modal>
                ) : null}
              </TabPane>
              <TabPane tabId={tabNames.PricingRules}>
                <Row>
                  <Col sm="12" className="mt-2">
                    <CompanyPricing
                      company={company}
                      isCaptiveCompany={isCaptiveCompany}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={tabNames.LDW}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="9">
                            <h2 className="page-title">
                              Liability Damage Waiver Threshold Prices
                            </h2>
                          </Col>
                          <Col>
                            {addNewLdw ? (
                              <ButtonGroup className="float-end">
                                <Button
                                  size="sm"
                                  color="success"
                                  className="text-light"
                                  onClick={() => saveLdw(false)}
                                >
                                  <FontAwesomeIcon icon="save" /> Save
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-secondary"
                                  onClick={() => toggleAddNewLdw()}
                                >
                                  <FontAwesomeIcon icon="times-circle" /> Cancel
                                </Button>
                              </ButtonGroup>
                            ) : (
                              <Button
                                size="sm"
                                color="success"
                                className="float-end text-light"
                                onClick={() => toggleAddNewLdw()}
                              >
                                <FontAwesomeIcon icon="plus" /> Add
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Collapse isOpen={addNewLdw}>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col>
                                  <Label>At Or Above Unit Price</Label>
                                  <Input
                                    type="text"
                                    name="minContractValue"
                                    value={newLdw.minContractValue}
                                    onChange={(event) =>
                                      handleLdwStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Label>Monthly LDW</Label>
                                  <Input
                                    type="text"
                                    name="monthlyPrice"
                                    value={newLdw.monthlyPrice}
                                    onChange={(event) =>
                                      handleLdwStateChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Collapse>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Table size="sm">
                              <thead>
                                <tr>
                                  <th>At Or Above Unit Price</th>
                                  <th>Monthly LDW</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {ldws.map((l) => (
                                  <tr key={l.id}>
                                    <td>
                                      {l.isEditing ? (
                                        <Input
                                          type="text"
                                          name="minContractValue"
                                          value={l.minContractValue}
                                          onChange={(event) =>
                                            updateLdwMinContractValue(
                                              l.id,
                                              event.target.value
                                            )
                                          }
                                        />
                                      ) : (
                                        ui_helpers.formatCurrency(
                                          l.minContractValue
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {l.isEditing ? (
                                        <Input
                                          type="text"
                                          name="monthlyPrice"
                                          value={l.monthlyPrice}
                                          onChange={(event) =>
                                            updateLdwMonthlyPrice(
                                              l.id,
                                              event.target.value
                                            )
                                          }
                                        />
                                      ) : (
                                        ui_helpers.formatCurrency(
                                          l.monthlyPrice
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {l.isEditing ? (
                                        <ButtonGroup className="float-end">
                                          <Button
                                            size="sm"
                                            className="btn-success cf-success"
                                            onClick={() => saveLdw(l)}
                                          >
                                            <FontAwesomeIcon icon="save" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            className="btn-secondary"
                                            onClick={() => cancelLdwEdit(l.id)}
                                          >
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </ButtonGroup>
                                      ) : (
                                        <ButtonGroup className="float-end">
                                          <Button
                                            size="sm"
                                            className="btn-dark"
                                            onClick={() =>
                                              toggleEditExistingLdw(l.id)
                                            }
                                          >
                                            <FontAwesomeIcon icon="edit" />
                                          </Button>
                                          <Button
                                            size="sm"
                                            className="btn-danger cf-danger text-light"
                                            onClick={() => deleteLdw(l.id)}
                                          >
                                            <FontAwesomeIcon icon="trash-alt" />
                                          </Button>
                                        </ButtonGroup>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </>
        )}
      </div>
      </div>
      <Footer/>
    </>
  );
}
