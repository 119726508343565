import React, { useState, useEffect } from "react";
import {Badge} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {ui_helpers} from "../helpers";
import {api, constants} from "../utils";
//import {icon} from "@fortawesome/fontawesome-svg-core";

export default function EssentialIcon({milestoneStatusId, daysSinceInception, icon, type, id}) {
  const [iconContent, setIconContent] = useState(null);
function getText() {
  if (milestoneStatusId === null)
    return "";
  if (milestoneStatusId === constants.MILESTONE_STATUS_IDS.NotApplicable)
    return "(Does not apply)";
  // constants.MILESTONE_STATUS_IDS.Incomplete
  switch (type) {
    case "contract":
      return milestoneStatusId === constants.MILESTONE_STATUS_IDS.Complete
        ? 'Contract is correct and signed'
        : 'Contract signature or other details are missing';
    case "delivery":
      return milestoneStatusId === constants.MILESTONE_STATUS_IDS.Complete
        ? 'Delivery has been confirmed'
        : 'Delivery has not been confirmed';
    case "landlord":
      return milestoneStatusId === constants.MILESTONE_STATUS_IDS.Complete 
        ? 'Landlord permission does not apply'
        : 'Landlord permission sheet has not been submitted';
    case "payment":
      return milestoneStatusId === constants.MILESTONE_STATUS_IDS.Complete 
        ? 'Payment has been received'
        : 'Payment has not been received';
    default:
      return "";
  }
}

const key = `fi-tt-${id}`;
const bgClass = ui_helpers.essentialBackground(milestoneStatusId, daysSinceInception);
api.getCachedImage(icon).then(image => {
  setIconContent(image)
});
if (!iconContent) return null;
var Tooltip = '';
if( getText() ) {
  Tooltip = <ReactTooltip place='left' id={key} type={ui_helpers.resolveTooltipTypeByBgColor(bgClass)} effect='solid'><p style={{fontSize: '1rem', opacity: '1'}}>{getText()}</p></ReactTooltip>
}
return (
  <div>
    <div id={id} data-tip data-for={key} className={`d-flex justify-content-center align-items-center m-1 rounded essential-badge bg-${bgClass}`}>
      <img src={iconContent} className="essential-icon" loading={"eager"} sizes={"sm"}/>
    </div>
    { Tooltip  }
  </div>);
}