import _ from "lodash";
import React, { useEffect, useState, useContext, Fragment } from "react";
import Alert from "react-s-alert-v3";
import { Link, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import {
    Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Spinner, Table, Row, Col, Card, CardBody,
    CardHeader, CardFooter, Label, Input, ButtonGroup
} from "reactstrap";
import {
    Header,
    Footer,
    EssentialIcon,
    FollowupIcon,
    Loading,
    SortHeader,
    ResultHeader,
    Pager,
    FilterSearch,
    SelectFilter,
    TextFilter,
    DateFilter
} from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, constants, UserContext } from "../utils";
import { date_helpers, filter_helpers, ui_helpers } from "../helpers";
import classnames from "classnames";
const ICONS = constants.ICONS;

const QuoteRow = ({ c, selectedContractIds, onSelectContractId, onSetRedirect, cancelQuote }) => {
    const navPath = `${constants.PATH_NAMES.QUOTE_VIEW}/${c.contractId}`;
    function onNavigate() {
      onSetRedirect(navPath);
    }
    const daysSinceInception = date_helpers.calculateAgeInDays(c.createdAt);
    return (
        <tr key={`c-${c.contractId}`} className='selectable-row'>
            <td style={{ textAlign: "left" }} onClick={onNavigate} className="clickable">
                {c.dealerName}<br />
                {c.assignedDriverUserFullName ? (
                    <div>
                        <em className="text-muted">
                            {c.assignedDriverUserFullName} ({date_helpers.dateTimeFormat(c.driverLastAssignedAt)})
                        </em>
                    </div>
                ) : null}
            </td>
            <td onClick={onNavigate} className="clickable">
                {c.customerFirstName} {c.customerLastName}
            </td>
            <td onClick={onNavigate}>{ui_helpers.formatCurrencyWithoutDecimal(c.unitPrice + c.sumOfPriceAdjustments)}</td>
            <td onClick={onNavigate}>{c.manufacturerName}</td>
            <td onClick={onNavigate}>{date_helpers.formatDateToShortDate(c.createdAt)}</td>
            <td onClick={onNavigate}>{c.serialNumber}</td>
            <td onClick={onNavigate}>{c.contractId}</td>
            <td onClick={onNavigate}>{date_helpers.formatDateToShortDate(c.mfgExpirationDate)}</td>
            <td>
                {ui_helpers.renderContractStatusBadge(c.typeOfContractStatus)}
            </td>
            <td>
              <Button color='light' size="sm" className="text-light cancel-btn" onClick={() => cancelQuote(c.contractId)} title="Cancel">
                Cancel <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 7.9C0 12.2533 3.54 15.7933 7.9 15.7933V15.8C12.26 15.8 15.8 12.26 15.8 7.9C15.8 3.54 12.26 0 7.9 0C3.54 0 0 3.54667 0 7.9ZM1.33333 7.9C1.33333 4.28 4.28 1.33333 7.9 1.33333C11.52 1.33333 14.4667 4.28 14.4667 7.9C14.4667 11.52 11.52 14.4667 7.9 14.4667C4.28 14.4667 1.33333 11.52 1.33333 7.9ZM11.1376 5.81403L8.9516 8H8.94073L11.1267 10.186C11.3877 10.447 11.3877 10.8766 11.1267 11.1376C10.9962 11.2681 10.8222 11.3333 10.6536 11.3333C10.485 11.3333 10.311 11.2681 10.1805 11.1376L7.99456 8.9516L5.80859 11.1376C5.67809 11.2681 5.50408 11.3333 5.33551 11.3333C5.16694 11.3333 4.99293 11.2681 4.86242 11.1376C4.60141 10.8766 4.60141 10.447 4.86242 10.186L7.0484 8L4.86242 5.81403C4.60141 5.55302 4.60141 5.12344 4.86242 4.86242C5.12344 4.60141 5.55302 4.60141 5.81403 4.86242L8 7.0484L10.186 4.86242C10.447 4.60141 10.8766 4.60141 11.1376 4.86242C11.3986 5.12344 11.3986 5.55302 11.1376 5.81403Z" fill="#5E6578"/>
                </svg>
              </Button>
            </td>
            <td>
              <a href={navPath} target="_blank" title="Open in new tab" className="open-ext">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_571_6911)">
                <path d="M12.7612 8.28584C12.3775 8.28584 12.0641 8.59919 12.0641 8.98288V12.3594C12.0641 13.1332 11.4374 13.7599 10.6636 13.7599H4.12806C3.35428 13.7599 2.72758 13.1332 2.72758 12.3594V5.81742C2.72758 5.04363 3.35428 4.41694 4.12806 4.41694H7.39585C7.77954 4.41694 8.09289 4.10359 8.09289 3.71989C8.09289 3.3362 7.77954 3.02285 7.39585 3.02285H4.12806C2.58689 3.01646 1.3335 4.27625 1.3335 5.81742V12.3658C1.3335 13.9069 2.58689 15.1667 4.12806 15.1667H10.6636C12.2048 15.1667 13.4582 13.9133 13.4582 12.3658V8.98928C13.4582 8.60558 13.1448 8.29224 12.7612 8.29224V8.28584Z" fill="#2B3B63"/>
                <path d="M14.6091 2.26823C14.5388 2.10196 14.4045 1.96127 14.2318 1.89093C14.1423 1.85256 14.0527 1.83337 13.9632 1.83337H9.57632C9.19263 1.83337 8.87928 2.14672 8.87928 2.53042C8.87928 2.91411 9.19263 3.22746 9.57632 3.22746H12.275L7.74099 7.76783C7.4724 8.04281 7.4724 8.48405 7.74099 8.75264C7.87528 8.88693 8.04794 8.95727 8.2334 8.95727C8.41885 8.95727 8.59151 8.88693 8.7258 8.75264L13.2534 4.21227V6.77022C13.2534 7.15392 13.5667 7.46727 13.9504 7.46727C14.3341 7.46727 14.6475 7.15392 14.6475 6.77022V2.53042C14.6475 2.44089 14.6283 2.35136 14.5899 2.26183L14.6091 2.26823Z" fill="#2B3B63"/>
                </g>
                <defs>
                <clipPath id="clip0_571_6911">
                <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.3335 1.83337)"/>
                </clipPath>
                </defs>
                </svg>
              </a>
            </td>
        </tr>);
}

const Quotes = (props) => {
  const [filters, setFilters] = useState([]);
  const [loadingPayload, setLoadingPayload] = useState(null);
  const [loadedPayload, setLoadedPayload] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortColumn, setSortColumn] = useState('CreatedAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [paginatedList, setPaginatedList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [users, setUsers] = useState([]);
  const [redirectTo, setRedirectTo] = useState('');
  const [canClear, setCanClear] = useState(false);
  // const [clear, setClear] = useState(false);
  const [newOrUsedSelection, setNewOrUsedSelection] = useState({});
  const [selectedContractIds, setSelectedContractIds] = useState([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [lastSearch, setLastSearch] = useState(null);
  const [pristineLastSearch, setPristineLastSearch] = useState(null);
  const userCtx = useContext(UserContext);
  const currentUser = userCtx?.currentUser;
  const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
  const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(currentUser);
  const quoteFilter = constants.QUOTE_STRING;
  const userCanCreateContract = ui_helpers.hasContractCreationPermission(currentUser);
  document.title = `Quotes - ${constants.appName}`;

  useEffect(() => {
    api.fetch("CashSale/GetContractSearchFilters/true").then(r => {
      if (!r.data) return;
      let tempUsers = ui_helpers.idNameToValueLabel(r.data.userSelect)
      r.data.companySelect.unshift({ value: 0, label: '[Any Company]' });
      r.data.manufacturerSelect.unshift({ value: 0, label: '[Any Manufacturer]' });
      r.data.dealerSelect.unshift({ label: '[Any Store]', value: 0 });
      tempUsers.unshift({ label: '[Any User]', value: 0 });
      setDealers(r.data.dealerSelect);
      setCompanies(r.data.companySelect);
      setManufacturers(r.data.manufacturerSelect);
      setUsers(tempUsers);
      const userSavedSearch = r.data.savedSearch;
      if (userSavedSearch) {
        const savedSearch = JSON.parse(userSavedSearch.serializedSearch);
        const sortDetails = savedSearch.resolvedSort;
        setPageNumber(sortDetails.page);
        setFilters(savedSearch.filters);
        setSortColumn(sortDetails.sortColumn);
        setSortDirection(sortDetails.sortAscending ? "asc" : "desc");
        setLastSearch(userSavedSearch.serializedSearch);
        setPristineLastSearch(userSavedSearch.serializedSearch);
        setLoadedPayload(
          resolvePayload(sortDetails.page, savedSearch.filters));
        setNewOrUsedSelection(
          ui_helpers.resolveNewOrUsedSelectionFromFilters(savedSearch.filters));
        setPaginatedList(r.data.initialData);
      }
    }).finally(() => setFiltersLoaded(true));
  }, []);

  useEffect(() => {
    if (!filtersLoaded) return;
    refreshData();
  }, [filtersLoaded, pageNumber, sortColumn, sortDirection, filters])

  function doSort(field, direction) {
    setSortColumn(field);
    setSortDirection(direction);
  }

  function onFilterChange(changedFilter) {
    let filterChanges = filter_helpers.getFilters(filters, changedFilter);
    setPageNumber(1);
    setCanClear(filterChanges.length > 0);
    setFilters(filterChanges);
  }

  function handleNewOrUsedSelection(selection) {
    if (selection.label === 'New Or Used')
    {
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly' || x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only New')
    {
      selection.filterName = 'NewOnly'
      selection.value = true;
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'UsedOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
    else if (selection.label === 'Only Used')
    {
      selection.filterName = 'UsedOnly'
      selection.value = true;
      let filterChanges = _.reject(
        filter_helpers.getFilters(filters, selection), x => x.filterName === 'NewOnly');
      setCanClear(filterChanges.length !== 0);
      setFilters(filterChanges);
    }
  }

  function resolveSort() {
    return {
      sortColumn,
      sortAscending: sortDirection === 'asc',
      quotesOnly: true
    };
  }

  function resolvePayload(pPageNumber = null, pFilters = null) {
    let payload = {
      ...resolveSort(),
      page: pPageNumber || pageNumber
    };
    const filterList = pFilters ? pFilters : filters;
    _.each(filterList, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData(pFilters = null, forceRefresh = false) {
    const payload = resolvePayload(null, pFilters);
    if (_.isEqual(payload, loadedPayload) && !forceRefresh) {
      // console.log('cancelled redundant refresh');
      return;
    }
    // console.log('running w/ payload', payload)
    setLoadingPayload(payload);
    api.post('CashSale/FindQuotes', payload).then((r) => {
      if (r) {
        setPaginatedList(r.data);
      }
    }).catch(error => {
      Alert.error('Failed to load quotes');
    }).finally(() => {
      if (filtersLoaded) {
        setLastSearch(
          filter_helpers.storeSaveSearch(
            filters, resolveSort(), pageNumber, null));
      }
      setLoadedPayload(payload);
      setLoadingPayload(null);
    });
  }

  function onSelectContractId(e, cId) {
      e.stopPropagation();
      let newList = selectedContractIds.slice();
      if (newList.includes(cId)) {
          newList = _.reject(newList, x => x === cId);
      } else {
          newList.push(cId);
      }
      setSelectedContractIds(newList);
  }

  function cancelQuote(quoteId) {
    confirmAlert({
      title: "Confirm Cancellation",
      message: "Are you sure you wish to cancel this quote?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api.post('contract/CancelContract', {
              id: quoteId,
              comment: ''
            }).then(r => {
              if (r.data.success) {
                refreshData(null, true);
              } else {
                Alert.error(r.data.message);
              }
            }).catch((e) => console.error(e));
          }
        },
        {
          label: "No"
        }
      ]
    });    
  }

  useEffect(() => {
    if (!filtersLoaded || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
    filter_helpers.saveSearch(
      constants.SEARCH_AREAS.QUOTES, 
      lastSearch
    );
    setPristineLastSearch(lastSearch);
  }, [pristineLastSearch, lastSearch])

  const clearFilters = () => {
    setFilters([]);
  };

  if (redirectTo) {
    return (<Redirect to={redirectTo} />);
  }

  return (
        <div>
            <Header pos></Header>
            <div className="inner">
              <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-inline-block bg-light rounded" style={{padding: "2px"}}>
                  {userCanCreateContract
                      ? (<Button
                          color="orange"
                          className="text-light"
                          tag={Link}
                          to={constants.PATH_NAMES.NEW_QUOTE}
                      >
                        <FontAwesomeIcon icon='plus' /> Add New
                      </Button>)
                      : null
                  }
                </div>
            <FilterSearch filters={filters} onClear={clearFilters} canClear={canClear} isLoading={loadingPayload ? true : false}>
                <Row>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='CompanyId'
                            displayName='Company'
                            value={filter_helpers.get_value('CompanyId', filters)}
                            options={companies ? companies : null}
                            isSingleSelect={true}
                            isDisable={!companies}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Company]'} />

                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='DealerId'
                            displayName='Store'
                            value={filter_helpers.get_value('DealerId', filters)}
                            options={dealers}
                            isSingleSelect={true}
                            isDisabled={!dealers}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Store]'} />
                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='ManufacturerId'
                            displayName='Manufacturer'
                            value={filter_helpers.get_value('ManufacturerId', filters)}
                            options={manufacturers}
                            isSingleSelect={true}
                            isDisabled={!manufacturers}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any Manufacturer]'} />
                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='RelatedToUserId'
                            displayName='Related to'
                            value={filter_helpers.get_value('RelatedToUserId', filters)}
                            options={users}
                            isSingleSelect={true}
                            isDisabled={!users}
                            onChangeCallback={onFilterChange}
                            placeholder={'[Any User]'} />

                    </Col>
                    <Col lg='2' sm='3'>
                        <SelectFilter
                            filterName='newOrUsed'
                            displayName='New Or Used'
                            value={newOrUsedSelection}
                            options={constants.NEW_OR_USED_OPTIONS}
                            isSingleSelect
                            onChangeCallback={handleNewOrUsedSelection}
                            placeholder={'New or Used'} />

                    </Col>
                </Row>
                <Row>
                    <Col lg='2' sm='3'>
                        <TextFilter
                            filterName='CustomerName'
                            displayName='Customer Name'
                            onChangeCallback={onFilterChange}
                            value={filter_helpers.get_value('CustomerName', filters)}
                        />
                    </Col>
                    <Col lg='2' sm='3'>
                        <TextFilter
                            filterName='SerialNumber'
                            displayName='Serial Number'
                            onChangeCallback={onFilterChange}
                            value={filter_helpers.get_value('SerialNumber', filters)}
                        />
                    </Col>
                </Row>
                <Row>
                  <Col lg='2' sm='3'>
          <DateFilter
            filterName='CreatedSince'
            displayName='After'
            value={filter_helpers.get_value('CreatedSince', filters)}
            onChangeCallback={onFilterChange} />
                    </Col>
                    <Col lg='2' sm='3'>
          <DateFilter
            filterName='CreatedBefore'
            displayName='Before'
            value={filter_helpers.get_value('CreatedBefore', filters)}
            onChangeCallback={onFilterChange} />
                    </Col>
                </Row>
            </FilterSearch>
        </div>
            <Card>
                
                    <Row>
                        <Col xs="8">
                            <ResultHeader
                              heading='Quotes'
                              totalResults={paginatedList ? paginatedList.totalCount : 0}
                              badgeClass="bg-dark"
                            />
                        </Col>
                        <Col xs="4" className='d-flex justify-content-end align-items-start'>
                        <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0}
                        totalResults={paginatedList ? paginatedList.totalCount : 0}
                        onSetPageCallback={setPageNumber} />
                        </Col>
                    </Row>
                
                <Table hover>
                    <thead>
                        <tr>
                            <th>
                              <SortHeader
                                spanOnly
                                displayName="Dealer"
                                field='DealerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'DealerName'}
                                sortCallback={doSort}
                                isAlphabetic
                              />
                            </th>
                            <SortHeader
                                displayName='Customer'
                                field='CustomerName'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'CustomerName'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Total'
                                field='Total'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Total'}
                                sortCallback={doSort} />
                            <SortHeader
                                displayName='Manufacturer'
                                field='Manufacturer'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Manufacturer'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Created'
                                field='CreatedAt'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'CreatedAt'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Serial #'
                                field='SerialNo'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'SerialNo'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Quote #'
                                field='Id'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'Id'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Expiration Date'
                                field='ExpirationDate'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'ExpirationDate'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <SortHeader
                                displayName='Status'
                                field='status'
                                sortDir={sortDirection}
                                sorted={sortColumn === 'status'}
                                sortCallback={doSort}
                                isAlphabetic />
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingPayload || !filtersLoaded
                          ? (<tr><td colSpan='12'><Loading /></td></tr>)
                          : paginatedList && paginatedList.items && paginatedList.items.length
                            ? _.map(paginatedList.items, c =>
                              (<QuoteRow
                                key={c.contractId}
                                c={c}
                                selectedContractIds={selectedContractIds}
                                onSelectContractId={onSelectContractId}
                                onSetRedirect={setRedirectTo}
                                cancelQuote={cancelQuote}
                              />))
                            : (<tr><td colSpan='12' className='text-center'>No quotes found.</td></tr>)
                        }
                    </tbody>
                </Table>
                <CardFooter>
                    <Pager pageNumber={pageNumber} pageSize={paginatedList ? paginatedList.pageSize : 0}
                        totalResults={paginatedList ? paginatedList.totalCount : 0}
                        onSetPageCallback={setPageNumber} />
                </CardFooter>
            </Card>
            </div>
            <Footer />
        </div>);
};

export default Quotes;