import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  ResultHeader,
  Footer,
} from "./";
import { api, filter_helpers, ui_helpers } from "../helpers";
import { constants } from "../utils";
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  FormText,
  ModalFooter,
  Modal,
  ButtonGroup,
  Collapse
} from "reactstrap";
import { MfgAdmin } from "./";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import _ from "lodash";
import {DebounceInput} from "react-debounce-input";

const defaultAddOn = {
  manufacturerId: 0,
  name: "",
  typeOfAddOn: 0,
  manufacturerBuildStepId: 0,
  description: "",
  standardPrice: 0,
  length: 0,
  width: 0,
  typeOfUnitOfMeasure: 1,
  showInFloorPlan: true,
  hasDimensions: false,
  additionalOption1Type: 0,
  additionalOptionType1Price: 0,
  additionalOption2Type: 0,
  additionalOptionType2Price: 0,
};

export default function ManufacturerAddOns(props) {
  const params = useParams();
  const mfgId = params.mfgId ? params.mfgId : props.mfgId;

  const [addOns, setAddOns] = useState([]);
  const [uomList, setUomList] = useState(ui_helpers.idNameToValueLabel(constants.UNITS_OF_MEASURE, null, false));
  const [message, setMessage] = useState(constants.ALERT_OBJECT);
  const [addOn, setAddOn] = useState(defaultAddOn);
  const [active, setActive] = useState(true);
  const [filters, setFilters] = useState([]);
  const [mfgBuildSteps, setMfgBuildSteps] = useState([]);
  const [addOnModal, setAddOnModal] = useState(false);
  const [isOpen, setIsOpen] = useState([]);
  const [manufacturerName, setManufacturerName] = useState("");
  const [buildFeature, setBuildFeature] = useState(false);
  const [copyToAllSKU, setCopyToAllSKU] = useState(true);

  const getAddons = () => {
    let payload = {
      id: mfgId,
      activeOnly: active,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api.post(`manufacturer/ListManufacturerAddons`, payload).then((r) => {
      if (!r.data.success) {
        setMessage({
          message: "There was an error retrieving Add Ons.",
          flavor: constants.flavor.error,
        });
      } else {
        setAddOns(
          _.map(r.data.message, 
            x => ({...x, standardPrice: parseFloat(x.standardPrice).toFixed(2)})));
      }
    });
  };

  const saveAddOn = () => {
    let payload = Object.assign({}, addOn);
    payload.manufacturerId = mfgId;
    payload.copyToAllSKU = copyToAllSKU;
    if (!payload.typeOfUnitOfMeasure) {
      setMessage({ message: 'Select a unit of measure', flavor: 'warning' });
      return;
    }
    api.post("manufacturer/SaveAddOn", payload).then((r) => {
      if (!r.data.success) {
        setMessage({
          message: "Error Saving Add On.",
          flavor: constants.flavor.error,
        });
      } else {
        getAddons();
        closeModal();
      }
    });
  };

  const toggleAddonActive = (addOnId) => {
    api.post(`manufacturer/ToggleAddOnActive/${addOnId}`).then((r) => {
      if (!r.data.success)
        setMessage({ message: r.data.message, flavor: constants.flavor.error });
      else getAddons();
    });
  };

  const onAddOnChange = (fieldName, fieldValue) => {
    let newAddOn = Object.assign({}, addOn);
    newAddOn[fieldName] = fieldValue;
    switch (fieldName) {
      case "additionalOptionType1Price ":
      case "additionalOptionType2Price ":
        if (isNaN(fieldValue) || !parseInt(fieldValue)) {
          newAddOn[fieldName] = 0;
        }
        break;
    }
    if ([
        constants.ADDON_TYPE_ID.SINGLE_WORKBENCH,
        constants.ADDON_TYPE_ID.SHELVING,
        constants.ADDON_TYPE_ID.DOUBLE_WORKBENCH
      ].includes(newAddOn.typeOfAddOn))
    {
      newAddOn.typeOfUnitOfMeasure = constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT;
    } else if (newAddOn.typeOfAddOn !== constants.ADDON_TYPE_ID.LOFT
      && newAddOn.typeOfAddOn !== constants.ADDON_TYPE_ID.OTHER) 
    {
      newAddOn.typeOfUnitOfMeasure = constants.UNITS_OF_MEASURE_ID.EACH;
    }
    setAddOn(newAddOn);
  };

  const closeModal = () => {
    setAddOnModal(false);
    setAddOn(defaultAddOn);
  };

  function onFilterChange(changedFilter) {
    const filterChanges = filter_helpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  const editAddOn = (editingAddOn) => {
    setAddOn(editingAddOn);
    // setLinearFeetRequired(
    //   _.some(constants.ADDON_TYPE_GROUPS.INTERIOR_UPGRADES.types, (x) => x === editingAddOn.typeOfAddOn)
    // );
    setAddOnModal(true);
  };

  const filteredAddons = _.chain(constants.ADDON_TYPES)
    .filter(x => _.some(addOns, y => y.typeOfAddOn === x.value))
    .sortBy(x => x.label)
    .map(x => {
      x.addOnList = _.chain(addOns).filter(y => y.typeOfAddOn === x.value).sortBy(y => y.name).value()
      return x;
    })
    .value()

  function handleAddOnClick (idx) {
    if (_.some(isOpen, x => x === idx)) {
      setIsOpen(
        _.reject(isOpen, x => x === idx)
      );
    }
    else {
      setIsOpen([...isOpen, idx]);
    }
  }
  
  const CollapseAddons = ({isOpen}) => {
    return(
      _.map(filteredAddons, (x, idx) => {
        return (
        <Fragment key={`${idx}addons`}>
          <CardHeader style={{cursor: "pointer", padding: "10px 0"}} onClick={() => handleAddOnClick(idx)}>
            <Row className="d-flex align-items-center justify-content-between">
              <Col sm={12}>
                <span className='float-start bg-dark ms-1 pb-1 mt-1 me-2 badge rounded-pill' style={{fontSize: "0.75rem"}}>{x.addOnList.length}</span>
                <h4 className="m-0">
                  {x.label}
                </h4>
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={_.includes(isOpen, idx)}>
            <Table bordered striped>
              <thead>
                <tr>
                  <th width="15%">Name</th>
                  <th>Description</th>
                  <th width="12%">Standard Price/%</th>
                  <th width="15%">Drawing Dimensions</th>
                  {buildFeature 
                  ? (<th width="8%">Show In Floorplan?</th>) 
                  : null}
                  {buildFeature 
                  ? (<th width="8%">Use SKU Dimensions?</th>) 
                  : null}
                  <th width="5%"/>
                </tr>
              </thead>
              <tbody>
                {x.addOnList.length > 0 ? (
                  _.map(x.addOnList, (a) => {
                    return (
                      <tr key={a.id}>
                        <td>{a.name}</td>
                        <td>{a.description}</td>
                        <td sm={1}>
                          {a.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE
                            ? a.standardPrice + "% of base"
                            : ui_helpers.formatCurrency(a.standardPrice)
                          }
                        </td>
                        <td>{`${a.width}" Depth x ${a.length}" Width`}</td>
                        {buildFeature ? (
                          <>
                            <td sm={1}>
                              {a.showInFloorPlan
                                ? ui_helpers.greenCheck()
                                : ui_helpers.redX()}
                            </td>{" "}
                          </>
                        ) : null}
                        {buildFeature ? (
                          <>
                            <td sm={1}>
                              {a.useSkuDimensions
                                ? ui_helpers.greenCheck()
                                : null}
                            </td>{" "}
                          </>
                        ) : null}
                        <td>
                          <ButtonGroup className="float-end">
                            <Button
                              size={"sm"}
                              className="bg-secondary"
                              onClick={() => editAddOn(a)}
                            >
                              <FontAwesomeIcon icon={"edit"} />
                            </Button>
                            {a.deactivatedAt === null ? (
                              <Button
                                size={"sm"}
                                className="btn-delete"
                                onClick={() => toggleAddonActive(a.id)}
                              >
                                <FontAwesomeIcon icon={"trash-alt"} />
                              </Button>
                            ) : (
                              <Button
                                size={"sm"}
                                className="bg-success border-success"
                                onClick={() => toggleAddonActive(a.id)}
                              >
                                <FontAwesomeIcon icon={"heartbeat"} />
                              </Button>
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                })
                ) : (
                  <tr>
                    <td colSpan={7} className={"text-center text-bold"}>
                      {" "}
                      Click the green button on the top right to create a new Add-On.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Collapse>
        </Fragment>
        )
      })
    )
  }

  const getConditionalMfgBuildSteps = () => {
    api
      .fetch(`Manufacturer/GetConditionalBuildSteps/${mfgId}`)
      .then((r) => {
        if (r.data.success) {
          setMfgBuildSteps(r.data.message.list);
          setManufacturerName(r.data.message.manufacturerName);
          setBuildFeature(r.data.message.enableBuildFeature);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(getAddons, [filters]);
  useEffect(getConditionalMfgBuildSteps, []);

  return (
    <>
      <MfgAdmin mfgId={mfgId} tabName="ADDONS"/>
          <Modal centered size="lg" isOpen={addOnModal}>
            <ModalHeader>
              {addOn.id > 0 ? `Editing ${addOn.name}` : "Add New Add On"}
            </ModalHeader>
            <ModalBody>
              <FormGroup row>
                <Col>
                  <Label>Name</Label>
                  <DebounceInput
                    name="name"
                    className="form-control"
                    value={addOn.name || ""}
                    onChange={(e) => onAddOnChange("name", e.target.value)}
                    min={0}
                    maxLength={75}
                    debounceTimeout={500}
                  />
                </Col>
                <Col>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    name="typeOfAddOn"
                    value={addOn.typeOfAddOn}
                    onChange={(e) =>
                      onAddOnChange(e.target.name, Number(e.target.value))
                    }
                  >
                    {_.chain(constants.ADDON_TYPES)
                      .filter((x) => x.value >= 0)
                      .sortBy(x => x.label)
                      .map(x => (
                        <option key={x.value} value={x.value}>
                          {x.label}
                        </option>
                      ))
                      .value()
                    }
                  </Input>
                </Col>
              </FormGroup>
              {addOn && (
                <FormGroup row>
                  <Col xs="6">
                    <Label>Unit of Measure</Label>
                    <Select
                      options={addOn.typeOfAddOn === constants.ADDON_TYPE_ID.OTHER
                        ? _.reject(uomList, uom => uom.value === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT)
                        : uomList
                      }
                      value={_.find(uomList, u => u.value === addOn.typeOfUnitOfMeasure)}
                      onChange={(e) => onAddOnChange('typeOfUnitOfMeasure', e.value)}
                      isDisabled={addOn.typeOfAddOn !== constants.ADDON_TYPE_ID.LOFT
                        && addOn.typeOfAddOn !== constants.ADDON_TYPE_ID.OTHER}
                    />
                  </Col>
                  <Col xs="6">
                  {addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.SQ_FOOT
                    ? (<Fragment>
                        <Label>Use SKU Dimensions</Label>
                        <ToggleButton
                            value={addOn.useSkuDimensions}
                            activeLabel={"Yes"}
                            inactiveLabel={"No"}
                            onToggle={(v) => onAddOnChange('useSkuDimensions', !v)}
                        />
                      </Fragment>)
                    : null
                  }
                  </Col>
                </FormGroup>
              )}
              <FormGroup row>
                <Col>
                  <Label>Standard Price</Label>
                  {addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE
                    ? (<CurrencyInput
                        className="form-control"
                        value={addOn.standardPrice}
                        name="standardPrice"
                        onValueChange={(value, name) => onAddOnChange(name, value)}
                        suffix="%"
                      />)
                    : (<CurrencyInput
                        className="form-control"
                        value={addOn.standardPrice}
                        name="standardPrice"
                        onValueChange={(value, name) => onAddOnChange(name, value)}
                        prefix="$"
                      />)
                  }
                </Col>
                {buildFeature ? (
                  <Col>
                    <Label>Related Build Step</Label>
                    <Input
                      type="select"
                      value={addOn.manufacturerBuildStepId}
                      name="manufacturerBuildStepId"
                      defaultValue="default"
                      onChange={(e) =>
                        onAddOnChange(e.target.name, Number(e.target.value))
                      }
                    >
                      <option key="bs-0" value="default">
                        [Select a Related Build Step]
                      </option>
                      {_.map(mfgBuildSteps, (m) => {
                        return (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                ) : null}
              </FormGroup>
              {addOn && addOn.typeOfAddOn === constants.ADDON_TYPE_ID.WINDOW && (
                <>
                  <FormGroup row>
                    <Col>
                      <Label>Additional Option 1</Label>
                      <Input
                        type="select"
                        name="additionalOptionType1"
                        value={addOn.additionalOptionType1}
                        onChange={(e) =>
                          onAddOnChange(e.target.name, Number(e.target.value))
                        }
                      >
                        {_.map(constants.ADDITIONAL_WINDOW_OPTION_TYPE_LIST, (t) => (
                          <option key={t.value} value={t.value}>
                            {t.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Label>Additional Option 1 Price</Label>
                      <CurrencyInput
                        className="form-control"
                        value={addOn.additionalOptionType1Price}
                        name="additionalOptionType1Price"
                        onValueChange={(value, name) =>
                          onAddOnChange(name, Number(value))
                        }
                        prefix={"$"}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col>
                      <Label>Additional Option 2</Label>
                      <Input
                        type="select"
                        name="additionalOptionType2"
                        value={addOn.additionalOptionType2}
                        onChange={(e) =>
                          onAddOnChange(e.target.name, Number(e.target.value))
                        }
                      >
                        {_.map(constants.ADDITIONAL_WINDOW_OPTION_TYPE_LIST, (t) => (
                          <option key={t.value} value={t.value}>
                            {t.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Label>Additional Option 2 Price</Label>
                      <CurrencyInput
                        className="form-control"
                        value={addOn.additionalOptionType2Price}
                        name="additionalOptionType2Price"
                        onValueChange={(value, name) =>
                          onAddOnChange(name, Number(value))
                        }
                        prefix={"$"}
                      />
                    </Col>
                  </FormGroup>
                </>
              )}
              {addOn && constants.ADDON_TYPE_GROUPS.DOORS.types.includes(addOn.typeOfAddOn) && (
                <>
                  <FormGroup row>
                    <Col>
                      <Label>Additional Option 1</Label>
                      <Input
                        type="select"
                        name="additionalOptionType1"
                        value={addOn.additionalOptionType1}
                        onChange={(e) =>
                          onAddOnChange(e.target.name, Number(e.target.value))
                        }
                      >
                        {_.map(constants.ADDITIONAL_DOOR_OPTION_TYPE_LIST, (t) => (
                          <option key={t.value} value={t.value}>
                            {t.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Label>Additional Option 1 Price</Label>
                      <CurrencyInput
                        className="form-control"
                        value={addOn.additionalOptionType1Price}
                        name="additionalOptionType1Price"
                        onValueChange={(value, name) =>
                          onAddOnChange(name, Number(value))
                        }
                        prefix={"$"}
                      />
                    </Col>
                  </FormGroup>
                </>
              )}
              {addOn &&
                !_.some(constants.ADDON_TYPE_GROUPS.ELECTRICAL.types, (x) => x == addOn.typeOfAddOn) && (
                  <>
                    <FormGroup row>
                      {addOn &&
                        ((addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.LINEAR_FOOT && addOn.typeOfAddOn !== constants.ADDON_TYPE_ID.LOFT)
                        || addOn.typeOfAddOn === constants.ADDON_TYPE_ID.OTHER) ? (
                        <Col>
                          <Label>Drawing Depth (inches)</Label>
                          <Input
                            type="number"
                            value={addOn.width}
                            name="width"
                            min={6}
                            onChange={(e) =>
                              onAddOnChange(
                                e.target.name,
                                Number(e.target.value)
                              )
                            }
                          />
                          {addOn.width < 6 && (
                            <FormText color="danger">
                              Depth must be greater at least 6 inches
                            </FormText>
                          )}
                        </Col>
                      ) : null}
                      {addOn && (addOn.typeOfUnitOfMeasure === constants.UNITS_OF_MEASURE_ID.EACH || addOn.typeOfAddOn === constants.ADDON_TYPE_ID.OTHER) ? (
                        <Col>
                          <Label>Drawing Width (inches)</Label>
                          <Input
                            type="number"
                            value={addOn.length}
                            name="length"
                            onChange={(e) =>
                              onAddOnChange(
                                e.target.name,
                                Number(e.target.value)
                              )
                            }
                          />
                          {addOn.length < 6 && (
                            <FormText color="danger">
                              Width must be greater at least 6 inches
                            </FormText>
                          )}
                        </Col>
                      ) : null}
                      <div className="pt-1">
                        <small>
                          <span className="text-info fw-bold">
                            <FontAwesomeIcon icon="info" /> NOTE:
                          </span>{" "}
                          The dimension(s) entered here are for setting how the
                          add-on will display in the floorplan drawing.
                        </small>
                      </div>
                    </FormGroup>
                  </>
                )}
              {props.buildFeature ? (
                <FormGroup row className="d-flex justify-content-start">
                  <Col className="d-flex flex-column align-items-center float-start">
                    <Label>Show in Floor Plan?</Label>
                    <ToggleButton
                      value={addOn.showInFloorPlan}
                      activeLabel={"Yes"}
                      inactiveLabel={"No"}
                      onToggle={(v) => onAddOnChange("showInFloorPlan", !v)}
                    />
                  </Col>
                  <Col className="d-flex flex-column align-items-center">
                    <Label>Has Dimensions?</Label>
                    <ToggleButton
                      value={addOn.hasDimensions}
                      activeLabel={"Yes"}
                      inactiveLabel={"No"}
                      onToggle={(v) => onAddOnChange("hasDimensions", !v)}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup>
                <Label>Description</Label>
                <DebounceInput
                  element='textarea'
                  name="description"
                  value={addOn.description || ""}
                  onChange={(e) => onAddOnChange("description", e.target.value)}
                  min={0}
                  className="w-100 form-control"
                  style={{minHeight: "55px"}}
                  maxLength={200}
                  debounceTimeout={500}
                />
              </FormGroup>
              {addOn && !addOn.id
                ? (<FormGroup>
                    <Input
                      type='checkbox'
                      onChange={() => setCopyToAllSKU(!copyToAllSKU)}
                      checked={copyToAllSKU}
                    /> Copy this new add on to all SKUs
                  </FormGroup>)
                : null
              }
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col className="d-flex flex-row justify-content-end">
                  <Col className="d-flex justify-content-end">
                    <Button color="secondary" onClick={() => closeModal()}>
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ms-1 d-flex justify-content-end">
                    <Button
                      color="primary"
                      className="float-end"
                      onClick={saveAddOn}
                    >
                      Save
                    </Button>
                  </Col>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        <div className="inner">
        <Card>
          <CardHeader>
            <Row className="d-flex align-items-center justify-content-between">
              <Col sm={7}>
                <ResultHeader
                  heading="Add Ons"
                  totalResults={addOns ? addOns.length : 0}
                />
              </Col>
              <Col
                className="d-flex justify-content-end align-items-center"
                sm={1}
              >
                <Button
                  className="bg-success float-end"
                  onClick={() => setAddOnModal(true)}
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CollapseAddons
            isOpen={isOpen}
          />
        </Card>
        </div>
        <Footer/>
  </>
  );
}
