import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { Row, Col, Input, Spinner, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from "reactstrap";
import { api } from "../utils";
import Alert from "react-s-alert-v3";
import Select from 'react-select';
import { Loading } from "./";

function CheckboxCell({cto, handleCheckboxChange}) {
  const [isChecked, setIsChecked] = useState(cto.manufacturerSubProductColorId !== null);
  return (<td className="text-center">
        {cto.isLoading
          ? (<Spinner size="sm" />)
          : (<Input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => {
                handleCheckboxChange(cto, e.target.checked);
                setIsChecked(!isChecked);
              }}
            />)
        }
    </td>);
}

export default function EditSubProductColorOptions({mfgId, subProductId, subProductName, 
    colorOptions, colorTypeOptions, colorTypes, onReloadColors}) {
  const APPLY_TO_OPTIONS = [
    {label: `Here in the ${subProductName} Product Type`, value: 'this'},
    {label: 'To All Product Types', value: 'all'},
    {label: 'To Selected Product Types', value: 'selected'}
  ];

  const [showColorModal, setShowColorModal] = useState(false);
  const [applyColorType, setApplyColorType] = useState(null);
  const [applyTo, setApplyTo] = useState(APPLY_TO_OPTIONS[0]);
  const [subproducts, setSubproducts] = useState([]);
  const [applyToSubproductIds, setApplyToSubproductIds] = useState([]);
  const [applyTargetColorType, setApplyTargetColorType] = useState(null);

  useEffect(() => {
    if (subproducts && subproducts.length) return;
    const payload = {
      manufacturerId: mfgId,
      activeOnly: true,
      name: null,
      productTypeId: null
    };
    api.post('manufacturer/ListOfSubProducts', payload).then(r => {
      if(!r.data.success) {
        Alert.error("There was an error retrieving the Sub Product List");
        return;
      }
      setSubproducts(r.data.message.subProductList);
    }).catch((err) => console.error(err))
  }, [])

  function handleCheckboxChange(color, type, value) {
    if (type.manufacturerSubProductColorId === null) {
      const payload = {
        manufacturerSubProductId: subProductId,
        manufacturerColorId: color.manufacturerColorId,
        colorTypeId: type.id,
      };
      api.post("Manufacturer/CreateOrActivateManufacturerSubProductColor", payload).then((res) => {
        if (res.data.success) {
          onReloadColors();
        } else {
          Alert.error(res.data.message);
        }
      }).catch((e) => console.error(e));
    } else {
      api.post(`Manufacturer/DeactivateManufacturerSubProductColor/${type.manufacturerSubProductColorId}`, {}).then((res) => {
        if (res.data.success) {
          onReloadColors();
        } else {
          Alert.error(res.data.message);
        }
      }).catch((e) => console.error(e));
    }
  }

  function onApply() {
    const useTargetColorType = !hideTargetColorType();
    if (useTargetColorType && !applyTargetColorType) {
      Alert.error('You must select a target color type in this case.');
      return;
    }
    if (applyTo && applyTo.value === 'selected' && applyToSubproductIds.length === 0) {
      Alert.error('You must select at least 1 product when using the "selected" option.');
      return;
    }
    let spIds = applyToSubproductIds.slice();
    if (applyTo && applyTo.value === 'all') {
      spIds = _.map(subproducts, sp => sp.id);
    }
    let payload = {
      sourceColorType: applyColorType.value, // {colortypename} or all
      mfgId: mfgId,
      sourceSubProductId: subProductId,
      targetScope: applyTo.value, // this or all
      applyToSubproductIds: spIds
    };
    if (applyTo.value === 'this' && useTargetColorType) {
      payload.targetColorType = applyTargetColorType.value;
    }
    api.post('Manufacturer/ApplySubProductColor', payload).then((res) => {
      if (res.data.success) {
        onReloadColors();
        setShowColorModal(false);
      } else {
        Alert.error(res.data.message);
      }
    }).catch((e) => console.error(e));
  }

  function hideTargetColorType() {
    return !applyTo || applyTo.value !== 'this' 
      || !applyColorType || applyColorType.value === 'all' || applyColorType.value === 'selected';    
  }

  return (<Fragment>
      <Row>
        <Col sm="6">
          <h3>Product Color Options</h3>
        </Col>
        <Col sm="6">
          <Button color='dark' className='float-end' onClick={() => setShowColorModal(true)}>
            Reuse Color Associations
          </Button>
        </Col>
      </Row>
      {colorTypes && colorTypes.length
        ? (<Table className="py-3" hover>
            <thead>
              <tr>
                <th>Color</th>
                {_.map(colorTypes, (n) => (
                  <th key={n}>
                    <div className="d-flex justify-content-center">{n}</div>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {colorOptions && colorOptions.length &&
                _.map(colorOptions, (co) => (
                  <tr key={co.manufacturerColorId}>
                    <th>
                      <div className="d-flex align-items-center">
                        {co.manufacturerColorName}
                      </div>
                    </th>
                    {co.colorTypeOptions && co.colorTypeOptions.length &&
                      _.map(co.colorTypeOptions, (cto) => 
                        (<CheckboxCell
                          key={`${co.manufacturerColorName}-${cto.id}-${cto.manufacturerSubProductColorId}`}
                          cto={cto}
                          handleCheckboxChange={(a, b) => handleCheckboxChange(co, a, b)}
                        />))}
                  </tr>
                ))}
            </tbody>
          </Table>)
        : null
      }
      {showColorModal
        ? (<Modal isOpen centered>
            <ModalHeader>
              Apply Colors
            </ModalHeader>
            <ModalBody>
            <Row>
              <Col xs="4" className="mt-2">
                Apply
              </Col>
              <Col xs="8">
                <Select
                  options={colorTypeOptions}
                  onChange={setApplyColorType}
                  value={applyColorType}
                />
              </Col>
            </Row>
            <Row className="pt-1">
              <Col xs={{offset: 4, size: 8}}>
                <Select
                  options={APPLY_TO_OPTIONS}
                  onChange={setApplyTo}
                  value={applyTo}
                />
              </Col>
            </Row>
            {hideTargetColorType()
              ? null
              : (<Row className="py-1">
                <Col xs={{offset: 4, size: 8}}>
                  <Select
                    options={_.chain(colorTypeOptions)
                      .reject(ct => ct.value === applyColorType.value)
                      .map(ct => ({label: 'To ' + ct.label, value: ct.value}))
                      .value()}
                    onChange={setApplyTargetColorType}
                    value={applyTargetColorType}
                  />
                </Col>
              </Row>)            
            }
            {applyTo && applyTo.value === 'selected'
              ? (<div>
                  {_.map(subproducts, sp => (
                    <Row key={`sp-${sp.id}`}>
                      <Col sm={{size: 1, offset: 1}}>
                        <input type="checkbox" checked={applyToSubproductIds.includes(sp.id)} 
                          onClick={() => {
                            if (applyToSubproductIds.includes(sp.id)) {
                              setApplyToSubproductIds(_.reject(applyToSubproductIds, x => x === sp.id));
                            } else {
                              setApplyToSubproductIds([sp.id, ...applyToSubproductIds]);
                            }
                          }}
                        />
                      </Col>
                      <Col sm="8">
                        {sp.abbr
                          ? sp.abbr + " /"
                          : null
                        } {sp.name}
                      </Col>
                    </Row>))}
                </div>)
              : null
            }
            </ModalBody>
            <ModalFooter>
              <ButtonGroup className="float-end">
                <Button color='primary' className='float-end' disabled={!applyTo || !applyColorType} onClick={onApply}>
                  Apply
                </Button>
                <Button color='secondary' onClick={() => setShowColorModal(false)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Modal>)
        : null
      }
    </Fragment>
  );
}
