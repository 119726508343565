import React from "react";
import { Spinner } from "reactstrap";

const Loading = ({messageText}) => {
  return <div className="w-100 h-100 d-flex justify-content-center align-items-center p-5 text-center">
      <Spinner />
      <h3 className="ms-2" style={{ margin: 0 }}>{messageText ? messageText : "Loading"}...</h3>
  </div>;
};

export default Loading;
