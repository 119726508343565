import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import _ from "lodash";
import {
  Container,
  Card,
  Button,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  ListGroup,
  ButtonGroup,
  CardHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-quill/dist/quill.snow.css";
import { api, constants } from "../utils";
import Alert from "react-s-alert-v3";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { ui_helpers } from "../helpers";
import ReactTooltip from "react-tooltip";

const customerNotificationTypeList = _.filter(
  constants.notificationTypes,
  (x) => x.label !== "Fax" && x.label !== "Phone"
);

const defaultUnitBuildStep = {
  id: 0,
  seq: 0,
  name: "",
  unitInventoryId: 0,
  deactivatedAt: null,
  deactivatedByUserId: null,
  completedBy: "",
  plannedFor: null,
  completedAt: null,
  contactCustomer: false,
  notificationTemplate: "",
  markedCompleteByUserId: null,
  customerNotificationType: _.find(
    customerNotificationTypeList,
    (x) => x.label === "Email"
  ),
};

const DragHandle = SortableHandle(() => (
  <span>
    <FontAwesomeIcon icon="bars" />
  </span>
));

const UnitBuildStepList = SortableContainer(
  ({ buildSteps, editBuildStep, toggleActiveCallback, showCreateProcess }) => {
    return (
      <ListGroup>
        {_.map(buildSteps, (b, index) => {
          return (
            <SortableItem
              key={`bstp-${index}`}
              buildStep={b}
              index={index}
              editBuildStep={editBuildStep}
              toggleActiveCallback={toggleActiveCallback}
              showCreateProcess={showCreateProcess}
            />
          );
        })}
      </ListGroup>
    );
  }
);

const SortableItem = SortableElement((props) => {
  const handleButtonControlVisibility = (
    toggleActiveCallback,
    editBuildStep
  ) => {
    if (!props.buildStep.completedAt) {
      return (
        <Col className="d-flex justify-content-end">
          <ButtonGroup>
            <Button
              size="sm"
              className="bg-secondary"
              onClick={() => editBuildStep(props.buildStep)}>
              <FontAwesomeIcon icon="edit" />
            </Button>
            {props.buildStep.deactivatedAt === null ? (
              <Button
                size={"sm"}
                className="btn-delete"
                onClick={() => toggleActiveCallback(props.buildStep.id)}>
                <FontAwesomeIcon icon={"trash-alt"} />
              </Button>
            ) : (
              <Button
                size={"sm"}
                className="bg-success border-success"
                onClick={() => toggleActiveCallback(props.buildStep.id)}>
                <FontAwesomeIcon icon={"heartbeat"} />
              </Button>
            )}
          </ButtonGroup>
        </Col>
      );
    }
  };

  return (
    <Card body className="my-1 build-step-card">
      <Row className="d-flex align-items-center">
        <Col sm={1}>{props.buildStep.completedAt ? null : <DragHandle />}</Col>
        <Col sm={1}>{props.buildStep.seq}</Col>
        <Col data-tip data-for={`uib-${props.buildStep.id}`}>
          <span>
            {props.showCreateProcess
              ? ui_helpers.replaceTextIfTooLong(15, props.buildStep.name)
              : props.buildStep.name}
          </span>
        </Col>

        <Col>
          <Row className={"d-flex justify-content-center"}>
            <small>Status:</small>{" "}
            {props.buildStep.completedAt ? (
              <span className={"text-success fw-bold ms-1"}>Complete</span>
            ) : (
              <span className={"text-danger fw-bold ms-1"}>Incomplete</span>
            )}
          </Row>
        </Col>
        <Col>
          {handleButtonControlVisibility(
            props.toggleActiveCallback,
            props.editBuildStep
          )}
        </Col>
      </Row>
      {props.showCreateProcess ? (
        <ReactTooltip
          id={`uib-${props.buildStep.id}`}
          place="bottom"
          effect={"solid"}>
          <p style={{ fontSize: "1rem", opacity: "1" }}>
            {props.buildStep.name ? props.buildStep.name : ""}
          </p>
        </ReactTooltip>
      ) : null}
    </Card>
  );
});

const templateDescriptions = [
  {
    value: "{first-name} we have exciting news, your build has been started!",
    label: "Build Started",
  },
  {
    value: "Electricity has successfully been installed on your unit!",
    label: "Electricity Completed",
  },
  {
    value:
      "Your build has been successfully completed! We will contact you for delivery soon!",
    label: "Build Completed",
  },
  {
    value:
      "Delivery has been scheduled for {date} please let us know if something comes up and that time no longer works..",
    label: "Delivery Scheduled",
  },
  {
    value:
      "{first-name} thank you for your business, we hope you love your new unit!",
    label: "Delivery Completed",
  },
];

export default function BuildProcessManagement({
  updateBuildStepSeq,
  unitBuildSteps,
  getBuildSteps,
  setUnitBuildSteps,
}) {
  const { id } = useParams();
  const [unitBuildStep, setUnitBuildStep] = useState(defaultUnitBuildStep);
  const [showCreateProcess, setShowCreateProcess] = useState(false);
  const [showNameSelect, setShowNameSelect] = useState(true);

  const onUnitBuildStepChange = (fieldName, fieldValue) => {
    let newBuild = Object.assign({}, unitBuildStep);
    newBuild[fieldName] = fieldValue;
    setUnitBuildStep(newBuild);
  };

  const editBuildStep = (step) => {
    setUnitBuildStep(step);
    setShowCreateProcess(true);
  };

  function saveUnitBuildStep() {
    let payload = Object.assign({}, unitBuildStep);
    if (payload.contactCustomer && !payload.notificationTemplate.length) {
      Alert.error(
        "If you marked to notify the customer you must include a Template Text."
      );
      return;
    }
    payload.unitInventoryId = id;
    payload.customerNotificationType = payload.customerNotificationType.value;
    api.post("inventory/SaveUnitBuildStep", payload).then((r) => {
      if (!r.data.success) {
        Alert.error("There was an issue saving this build step.");
      }
      getBuildSteps();
      setShowCreateProcess(false);
      clearBuildStepEdit();
    });
  }

  const handleEditOpen = () => {
    if (showCreateProcess) {
      setShowCreateProcess(false);
      clearBuildStepEdit();
    } else {
      setShowCreateProcess(true);
    }
  };

  const clearBuildStepEdit = () => {
    setUnitBuildStep(defaultUnitBuildStep);
  };

  const onSortEnd = (params) => {
    let newBuildSteps = unitBuildSteps.slice();
    if (newBuildSteps[params.newIndex].completedAt) {
      return;
    }
    newBuildSteps = arrayMove(newBuildSteps, params.oldIndex, params.newIndex);
    newBuildSteps = updateBuildStepSeq(newBuildSteps);
    setUnitBuildSteps(newBuildSteps);
  };

  const toggleDeleteBuildStep = (buildStepId) => {
    api
      .delete(`Inventory/toggleInventoryStepActive/${buildStepId}`)
      .then((r) => {
        if (!r.data.success) {
          Alert.error("There was an issue deleting this build step.");
          return;
        }
        getBuildSteps();
      });
  };

  return (
    <Container className="mt-2" fluid>
      <Card>
        <CardHeader>
          <CardTitle>
            <h4>Manage Build Steps</h4>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="d-flex align-items-center ms-0 mb-2">
            <Col>
              <Button
                className="bg-success border-success float-end"
                onClick={handleEditOpen}>
                <FontAwesomeIcon
                  icon={`${showCreateProcess ? "arrow-right" : "plus"}`}
                />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {unitBuildSteps ? (
                <UnitBuildStepList
                  buildSteps={unitBuildSteps}
                  editBuildStep={editBuildStep}
                  toggleActiveCallback={toggleDeleteBuildStep}
                  useDragHandle
                  onSortEnd={onSortEnd}
                  showCreateProcess={showCreateProcess}
                />
              ) : null}
            </Col>
            {showCreateProcess ? (
              <Col>
                <Card>
                  <CardBody>
                    <Form className="my-3">
                      <FormGroup>
                        <Label for="processName">Inventory Step Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="processName"
                          value={unitBuildStep.name}
                          onChange={(e) =>
                            onUnitBuildStepChange(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={unitBuildStep.contactCustomer}
                            onChange={(e) =>
                              onUnitBuildStepChange(
                                "contactCustomer",
                                e.target.checked
                              )
                            }
                          />{" "}
                          Notify customer upon completion of this Step?
                        </Label>
                      </FormGroup>
                      {unitBuildStep.contactCustomer ? (
                        <FormGroup>
                          <Row className="my-2">
                            <Col>
                              <FormGroup>
                                <Label for="processName">
                                  Notification Type
                                </Label>
                                <Select
                                  value={unitBuildStep.customerNotificationType}
                                  options={customerNotificationTypeList}
                                  onChange={(x) =>
                                    onUnitBuildStepChange(
                                      "customerNotificationType",
                                      x
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="my-2">
                            <Col>
                              <FormGroup className="mb-1">
                                {showNameSelect ? (
                                  <div>
                                    <Label for="processName">
                                      Select Template To Send
                                    </Label>
                                    <Select
                                      options={templateDescriptions}
                                      onChange={(value) =>
                                        onUnitBuildStepChange(
                                          "notificationTemplate",
                                          value.value
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <Label for="processName">Name</Label>
                                    <Input placeholder="Enter name of new template here"></Input>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col>
                              <FormGroup>
                                <Label>Template Text (HTML format)</Label>
                                <Input
                                  className="mt-1"
                                  type="textarea"
                                  style={{ height: "300px" }}
                                  name="template"
                                  id="template"
                                  placeholder="Provide your template content here"
                                  value={unitBuildStep.notificationTemplate}
                                  onChange={(e) =>
                                    onUnitBuildStepChange(
                                      "notificationTemplate",
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      ) : null}
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <Button
                            className="my-2 bg-success border-success"
                            onClick={saveUnitBuildStep}>
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}
