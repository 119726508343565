import React, { Component, useEffect, useState, useContext, Fragment  } from 'react';
import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Label,
    Table,
    Input,
    ButtonGroup
} from "reactstrap";
import { api, ui_helpers, filter_helpers, date_helpers } from "../helpers";
import {
    FilterSet,
    TextFilter,
    UnmappedFilter,
    UserAddEdit,
    Header,
    Footer,
    ResultHeader,
    FilterSearch, ActiveToggleFilter, Pager, AdminHeader, Loading
} from "../components";
import { Users, Companies, Manufacturers, Stores } from "../pages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert-v3";
import classnames from 'classnames';
import Select from "react-select";
import {Link, Redirect} from 'react-router-dom';
import _ from "lodash";
import { RadioGroup, Radio } from "react-radio-group";
import { UserContext, constants } from "../utils";

export default function TaxRates(props) {
    const PATHNAMES = constants.PATH_NAMES;
    // const [activeTab, setActiveTab] = useState(props.location.state ? props.location.state.tab : constants.ADMIN_TAB_INDEXES.TAX_RATES);
    const [taxRates, setTaxRates] = useState([]);
    const [filters, setFilters] = useState([]);
    const [states, setStates] = useState([]);
    const [pagedList, setPagedList] = useState(null);
    const [sortColumn, setSortColumn] = useState("Id");
    const [sortAscending, setSortAscending] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [newUser, setNewUser] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [stores, setStores] = useState([]);
    const [driversDispatchers, setDriversDispatchers] = useState([]);
    const [allUsers, setAllUsers] = useState("allUsers");
    const [showOptions, setShowOptions] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState('');

    const userCtx = useContext(UserContext);
    const isSysAdmin = ui_helpers.isSystemAdministrator(userCtx.currentUser);
    const isMfgAdmin = ui_helpers.isAnyManufacturerAdministrator(userCtx.currentUser);
    const isStoreAdmin = ui_helpers.isAnyDealerAdministrator(userCtx.currentUser);
    useEffect(() => {
      let isActive = true;
      refreshList();
      getCompanies(isActive);
      getManufacturers(isActive);
      getAllStates(isActive);
      getStores(isActive);
      return () => {
        isActive = false;
      };
    }, []);

    useEffect(() => {
        if (!pageNumber || (pagedList && pagedList.page === pageNumber)) return;
        refreshList(null, null, filters, pageNumber);
    }, [pageNumber])

    function getAllStates(isActive) {
      api.fetch("reference/RegionList").then(response => {
        if (isActive) {
          setStates(ui_helpers.idNameToValueLabel(response.data.data));
        }
      }).catch(error => Alert.error("There was an error loading the states list."));
    }

    function getTableRowColor(regionId) {
      if (!regionId) {
        return { backgroundColor: "#F2DEDE"};
      }
      return {};
    }

    function refreshList(newSortColumn, newSortAscending, newFilters, pageNumber) {
        if(isLoading) return;
        setIsLoading(true);
        const resolvedFilters = newFilters || filters;
        const resolvedSort = {
          sortColumn: newSortColumn || sortColumn,
          sortAscending: newSortAscending || sortAscending
        };
        const payload = {
          unmappedOnly: null,
          name: null,
          state: null,
          city: null,
          county: null,
          ...resolvedSort,
          ...filter_helpers.filtersToObject(resolvedFilters),
          pageSize: 100,
          page: pageNumber || (pagedList ? pagedList.pageNumber : 1)
        };
        api.post("reference/TaxRateList", payload).then(response => {
         let {sortColumn, sortAscending} = resolvedSort;
         setSortColumn(sortColumn);
         setSortAscending(sortAscending);
         setPageNumber(response.data.data.pageNumber);
         setTaxRates(response.data.data.dataSource);
         setPagedList({
           // list: ui_helpers.addIsEditingField(response.data.data.dataSource),
           page: response.data.data.pageNumber,
           pageSize: response.data.data.pageSize,
           totalItems: response.data.data.totalItems
         });
         setFilters(resolvedFilters);
        }).catch(error =>
            Alert.error("There was an error loading the company list")
        ).finally(() => setIsLoading(false));
    }

    function filterChange(changed_filter) {
        const newFilters = filter_helpers.getFilters(
            filters,
            changed_filter
        );
        setFilters(newFilters);
        refreshList(null, null, newFilters, 1);
    }

    function clearFilters() {
        const newFilters = [];
        setFilters(newFilters);
        refreshList(null, null, newFilters);
    }

    // function getRegionForTaxRate(regionId) {
    //     const selected_option = _.find(states, x => x.value === regionId);
    //     if (selected_option) {
    //       return [selected_option];
    //     }
    //     return null;
    // }

    function onChangeTaxRateById(fieldName, newValue, taxRateId) {
      let newTaxRates = taxRates.slice();
      let rate = _.find(newTaxRates, x => x.id === taxRateId);
      if (fieldName === 'region') {
        rate.region = newValue.value;
        rate.regionId = newValue.value;
      } else {
        rate[fieldName] = newValue;
      }
      setTaxRates(newTaxRates);
    }

    function saveTaxRate(taxRate) {
      api.post("reference/EditTaxRate", taxRate).then(response => {
        onChangeTaxRateById('isEditing', false, taxRate.id);
      }).catch(error => Alert.error("There was an error saving the tax rate."));
    }

    function getCompanies(isActive) {
      api.fetch("reference/CompanyList").then(response => {
        if(isActive) {
          setCompanies(ui_helpers.idNameToValueLabel(response.data.data));
        }
      }).catch(error => Alert.error("There was an error loading the company list."));
    }

    function getManufacturers(isActive) {
      api.fetch("reference/ManufacturerList").then(response => {
        if(isActive) {
          setManufacturers(ui_helpers.idNameToValueLabel(response.data.data));
        }
      }).catch(error => Alert.error("There was an error loading the manufacturer list."));
    }

    function getStores(isActive) {
        api
            .fetch("reference/StoreList")
            .then(response => {
               if(isActive) {
                   setStores(ui_helpers.idNameToValueLabel(response.data.data));
               }
            })
            .catch(error => Alert.error("There was an error loading the store list."));
    }

    function getSelectedCompany(company) {
        if (!company) return null;
        const selected_option = _.find(companies, x => x.label === company.label);
        if (selected_option) {
            return [selected_option];
        }
        return null;
    }

    function setTheSelectedCompany(option) {
        const data = {
            "companyId": option.value,
            "allUsers": allUsers === "allUsers" ? true : false
        }
        api
            .post(`reference/CompanyDriversDispatchers`, data)
            .then(response => {
                // this.setState({
                //     driversDispatchers: response.data.data,
                //     showOptions: false,
                //     selectedCompany: {
                //         value: option.value,
                //         label: option.label
                //     }
                // });
                setDriversDispatchers(response.data.data);
                setShowOptions(false);
                setSelectedCompany({
                    value: option.value,
                    label: option.label
                });
            })
            .catch(error => Alert.error("There was an error loading the user list"));
    }

    function getSelectedManufacturer(manufacturer) {
        if (!manufacturer) {
            return null;
        }
        const selected_option = _.find(manufacturers, x => x.label === manufacturer.label);
        if (selected_option) {
            return [selected_option];
        }
        return null;
    }

    function setTheSelectedManufacturer(option) {
        const data = {
            "manufacturerId": option.value,
            "allUsers": allUsers === "allUsers" ? true : false
        }
        api
            .post(`reference/MfgDriversDispatchers`, data)
            .then(response => {
                // this.setState({
                //     driversDispatchers: response.data.data,
                //     showOptions: false,
                //     selectedManufacturer: {
                //         value: option.value,
                //         label: option.label
                //     }
                // });
                setDriversDispatchers(response.data.data);
                setShowOptions(false);
                setSelectedManufacturer({
                    value: option.value,
                    label: option.label
                });
            })
            .catch(error => Alert.error("There was an error loading the user list"));
    }

    function getSelectedStore(store) {
        if (!store) return null;
        const selected_option = _.find(stores, x => x.label === store.label);
        if (selected_option) {
            return [selected_option];
        }
        return null;
    }

    function setTheSelectedStore(option) {
        const data = {
            "dealerId": option.value,
            "allUsers": allUsers === "allUsers" ? true : false
        }
        api
            .post(`reference/StoreDriversDispatchers`, data)
            .then(response => {
                // this.setState({
                //     driversDispatchers: response.data.data,
                //     showOptions: false,
                //     selectedStore: {
                //         value: option.value,
                //         label: option.label
                //     }
                // });
                setDriversDispatchers(response.data.data);
                setShowOptions(false);
                setSelectedStore({
                    value: option.value,
                        label: option.label
                });
            })
            .catch(error => Alert.error("There was an error loading the user list"));
    }

    function clearSelected() {
        // this.setState(prevState => ({
        //     ...prevState,
        //     selectedStore: null,
        //     selectedCompany: null,
        //     selectedManufacturer: null,
        //     showOptions: true,
        //     driversDispatchers: []
        // }));
        setSelectedStore(null);
        setSelectedCompany(null);
        setSelectedManufacturer(null);
        setShowOptions(true);
        setDriversDispatchers([]);
    }

    function handleRoleChange(value, userId, role) {
        const userRoleId = _.find(constants.roles, x => x.label === role).value;
        const theSelectedCompany = selectedCompany;
        const theSelectedManufacturer = selectedManufacturer;
        const theSelectedStore = selectedStore;
        if (value) {
            //remove role         
            const userRoles = _.find(driversDispatchers, x => x.id === userId).roles;
            const roleId = _.find(userRoles, x => x.typeOfUserRole === userRoleId).id;
            const data = {
              "typeOfUserRole": userRoleId,
              "userId": userId,
              "userRoleId": roleId,
              "manufacturerId": theSelectedManufacturer ? theSelectedManufacturer.value : null,
              "companyId": theSelectedCompany ? theSelectedCompany.value : null,
              "dealerId": theSelectedStore ? theSelectedStore.value : null,
              "allUsers": allUsers === "allUsers" ? true : false
            };
            api.post("reference/RemoveDriverDispatcherRole", data).then(r => {
                    if (r.data.success) {
                        if (theSelectedCompany !== null) {
                            setTheSelectedCompany(theSelectedCompany);
                        }
                        if (theSelectedManufacturer !== null) {
                            setTheSelectedManufacturer(theSelectedManufacturer);
                        }
                        if (theSelectedStore !== null) {
                            setTheSelectedStore(theSelectedStore);
                        }
                    } else {
                        Alert.warning("There was an error saving the new role.");
                    }
                });
        } else {
            const data = {
                "typeOfUserRole": userRoleId,
                "userId": userId,
                "manufacturerId": theSelectedManufacturer ? theSelectedManufacturer.value : null,
                "companyId": theSelectedCompany ? theSelectedCompany.value : null,
                "dealerId": theSelectedStore ? theSelectedStore.value : null,
                "allUsers": allUsers === "allUsers" ? true : false
            };
            //add role
            api.post("reference/AddDriverDispatcherRole", data)
                .then(r => {
                    if (r.data.success) {
                        if (theSelectedCompany !== null) {
                            setTheSelectedCompany(theSelectedCompany);
                        }
                        if (theSelectedManufacturer !== null) {
                            setTheSelectedManufacturer(theSelectedManufacturer);
                        }
                        if (theSelectedStore !== null) {
                            setTheSelectedStore(theSelectedStore);
                        }
                    } else {
                        Alert.warning("There was an error saving the new role.");
                    }
                });


        }
    }

    useEffect(() => {
        if(ui_helpers.isSystemAdministrator(userCtx.currentUser)) return;
        setRedirect('/');
    }, []);

    if(redirect) {
        return <Redirect to={redirect}/>
    }

  return (<>
    <AdminHeader taxes/>
    <div className='inner'>
    <div className="inner-white d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex justify-content-center">
          <div className="me-0 rounded">
          <Button
            tag={Link}
            to={PATHNAMES.ADMIN_USERS}
            className="cf-sidebar"
            active={props.users}>
            USERS
          </Button>
          <Button
            tag={Link}
            to={PATHNAMES.ADMIN_COMPANIES}
            className="cf-sidebar"
            active={props.companies}>
            COMPANIES
          </Button>
          {isSysAdmin || isMfgAdmin ? (
            <Button
              tag={Link}
              to={PATHNAMES.ADMIN_MANUFACTURERS}
              className="cf-sidebar"
              active={props.mfg}>
              MANUFACTURERS
            </Button>
          ) : null}
          {isSysAdmin || isStoreAdmin ? (
            <Button
              tag={Link}
              to={PATHNAMES.ADMIN_STORES}
              className="cf-sidebar"
              active={props.stores}>
              STORES
            </Button>
          ) : null}
          {isSysAdmin ? (
            <Fragment>
              <Button
                tag={Link}
                to={PATHNAMES.ADMIN_TAXRATES}
                className="cf-sidebar"
                active={props.taxes}>
                TAX RATES
              </Button>
              <Button
                tag={Link}
                to={PATHNAMES.ADMIN_REGIONS}
                className="cf-sidebar"
                active={props.regions}>
                STATES
              </Button>
            </Fragment>
          ) : null}
        </div>
        </div>
        </div>
    <Card>
        <FilterSet
            filters={filters}
            clearFilters={clearFilters}
            action={refreshList}
        >
            <Col sm={3}>
                <TextFilter
                    filterName="name"
                    displayName="Name"
                    onChangeCallback={filterChange}
                />
            </Col>
            <Col sm={3}>
                <UnmappedFilter
                    filterName="unmappedOnly"
                    displayName="Unmapped Only"
                    onChangeCallback={filterChange}
                />
            </Col>
        </FilterSet>
    </Card>
    <Card>
          <div className='d-flex align-items-center justify-content-between'>
            <ResultHeader heading='Tax Rates' totalResults={pagedList ? pagedList.totalItems : 0}/>
            <Pager pageNumber={pageNumber} pageSize={pagedList ? pagedList.pageSize : 0}
            totalResults={pagedList ? pagedList.totalItems : 0}
            onSetPageCallback={setPageNumber}/>
          </div>
                <Table hover>
                        <thead>
                        <tr>
                            <th>Region</th>
                            <th>Zone</th>
                            <th>Description</th>
                            <th>State</th>
                            <th>County</th>
                            <th>City</th>
                            <th>Tax Rate</th>
                            <th>State Rate</th>
                            <th>County Rate</th>
                            <th>City Rate</th>
                            <th>Last Imported</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading
                            ? <tr><td><Loading/></td></tr>
                            : (<>
                                {taxRates
                                    ? (
                                        _.map(taxRates, tr => (
                                            <tr key={`tax-rate-${tr.id}`} style={getTableRowColor(tr.regionId)}>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <Select
                                                          options={states}
                                                          value={_.find(states, x => x.label === tr.regionName)}
                                                          onChange={(option) => onChangeTaxRateById('region', option, tr.id)}
                                                        />
                                                    ) : tr.regionName}
                                                </td>
                                                <td>{tr.zone}</td>
                                                <td>{tr.dsc}</td>
                                                <td>{tr.stateName}</td>
                                                <td>{tr.county}</td>
                                                <td>{tr.city}</td>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <Input
                                                          type="text"
                                                          name="taxRateValue"
                                                          value={tr.taxRateValue}
                                                          onChange={(event) => onChangeTaxRateById('taxRateValue', event.target.value, tr.id)}
                                                        />
                                                    ) : tr.taxRateValue}
                                                </td>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <Input
                                                            type="text"
                                                            name="stateRate"
                                                            value={tr.stateRate}
                                                            onChange={(event) => onChangeTaxRateById('stateRate', event.target.value, tr.id)}
                                                        />
                                                    ) : tr.stateRate}
                                                </td>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <Input
                                                            type="text"
                                                            name="countyRate"
                                                            value={tr.countyRate}
                                                            onChange={(event) => onChangeTaxRateById('countyRate', event.target.value, tr.id)}
                                                        />
                                                    ) : tr.countyRate}
                                                </td>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <Input
                                                            type="text"
                                                            name="cityRate"
                                                            value={tr.cityRate}
                                                            onChange={(event) => onChangeTaxRateById('cityRate', event.target.value, tr.id)}
                                                        />
                                                    ) : tr.cityRate}
                                                </td>
                                                <td>{date_helpers.formatDateToShortDate(tr.lastReplicatedInAt)}</td>
                                                <td>
                                                    {tr.isEditing ? (
                                                        <ButtonGroup className="float-end">
                                                            <Button
                                                                size="sm"
                                                                className="btn-success cf-success"
                                                                style={{color:"white"}}
                                                                onClick={() => saveTaxRate(tr)}
                                                            >
                                                                <FontAwesomeIcon icon="save" />
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                className="btn-secondary"
                                                                onClick={() => onChangeTaxRateById('isEditing', false, tr.id)}
                                                            >
                                                                <FontAwesomeIcon icon="times-circle" />
                                                            </Button>
                                                        </ButtonGroup>
                                                    ) : (
                                                        <Button
                                                            size="sm"
                                                            className="float-end btn-dark"
                                                            onClick={() => onChangeTaxRateById('isEditing', true, tr.id)}
                                                        >
                                                            <FontAwesomeIcon icon="edit" />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    : null}
                            </>)}
                        </tbody>
                    </Table>
                {/*<Card style={{ borderColor: "transparent" }}>*/}
                {/*    <Row>*/}
                {/*        <Col xs="12">*/}
                {/*                    <Row>*/}
                {/*                        <Col sm="12">*/}
                {/*                            <Card style={{ borderColor: "transparent" }}>*/}
                {/*                                <CardHeader style={{ borderColor: "transparent" }}>*/}
                {/*                                    <Row>*/}
                {/*                                        <Col>*/}
                {/*                                            <a href='/' className="btn btn-dark btn-sm float-end">*/}
                {/*                                                <FontAwesomeIcon icon="home" />*/}
                {/*                                            </a>*/}
                {/*                                            <h2 className="page-title">Tax Rates</h2>*/}
                {/*                                        </Col>*/}
                {/*                                    </Row>*/}
            </Card>
            </div>
            <Footer/>
            </>
        );
    }




